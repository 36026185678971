import moment from "moment";
import cogoToast from "cogo-toast";

export const formatAmount = (value: number, currency = '€') : string => {
    return `${formatNumber(value)} ${currency}`
};

export const formatKilometers = (value: number, suffix = 'km') : string => {
    return `${formatNumber(value)} ${suffix}`
};

export const formatNumber = (value: number) : string => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const formatDate = (value: string) : string => {
    if (value === null || value === undefined) {
        return ""
    }

    return moment(value).format('DD/MM/YYYY');
};

export const formatHour = (value: string) : string => {
    if (value === null || value === undefined) {
        return ""
    }
    return moment(value).format('HH:mm:ss');
};


export const stringFormat = (str: string, ...args: string[]) : string =>
    str.replace(/{(\d+)}/g, (match, index) => args[index] || '');


export const stringToFloat = (str: string, separator: string = ' ') : number => {
    const value = str.replace(separator, '');
    if (value === "")
        return 0;

    try {
        const parsed = parseFloat(value);
        return parsed;
    } catch (ex) {
        return 0
    }
};

export const initialsByName = (name: string) :string => {
    const namesArray = name.split(' ');
    if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
    else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`;
};

export const successNotifier = (message: string) : void =>  {
    cogoToast.success(message, {hideAfter: 5}).then(r => {});
};

export const errorNotifier = (message: string) : void => {
    cogoToast.error(message, {hideAfter: 10}).then(r => {});
};
