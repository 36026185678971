import React, {useState} from 'react';
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import {QRY_SALES_SEARCH, QRY_SALES_PROCESS_SEARCH} from "./queries";
import SalesSearch from "./components/SalesSearch";
import CustomerForm from "./components/CustomerForm";
import {
    companySetByData,
    customerReset,
    customerSetLookupDone,
    customerSetSalesProcessData,
    individualSetByData
} from "./data";

const Customer = ({dispatch, customer, validationErrors, showAllErrors}) => {
    const [salesSearchData, setSalesSearchData] = useState(null);
    const [salesProcessSearchData, setSalesProcessSearchData] = useState(null);
    const [errors, setErrors] = useState(null);
    const [selectedSales, setSelectedSales] = useState(null);
    const [selectedSalesProcess, setSelectedSalesProcess] = useState(null);
    const searchCompleted = customer.lookupDone;

    const [salesSearch, {loading: salesSearchLoading, error: salesSearchError}] = useLazyQuery(
        QRY_SALES_SEARCH,
        {
            fetchPolicy: 'network-only',
            onCompleted: result => {
                if (result && result.salesSearch && result.salesSearch.errors.length === 0 && (result.salesSearch.companies.length > 0 || result.salesSearch.individuals.length > 0)) {
                    setSalesSearchData(result.salesSearch);
                    setErrors(null);

                } else if (result.salesSearch.errors.length > 0) {
                    setSalesSearchData({individuals: [], companies: []});
                    setErrors(result.salesSearch.errors);

                } else {
                    setSalesSearchData({individuals: [], companies: []});
                    setErrors(null);
                }
            }
        }
    );

    const [salesProcessSearch, {loading: salesProcessSearchLoading, error: salesProcessSearchError}] = useLazyQuery(
        QRY_SALES_PROCESS_SEARCH,
        {
            fetchPolicy: 'network-only',
            onCompleted: result => {
                if (result && result.salesProcessSearch && result.salesProcessSearch.errors.length === 0 && result.salesProcessSearch.processes.length > 0) {
                    setSalesProcessSearchData(result.salesProcessSearch);
                    setErrors(null);
                } else if (result.salesProcessSearch.errors.length > 0) {
                    setSalesProcessSearchData({processes: []});
                    setErrors(result.salesProcessSearch.errors);
                } else {
                    setSalesProcessSearchData({processes: []});
                    setErrors(null);
                    setData(selectedSales);
                }
            }
        }
    );




    const handleSalesSearch = (firstName, lastName, companyName, postalCode) => {
        setSalesSearchData(null);
        setSalesProcessSearchData(null);
        setSelectedSales(null);
        setSelectedSalesProcess(null);
        customerSetLookupDone(dispatch, false);

        salesSearch({
            variables: {
                firstName: firstName,
                lastName: lastName,
                companyName: companyName,
                postalCode: postalCode,
            }
        });
    };

    const handleCreateManual = () => {
        customerSetLookupDone(dispatch, true);
    };

    const handleSelectSales = (data) => {
        setSelectedSales(data);

        let individualKey = "";
        let companyKey = "";
        if (data.individual) {
            individualKey = data.individual.key
        } else if (data.company) {
            companyKey = data.company.key;
        }

        if (individualKey !== "" || companyKey !== "") {
            salesProcessSearch({
                variables: {
                    individualKey,
                    companyKey
                }
            })
        }

    };

    const handleSelectSalesProcess = (data) => {
        setSelectedSalesProcess(data);
        setData(selectedSales, data);

    };

    const setData = (sales, salesProcess) => {
        if (sales && sales.individual) {
            const {individual} = sales;
            individualSetByData(dispatch,
                individual.key,
                individual.origin,
                individual.title || "",
                individual.lastName,
                individual.firstName,
                individual.address.street,
                individual.address.number,
                individual.address.box,
                individual.address.locality.postalCode,
                individual.address.locality.city,
                individual.address.country.code,
                individual.phone || "",
                individual.mobile || "",
                individual.email || "",
                individual.language || "",
            );
        } else if (sales && sales.company) {
            const {company} = sales;
            companySetByData(dispatch,
                company.key || "",
                company.origin,
                company.name,
                company.vat,
                company.address.street,
                company.address.number,
                company.address.box,
                company.address.locality.postalCode,
                company.address.locality.city,
                company.address.country.code,
                company.telephone || "",
                company.email || "",
                company.legalForm || "",
            );
        }

        if (salesProcess !== null && salesProcess !== undefined) {
            customerSetSalesProcessData(dispatch, salesProcess)
        }

        customerSetLookupDone(dispatch, true);
    };

    const resetAll = () => {
        setSalesSearchData(null);
        setSalesProcessSearchData(null);
        setSelectedSales(null);
        setSelectedSalesProcess(null);
        customerSetLookupDone(dispatch, false);
        customerReset(dispatch)
    };



    if (!searchCompleted) {
        const allErrors = errors || [];
        if (salesSearchError)
            allErrors.push(salesSearchError.message);

        if (salesProcessSearchError)
            allErrors.push(salesProcessSearchError.message);

        return (
            <div className="w-full">

                <SalesSearch
                    individuals={(!salesSearchData) ? null : (salesSearchData && salesSearchData.individuals) ? salesSearchData.individuals : []}
                    companies={(!salesSearchData) ? null : (salesSearchData && salesSearchData.companies) ? salesSearchData.companies : []}
                    processes={(!salesProcessSearchData) ? null : (salesProcessSearchData && salesProcessSearchData.processes) ? salesProcessSearchData.processes : []}
                    onSalesSearch={handleSalesSearch}
                    onSelectSales={handleSelectSales}
                    onSelectSalesProcess={handleSelectSalesProcess}
                    selectedSales={selectedSales}
                    selectedSalesProcess={selectedSalesProcess}
                    loading={salesSearchLoading || salesProcessSearchLoading}
                    errors={errors}
                    onCreateManual={handleCreateManual}
                    onResetSelectedSales={() => {
                        setSalesSearchData(null);
                        customerSetLookupDone(dispatch, false);
                    }}
                    onResetSelectedSalesProcess={() => {
                        setSalesProcessSearchData(null);
                        setSelectedSales(null);
                    }}
                />
            </div>
        )
    }

    return (
        <div className="w-full">
            {/*<div*/}
            {/*    className="flex flex-row justify-between p-2 pl-0 border border-gray-300 border-t-0 border-l-0 border-r-0 font-medium mb-2">*/}

            {/*    <div>*/}
            {/*        <i className="fas fa-cloud"/>*/}
            {/*        <span>{customer.origin}</span>*/}
            {/*        <span>{customer.process.brand}</span>*/}
            {/*    </div>*/}

            {/*    <div>*/}
            {/*        <i onClick={resetAll} className="fal fa-bolt cursor-pointer"*/}
            {/*           style={{fontSize: 14}}/>*/}
            {/*    </div>*/}

            {/*</div>*/}
            <CustomerForm customer={customer}
                          dispatch={dispatch}
                          errors={validationErrors}
                          showAllErrors={showAllErrors}
            />
        </div>
    )


};

Customer.defaultProps = {
    validationErrors: [],
    showAllErrors: false,
};


export default Customer;