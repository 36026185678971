import React from 'react'

const IndividualItem = ({individual: {title, firstName, lastName, mobile, telephone, email, address}, onClick}) => {


    return (
        <div onClick={onClick}
             className={`bg-gray-100 text-gray-900 hover:bg-blue-400 hover:text-white flex flex-row px-2 py-2 mb-1 mr-1 cursor-pointer group`}>

            <div className="flex items-center ml-2 mr-4">
                <i className="fas fa-user text-3xl"></i>
            </div>

            <div>
                <div className="font-medium text-sm  mr-1 text-blue-500 group-hover:text-white">
                    {title} {firstName} {lastName}
                </div>
                <div>
                    {`${address.street} ${address.number} ${(address.bus) ? '/' : ''} ${(address.bus) ? address.bus : ""}`}
                </div>
                <div>
                    {`${address.locality.postalCode} ${address.locality.city}  (${address.country.code})`}
                </div>
            </div>

            <div className="flex items-center ml-4">
                {email}
            </div>
            <div className="flex items-center ml-4">
                {mobile} {telephone}
            </div>

        </div>
    );
};

export default IndividualItem;