import React from 'react';
import {loadMore} from './utils';
import {camelCase} from 'lodash';
import {withApollo} from "react-apollo";
import CustomQuery from './Query';

const withQuery = injectedProps => WrappedComponent => {
    const WithQuery = props => {
        return (

            <CustomQuery query={injectedProps.query}
                   fetchPolicy={injectedProps.fetchPolicy}
                   variables={(typeof injectedProps.variables === "function") ? injectedProps.variables(props) : injectedProps.variables}
                   notifyOnNetworkStatusChange
            >
                {(data) => {

                    let updateName = '';
                    if (injectedProps.query.definitions[0].name !== undefined) {
                        updateName = camelCase(injectedProps.query.definitions[0].name.value);
                    }
                    return <React.Fragment>

                        <WrappedComponent
                            {...props}
                            data={{...props.data, ...data.data}}

                            loadMore={() => loadMore(data, updateName)} />

                        {data.loading && data.networkStatus === 3 &&
                        <div> loading more ... </div>}

                    </React.Fragment>
                }}
            </CustomQuery>
        )
    };

    return withApollo(WithQuery);
};

export default withQuery;




