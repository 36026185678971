import React from 'react';

export default class DefaultContent extends React.Component {

    render() {
        return (
            <div>
                <p>Content component not implemented yet</p>
            </div>
        );
    }
}

