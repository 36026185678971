import ReactSVG from "react-svg";
import React, {useMemo} from "react";
import {some, filter} from 'lodash';

import ClipLoader from "react-spinners/ClipLoader";

const LocationThumbnail = ({uniqueId, onClick, onDragOver, svgFileName, damages}) => {
    const damage_exists = some(damages, ['item', uniqueId]);
    const total = (filter(damages, d => d.item === uniqueId)).length;

    const thumb = useMemo(() => {

        return (
            <ReactSVG
                renumerateIRIElements={false}
                src={require(`../../../../PUBLIC/damages/${svgFileName}`)}
                className="cursor-pointer"
                beforeInjection={svg => {
                    Object.assign(svg.style, {width: 60, height: 60});
                }}
                loading={() => <ClipLoader
                    sizeUnit={"px"}
                    size={25}
                    color={'black'}
                />}
            />
        )
    }, [svgFileName]);

    return (
        <div key={uniqueId}
             onClick={() => onClick(uniqueId)}
             onDragOver={() => onDragOver(uniqueId)}
             className={`flex flex-1 flex-col items-center justify-center relative mb-2 mr-2 relative rounded ${damage_exists && "bg-red-100"}`}
        >
            {/*{damage_exists && <div className="flex items-center justify-center rounded-full bg-red-500 absolute" style={{right: 5, top: 5, width: 10, height: 10}} />}*/}
            {damage_exists && <div className="flex items-center justify-center rounded-full border border-red-500 absolute text-red-500 font-medium" style={{right: 5, top: 5, width: 15, height: 15, fontSize: 10}} >
                <span>{total}</span>
            </div>}
            {thumb}
        </div>
    )

};

export default LocationThumbnail;

