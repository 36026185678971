import gql from "graphql-tag";

export const UPLOADED_FILE = gql`
 query UploadedFile($id: ID!) {
        uploadedFile(id: $id) {              
            uploadIdentification          
            fullUrl
            thumbnailUrl
        }
   }
`;
