import gql from "graphql-tag";


export const TOKEN_AUTH = gql`
 mutation($username: String!, $password: String!) {
        jwtTokenAuthHandler(username: $username, password: $password ) {
          token
          refreshToken
          user {
            id
            firstName
            lastName
            email
            username
          }       
        }
      }
`;

export const TOKEN_REFRESH = gql`
 mutation($refreshToken: String!) {
     jwtTokenRefreshHandler(refreshToken: $refreshToken) {
        token    
        payload
     }        
 }
`;

export const TOKEN_REVOKE = gql`
 mutation($refreshToken: String!) {
     jwtTokenRevokeHandler(refreshToken: $refreshToken) {
        revoked
  }
 }
`;