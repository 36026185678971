import React, {useState} from 'react';
import {map} from "lodash";
import {formatDate} from "../../../../../../../general/utils";
import {v4} from 'uuid';
import {useTranslate} from "../../../../../../../translations";
import moment from 'moment';


const history = [
    {
        id: v4(),
        type: 'not_interested',
        name:'AlphaCars',
        date: null,
    },
    {
        id: v4(),
        type: 'offered',
        name:'BetaCars',
        date: null,
    },
    {
        id: v4(),
        type: 'outfiltered',
        name:'KloddeCars',
        date: null,
    },
    {
        id: v4(),
        type: 'no_response',
        name:'DKMA',
        date: null,
    },
    {
        id: v4(),
        type: 'not_interested',
        name:'Autralis',
        date: null,
    },
];


const TraderHistory = () => {
    const {getText} = useTranslate();

    const mapToColor = (type) => {
        switch (type) {
            case 'not_interested':
                return 'black';
            case 'offered':
                return 'green-500';
            case 'outfiltered':
                return 'red-300';
            case 'no_response':
                return 'gray-300';
            default:
                return 'black';
        }
    };

    return (
        <div className="">
            <div className="flex flex-row bg-gray-100 p-2">
                <div className="w-full flex flex-row">
                    <div className="flex flex-1 flex-row justify-end items-center">
                        <div className="flex flex-row mr-4 items-center">
                            <div style={{width: 8, height: 8, borderRadius: 4}} className="bg-black mr-1"/>
                            <span>{getText("Not interested")}</span>
                        </div>
                        <div className="flex flex-row mr-4 items-center">
                            <div style={{width: 8, height: 8, borderRadius: 4}} className="bg-green-500 mr-1"/>
                            <span>{getText("Offered")}</span>
                        </div>
                        <div className="flex flex-row mr-4 items-center">
                            <div style={{width: 8, height: 8, borderRadius: 4}} className="bg-red-500 mr-1"/>
                            <span>{getText("Outfiltered")}</span>
                        </div>
                        <div className="flex flex-row items-center">
                            <div style={{width: 8, height: 8, borderRadius: 4}} className="bg-gray-300 mr-1"/>
                            <span>{getText("No response yet")}</span>
                        </div>
                    </div>
                </div>
            </div>

            {map(history, (historyItem, i) => {
                const bgColor = ((i % 2) === 0) ? 'bg-white' : "bg-gray-100";

                return (
                    <div key={`seller_history_${historyItem.id}`} className={`p-2 border border-gray-200 border-solid border-r-0 border-l-0 border-t-0 ${bgColor}`}>
                        <div className="flex flex-row">
                            <div className="flex flex-col justify-center mr-2 items-center" style={{width: 55}}>
                                <div style={{width: 10, height: 10, borderRadius: 5}} className={`bg-${mapToColor(historyItem.type)}`}/>
                            </div>
                            <div className="w-2/3 flex flex-col justify-center">
                                <div className="flex flex-row mb-1">
                                   <span className="">{historyItem.name}</span>
                                </div>
                            </div>
                            <div className="w-1/3 flex flex-row items-center justify-end">
                                <span className=" ">{formatDate(moment())}</span>
                            </div>
                        </div>
                    </div>
                )
            })}

        </div>
    )
};

export default TraderHistory;
