import React, {Component} from 'react';
import {Button} from "../../../general";

class HeaderFilters extends Component {
    render() {
        const {filters, removeFilter} = this.props;
        if (filters === null || filters === undefined) return null;

        return (
            <div className="flex flex-row bg-white p-2">
                {Object.keys(filters).map(filter => {
                    if(filter === "searchTerm") return null;
                    return filters[filter].map(item => {
                        const id = item.id;
                        return (
                            <div key={id} className="capitalize mr-2">
                                {item.name.includes("LOCKED_") ? "Locked" : item.name.toLowerCase()}
                                <Button className="ml-1" onClick={(e) => removeFilter(e, filter, id) }><i className="fal fa-times" /></Button>
                            </div>
                        )
                    })

                })}
            </div>
        );
    }
}

export default HeaderFilters;