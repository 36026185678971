import {emptyModuleConfig} from "../common";


const MODULE_NAME = 'selfcheck';

export function gatherConfig() {
    const config = emptyModuleConfig(MODULE_NAME);
    config.setMainMenu("SelfCheck", '#23a9d0');

    config.addBlock("Home", "home", "manager", 0, null, null);
    config.addBlock("Inspections", "car", "manager", 0, null, null, );
    config.addBlock("Test", "schedule", "manager", 0, null, null);

    return config;

}


