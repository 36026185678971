import {getText} from "../../../translations";
import {includes, find, upperCase, lowerCase, values} from 'lodash';

export function useLeadStates() {
    const leadStates = {
        new: {
            key: "NEW",
            value: getText('New'),
            mappings: ['CONFIRMED']
        },
        declined: {
            key: "DECLINED",
            value: getText('Declined'),
            mappings: ['VERIFY']
        },
        pending: {
            key: "PENDING",
            value: getText('Pending'),
            mappings: ['BUFFER', 'CONTROL', 'REVIEW']
        },
        offers: {
            key: "OFFERS",
            value: getText('Offers'),
            mappings: ['SELLER', 'SETTLED']
        },
        proposed: {
            key: "PROPOSED",
            value: getText('Proposed'),
            mappings: ['PROPOSED']
        },
        refused: {
            key: "REFUSED",
            value: getText('Refused'),
            mappings: ['REFUSED']
        },
        accepted: {
            key: "ACCEPTED",
            value: getText('Accepted'),
            mappings: ['ACCEPTED']
        },
        archived: {
            key: "ARCHIVED",
            value: getText('History'),
            mappings: ['ARCHIVED']
        }
    };


    const displayStateByLeadState = (state) => {
        const leadState = find(values(leadStates), (leadState) => includes(leadState.mappings, upperCase(state)));
        return (leadState) ? leadState.key : "";
    };
    const leadStatesByDisplayState = (key) => {
        const lowerKey = lowerCase(key);
        if (leadStates[lowerKey]) {
            return leadStates[lowerKey].mappings
        }
        return []
    };

    return {leadStates, displayStateByLeadState, leadStatesByDisplayState};
}