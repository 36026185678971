import React, {Component} from 'react';

class SuccessIndicator extends Component {
    render() {
        const title = this.props.title ? this.props.title : "Success!";
        const description = this.props.description ? this.props.description : "Successfully executed!";

        return (
            <div className="w-full bg-green-100 border-l-4 border-green-500 text-green-600 p-4">
                <p className="font-medium">{title}</p>
                <p>{description}</p>
            </div>
        )
    }
}

export default SuccessIndicator