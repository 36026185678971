import {groupBy, keys, forEach,  orderBy, every} from 'lodash';
import UUID from 'uuid';

const recursiveLevels = [
    {
        key: 'area',
        prefix: 'Area:',
    },
    {
        key: 'zone',
        prefix: 'Zone:',
    },
    {
        key: 'aisle',
        prefix: 'Aisle:',
    },
    {
        key: 'side',
        prefix: 'Side:',
    },
    {
        key: 'col',
        prefix: 'Col:',
    },
    {
        key: 'level',
        prefix: 'Level:',
    },
    {
        key: 'position',
        prefix: 'Position:',
    },

];


const calcTreeState = (data, field) => {

    const allTrue = every(data, [field, true]);
    const allFalse = every(data, [field, false]);

    if (allFalse) {
        return 0;
    }
    else if (allTrue) {
        return 1;
    }
    else {
        return 2;
    }
};

const recursive = (data, parent, level, dummySlot) => {
    const levelKey = recursiveLevels[level];
    if (levelKey === undefined) return;

    const groups = groupBy(data, levelKey.key);


    forEach(orderBy(keys(groups)), (key) => {

        if (key !== 'null') {

            const slot = {...dummySlot, [levelKey.key]: key};

                const node = {
                name: `${levelKey.prefix} ${key}`,
                disabled: calcTreeState(groups[key], 'disabled'),
                print: calcTreeState(groups[key], 'printEntry'),
                slotProperty: levelKey.key,
                slotValue: key,
                slot,
                children: [],
                level: parent.level + 1,
                id: UUID.v4(),
                parent: parent.id,
            };

            recursive(groups[key], node, level + 1, slot);


            if (node.children.length === 0) {
                // this is te last level item -> identify it with the id
                node.slotId = groups[key][0].id
            }

            parent.children.push(node)
        }
        else {
            recursive(groups[key], parent, level + 1, dummySlot);
        }
    });

}

export const convertSlotsToTree = (data) => {
    const nodes = [];
    const compoundAddresses = data.companyAddresses.filter(address => address.compound);


    forEach(compoundAddresses, (ca) => {
        const {address} = ca;
        const slot = {address: {
            addressFull: address.addressFull,
            city: address.city,
            countryCode: address.countryCode,
            id: address.id,
            postCode: address.postCode,
        }};

        const rootNode = {
            name: `${address.addressFull} ${address.postCode} ${address.city}`,
            slotProperty: 'address',
            slotValue: address.id,
            disabled: calcTreeState(address.slots, 'disabled'),
            print: calcTreeState(address.slots, 'printEntry'),
            slot,
            children: [],
            level: 0,
            id: UUID.v4(),
            parent: null,
        };

        recursive(address.slots, rootNode, 0, slot);
        nodes.push(rootNode)
    });
    return nodes;
};

