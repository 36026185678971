import React, {useState, useRef, useEffect} from 'react';
import * as PropTypes from "prop-types";

const TextInput = ({name, value, required, placeholder, disabled, onChange, className, showBorder, hasError, hasFocus, onBlur, onEnter, onEscape}) => {
    const inputRef = useRef(null);
    const [inputHasFocus, setInputHasFocus] = useState(hasFocus);

    useEffect(() => {
        if (hasFocus) {
            inputRef.current.focus();
            inputRef.current.select()
        }
    }, [hasFocus, inputHasFocus]);

    const handleKeyDown = (e) => {
        if (e.keyCode === 13 && onEnter) {
            onEnter(value)
        } else if (e.keyCode === 27 && onEscape) {
            onEscape()
        }
    };

    const borderClass = (showBorder) ? `border ${(hasError) ? "border-red-500" : ((hasFocus) ? "border-blue-500" : (required && !value) ? "border-blue-200" : "border-gray-200")} border-t-0 border-l-0 border-r-0` : '';

    return (
        <input name={name}
               ref={inputRef}
               onFocus={() => setInputHasFocus(true)}
               onBlur={() => {
                   setInputHasFocus(false);
                   if (onBlur) {
                       onBlur()
                   }
               }}
               type="text"
               value={value}
               placeholder={placeholder}
               disabled={disabled}
               onChange={onChange}
               className={`w-full font-normal text-md pt-1 pb-1 text-gray-900 bg-white ${borderClass} ${className}`}
               onKeyDown={handleKeyDown}
        />
    )
};

TextInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string.isRequired,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    showBorder: PropTypes.bool,
    hasError: PropTypes.bool,
    hasFocus: PropTypes.bool,
};

TextInput.defaultProps = {
    name: 'TextInput',
    value: "",
    required: false,
    placeholder: "",
    disabled: false,
    inputRef: null,
    onChange: null,
    className: "",
    showBorder: true,
    hasError: false,
    hasFocus: false,
};

export default TextInput;


