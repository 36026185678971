import React from 'react';
import LoadingIndicator from '../indicators/LoadingIndicator';
import ErrorIndicator from '../indicators/ErrorIndicator';
import SuccessIndicator from '../indicators/SuccessIndicator';

const FormIndicators = ({loading, error, success}) => {

    if (loading) {
        return <LoadingIndicator overlay={true}/>
    }

    const hidden = ((error === '') && !success);

    return (
        <div className={`sticky z-10 -mx-2 mt-2 mb-4 top-0 ${hidden ? 'hidden' : ''}`}>
            {error !== '' && <ErrorIndicator description={error}/>}
            {success && <SuccessIndicator/>}
        </div>


    );
};

FormIndicators.defaultProps = {
    loading: false,
    error: '',
    success: false,
};

export default FormIndicators;



