import React from 'react';
import Buttons from "./Buttons";
import Summary from "./Summary";
import {PicturesComponent} from '../../components';
import WizardAnimation from "./WizardAnimation";

const Pictures = ({onNext, onPrev, onStepClick, wizard, data}) => {

    return (
        <div className="flex h-full w-full">
            <div className="w-1/4">
                <Summary data={wizard} onStepClick={onStepClick}/>
            </div>

            <div className="w-3/4 flex flex-col flex-1 bg-white p-2">
                <div className="flex flex-1 overflow-scroll ">
                    <WizardAnimation className="flex-col">
                        <PicturesComponent pictures={data.inspection.vehicle.pictures}
                                           dispatch={data.dispatch}
                                           pictureTypes={data.pictureTypes}
                        />
                    </WizardAnimation>
                </div>
                <Buttons onNext={onNext}
                         onPrev={onPrev}
                         data={wizard}
                />
            </div>
        </div>
    )
};

export default Pictures;