import {getText} from "../../../../translations";
import UUID from "uuid";


const INITIAL_INDIVIDUAL = {
    title: "",
    lastName: "",
    firstName: "",
    street: "",
    number: "",
    box: "",
    postalCode: "",
    city: "",
    country: "",
    telephone: "",
    mobile: "",
    email: "",
    language: "",
};
const INITIAL_COMPANY = {
    name: "",
    vat: "",
    legalForm: 0,
    street: "",
    number: "",
    box: "",
    postalCode: "",
    city: "",
    country: "",
    telephone: "",
    email: "",
};
const INITIAL_TAXATION = {
    isTaxable: null,
    isCompany: null,
    taxablePercent: 0,
    isHandicapped: null,
    isDiplomat: null,
    hasBelgianLicensePlate: null,
};
const INITIAL_PROCESS = {
    key: "",
    origin: "",
    brand: "",
    state: "",
    date: "",
    leadSalesMan: "",
};

export const customer_initialize = () => {
    return {
        guid: UUID.v4(),
        key: "",
        origin: "",
        individual: {...INITIAL_INDIVIDUAL},
        company: {...INITIAL_COMPANY},
        taxation: {...INITIAL_TAXATION},
        process: {...INITIAL_PROCESS},
        lookupDone: false,
    }
};

const INDIVIDUAL_TYPES = {
    INDIVIDUAL_SET_TITLE: 'INDIVIDUAL_SET_TITLE',
    INDIVIDUAL_SET_LAST_NAME: 'INDIVIDUAL_SET_LAST_NAME',
    INDIVIDUAL_SET_FIRST_NAME: 'INDIVIDUAL_SET_FIRST_NAME',
    INDIVIDUAL_SET_SET_ADDRESS: 'INDIVIDUAL_SET_SET_ADDRESS',
    INDIVIDUAL_SET_ADDRESS_STREET: 'INDIVIDUAL_SET_ADDRESS_STREET',
    INDIVIDUAL_SET_ADDRESS_NUMBER: 'INDIVIDUAL_SET_ADDRESS_NUMBER',
    INDIVIDUAL_SET_ADDRESS_BOX: 'INDIVIDUAL_SET_ADDRESS_BOX',
    INDIVIDUAL_SET_ADDRESS_POSTAL_CODE: 'INDIVIDUAL_SET_ADDRESS_POSTAL_CODE',
    INDIVIDUAL_SET_ADDRESS_CITY: 'INDIVIDUAL_SET_ADDRESS_CITY',
    INDIVIDUAL_SET_ADDRESS_COUNTRY: 'INDIVIDUAL_SET_ADDRESS_COUNTRY',
    INDIVIDUAL_SET_TELEPHONE: 'INDIVIDUAL_SET_TELEPHONE',
    INDIVIDUAL_SET_MOBILE: 'INDIVIDUAL_SET_MOBILE',
    INDIVIDUAL_SET_MAIL: 'INDIVIDUAL_SET_MAIL',
    INDIVIDUAL_SET_LANGUAGE: 'INDIVIDUAL_SET_LANGUAGE',
    INDIVIDUAL_SET_BY_DATA: 'INDIVIDUAL_SET_BY_DATA',
};

const COMPANY_TYPES = {
    COMPANY_SET_NAME: 'COMPANY_SET_NAME',
    COMPANY_SET_VAT: 'COMPANY_SET_VAT',
    COMPANY_SET_SET_ADDRESS: 'COMPANY_SET_SET_ADDRESS',
    COMPANY_SET_ADDRESS_STREET: 'COMPANY_SET_ADDRESS_STREET',
    COMPANY_SET_ADDRESS_NUMBER: 'COMPANY_SET_ADDRESS_NUMBER',
    COMPANY_SET_ADDRESS_BOX: 'COMPANY_SET_ADDRESS_BOX',
    COMPANY_SET_ADDRESS_POSTAL_CODE: 'COMPANY_SET_ADDRESS_POSTAL_CODE',
    COMPANY_SET_ADDRESS_CITY: 'COMPANY_SET_ADDRESS_CITY',
    COMPANY_SET_ADDRESS_COUNTRY: 'COMPANY_SET_ADDRESS_COUNTRY',
    COMPANY_SET_TELEPHONE: 'COMPANY_SET_TELEPHONE',
    COMPANY_SET_MAIL: 'COMPANY_SET_MAIL',
    COMPANY_SET_LEGAL_FORM: 'COMPANY_SET_LEGAL_FORM',
    COMPANY_SET_BY_DATA: 'COMPANY_SET_BY_DATA',
    COMPANY_SET_BY_VIES_DATA: 'COMPANY_SET_BY_VIES_DATA',
};

const TAXATION_TYPES = {
    TAXATION_IS_TAXABLE: 'TAXATION_IS_TAXABLE',
    TAXATION_IS_COMPANY: 'TAXATION_IS_COMPANY',
    TAXATION_TAXABLE_PERCENT: 'TAXATION_TAXABLE_PERCENT',
    TAXATION_IS_HANDICAPPED: 'TAXATION_IS_HANDICAPPED',
    TAXATION_IS_DIPLOMAT: 'TAXATION_IS_DIPLOMAT',
    TAXATION_HAS_BELGIAN_LICENSE_PLATE: 'TAXATION_HAS_BELGIAN_LICENSE_PLATE',
};

const CUSTOMER_TYPES = {
    CUSTOMER_SET_ORIGIN: 'CUSTOMER_SET_ORIGIN',
    CUSTOMER_LOOKUP_DONE: 'CUSTOMER_LOOKUP_DONE',
    CUSTOMER_RESET: 'CUSTOMER_RESET',
    CUSTOMER_SET: 'CUSTOMER_SET',
};

const PROCESS_TYPES = {
    PROCESS_SET_DATA: 'PROCESS_SET_DATA',
}

export const individualReducer = (state, action) => {
    switch (action.type) {
        case INDIVIDUAL_TYPES.INDIVIDUAL_SET_TITLE:
            return {
                ...state,
                title: action.payload.title,
            };
        case INDIVIDUAL_TYPES.INDIVIDUAL_SET_LAST_NAME:
            return {
                ...state,
                lastName: action.payload.lastName,
            };
        case INDIVIDUAL_TYPES.INDIVIDUAL_SET_FIRST_NAME:
            return {
                ...state,
                firstName: action.payload.firstName,
            };
        case INDIVIDUAL_TYPES.INDIVIDUAL_SET_SET_ADDRESS:
            return {
                ...state,
                street: action.payload.street,
                number: action.payload.number,
                box: action.payload.box,
                postalCode: action.payload.postalCode,
                city: action.payload.city,
                country: action.payload.country,
            };
        case INDIVIDUAL_TYPES.INDIVIDUAL_SET_ADDRESS_STREET:
            return {
                ...state,
                street: action.payload.street,
            };
        case INDIVIDUAL_TYPES.INDIVIDUAL_SET_ADDRESS_NUMBER:
            return {
                ...state,
                number: action.payload.number,
            };
        case INDIVIDUAL_TYPES.INDIVIDUAL_SET_ADDRESS_BOX:
            return {
                ...state,
                box: action.payload.box,
            };
        case INDIVIDUAL_TYPES.INDIVIDUAL_SET_ADDRESS_POSTAL_CODE:
            return {
                ...state,
                postalCode: action.payload.postalCode,
            };
        case INDIVIDUAL_TYPES.INDIVIDUAL_SET_ADDRESS_CITY:
            return {
                ...state,
                city: action.payload.city,
            };
        case INDIVIDUAL_TYPES.INDIVIDUAL_SET_ADDRESS_COUNTRY:
            return {
                ...state,
                country: action.payload.country,
            };
        case INDIVIDUAL_TYPES.INDIVIDUAL_SET_TELEPHONE:
            return {
                ...state,
                telephone: action.payload.telephone,
            };
        case INDIVIDUAL_TYPES.INDIVIDUAL_SET_MOBILE:
            return {
                ...state,
                mobile: action.payload.mobile,
            };
        case INDIVIDUAL_TYPES.INDIVIDUAL_SET_MAIL:
            return {
                ...state,
                email: action.payload.email,
            };
        case INDIVIDUAL_TYPES.INDIVIDUAL_SET_LANGUAGE:
            return {
                ...state,
                language: action.payload.language,
            };
        case INDIVIDUAL_TYPES.INDIVIDUAL_SET_BY_DATA:
            return {
                ...state,
                title: action.payload.title,
                lastName: action.payload.lastName,
                firstName: action.payload.firstName,
                street: action.payload.street,
                number: action.payload.number,
                box: action.payload.box,
                postalCode: action.payload.postalCode,
                city: action.payload.city,
                country: action.payload.country,
                mobile: action.payload.mobile,
                telephone: action.payload.telephone,
                email: action.payload.email,
                language: action.payload.language,
            };
        default:
            return state;
    }
};

export const companyReducer = (state, action) => {
    switch (action.type) {
        case COMPANY_TYPES.COMPANY_SET_NAME:
            return {
                ...state,
                name: action.payload.name,
            };
        case COMPANY_TYPES.COMPANY_SET_VAT:
            return {
                ...state,
                vat: action.payload.vat,
            };
        case COMPANY_TYPES.COMPANY_SET_SET_ADDRESS:
            return {
                ...state,
                street: action.payload.street,
                number: action.payload.number,
                box: action.payload.box,
                postalCode: action.payload.postalCode,
                city: action.payload.city,
                country: action.payload.country,
            };
        case COMPANY_TYPES.COMPANY_SET_ADDRESS_STREET:
            return {
                ...state,
                street: action.payload.street,
            };
        case COMPANY_TYPES.COMPANY_SET_ADDRESS_NUMBER:
            return {
                ...state,
                number: action.payload.number,
            };
        case COMPANY_TYPES.COMPANY_SET_ADDRESS_BOX:
            return {
                ...state,
                box: action.payload.box,
            };
        case COMPANY_TYPES.COMPANY_SET_ADDRESS_POSTAL_CODE:
            return {
                ...state,
                postalCode: action.payload.postalCode,
            };
        case COMPANY_TYPES.COMPANY_SET_ADDRESS_CITY:
            return {
                ...state,
                city: action.payload.city,
            };
        case COMPANY_TYPES.COMPANY_SET_ADDRESS_COUNTRY:
            return {
                ...state,
                country: action.payload.country,
            };
        case COMPANY_TYPES.COMPANY_SET_TELEPHONE:
            return {
                ...state,
                telephone: action.payload.telephone,
            };
        case COMPANY_TYPES.COMPANY_SET_MAIL:
            return {
                ...state,
                email: action.payload.email,
            };
        case COMPANY_TYPES.COMPANY_SET_LEGAL_FORM:
            return {
                ...state,
                legalForm: action.payload.legalForm,
            };
        case COMPANY_TYPES.COMPANY_SET_BY_DATA:
            return {
                ...state,
                name: action.payload.name,
                vat: action.payload.vat,
                street: action.payload.street,
                number: action.payload.number,
                box: action.payload.box,
                postalCode: action.payload.postalCode,
                city: action.payload.city,
                country: action.payload.country,
                telephone: action.payload.telephone,
                email: action.payload.email,
                legalForm: action.payload.legalForm,
            };
        case COMPANY_TYPES.COMPANY_SET_BY_VIES_DATA:
            return {
                ...state,
                name: action.payload.name,
                street: action.payload.street,
                number: action.payload.number,
                box: action.payload.box,
                postalCode: action.payload.postalCode,
                city: action.payload.city,
                country: action.payload.country,
            };
        default:
            return state;
    }
};

export const taxationReducer = (state, action) => {
    switch (action.type) {
        case TAXATION_TYPES.TAXATION_IS_TAXABLE:
            return {
                ...state,
                isTaxable: action.payload.value,
                isCompany: null,
                taxablePercent: 0,
                isHandicapped: null,
                isDiplomat: null,
                hasBelgianLicensePlate: null,
            };
        case TAXATION_TYPES.TAXATION_IS_COMPANY:
            return {
                ...state,
                isCompany: action.payload.value,
                isTaxableFiftyPercent: null,
                taxablePercent: 0,
            };
        case TAXATION_TYPES.TAXATION_TAXABLE_PERCENT:
            return {
                ...state,
                taxablePercent: action.payload.value,
            };
        case TAXATION_TYPES.TAXATION_IS_HANDICAPPED:
            return {
                ...state,
                isHandicapped: action.payload.value,
            };
        case TAXATION_TYPES.TAXATION_IS_DIPLOMAT:
            return {
                ...state,
                isDiplomat: action.payload.value,
            };
        case TAXATION_TYPES.TAXATION_HAS_BELGIAN_LICENSE_PLATE:
            return {
                ...state,
                hasBelgianLicensePlate: action.payload.value,
            };
        default:
            return state;
    }
};

export const customerReducer = (state, action) => {
    console.log(state)
    switch (action.type) {
        case CUSTOMER_TYPES.CUSTOMER_SET:
            return {
                ...customer_initialize(),
                ...action.payload.customer,
            };
        case CUSTOMER_TYPES.CUSTOMER_RESET:
            return {
                ...customer_initialize(),
                lookupDone: false,
            };
        case CUSTOMER_TYPES.CUSTOMER_LOOKUP_DONE:
            return {
                ...state,
                lookupDone: action.payload.value
            };
        case PROCESS_TYPES.PROCESS_SET_DATA:
            return {
                ...state,
                process: {
                    ...action.payload
                }
            };
        case CUSTOMER_TYPES.CUSTOMER_SET_ORIGIN:
            return {
                ...state,
                key: action.payload.key,
                origin: action.payload.origin,
            };
        default:
            return {
                ...state,
                guid: (state.guid == null) ? UUID.v4() : state.guid,
                individual: individualReducer(state.individual, action),
                company: companyReducer(state.company, action),
                taxation: taxationReducer(state.taxation, action),
            };
    }
};


export const setCustomer = (dispatch, data) => {
    dispatch({
        type: CUSTOMER_TYPES.CUSTOMER_SET,
        payload: {
            customer: data
        }
    });
};


export const individualSetTitle = (dispatch, title) => {
    dispatch({
        type: INDIVIDUAL_TYPES.INDIVIDUAL_SET_TITLE,
        payload: {
            title,
        }
    });
};
export const individualSetLastName = (dispatch, lastName) => {
    dispatch({
        type: INDIVIDUAL_TYPES.INDIVIDUAL_SET_LAST_NAME,
        payload: {
            lastName
        }
    });
};
export const individualSetFirstName = (dispatch, firstName) => {
    dispatch({
        type: INDIVIDUAL_TYPES.INDIVIDUAL_SET_FIRST_NAME,
        payload: {
            firstName
        }
    });
};
export const individualSetAddress = (dispatch, street, number, box, postalCode, city, country) => {
    dispatch({
        type: INDIVIDUAL_TYPES.INDIVIDUAL_SET_SET_ADDRESS,
        payload: {
            street,
            number,
            box,
            postalCode,
            city,
            country,
        }
    });
};
export const individualSetStreet = (dispatch, street) => {
    dispatch({
        type: INDIVIDUAL_TYPES.INDIVIDUAL_SET_ADDRESS_STREET,
        payload: {
            street,
        }
    });
};
export const individualSetNumber = (dispatch, number) => {
    dispatch({
        type: INDIVIDUAL_TYPES.INDIVIDUAL_SET_ADDRESS_NUMBER,
        payload: {
            number,
        }
    });
};
export const individualSetBox = (dispatch, box) => {
    dispatch({
        type: INDIVIDUAL_TYPES.INDIVIDUAL_SET_ADDRESS_BOX,
        payload: {
            box
        }
    });
};
export const individualSetPostalCode = (dispatch, postalCode) => {
    dispatch({
        type: INDIVIDUAL_TYPES.INDIVIDUAL_SET_ADDRESS_POSTAL_CODE,
        payload: {
            postalCode
        }
    });
};
export const individualSetCity = (dispatch, city) => {
    dispatch({
        type: INDIVIDUAL_TYPES.INDIVIDUAL_SET_ADDRESS_CITY,
        payload: {
            city
        }
    });
};
export const individualSetCountry = (dispatch, country) => {
    dispatch({
        type: INDIVIDUAL_TYPES.INDIVIDUAL_SET_ADDRESS_COUNTRY,
        payload: {
            country
        }
    });
};
export const individualSetTelephone = (dispatch, telephone) => {
    dispatch({
        type: INDIVIDUAL_TYPES.INDIVIDUAL_SET_TELEPHONE,
        payload: {
            telephone
        }
    });
};
export const individualSetMobile = (dispatch, mobile) => {
    dispatch({
        type: INDIVIDUAL_TYPES.INDIVIDUAL_SET_MOBILE,
        payload: {
            mobile
        }
    });
};
export const individualSetMail = (dispatch, email) => {
    dispatch({
        type: INDIVIDUAL_TYPES.INDIVIDUAL_SET_MAIL,
        payload: {
            email
        }
    });
};
export const individualSetLanguage = (dispatch, language) => {
    dispatch({
        type: INDIVIDUAL_TYPES.INDIVIDUAL_SET_LANGUAGE,
        payload: {
            language
        }
    });
};

export const individualSetByData = (dispatch, key, origin, title, lastName, firstName, street, number, box, postalCode, city, country, telephone, mobile, email, language) => {

    dispatch({
        type: CUSTOMER_TYPES.CUSTOMER_SET_ORIGIN,
        payload: {
            key,
            origin,
        }
    });

    dispatch({
        type: INDIVIDUAL_TYPES.INDIVIDUAL_SET_BY_DATA,
        payload: {
            title,
            lastName,
            firstName,
            street,
            number,
            box,
            postalCode,
            city,
            country,
            telephone,
            mobile,
            email,
            language

        }
    });
};


export const companySetName = (dispatch, name) => {
    dispatch({
        type: COMPANY_TYPES.COMPANY_SET_NAME,
        payload: {
            name
        }
    });
};
export const companySetVat = (dispatch, vat) => {
    dispatch({
        type: COMPANY_TYPES.COMPANY_SET_VAT,
        payload: {
            vat
        }
    });
};

export const companySetStreet = (dispatch, street) => {
    dispatch({
        type: COMPANY_TYPES.COMPANY_SET_ADDRESS_STREET,
        payload: {
            street,
        }
    });
};
export const companySetNumber = (dispatch, number) => {
    dispatch({
        type: COMPANY_TYPES.COMPANY_SET_ADDRESS_NUMBER,
        payload: {
            number,
        }
    });
};
export const companySetBox = (dispatch, box) => {
    dispatch({
        type: COMPANY_TYPES.COMPANY_SET_ADDRESS_BOX,
        payload: {
            box
        }
    });
};
export const companySetPostalCode = (dispatch, postalCode) => {
    dispatch({
        type: COMPANY_TYPES.COMPANY_SET_ADDRESS_POSTAL_CODE,
        payload: {
            postalCode
        }
    });
};
export const companySetCity = (dispatch, city) => {
    dispatch({
        type: COMPANY_TYPES.COMPANY_SET_ADDRESS_CITY,
        payload: {
            city
        }
    });
};
export const companySetCountry = (dispatch, country) => {
    dispatch({
        type: COMPANY_TYPES.COMPANY_SET_ADDRESS_COUNTRY,
        payload: {
            country
        }
    });
};
export const companySetTelephone = (dispatch, telephone) => {
    dispatch({
        type: COMPANY_TYPES.COMPANY_SET_TELEPHONE,
        payload: {
            telephone
        }
    });
};
export const companySetMail = (dispatch, email) => {
    dispatch({
        type: COMPANY_TYPES.COMPANY_SET_MAIL,
        payload: {
            email
        }
    });
};
export const companySetLegalForm = (dispatch, legalForm) => {
    dispatch({
        type: COMPANY_TYPES.COMPANY_SET_LEGAL_FORM,
        payload: {
            legalForm
        }
    });
};
export const companySetByData = (dispatch, key, origin, name, vat, street, number, box, postalCode, city, country, telephone, email, legalForm) => {

    dispatch({
        type: CUSTOMER_TYPES.CUSTOMER_SET_ORIGIN,
        payload: {
            key,
            origin,
        }
    });

    dispatch({
        type: COMPANY_TYPES.COMPANY_SET_BY_DATA,
        payload: {
            name,
            vat,
            street,
            number,
            box,
            postalCode,
            city,
            country,
            telephone,
            email,
            legalForm

        }
    });
};
export const companySetByViesData = (dispatch, name, street, number, box, postalCode, city, country) => {
    dispatch({
        type: COMPANY_TYPES.COMPANY_SET_BY_VIES_DATA,
        payload: {
            name,
            street,
            number,
            box,
            postalCode,
            city,
            country,
        }
    });
};


export const taxationIsTaxable = (dispatch, value) => {
    dispatch({
        type: TAXATION_TYPES.TAXATION_IS_TAXABLE,
        payload: {
            value
        }
    });
};
export const taxationIsCompany = (dispatch, value) => {
    dispatch({
        type: TAXATION_TYPES.TAXATION_IS_COMPANY,
        payload: {
            value
        }
    });
};
export const taxationSetTaxablePercent = (dispatch, value) => {
    dispatch({
        type: TAXATION_TYPES.TAXATION_TAXABLE_PERCENT,
        payload: {
            value
        }
    });
};
export const taxationIsHandicapped = (dispatch, value) => {
    dispatch({
        type: TAXATION_TYPES.TAXATION_IS_HANDICAPPED,
        payload: {
            value
        }
    });
};
export const taxationIsDiplomat = (dispatch, value) => {
    dispatch({
        type: TAXATION_TYPES.TAXATION_IS_DIPLOMAT,
        payload: {
            value
        }
    });
};
export const taxationHasBelgianRegistration = (dispatch, value) => {
    dispatch({
        type: TAXATION_TYPES.TAXATION_HAS_BELGIAN_LICENSE_PLATE,
        payload: {
            value
        }
    });
};


export const customerSetLookupDone = (dispatch, done) => {
    dispatch({
        type: CUSTOMER_TYPES.CUSTOMER_LOOKUP_DONE,
        payload: {
            value: done,
        }
    });
};
export const customerReset = (dispatch) => {
    dispatch({
        type: CUSTOMER_TYPES.CUSTOMER_RESET,
        payload: {}
    });
};

export const customerSetSalesProcessData = (dispatch, data) => {
    dispatch({
        type: PROCESS_TYPES.PROCESS_SET_DATA,
        payload: {
            key: data.process.key,
            origin: data.process.origin,
            brand: data.process.brand,
            state: data.process.status,
            date: data.process.date,
            leadSalesMan: data.process.leadSalesMan,
        }
    });
};

export const customerValidator = (customer, data) => {
    const errors = [];
    let hasIndividualData = false;
    let hasCompanyData = false;

    if (customer.individual.lastName !== "" ||
        customer.individual.language !== "" ||
        customer.individual.street !== "" ||
        customer.individual.number !== "" ||
        customer.individual.postalCode !== "" ||
        customer.individual.city !== "" ||
        customer.individual.country !== "") {

        hasIndividualData = true;

    }

    if (customer.company.name !== "" ||
        customer.company.legalForm !== "" ||
        customer.company.street !== "" ||
        customer.company.number !== "" ||
        customer.company.postalCode !== "" ||
        customer.company.city !== "" ||
        customer.company.country !== "") {

        hasCompanyData = true;
    }

    if (hasIndividualData || (!hasIndividualData && !hasCompanyData)) {

        if (customer.individual.lastName === "") {
            errors.push(validationError(ErrorMessages.REQUIRED, 'individual.lastName'));
        }

        if (customer.individual.language === "") {
            errors.push(validationError(ErrorMessages.REQUIRED, 'individual.language'));
        }

        if (customer.individual.street === "") {
            errors.push(validationError(ErrorMessages.REQUIRED, 'individual.street'));
        }

        if (customer.individual.number === "") {
            errors.push(validationError(ErrorMessages.REQUIRED, 'individual.number'));
        }

        if (customer.individual.postalCode === "") {
            errors.push(validationError(ErrorMessages.REQUIRED, 'individual.postalCode'));
        }

        if (customer.individual.city === "") {
            errors.push(validationError(ErrorMessages.REQUIRED, 'individual.city'));
        }

        if (customer.individual.country === "") {
            errors.push(validationError(ErrorMessages.REQUIRED, 'individual.country'));
        }
    }

    if (hasCompanyData || (!hasIndividualData && !hasCompanyData)) {

        if (customer.company.name === "") {
            errors.push(validationError(ErrorMessages.REQUIRED, 'company.name'));
        }

        if (customer.company.legalForm === "") {
            errors.push(validationError(ErrorMessages.REQUIRED, 'company.legalForm'));
        }

        if (customer.company.street === "") {
            errors.push(validationError(ErrorMessages.REQUIRED, 'company.street'));
        }

        if (customer.company.number === "") {
            errors.push(validationError(ErrorMessages.REQUIRED, 'company.number'));
        }

        if (customer.company.postalCode === "") {
            errors.push(validationError(ErrorMessages.REQUIRED, 'company.postalCode'));
        }

        if (customer.company.city === "") {
            errors.push(validationError(ErrorMessages.REQUIRED, 'company.city'));
        }

        if (customer.company.country === "") {
            errors.push(validationError(ErrorMessages.REQUIRED, 'company.country'));
        }
    }

    if (customer.taxation.isTaxable === null) {
        errors.push(validationError(ErrorMessages.REQUIRED, 'taxation.isTaxable'));
    } else if (customer.taxation.isTaxable === true) {

        if (customer.taxation.isCompany === null) {
            errors.push(validationError(ErrorMessages.REQUIRED, 'taxation.isCompany'));
        } else if (customer.taxation.taxablePercent === 0) {
            errors.push(validationError(ErrorMessages.REQUIRED, 'taxation.taxablePercent'));
        }
    } else {
        if (customer.taxation.isHandicapped === null) {
            errors.push(validationError(ErrorMessages.REQUIRED, 'taxation.isHandicapped'));
        }

        if (customer.taxation.isDiplomat === null) {
            errors.push(validationError(ErrorMessages.REQUIRED, 'taxation.isDiplomat'));
        }

        if (customer.taxation.hasBelgianLicensePlate === null) {
            errors.push(validationError(ErrorMessages.REQUIRED, 'taxation.hasBelgianLicensePlate'));
        }

    }

    return errors;
};


const validationError = (message, field = '') => {
    return {
        message,
        field,
    }
};

const ErrorMessages = {
    // TODO ADD ALL ERRORS HERE, in subobjects
    'NO_DATA': getText('Individual or company is required'),
    'REQUIRED': getText('Required')
};


export const individualByData = (key, origin, title, lastName, firstName, street, number, box, postalCode, city, country, telephone, mobile, email, language) => {
    return {
        ...INITIAL_INDIVIDUAL,
        key,
        origin,
        title,
        firstName,
        lastName,
        street,
        number,
        box,
        postalCode,
        city,
        country,
        telephone,
        mobile,
        email,
        language
    }
};

export const companyByData = (key, origin, name, vat, legalForm, street, number, box, postalCode, city, country, telephone, email) => {
    return {
        ...INITIAL_COMPANY,
        key,
        origin,
        name,
        vat,
        legalForm,
        street,
        number,
        box,
        postalCode,
        city,
        country,
        telephone,
        email,
    }
};

export const taxationByData = (isTaxable, isCompany, taxablePercent, isHandicapped, isDiplomat, hasBelgianRegistration) => {
    return {
        ...INITIAL_TAXATION,
        isTaxable,
        isCompany,
        taxablePercent,
        isHandicapped,
        isDiplomat,
        hasBelgianRegistration

    }
};

export const processByData = (key, origin, brand, state, data, leadSalesMan) => {
    return {
        ...INITIAL_PROCESS,
        key,
        origin,
        brand,
        state,
        data,
        leadSalesMan,
    }
};

export const initialStateByData = (uniqueId, individual, company, taxation, process, lookupDone = true) => {
    return {
        ...customer_initialize(),
        guid: uniqueId,
        individual,
        company,
        taxation,
        process,
        lookupDone,
    }
};

