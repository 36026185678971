import React, {Component} from 'react';

export default class HeaderToolbar extends Component {

    render() {
        const {title, subtitle} = this.props;

        return (

            <div className="h-16 bg-white sticky top-0 pl-2 flex z-20" style={{borderBottom: '0.5rem solid rgb(244, 244, 245)'}}>

                <div className="flex flex-col justify-center">
                    <span className="text-2xl">{title}</span>
                    <span className="pt-1">{subtitle}</span>
                </div>
                <div className="ml-6 flex items-end mb-2">
                    {this.props.children}
                </div>
            </div>
        );
    }
}



