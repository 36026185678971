import React from 'react';
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import LoadingIndicator from "../../../../general/indicators/LoadingIndicator";
import ErrorIndicator from "../../../../general/indicators/ErrorIndicator";
import {useTranslate} from "../../../../translations";
import moment from "moment";

export const GET_CONSTRUCTOR_INFO = gql`
  query getConstructorInfo($vin: String!) {
    constructorInfo(vin: $vin) {
      modelYear
      shortDescription
      longDescription
      interior
      exterior
      places
      doors
      firstRegistration
      catalogPrice
      catalogPriceWithOptions
      vin
      commission
      powerKw
      modelCode
      options {
        code
        description
      }
    }
  }
`;

const ConstructorInformation = ({vin, textSize, general, finances, features, onDataFetched}) => {
    const {getText} = useTranslate();

    const {data: constructorInfoData, loading: constructorInfoDataLoading, error: constructorInfoDataError} = useQuery(GET_CONSTRUCTOR_INFO, {
        skip: (vin === ""),
        variables: {
            vin: vin,
        },
        onCompleted: (data) => {
            if(onDataFetched){
                onDataFetched(data.constructorInfo)
            }

        }
    });

    if (constructorInfoDataLoading) {
        return (
            <div className="flex h-full relative overflow-scroll">
                <LoadingIndicator/>
            </div>
        );
    }

    if (constructorInfoDataError) {
        return (
            <div className="flex h-full relative overflow-scroll">
                <ErrorIndicator description={constructorInfoDataError.message}/>
            </div>
        );
    }

    if (vin === "" || constructorInfoData === undefined || constructorInfoData.constructorInfo === undefined || constructorInfoData.constructorInfo === null) {
        return <div
            className="flex flex-1 flex-grow items-center justify-center text-base">{getText("No data found")}</div>;
    }

    const {modelYear, longDescription, interior, exterior, places, doors, firstRegistration, vin: vinFromInfo, commission, powerKw, modelCode, catalogPrice, catalogPriceWithOptions, options} = constructorInfoData.constructorInfo;

    return (
        <div className="flex h-full relative overflow-scroll">
            <div className="flex flex-col w-full pl-2 overflow-y-scroll construction-wrapper">

                {general &&
                <React.Fragment>
                    <span className="font-medium mb-2">{longDescription}</span>
                    <div className={`flex flex-row mb-1 ${textSize}`}>
                        <span className="mb-1 w-2/7 text-2xs">{getText("VIN")}: </span>
                        <span className="font-medium w-3/5">{vinFromInfo}</span>
                    </div>
                    <div className={`flex flex-row mb-1 ${textSize}`}>
                        <span className="mb-1 w-2/7 text-2xs">{getText("First registration")}: </span>
                        <span className="font-medium w-3/5">{moment(firstRegistration).format('DD/MM/YYYY')}</span>
                    </div>
                    <div className={`flex flex-row mb-1 ${textSize}`}>
                        <span className="mb-1 w-2/7 text-2xs">{getText("Model year")}: </span>
                        <span className="font-medium w-3/5">{modelYear}</span>
                    </div>
                    <div className={`flex flex-row mb-1 ${textSize}`}>
                        <span className="mb-1 w-2/7 text-2xs">{getText("Model code")}: </span>
                        <span className="font-medium w-3/5">{modelCode}</span>
                    </div>
                    <div className={`flex flex-row mb-1 ${textSize}`}>
                        <span className="mb-1 w-2/7 text-2xs">{getText("Exterior")}: </span>
                        <span className="font-medium w-3/5">{exterior}</span>
                    </div>
                    <div className={`flex flex-row mb-1 ${textSize}`}>
                        <span className="mb-1 w-2/7 text-2xs-2x">{getText("Interior")}: </span>
                        <span className="font-medium w-3/5">{interior}</span>
                    </div>
                    <div className={`flex flex-row mb-1 ${textSize}`}>
                        <span className="mb-1 w-2/7 text-2xs">{getText("Places")}: </span>
                        <span className="font-medium w-3/5">{places}</span>
                    </div>
                    <div className={`flex flex-row mb-1 ${textSize}`}>
                        <span className="mb-1 w-2/7 text-2xs">{getText("Doors")}: </span>
                        <span className="font-medium w-3/5">{doors}</span>
                    </div>

                    <div className={`flex flex-row mb-1 ${textSize}`}>
                        <span className="mb-1 w-2/7 text-2xs">{getText("Power kW")}: </span>
                        <span className="font-medium w-3/5">{powerKw}</span>
                    </div>

                    <div className={`flex flex-row mb-1 ${textSize}`}>
                        <span className="mb-1 w-2/7 text-2xs">{getText("Commission")}: </span>
                        <span className="font-medium w-3/5">{commission}</span>
                    </div>
                </React.Fragment>}

                {finances &&
                <React.Fragment>

                    <div className="flex p-2 pl-0 font-medium">
                        {getText('Finances')}
                    </div>

                    <div className={`flex flex-row mb-1 ${textSize}`}>
                        <span className="mb-1 w-2/4 text-2xs">{getText("Catalog")}: </span>
                        <span className="font-medium w-3/5">{catalogPrice} €</span>
                    </div>
                    <div className={`flex flex-row mb-1 ${textSize}`}>
                        <span className="mb-1 w-2/4 text-2xs">{getText("Catalog + Options")}: </span>
                        <span className="font-medium w-3/5">{catalogPriceWithOptions} €</span>
                    </div>
                    <div className="flex p-2 pl-0 font-medium ">
                        {getText('Equipments')}
                    </div>
                </React.Fragment>}


                {features &&
                <React.Fragment>
                    {!general && !finances &&
                    <div
                        className="flex p-2 pl-0 border border-gray-300 border-t-0 border-l-0 border-r-0 font-medium mb-2">
                        {getText('Constructor options')}
                    </div>}

                    {options.map(option => {
                        return (
                            <div key={option.code} className="flex flex-row mb-1 items-center construction-option">


                                <span className="text-gray-700 text-xs font-medium mr-1"
                                      style={{width: 30, minWidth: 30}}>
                                    {option.code}
                                </span>


                                <div className={`text-2xs truncate`}>{option.description.replace('"', "")}</div>
                            </div>
                        )
                    })}
                </React.Fragment>}

            </div>

        </div>
    )
};

ConstructorInformation.defaultProps = {
    textSize: 'text-xs',
    general: true,
    finances: true,
    features: true,
};

export default ConstructorInformation;
