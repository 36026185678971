import React from 'react';
import {Button} from '../../../../general';
import {findIndex} from "lodash";
import {useTranslate} from "../../../../translations";

const Buttons = ({onNext, onPrev, nextText, data: {flow, currentStepId}, nextDisabled, prevDisabled}) => {
    const {getText} = useTranslate();
    const index = findIndex(flow, (id) => id === currentStepId);
    // const canNext = flow.length -1 >= index + 1;
    const canNext = true;
    const canPrev = index >= 1;

    return (
        <div id="takeover-wizard-buttons"
             className="flex justify-end items-center w-full text-right bottom-0 py-2 bg-white">
            {!prevDisabled &&
            <Button id="takeover-wizard-btn-prev" disabled={prevDisabled} className="mr-2"
                    onClick={() => (canPrev) ? onPrev() : null}>{getText('Previous')}</Button>
            }
            <Button id="takeover-wizard-btn-next" disabled={nextDisabled} type="primary"
                    onClick={() => (canNext) ? onNext() : null}>{(nextText !== null) ? nextText : getText('Next')}</Button>
        </div>
    )
};

Buttons.defaultProps = {
    nextText: null,
    nextDisabled: false,
    prevDisabled: false,
}

export default Buttons;