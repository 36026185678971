import React, {useState} from "react";

const colors = {
    0: 'white',
    1: 'green',
    2: 'red',
};

const TriButton = ({value, onChange}) => {
    const [hovered, setHovered] = useState(false);

    const handleClick = () => {
        if (value === 2) {
            onChange(0);
            setHovered(false);
        } else {
            onChange(value + 1);
            setHovered(false);
        }
    };

    const buttonState = (value !== null) ? value : 0;

    const tempState = (hovered) ? ((buttonState + 1) > 2) ? 0 : (buttonState + 1) : buttonState;
    const icon = (tempState === 0) ? 'fa fa-minus' : (tempState === 1) ? 'fa fa-check' : 'fa fa-times';

    const bg = ((colors[tempState]) === 'white') ? 'bg-white' : `bg-${colors[tempState]}-400 hover:bg-${colors[tempState]}-500`;
    const text = (tempState === 0) ? 'text-gray-400 group-hover:text-gray-600' : `text-${colors[tempState]}-600`;
    const border = ((colors[tempState]) === 'white') ? 'border-gray-400 hover:border-gray-500' : `border-${colors[tempState]}-400 hover:border-${colors[tempState]}-500`;

    return (
        <div
            className={`w-full h-full ${bg} border ${border} flex justify-center items-center rounded group`}
            onClick={handleClick}>
            <i className={`${icon} ${text}`}/>
        </div>
    )
};


export default TriButton;