import React, {useState} from 'react';
import {map} from 'lodash';
import {v4} from 'uuid';
import {formatAmount, formatDate, formatHour, formatKilometers, initialsByName} from "../../../../../../../general/utils";
import moment from "moment";
import {Similarity} from "../../../valuation/RadarValuation";


const history = [
    {
        id: v4(),
        user: 'Sven Van Lierde',
        date: moment(),
        description: 'Consultation of file'
    },
    {
        id: v4(),
        user: 'Sven Van Lierde',
        date: moment(),
        description: 'Consultation of file'
    },
    {
        id: v4(),
        user: 'Sven Van Lierde',
        date: moment(),
        description: 'Consultation of file'
    },
    {
        id: v4(),
        user: 'Sven Van Lierde',
        date: moment(),
        description: 'Consultation of file'
    },
    {
        id: v4(),
        user: 'Sven Van Lierde',
        date: moment(),
        description: 'Consultation of file'
    },
    {
        id: v4(),
        user: 'Sven Van Lierde',
        date: moment(),
        description: 'Consultation of file'
    },
];

const UserHistory = () => {
    return (
        <div className="">
            {map(history, (historyItem, i) => {
                const bgColor = ((i % 2) === 0) ? 'bg-white' : "bg-gray-100";

                return (
                    <div key={`history_${historyItem.id}`} className={`p-2 border border-gray-200 border-solid border-r-0 border-l-0 border-t-0 ${bgColor}`}>
                        <div className="flex flex-row">
                            <div className="flex flex-col justify-center mr-2 items-center" style={{width: 55}}>
                                <div style={{width: 40, height: 40, borderRadius: 20}} className="bg-gray-200 bg-gray-200 text-md text-gray-600 font-medium flex justify-center items-center">
                                    <span>{initialsByName(historyItem.user)}</span>
                                </div>
                            </div>
                            <div className="w-2/3 flex flex-col justify-center">
                                <div className="flex flex-row font-medium mb-1">
                                    {historyItem.user}
                                </div>
                                <div className="flex flex-row mb-1">
                                    <div>{`${formatDate(moment())} on ${formatHour(moment())}`}</div>
                                </div>
                            </div>
                            <div className="w-1/3 flex flex-row items-center justify-end">
                                <span className="">{historyItem.description}</span>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
};

export default UserHistory;
