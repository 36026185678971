import React from 'react';
import Button from "../action/Button";

const FormActionButton = ({formId, disabled, children}) => {
    return (
        <Button type="primary"
                disabled={disabled}
                onClick={() => document.getElementById(formId).dispatchEvent(new Event('submit', {cancelable: true}))}>
            {children}
        </Button>
    );
};

export default FormActionButton;


