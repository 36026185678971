import React, {useState} from 'react';
import {default as BaseDatePicker} from "../base/DatePicker";
import * as PropTypes from "prop-types";
import LabelContainer from "./LabelContainer";



const DatePicker = ({label, value, required, placeholder, disabled, onChange, className, inputClassName, showBorder, error, hasError, defaultView, minDate, maxDate, labelContainerClassName, valueContainerClassName, textClassName, marginBottom, disabledOpacity, calendarLocation}) => {
    const [hasFocus, setHasFocus] = useState(false);

    return (
        <LabelContainer label={label}
                        disabled={disabled}
                        className={className}
                        error={error}
                        hasValue={value !== ""}
                        hasError={error !== ""}
                        onFocus={() => setHasFocus(true)}
                        onBlur={() => setHasFocus(false)}
                        labelContainerClassName={labelContainerClassName}
                        valueContainerClassName={valueContainerClassName}
                        textClassName={textClassName}
                        marginBottom={marginBottom}
                        disabledOpacity={disabledOpacity}
        >

            <BaseDatePicker required={required}
                            disabled={disabled}
                            placeholder={placeholder}
                            onChange={onChange}
                            showBorder={showBorder}
                            hasError={error !== ""}
                            value={value}
                            defaultView={defaultView}
                            minDate={minDate}
                            maxDate={maxDate}
                            className={inputClassName}
                            hasFocus={hasFocus}
                            calendarLocation={calendarLocation}
            />

        </LabelContainer>
    );
};

DatePicker.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    value: PropTypes.string.isRequired,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    showBorder: PropTypes.bool,
    error: PropTypes.string,
};

DatePicker.defaultProps = {
    label: "Label",
    value: "",
    required: false,
    placeholder: "",
    disabled: false,
    onChange: null,
    className: "",
    inputClassName: "",
    showBorder: true,
    error: ""
};

export default DatePicker;


