import React, {Component} from 'react';
import {head, filter} from 'lodash';
import {Button} from '../../general';

const DrawerHeader = ({children}) => <div>{children}</div>;
const DrawerFooter = ({children}) => <div>{children}</div>;

export default class Drawer extends Component {
    static Header = DrawerHeader;
    static Footer = DrawerFooter;


    renderTitle() {
        const {title} = this.props;
        if (title && typeof title === 'function') {
            return title(title)
        }
        return title;
    }

    render() {
        const {size, visible, children, closable, onClose, contentStyle} = this.props;

        let width = 400;
        switch(size) {
            case "lg":
                width= 900;
                break;
            case "xl":
                width= 1050;
                break;
            default:
                width = 400;
                break;
        }

        if (!visible) return null;

        let header = null;
        let content = null;
        let footer = null;

        if (children.length === undefined) { // children is object !
            switch (children.type.name) {
                case 'DrawerHeader':
                    header = children;
                    break;
                case 'DrawerFooter':
                    footer = children;
                    break;
                default:
                    content = children;
            }
        } else { //children is array
            header = head(filter(children, (child) => child.type.name === 'DrawerHeader'));
            content = filter(children, (child) => child.type.name !== 'DrawerHeader' && child.type.name !== 'DrawerFooter');
            footer = head(filter(children, (child) => child.type.name === 'DrawerFooter'));
        }

        return (

            <div className="collapsiblePanel absolute top-0 right-0 bottom-0 bg-white flex flex-col z-50"
                 style={{
                     width: width,
                     right: 0,
                     height: "calc(100vh - 75px)",
                     overflow: 'auto',
                     boxShadow: "rgba(0, 0, 0, 0.15) -2px 0 8px",
                     marginTop: 47
                 }}>
                {/*style={{boxShadow: 'rgba(0, 0, 0, 0.10) 0px 1px 8px'}}*/}
                <div
                    className="flex flex-row bg-white text-white flex items-center p-2 sticky top-0 border-b border-solid border-gray-400">

                    <div className="flex flex-grow items-center font-medium text-lg text-black">
                        {this.renderTitle()}

                    </div>
                    <div className="flex items-center justify-end text-gray-800">
                        {closable &&
                        <div className="cursor-hand">
                            <Button size="lg" type="icon-only" icon="fal fa-times" className="ml-2 mr-2"
                                    onClick={onClose}/>
                        </div>
                        }
                    </div>
                </div>

                {header &&
                <div className="sticky top-0 w-full pr-2 bg-white mb-2 z-10" style={{top: 57}}>
                    {header}
                </div>}

                <div className="overflow-auto flex-1 relative" style={{contentStyle}}>
                    {content}
                </div>

                {footer && <div className="sticky bottom-0 w-full border-t border-gray-400 bg-white p-5 text-right">
                    {footer}
                </div>}

            </div>
        );
    }
}

Drawer.defaultProps = {
    size: "sm",
    closable: true,
    contentStyle: {},
};


