import React from 'react';
import {map, orderBy} from 'lodash';
import './ColorListPicker.css';
import * as PropTypes from "prop-types";

const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
};

const ColorListPicker = ({name, value, items, disabled, onSelect, className, showBorder, required, hasError}) => {

    const borderClass = (showBorder) ? `border ${(hasError) ? "border-red-500" : (required && !value) ? "border-blue-200" : "border-gray-200"} border-t-0 border-l-0 border-r-0` : '';

    return (
        <div className={`flex flex-row flex-no-wrap w-full pt-2 pb-2 ${borderClass}`} style={{height: 65}}>
            {map(orderBy(items, "name"), (item, i) => {
                    const selected = value === item.value;
                    const last_item = items.length - 1 === i;
                    return (
                        <div key={item.name}
                             className={`w-1/16 relative flex flex-col items-center relative`}
                             style={{marginRight: last_item ? 0 : 1}}>

                            {selected &&
                            <div className="absolute  bg-blue-500 flex items-center justify-center rounded-full border border-white" style={{width: 25, height: 25, top:4, left: 7}}>
                                <span className="text-white fa fa-check" style={{fontSize: 12}}/>
                            </div>}

                            <div className="flex items-center justify-center cursor-pointer w-full"
                                 style={{height: 40, backgroundColor: hex2rgba(item.color, 0.6)}}
                                 onClick={e => onSelect(item.value)}>
                            </div>

                            <div className={`color-name flex items-center justify-center w-full text-center ${selected ? "bg-gray-100 text-gray-900 font-medium" : "text-gray-900"}`}
                                 style={{fontSize: 9, padding: "2px 1px"}}>{item.name}</div>
                        </div>
                    )
                }
            )}
        </div>
    )
};

ColorListPicker.propTypes = {
    name: PropTypes.string,
    items: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    className: PropTypes.string,
    showBorder: PropTypes.bool,
    required: PropTypes.bool,
    hasError: PropTypes.bool,
};

ColorListPicker.defaultProps = {
    name: "ColorListPicker",
    items: [],
    value: "",
    disabled: false,
    onSelect: null,
    className: "",
    showBorder: true,
    required: false,
    hasError: false,
};

export default ColorListPicker;