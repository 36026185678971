import React from 'react';
import {Field} from "react-final-form";
import {composeValidators} from '../forms/validators';
import {default as FormSwitch} from "../forms/Switch";

const TextInput = ({name, label, placeholder, validators, disabled, className, dataTip, dataFor, inputClassName, showBorder}) => {
    return (
        <Field name={name}
               validate={(validators) ? composeValidators(...validators) : false}
               data-tip={dataTip}
               data-for={dataFor}>

            {({input, meta}) => (
                <FormSwitch name={name}
                            label={label}
                            value={input.value}
                            onChange={input.onChange}
                            {...input}
                            placeholder={placeholder}
                            disabled={disabled}
                            className={className}
                            inputClassName={inputClassName}
                            showBorder={showBorder}
                            error={meta.error}
                            hasError={meta.touched && meta.error !== undefined}
                />
            )}
        </Field>
    );
};

export default TextInput;


