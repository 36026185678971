import React from 'react';
import Buttons from "./Buttons";
import Summary from "./Summary";
import {map, filter} from "lodash";
import WizardAnimation from "./WizardAnimation";
import {vehicleSetModelGroup} from "../vehicleData";
import {LoadingIndicator, ErrorIndicator} from "../../../../general";
import {useTranslate} from "../../../../translations";

const ModelGroup = ({onNext, onPrev, onStepClick, wizard, data}) => {
    const {getText} = useTranslate();
    const currentModelGroup = data.inspection.vehicle.modelGroupId;

    return (
        <div className="flex h-full w-full">
            <div className="w-1/4">
                <Summary data={wizard} onStepClick={onStepClick}/>
            </div>

            <div className="w-3/4 flex flex-col flex-1 bg-white p-2 relative">

                {data.dataFetching &&
                <LoadingIndicator/>}

                {data.dataFetchError &&
                <ErrorIndicator description={"Error fetching data, please try again"}/>}

                {!data.dataFetching && !data.dataFetchError &&
                <React.Fragment>
                    <WizardAnimation>
                        <div className="flex flex-1 flex-col overflow-scroll ">
                            <div
                                className="flex flex-row justify-between p-2 pl-0 border border-gray-300 border-t-0 border-l-0 border-r-0  font-medium mb-2">
                                <span onClick={onPrev} className="cursor-pointer"><i
                                    className="fal fa-chevron-left mr-2 text-xs font-medium cursor-pointer "></i>{getText('Select model group')} </span>
                                <i onClick={data.resetGeneralInfo} className="fal fa-bolt cursor-pointer"
                                   style={{fontSize: 14}}/>
                            </div>


                            <div className="flex flex-col">
                                <div className="flex flex-row flex-wrap">
                                    {map(data.modelGroups, (group) => {
                                        return <div
                                            key={`group_${group.id}`}
                                            className="w-1/3"
                                            onClick={() => {
                                                if (currentModelGroup !== group.id)
                                                    vehicleSetModelGroup(data.dispatch, group.id);
                                                onNext();
                                            }}>
                                            <div
                                                className={`${(group.id === currentModelGroup) ? 'bg-blue-400 text-white' : 'bg-gray-100 text-gray-900 hover:bg-blue-400 hover:text-white'} flex flex-1  mr-1 mb-1 cursor-pointer px-2 py-2`}>
                                                {group.name}
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </WizardAnimation>


                    <Buttons onNext={onNext}
                             onPrev={onPrev}
                             data={wizard}
                             nextDisabled={data.inspection.vehicle.modelGroupId === 0}
                    />
                </React.Fragment>}

            </div>
        </div>
    )
};

export default ModelGroup;