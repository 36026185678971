import React, {useState, useEffect, useCallback} from 'react';
import Wizard from "./wizard/Wizard";
import {findIndex, includes, concat, filter, orderBy, last, forEach, find} from "lodash";
import {withQuery} from "../../../general";
import {
    wizardSteps,
    inspectionSetCurrentStepId,
    inspectionSetSkippedSteps,
    wizardSummaryGroups,

     inspectionSetManualInput,
} from "./inspectionData";
import {useLazyQuery} from "@apollo/react-hooks";
import {GET_MODEL_GROUPS, GET_MODELS, GET_VERSIONS, GET_ALL_DATA, GET_POWERS} from "./queries";

import {
    vehicleResetGeneralInfo,
    vehicleSetModelGroup,
    vehicleSetPower,
    vehicleSetVersion,
    vehicleSetVin
} from "./vehicleData";


const InspectionWizard = ({inspection, dispatch, flow, onComplete, data: {makes, makePriorities, settings, fuels, gearboxes, emissionNorms, engines, bodyTypes, wheelFormulas, exteriorColors, interiorColors, checkGroups, damageLocations, pictureTypes, featureGroups, legalForms, countries}}) => {
    const {currentStepId, skippedSteps} = inspection;
    const [lastCompletedStepId, setLastCompletedStepId] = useState(-1);


    const [getModelGroups, {data: getModelGroupsData, loading: getModelGroupsLoading, error: getModelGroupsError}] = useLazyQuery(
        GET_MODEL_GROUPS,
        {
            fetchPolicy: 'network-only',
            onCompleted: result => {

                if (result.modelGroups.length === 1) {
                    const group = result.modelGroups[0];
                    if (group.id !== inspection.vehicle.modelGroupId) {
                        vehicleSetModelGroup(dispatch, result.modelGroups[0].id);
                        handleNext();
                    }

                } else if (result.modelGroups.length > 0) {
                    if (includes(skippedSteps, wizardSteps.modelGroup.id)) {
                        removeSkippedStep(wizardSteps.modelGroup.id);
                    }
                }
            }
        });
    const [getModels, {data: getModelsData, loading: getModelsLoading, error: getModelsError}] = useLazyQuery(GET_MODELS, {
        fetchPolicy: 'network-only',
    });

    const [getPowers, {data: getPowersData, loading: getPowersLoading, error: getPowersError}] = useLazyQuery(GET_POWERS, {
        fetchPolicy: 'network-only',
        onCompleted: result => {

            if (result.powers.length === 1) {
                const kw = result.powers[0].kw;
                const hp = result.powers[0].hp
                if (kw !== inspection.vehicle.powerKw) {
                    vehicleSetPower(dispatch, kw, hp);
                    handleNext();
                }

            } else if (result.powers.length > 0) {
                if (includes(skippedSteps, wizardSteps.power.id)) {
                    removeSkippedStep(wizardSteps.power.id);
                }
            }


        }
    });
    const [getVersions, {data: getVersionsData, loading: getVersionsLoading, error: getVersionsError}] = useLazyQuery(GET_VERSIONS, {
        fetchPolicy: 'network-only',
        onCompleted: result => {

            if (result.versions.length === 1) {
                const version = result.versions[0];

                if (version.id !== inspection.vehicle.versionId) {
                    vehicleSetVersion(dispatch, version);
                }

            } else if (result.versions.length > 0) {
                if (includes(skippedSteps, wizardSteps.version.id)) {
                    removeSkippedStep(wizardSteps.version.id);
                }
            }
        }

    });

    const fetchModelGroupsForMake = useCallback((makeId) => {
        return getModelGroups({
            variables: {
                makeId
            }
        });
    }, [getModelGroups]);
    const fetchModelsForModelGroup = useCallback((makeId, modelGroupId, firstRegistration) => {
        return getModels({
            variables:
                {
                    makeId,
                    modelGroupId,
                    firstRegistration,
                }
        });
    }, [getModels]);
    const fetchPowersForModel = useCallback((modelId, firstRegistration) => {
        return getPowers({
            variables: {
                modelId,
                firstRegistration,
            }
        });
    }, [getPowers]);
    const fetchVersionsForModel = useCallback((modelId, kw, firstRegistration) => {
        return getVersions({
            variables: {
                modelId,
                kw,
                firstRegistration,

            }
        });
    }, [getVersions]);


    useEffect(() => {
        if (inspection.vehicle.makeId > 0 && (inspection.vehicle.firstRegistration !== null)) {
            fetchModelGroupsForMake(inspection.vehicle.makeId);
        }

        if (inspection.vehicle.modelGroupId > 0 && (inspection.vehicle.firstRegistration !== null)) {
            fetchModelsForModelGroup(inspection.vehicle.makeId, inspection.vehicle.modelGroupId, inspection.vehicle.firstRegistration);
        }

        if (inspection.vehicle.modelId > 0 && (inspection.vehicle.firstRegistration !== null)) {
            fetchPowersForModel(inspection.vehicle.modelId, inspection.vehicle.firstRegistration);
        }

        if (inspection.vehicle.modelId > 0 && inspection.vehicle.powerKw > 0 && (inspection.vehicle.firstRegistration !== null) && inspection.vehicleVersions.length === 0) {
            fetchVersionsForModel(inspection.vehicle.modelId, inspection.vehicle.powerKw, inspection.vehicle.firstRegistration);
        }
    }, [inspection.vehicle.makeId, inspection.vehicle.modelGroupId, inspection.vehicle.modelId, inspection.vehicle.firstRegistration, inspection.vehicle.powerKw, inspection.vehicleVersions, fetchModelGroupsForMake, fetchModelsForModelGroup, fetchPowersForModel, fetchVersionsForModel]);


    const generateFlowProcess = (stepId = null) => {
        console.log('handle validation ' + stepId);
        let newFlowProgress = {...flowProgress};
        const stepsToCheck = (stepId === null) ? flow : [stepId];
        forEach(stepsToCheck, (stepId) => {
            if (!includes(skippedSteps, stepId)) {
                const step = find(wizardSteps, (wizStep) => wizStep.id === stepId);
                newFlowProgress = {
                    ...newFlowProgress,
                    [stepId]: createFlowProgress(stepId, step.validator(inspection, {
                        settings,
                        exteriorColors,
                        interiorColors,
                        checkGroups,
                        legalForms,
                        damageLocations,
                        pictureTypes,
                        featureGroups,
                        flowProgress,
                    }))
                };
            }
        });
        return newFlowProgress;
    };
    const createFlowProgress = (stepId, errors) => {
        return {
            stepId: stepId,
            completed: errors.length === 0,
            errors,
        }
    };
    // const skipStep = (stepId) => {
    //     if (!includes(skippedSteps, stepId)) {
    //         inspectionSetSkippedSteps(dispatch, [...skippedSteps, stepId]);
    //         if (flowProgress[stepId]) {
    //             inspectionDeleteFlowProgress(dispatch, stepId);
    //         }
    //     }
    // };
    const removeSkippedStep = (stepId) => {
        inspectionSetSkippedSteps(dispatch, filter(skippedSteps, (id) => id !== stepId));
    };
    const recursiveCheckSkippedSteps = (index, skippedStepsByProps, next = true) => {
        const allSkippedSteps = concat(skippedSteps, skippedStepsByProps);

        let indexToCheck = index;
        if (next)
            indexToCheck += 1;
        else
            indexToCheck -= 1;

        if (includes(allSkippedSteps, flow[indexToCheck])) {
            return recursiveCheckSkippedSteps(indexToCheck, skippedStepsByProps, next);
        } else {
            return indexToCheck;
        }
    };


    const handlePrev = (validationStepId) => {
        const prevStep = getPrev();
        if (prevStep > 0) {
            inspectionSetCurrentStepId(dispatch, prevStep);
        }
    };
    const getPrev = () => {
        let currentIndex = 0;
        if (currentStepId > 0)
            currentIndex = findIndex(flow, (id) => id === currentStepId);

        return flow[recursiveCheckSkippedSteps(currentIndex, [], false)];
    };
    const handleNext = (validationStepId) => {
        const temp = getNext(currentStepId, skippedSteps);
        inspectionSetCurrentStepId(dispatch, temp.nextStepId);
        setLastCompletedStepId(temp.lastCompletedStepId);
    };
    const getNext = (currentId, skippedSteps = []) => {
        let currentIndex = 0;
        if (currentId > 0)
            currentIndex = findIndex(flow, (id) => id === currentId);


        let completedIndex = -1;
        if (lastCompletedStepId > 0)
            completedIndex = findIndex(flow, (id) => id === lastCompletedStepId);

        const nextStep = flow[recursiveCheckSkippedSteps(currentIndex, skippedSteps)];

        return {
            nextStepId: (nextStep) ? nextStep : currentStepId,
            lastCompletedStepId: (currentIndex > completedIndex) ? currentStepId : lastCompletedStepId
        }
    };

    const handleOnStepClick = (group) => {
        if (group.steps.length === 1 && group.steps[0] === wizardSteps.confirmation.id) {
            // validate all
           // handleValidation();
        }
        if (group.steps.length > 1) {
            const lastCompleted = last(orderBy(filter(flowProgress, (fp) => fp.completed && includes(group.steps, fp.stepId)), ['stepId']));

            if (lastCompleted !== null && lastCompleted !== undefined) {
                inspectionSetCurrentStepId(dispatch, group.steps[group.steps.length - 1]);
            } else {
                inspectionSetCurrentStepId(dispatch, group.steps[0]);
            }

        } else if (group.steps.length === 1) {
            inspectionSetCurrentStepId(dispatch, group.steps[0]);
        }
    };
    const resetGeneralInfo = () => {
        vehicleResetGeneralInfo(dispatch);

        if (!inspection.manual) {
            vehicleSetVin(dispatch, "");
            inspectionSetManualInput(dispatch, false)
        }


        forEach(wizardSummaryGroups.model.steps, (stepId) => {
            if (includes(skippedSteps, stepId)) {
                removeSkippedStep(stepId);
            }
        });
        inspectionSetCurrentStepId(dispatch, (inspection.manual) ? wizardSteps.make.id : wizardSteps.vinSearch.id);
    };

    const flowProgress = generateFlowProcess();

    return (
        <Wizard currentStepId={currentStepId}
                lastCompletedStepId={lastCompletedStepId}
                flow={flow}
                flowProgress={flowProgress}
                dataForChildren={{
                    inspection,
                    dispatch,
                    makes: (makes) ? makes : [],
                    makePriorities: makePriorities,
                    modelGroups: (getModelGroupsData && getModelGroupsData.modelGroups) ? getModelGroupsData.modelGroups : [],
                    models: (getModelsData && getModelsData.models) ? getModelsData.models : [],
                    powers: (getPowersData && getPowersData.powers) ? getPowersData.powers : [],
                    versions: (getVersionsData && getVersionsData.versions) ? getVersionsData.versions : [],
                    featureGroups: featureGroups,
                    exteriorColors: exteriorColors,
                    interiorColors: interiorColors,
                    checkGroups: checkGroups,
                    fuels: fuels,
                    gearboxes: gearboxes,
                    emissionNorms: emissionNorms,
                    engines: engines,
                    bodyTypes: bodyTypes,
                    wheelFormulas: wheelFormulas,
                    pictureTypes: pictureTypes,
                    damageLocations: damageLocations,
                    legalForms: legalForms,
                    countries: countries,
                    settings,
                    prevStep: getPrev(),
                    dataFetching: (getModelGroupsLoading || getPowersLoading || getModelsLoading || getVersionsLoading),
                    dataFetchError: (getModelGroupsError || getPowersError || getModelsError || getVersionsError),
                    resetGeneralInfo: resetGeneralInfo,
                }}
                onNext={handleNext}
                onPrev={handlePrev}
                onStepClick={handleOnStepClick}
                onComplete={onComplete}
        />
    )
};

export default withQuery({query: GET_ALL_DATA})(InspectionWizard);

