import React, {useEffect, useState} from "react";
import {TextdomainContext} from "react-gettext";
import {BuildContextValue} from './index';
import useSession from "../project/session/useSession";

const TranslationsProvider = (props) => {
    const [textDomainValue, setTextDomainValue] = useState(null);
    const session = useSession();

    useEffect(() => {
        if(session.locale !== ""){
            setTextDomainValue(BuildContextValue(session.locale))
        }
    }, [session.locale]);

    return (
        <TextdomainContext.Provider value={textDomainValue}>
            {props.children}
        </TextdomainContext.Provider>
    )
};

export default TranslationsProvider;