import {damageReducer, featuresReducer, INITIAL_DAMAGES, INITIAL_FEATURES, pictureReducer} from "../components";
import {checksReducer, INITIAL_CHECKS} from "./components/checks/data";
import {filter, forEach, includes, keys, map, flatten} from "lodash";
import {getText} from "../../../translations";
import UUID from "uuid";

export const generate_initial_vehicle = () => {
    return {
        guid: UUID.v4(),
        makeId: 0,
        makeText: "",
        modelGroupId: 0,
        modelId: 0,
        modelText: "",
        firstRegistration: null,
        powerKw: 0,
        powerHp: 0,
        versionId: 0,
        versionText: "",
        vin: "",
        co2: 0,
        registration: "",
        reference: "",
        kilometers: "",
        seats: 0,
        doors: 0,
        colorInteriorId: 0,
        colorInteriorText: "",
        colorInteriorOrigin: "",
        colorExteriorId: 0,
        colorExteriorText: "",
        colorExteriorOrigin: "",
        commentPositive: "",
        commentNegative: "",
        commentInternal: "",
        title: "",
        fuelId: 0,
        gearboxId: 0,
        emissionNormId: 0,
        engineId: 0,
        bodyId: 0,
        wheelFormulaId: 0,
        catalog: 0,
        catalogOptions: 0,

        damages: INITIAL_DAMAGES,
        checks: INITIAL_CHECKS,
        pictures: {},
        featureIds: INITIAL_FEATURES,
    }
};

const TYPES = {
    VEHICLE_SET_MAKE: 'INSPECTION_VEHICLE_SET_MAKE',
    VEHICLE_SET_MODEL_GROUP: 'INSPECTION_VEHICLE_SET_MODEL_GROUP',
    VEHICLE_SET_MODEL: 'INSPECTION_VEHICLE_SET_MODEL',
    VEHICLE_SET_POWER: 'INSPECTION_VEHICLE_SET_POWER',
    VEHICLE_SET_VERSION: 'INSPECTION_VEHICLE_SET_VERSION',
    VEHICLE_SET_FIRST_REGISTRATION: 'INSPECTION_VEHICLE_SET_FIRST_REGISTRATION',
    VEHICLE_SET_FEATURE_IDS: 'INSPECTION_VEHICLE_SET_FEATURE_IDS',
    VEHICLE_SET_NO_DAMAGES: 'INSPECTION_VEHICLE_SET_NO_DAMAGES',
    VEHICLE_SET_ADDITIONAL: "INSPECTION_VEHICLE_SET_ADDITIONAL",
    VEHICLE_SET_CHECKS: "INSPECTION_VEHICLE_SET_CHECKS",

    VEHICLE_SET_DATA: "INSPECTION_VEHICLE_SET_DATA",
    VEHICLE_SET_VIN: "INSPECTION_VEHICLE_SET_VIN",
    VEHICLE_SET_CO2: "INSPECTION_VEHICLE_SET_CO2",
    VEHICLE_SET_REGISTRATION: "INSPECTION_VEHICLE_SET_REGISTRATION",
    VEHICLE_SET_REFERENCE: "INSPECTION_VEHICLE_SET_REFERENCE",
    VEHICLE_SET_KILOMETERS: "INSPECTION_VEHICLE_SET_KILOMETERS",
    VEHICLE_SET_SEATS: "INSPECTION_VEHICLE_SET_SEATS",
    VEHICLE_SET_DOORS: "INSPECTION_VEHICLE_SET_DOORS",
    VEHICLE_SET_COLOR_INTERIOR: "INSPECTION_VEHICLE_SET_COLOR_INTERIOR",
    VEHICLE_SET_COLOR_EXTERIOR: "INSPECTION_VEHICLE_SET_COLOR_EXTERIOR",
    VEHICLE_SET_COMMENT_POSITIVE: "INSPECTION_VEHICLE_SET_COMMENT_POSITIVE",
    VEHICLE_SET_COMMENT_NEGATIVE: "INSPECTION_VEHICLE_SET_COMMENT_NEGATIVE",
    VEHICLE_SET_COMMENT_INTERNAL: "INSPECTION_VEHICLE_SET_COMMENT_INTERNAL",
    VEHICLE_SET_TITLE: "INSPECTION_VEHICLE_SET_TITLE",
    VEHICLE_SET_FUEL: "INSPECTION_VEHICLE_SET_FUEL",
    VEHICLE_SET_GEARBOX: "INSPECTION_VEHICLE_SET_GEARBOX",
    VEHICLE_SET_EMISSION_NORM: "INSPECTION_SET_EMISSION_NORM",
    VEHICLE_SET_ENGINE: "INSPECTION_VEHICLE_SET_ENGINE",
    VEHICLE_SET_BODY: "INSPECTION_VEHICLE_SET_BODY",
    VEHICLE_SET_WHEEL_FORMULA: "INSPECTION_VEHICLE_SET_WHEEL_FORMULA",
    VEHICLE_SET_CATALOG: "INSPECTION_VEHICLE_SET_CATALOG",

    VEHICLE_RESET_GENERAL: "INSPECTION_VEHICLE_RESET_GENERAL",
};

export const vehicleReducer = (state, action) => {
    switch (action.type) {
        case TYPES.VEHICLE_RESET_GENERAL:
            return {
                ...state,
                makeId: 0,
                firstRegistration: null,
                makeText: "",
                modelGroupId: 0,
                modelId: 0,
                modelText: "",
                powerKw: 0,
                powerHp: 0,
                versionId: 0,
                versionText: "",
            };
        case TYPES.VEHICLE_SET_MAKE:
            return {
                ...state,
                makeId: action.payload.makeId,
                makeText: action.payload.makeText,
                modelGroupId: 0,
                modelId: 0,
                modelText: "",
                powerKw: 0,
                powerHp: 0,
                versionId: 0,
                versionText: "",
            };
        case TYPES.VEHICLE_SET_MODEL_GROUP:
            return {
                ...state,
                modelGroupId: action.payload.modelGroupId,
                modelId: 0,
                powerKw: 0,
                powerHp: 0,
                versionId: 0,
                versionText: "",
            };
        case TYPES.VEHICLE_SET_MODEL:
            return {
                ...state,
                modelId: action.payload.modelId,
                modelText: action.payload.modelText,
                powerKw: 0,
                powerHp: 0,
                versionId: 0,
                versionText: "",
            };
        case TYPES.VEHICLE_SET_POWER:
            return {
                ...state,
                powerKw: action.payload.powerKw,
                versionId: 0,
                versionText: "",
            };
        case TYPES.VEHICLE_SET_VIN:
        case TYPES.VEHICLE_SET_REGISTRATION:
        case TYPES.VEHICLE_SET_REFERENCE:
        case TYPES.VEHICLE_SET_KILOMETERS:
        case TYPES.VEHICLE_SET_SEATS:
        case TYPES.VEHICLE_SET_DOORS:
        case TYPES.VEHICLE_SET_CO2:
        case TYPES.VEHICLE_SET_COLOR_INTERIOR:
        case TYPES.VEHICLE_SET_COLOR_EXTERIOR:
        case TYPES.VEHICLE_SET_COMMENT_NEGATIVE:
        case TYPES.VEHICLE_SET_COMMENT_POSITIVE:
        case TYPES.VEHICLE_SET_COMMENT_INTERNAL:
        case TYPES.VEHICLE_SET_TITLE:
        case TYPES.VEHICLE_SET_FUEL:
        case TYPES.VEHICLE_SET_GEARBOX:
        case TYPES.VEHICLE_SET_EMISSION_NORM:
        case TYPES.VEHICLE_SET_ENGINE:
        case TYPES.VEHICLE_SET_BODY:
        case TYPES.VEHICLE_SET_WHEEL_FORMULA:
        case TYPES.VEHICLE_SET_FIRST_REGISTRATION:
        case TYPES.VEHICLE_SET_VERSION:
        case TYPES.VEHICLE_SET_DATA:
            return {
                ...state,
                ...action.payload,
            };
        case TYPES.VEHICLE_SET_CATALOG:
            return {
                ...state,
                catalog: action.payload.catalog,
                catalogOptions: action.payload.catalogOptions,
            };
        default:
            return {
                ...state,
                guid: (state.guid == null) ? UUID.v4() : state.guid,
                damages: damageReducer(state.damages, action),
                pictures: pictureReducer(state.pictures, action),
                checks: checksReducer(state.checks, action),
                featureIds: featuresReducer(state.featureIds, action),
            };
    }
};

export const vehicleSetMake = (dispatch, make) => {
    dispatch({
        type: TYPES.VEHICLE_SET_MAKE,
        payload: {
            makeId: make.id,
            makeText: make.name,
        }
    });
};
export const vehicleSetModelGroup = (dispatch, modelGroupId) => {
    dispatch({
        type: TYPES.VEHICLE_SET_MODEL_GROUP,
        payload: {
            modelGroupId,
        }
    });
};
export const vehicleSetModel = (dispatch, model) => {
    dispatch({
        type: TYPES.VEHICLE_SET_MODEL,
        payload: {
            modelId: model.id,
            modelText: model.name,
        }
    });
};
export const vehicleSetPower = (dispatch, kw, hp) => {
    dispatch({
        type: TYPES.VEHICLE_SET_POWER,
        payload: {
            powerKw: kw,
            powerHp: hp
        }
    });
};
export const vehicleSetVersion = (dispatch, version) => {
    dispatch({
        type: TYPES.VEHICLE_SET_VERSION,
        payload: {
            ...version,
        }
    });
};
export const vehicleSetFirstRegistration = (dispatch, firstRegistration) => {
    dispatch({
        type: TYPES.VEHICLE_SET_FIRST_REGISTRATION,
        payload: {
            firstRegistration,
        }
    });
};


export const vehicleSetVin = (dispatch, vin) => {
    dispatch({
        type: TYPES.VEHICLE_SET_VIN,
        payload: {
            vin,
        }
    });
};

export const vehicleSetRegistration = (dispatch, registration) => {
    dispatch({
        type: TYPES.VEHICLE_SET_REGISTRATION,
        payload: {
            registration,
        }
    });
};

export const vehicleSetReference = (dispatch, reference) => {
    dispatch({
        type: TYPES.VEHICLE_SET_REFERENCE,
        payload: {
            reference,
        }
    });
};

export const vehicleSetKilometers = (dispatch, kilometers) => {
    dispatch({
        type: TYPES.VEHICLE_SET_KILOMETERS,
        payload: {
            kilometers,
        }
    });
};

export const vehicleSetSeats = (dispatch, seats) => {
    dispatch({
        type: TYPES.VEHICLE_SET_SEATS,
        payload: {
            seats,
        }
    });
};

export const vehicleSetDoors = (dispatch, doors) => {
    dispatch({
        type: TYPES.VEHICLE_SET_DOORS,
        payload: {
            doors,
        }
    });
};


export const vehicleSetCO2 = (dispatch, co2) => {
    dispatch({
        type: TYPES.VEHICLE_SET_CO2,
        payload: {
            co2,
        }
    });
};

export const vehicleSetColorInterior = (dispatch, color) => {
    dispatch({
        type: TYPES.VEHICLE_SET_COLOR_INTERIOR,
        payload: {
            colorInteriorId: color.id,
            colorInteriorText: color.description,
        }
    });
};

export const vehicleSetColorExterior = (dispatch, color) => {
    dispatch({
        type: TYPES.VEHICLE_SET_COLOR_EXTERIOR,
        payload: {
            colorExteriorId: color.id,
            colorExteriorText: color.description,
        }
    });
};

export const vehicleSetCommentPositive = (dispatch, commentPositive) => {
    dispatch({
        type: TYPES.VEHICLE_SET_COMMENT_POSITIVE,
        payload: {
            commentPositive,
        }
    });
};

export const vehicleSetCommentNegative = (dispatch, commentNegative) => {
    dispatch({
        type: TYPES.VEHICLE_SET_COMMENT_NEGATIVE,
        payload: {
            commentNegative,
        }
    });
};

export const vehicleSetCommentInternal = (dispatch, commentInternal) => {
    dispatch({
        type: TYPES.VEHICLE_SET_COMMENT_INTERNAL,
        payload: {
            commentInternal,
        }
    });
};

export const vehicleResetGeneralInfo = (dispatch) => {
    dispatch({
        type: TYPES.VEHICLE_RESET_GENERAL,
        payload: {}
    });
};

export const vehicleSetTitle = (dispatch, title) => {
    dispatch({
        type: TYPES.VEHICLE_SET_TITLE,
        payload: {
            title,
        }
    });
};

export const vehicleSetFuel = (dispatch, fuelId) => {
    dispatch({
        type: TYPES.VEHICLE_SET_FUEL,
        payload: {
            fuelId,
        }
    });
};

export const vehicleSetGearbox = (dispatch, gearboxId) => {
    dispatch({
        type: TYPES.VEHICLE_SET_GEARBOX,
        payload: {
            gearboxId,
        }
    });
};

export const vehicleSetEmissionNorm = (dispatch, emissionNormId) => {
    dispatch({
        type: TYPES.VEHICLE_SET_EMISSION_NORM,
        payload: {
            emissionNormId,
        }
    });
};

export const vehicleSetEngine = (dispatch, engineId) => {
    dispatch({
        type: TYPES.VEHICLE_SET_ENGINE,
        payload: {
            engineId,
        }
    });
};

export const vehicleSetBody = (dispatch, bodyId) => {
    dispatch({
        type: TYPES.VEHICLE_SET_BODY,
        payload: {
            bodyId,
        }
    });
};

export const vehicleSetWheelFormula = (dispatch, wheelFormulaId) => {
    dispatch({
        type: TYPES.VEHICLE_SET_WHEEL_FORMULA,
        payload: {
            wheelFormulaId,
        }
    });
};

export const vehicleSetCatalog = (dispatch, catalog, catalogOptions) => {
    dispatch({
        type: TYPES.VEHICLE_SET_CATALOG,
        payload: {
            catalog,
            catalogOptions,
        }
    });
};


const validationError = (message, field = '') => {
    return {
        message,
        field,
    }
};
export const vehicleMakeValidator = (makeId, data = null) => {
    const errors = [];

    if (makeId === 0)
        errors.push(validationError(ErrorMessages.make.NO_MAKE_SELECTED, 'make'));

    return errors;
};
export const vehicleFeaturesValidator = (features, data = null) => {
    const errors = [];

    if (features.length === 0)
        errors.push(validationError(ErrorMessages.features.NO_FEATURES, 'features'));

    return errors;
};
export const vehicleRegistrationValidator = (registration, data = null) => {
    const errors = [];

    if (registration === null)
        errors.push(validationError(ErrorMessages.registration.NO_REGISTRATION_SELECTED, 'firstRegistration'));

    return errors;
};
export const vehicleModelGroupValidator = (modelGroupId, data = null) => {
    const errors = [];

    if (modelGroupId === 0)
        errors.push(validationError(ErrorMessages.modelGroup.NO_MODELGROUP_SELECTED, 'modelGroup'));

    return errors;
};
export const vehicleModelValidator = (modelId, data = null) => {
    const errors = [];

    if (modelId === 0)
        errors.push(validationError(ErrorMessages.model.NO_MODEL_SELECTED, 'model'));

    return errors;
};
export const vehiclePowerValidator = (powerKw, data = null) => {
    const errors = [];

    if (powerKw === 0)
        errors.push(validationError(ErrorMessages.power.NO_POWER_SELECTED, 'power'));

    return errors;
};
export const vehicleVersionValidator = (versionId, data = null) => {
    const errors = [];

    if (versionId === 0)
        errors.push(validationError(ErrorMessages.version.NO_VERSION_SELECTED, 'version'));

    return errors;
};
export const vehicleChecksValidator = (checks, data = null) => {
    const errors = [];
    const checkIds = map(checks, (v) => v.checkId);

    let mandatoryChecks = [];
    forEach(data, (d) => {
        forEach(d.checks, (c) => {
            if (c.mandatory)
                mandatoryChecks.push(c)
        });
    });

    forEach(mandatoryChecks, (check) => {
        if (!includes(checkIds, check.id)) {
            errors.push(validationError(ErrorMessages.other.REQUIRED, `checkType_${check.id}`));
        }
    });
    return errors;
};
export const vehicleAdditionalValidator = (vehicle, data = null) => {
    const errors = [];
    if (vehicle.vin !== "") {
        const vinValidation = vehicleVinValidator(vehicle.vin);
        if (vinValidation !== "") {
            errors.push(validationError(vinValidation, 'vin'));
        }
    } else {
        errors.push(validationError(ErrorMessages.other.REQUIRED, 'vin'));
    }

    if (vehicle.kilometers === "") {
        errors.push(validationError(ErrorMessages.other.REQUIRED, 'kilometers'));
    }

    if (data.INSPECTION_ADDITIONAL_LICENSE_PLATE_REQUIRED && vehicle.registration === "") {
        errors.push(validationError(ErrorMessages.other.REQUIRED, 'registration'));
    }

    if (data.INSPECTION_ADDITIONAL_REFERENCE_REQUIRED && vehicle.reference === "") {
        errors.push(validationError(ErrorMessages.other.REQUIRED, 'reference'));
    }

    if (vehicle.bodyId === 0) {
        errors.push(validationError(ErrorMessages.other.REQUIRED, 'bodyId'));
    }

    if (vehicle.wheelFormulaId === 0) {
        errors.push(validationError(ErrorMessages.other.REQUIRED, 'wheelFormulaId'));
    }

    if (vehicle.seats === 0) {
        errors.push(validationError(ErrorMessages.other.REQUIRED, 'seats'));
    }

    if (vehicle.doors === 0) {
        errors.push(validationError(ErrorMessages.other.REQUIRED, 'doors'));
    }

    if (vehicle.engineId === 0) {
        errors.push(validationError(ErrorMessages.other.REQUIRED, 'engineId'));
    }

    if (vehicle.gearboxId === 0) {
        errors.push(validationError(ErrorMessages.other.REQUIRED, 'gearboxId'));
    }

    if (vehicle.co2 === "") {
        errors.push(validationError(ErrorMessages.other.REQUIRED, 'co2'));
    }

    if (vehicle.emissionNormId === 0) {
        errors.push(validationError(ErrorMessages.other.REQUIRED, 'emissionNormId'));
    }

    if (vehicle.fuelId === 0) {
        errors.push(validationError(ErrorMessages.other.REQUIRED, 'fuelId'));
    }

    if (vehicle.firstRegistration === null) {
        errors.push(validationError(ErrorMessages.other.REQUIRED, 'firstRegistration'));
    }

    if (vehicle.colorExterior === "") {
        errors.push(validationError(ErrorMessages.other.REQUIRED, 'colorExterior'));
    }

    if (vehicle.colorInterior === "") {
        errors.push(validationError(ErrorMessages.other.REQUIRED, 'colorInterior'));
    }


    return errors;
};
export const vehiclePicturesValidator = (pictures, data) => {
    const errors = [];
    const validationMessage = ErrorMessages.pictures.INVALID_LENGTH;
    const tempPictureTypes = map(filter(pictures, (p) => p.refType === 'pictureType' && p.url !== ''), (p) => p.refId);
    const requiredTypes = filter(data, (picType) => picType.mandatory);

    // if (requiredTypes.length > 0 && tempPictureTypes.length === 0) {
    //     errors.push(validationError(validationMessage));
    //     return errors;
    // }

    forEach(requiredTypes, (pictureType) => {
        if (!includes(tempPictureTypes, pictureType.id)) {
            errors.push(validationError(validationMessage, `pictureType_${pictureType.id}`));
        }
    });
    return errors;
};

export const vehicleDamagesValidator = (damages, noDamages, damageDetailsRequired) => {
    const errors = [];
    if (noDamages) return errors;

    if (keys(damages).length === 0) {
        errors.push(validationError(ErrorMessages.damages.LENGTH_ZERO));
    } else if (keys(damages).length > 0 && damageDetailsRequired) {
        let invalid = false;
        forEach(damages, (d) => {
            if (d.typeId === 0 || d.severityId === 0) {
                invalid = true;
                return false;
            }
        });

        if (invalid) {
            errors.push(validationError(ErrorMessages.damages.INVALID_DETAILS));
        }
    }

    return errors;
};


export const vehicleVinValidator = (vin) => {
    if (vin.length !== 17) {
        return ErrorMessages.vin.INVALID_LENGTH;
    } else return "";
};

const ErrorMessages = {
    // TODO ADD ALL ERRORS HERE, in subobjects
    make: {
        "NO_MAKE_SELECTED": getText("No make selected")
    },
    features: {
        "NO_FEATURES": getText("No model selected")
    },
    model: {
        "NO_MODEL_SELECTED": getText("No model selected")
    },
    modelGroup: {
        "NO_MODELGROUP_SELECTED": getText("No model group selected")
    },
    power: {
        "NO_POWER_SELECTED": getText("No power selected")
    },
    version: {
        "NO_VERSION_SELECTED": getText("No version selected")
    },
    registration: {
        "NO_REGISTRATION_SELECTED": getText("No registration selected")
    },
    vin: {
        "INVALID_LENGTH": getText("Vin must be 17 characters long!"),
    },
    damages: {
        "LENGTH_ZERO": getText("Please define damages, or set option this inspection contains no damages"),
        "INVALID_DETAILS": getText("Damage has invalid details"),
    },
    pictures: {
        "INVALID_LENGTH": getText("Not all the required pictures are defined.")
    },
    other: {
        'REQUIRED': getText('Required')
    }
};

