import {customer_initialize} from '../../../components/customer/data'

export const CustomerToState = (data) => {

    let customer = customer_initialize();
    customer.guid = data.id;
    customer.key = data.origin.key;
    customer.origin = data.origin.origin;

    customer.individual.title = data.individual.title;
    customer.individual.language = data.individual.culture;
    customer.individual.firstName = data.individual.firstName;
    customer.individual.lastName = data.individual.lastName;
    customer.individual.street = data.individual.address.street;
    customer.individual.number = data.individual.address.number;
    customer.individual.box = data.individual.address.box;
    customer.individual.postalCode = data.individual.address.postalCode;
    customer.individual.city = data.individual.address.city;
    customer.individual.country = data.individual.address.countryCode;
    customer.individual.telephone = data.individual.telephone;
    customer.individual.mobile = data.individual.mobile;
    customer.individual.email = data.individual.email;

    customer.company.name = data.company.name;
    customer.company.vat = data.company.vatNumber;
    customer.company.street = data.company.address.street;
    customer.company.number = data.company.address.number;
    customer.company.box = data.company.address.box;
    customer.company.postalCode = data.company.address.postalCode;
    customer.company.city = data.company.address.city;
    customer.company.country = data.company.address.countryCode;
    customer.company.telephone = data.company.telephone;
    customer.company.email = data.company.email;
    customer.company.legalForm = data.company.legalFormId;

    customer.taxation.isTaxable = data.taxation.isTaxable;
    customer.taxation.isCompany = data.taxation.isCorporation;
    customer.taxation.taxablePercent = data.taxation.taxablePercent;
    customer.taxation.isHandicapped = data.taxation.isDisabledPerson;
    customer.taxation.isDiplomat = data.taxation.isDiplomatic;
    customer.taxation.hasBelgianLicensePlate = data.taxation.hasBelgianRegistration;

    customer.lookupDone = true;

    return customer
};

export const customerStateToCustomerInputType = (customer) => {
    const individual = {
        title: customer.individual.title,
        culture: customer.individual.language,
        firstName: customer.individual.firstName,
        lastName: customer.individual.lastName,
        address: {
            street: customer.individual.street,
            number: customer.individual.number,
            box: customer.individual.box,
            postalCode: customer.individual.postalCode,
            city: customer.individual.city,
            countryCode: customer.individual.country,
        },
        telephone: customer.individual.telephone,
        mobile: customer.individual.mobile,
        email: customer.individual.email,
    };

    const company = {
        name: customer.company.name,
        vatNumber: customer.company.vat,
        legalFormId: customer.company.legalForm,
        address: {
            street: customer.company.street,
            number: customer.company.number,
            box: customer.company.box,
            postalCode: customer.company.postalCode,
            city: customer.company.city,
            countryCode: customer.company.country,
        },
        telephone: customer.company.telephone,
        email: customer.company.email,
    };

    const taxation = {
        isTaxable: (customer.taxation.isTaxable !== null) ? customer.taxation.isTaxable : undefined,
        isCorporation: (customer.taxation.isCompany !== null) ? customer.taxation.isCompany : undefined,
        taxablePercent: (customer.taxation.taxablePercent !== null) ? customer.taxation.taxablePercent : undefined,
        isDisabledPerson: (customer.taxation.isHandicapped !== null) ? customer.taxation.isHandicapped : undefined,
        isDiplomatic: (customer.taxation.isDiplomat !== null) ? customer.taxation.isDiplomat : undefined,
        hasBelgianRegistration: (customer.taxation.hasBelgianLicensePlate !== null) ? customer.taxation.hasBelgianLicensePlate : undefined,
    };

    return {
        guid: "",
        origin: {
            origin: customer.origin,
            key: customer.key,
        },
        individual: individual,
        company: company,
        taxation: taxation,
    };
};