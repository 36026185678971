import React from "react";
import "./ApplicationsMenu.css";
import gql from "graphql-tag";
import {withQuery} from "../../general";
import {withNavigation} from "../navigation";
import {compose} from "recompose";

const GET_DATA = gql`
  {
    currentApp @client {
      name
      title 
      color
      blocks {
        name
        title
        icon
      }      
   }
   
   currentBlock @client {
     name
   }
  }
`;

class ApplicationsBlockMenu extends React.Component {


    render() {
        const {data: {currentApp, currentBlock}, navigate} = this.props;
        return (
            <div className="blocks-menu">
                {currentApp.blocks.map((b) => {
                        return (
                            <div key={b.name}
                                 id="menu-block"
                                 className={`app-menu-block text-center p-2 text-2xl ${(currentBlock.name === b.name) ? 'block-active' : ''}`}
                                 onClick={(item) => navigate.application(currentApp.name, b.name)}>
                                <span style={{color: 'white', fontSize: 26}} className={b.icon}/>
                                <br/>
                            </div>
                        )
                    }
                )}
            </div>
        );
    }
}

export default compose(
    withNavigation,
    withQuery({query: GET_DATA}),
)(ApplicationsBlockMenu);

