import React from 'react';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import {convertSlotsToTree} from './helpers';
import {map, sumBy, filter, minBy} from 'lodash';
import AddSlot from "./AddSlot";
import EditSlots from "./EditSlots";
import EditSlot from "./EditSlot";

import cogoToast from 'cogo-toast';
import DeleteSlots from "./DeleteSlots";
import DisableSlot from "./DisableSlot";
import * as _ from 'lodash';

import {
    Drawer,
    Button,
    TreeTable,
    HeaderToolbar,
    TableToolbar,
    LoadingIndicator,
    ErrorIndicator
} from '../../../../general';
import {FormActionButton} from '../../../../general/forms-final-form';
import {withSession} from "../../../../project/session";
import {getText} from "../../../../translations";

const DrawerTypes = {
    ADD_SLOT: 'ADD_SLOT',
    EDIT_SLOT: 'EDIT_SLOT',
    EDIT_SLOTS: 'EDIT SLOTS',
    DELETE_SLOTS: 'DELETE_SLOTS',
};

export const GET_COMPANY_ADDRESSES = gql`
  query CompanyAddresses($companyId: Int!) {
    companyAddresses(companyId: $companyId) {
      id    
      name
      default
      compound
      address {
        id
        addressFull
        countryCode
        city
        postCode
        slots{
            id
            area
            zone
            aisle
            side
            level
            position
            disabled
            col
            printEntry
            fullName
        }
      }
    }
  }
`;

const initialState = () => {
    return {
        selectedNode: null,
        selectedNodes: null,
        drawerVisible: false,
        drawerType: null,
        drawerData: null
    }
};

class SlotsConfiguration extends React.Component {
    constructor(props) {
        super(props);

        this.state = initialState();
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.renderDrawer = this.renderDrawer.bind(this);
        this.handleRowCheck = this.handleRowCheck.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.checkAddAllowed = this.checkAddAllowed.bind(this);
    }

    renderHeader(text) {
        return <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
            {text}
        </div>
    }

    renderTreeCell = (value, node) => {
        return (
            <div style={{display: 'flex', flex: 1, justifyContent: 'space-between', padding: 0}}>
                <div>
                    {value}
                </div>

                <div style={{marginRight: 10}}>

                    {node.children.length !== 0 &&
                    <Button size="md"
                            type="icon-only"
                            icon="fal fa-plus"
                            tooltip={getText('Add slot')}
                            className="mr-3"
                            onClick={(e) => {
                                e.stopPropagation();
                                this.toggleDrawer(DrawerTypes.ADD_SLOT, node)
                            }}/>}


                    {node._level > 0 &&
                    <Button size="md"
                            type="icon-only"
                            icon="fal fa-pencil-alt"
                            tooltip={getText("Edit slot")}
                            onClick={(e) => {
                                e.stopPropagation();
                                this.toggleDrawer(DrawerTypes.EDIT_SLOT, node)
                            }}/>}

                </div>
            </div>
        )
    };
    renderThreeStateCell = (value) => {
        return (
            <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                {this.renderThreeStateCellIcon(value)}
            </div>
        )
    };
    renderThreeStateCellIcon = (value) => {
        switch (value) {
            case 0: //FALSE
                return <span style={{fontSize: 18}} className="fal fa-square"/>;
            case 1: // TRUE
                return <span style={{fontSize: 18}} className="fal fa-check-square"/>;
            default: // Intermediate
                return <span style={{fontSize: 18}} className="fal fa-minus-square"/>;
        }
    };

    toggleDrawer(type = null, data) {
        if (type !== null) {
            this.setState({drawerVisible: true, drawerType: type, drawerData: data})
        } else {
            this.setState({drawerVisible: false, drawerType: null, drawerData: null})
        }
    }

    renderDrawer(refetch) {
        const {drawerType, drawerVisible, drawerData} = this.state;
        if (!drawerVisible) return null;

        switch (drawerType) {
            case DrawerTypes.ADD_SLOT:
                return this.renderAddSlotDrawer(drawerData, refetch);
            case DrawerTypes.EDIT_SLOT:
                return this.renderEditSlotDrawer(drawerData, refetch);
            case DrawerTypes.EDIT_SLOTS:
                return this.renderEditSlotsDrawer(drawerData, refetch);
            case DrawerTypes.DELETE_SLOTS:
                return this.renderDeleteSlotDrawer(drawerData, refetch);
            default:
                return null;
        }
    }

    onComplete(message, refetch){
        this.setState(initialState());
        refetch();
        cogoToast.success(message, { hideAfter: 5 });

    }

    renderAddSlotDrawer(data, refetch) {
        if (data === null) return null;

        const {session} = this.props;
        const {slot} = data;

        const title = () => (
            <div className="flex flex-grow justify-between items-center">
                <FormActionButton formId="add_slot_form">{getText("Save")}</FormActionButton>
            </div>
        );

        return (
            <Drawer title={title}
                    onClose={this.toggleDrawer}
                    visible={true}>

                <AddSlot data={slot}
                         session={session}
                         onComplete={(message) => this.onComplete(message, refetch)}
                         />
            </Drawer>
        )
    }

    onDisableComplete(isDisabled, isArray, refetch) {
        if (isArray) {

        } else {
            const disabled_value = !isDisabled ? 0 : 1;
            this.setState({drawerData: {...this.state.drawerData, disabled: disabled_value}})
        }

        this.toggleDrawer();
        refetch()
            .then((res) => {
                cogoToast.success(getText('Saved successfully!'), { hideAfter: 5 });
            });
    }

    findIds(row, arrResult) {
        arrResult = arrResult || [];

        if (row.slotId !== undefined) {
            arrResult.push(row.slotId);
        }
        _.forEach(row.children, (child) => {
            this.findIds(child, arrResult)
        });

        return arrResult;
    }


    renderEditSlotDrawer(data, refetch) {
        if (data === null) return null;
        const {session} = this.props;
        const ids = this.findIds(data);

        const title = () => (
            <div className="flex flex-grow justify-between items-center">
                <div className="flex flex-row">
                    <div className={`${data.disabled === 1 ? "hidden" : "block"}`}>
                        <FormActionButton formId="edit-slot">{getText("Save")}</FormActionButton>
                    </div>
                    <DisableSlot data={data} ids={ids} onComplete={(isDisabled, isArray) => this.onDisableComplete(isDisabled, isArray, refetch)}/>
                </div>

                <div className="items-center">
                    <Button type="icon-only"
                            icon="fal fa-trash-alt"
                            tooltip={getText("Delete")}
                            size="lg"
                            className="mr-2"
                            onClick={() => this.toggleDrawer(DrawerTypes.DELETE_SLOTS, data)}/>
                </div>
            </div>
        );

        return (
            <Drawer title={title}
                    onClose={this.toggleDrawer}
                    visible={true}>

                <EditSlot data={data}
                          session={session}
                          ids={ids}
                          onComplete={(message) => this.onComplete(message, refetch)}
                />
            </Drawer>
        )
    }

    renderEditSlotsDrawer(data, refetch) {
        if (data === null) return null;
        const ids = map(filter(data, (node) => node.slotId !== undefined), 'slotId');

        const title = () => (
            <div className="flex flex-grow justify-between items-center">

                <div className="flex flex-row">
                    <FormActionButton formId="edit-slots">{getText("Save")}</FormActionButton>

                    <DisableSlot data={data} ids={ids} onComplete={(isDisabled, isArray) => this.onDisableComplete(isDisabled, isArray, refetch)}/>
                </div>

                <div className="items-center">
                    <Button type="icon-only"
                            icon="fal fa-trash-alt"
                            tooltip={getText("Delete")}
                            size="lg"
                            className="mr-2"
                            onClick={() => this.toggleDrawer(DrawerTypes.DELETE_SLOTS, data)}/>

                </div>
            </div>
        );

        return (
            <Drawer title={title}
                    onClose={this.toggleDrawer}
                    visible={true}>

                <EditSlots ids={ids}
                           data={data}
                           onComplete={(message) => this.onComplete(message, refetch)}
                           />
            </Drawer>
        )
    }

    renderDeleteSlotDrawer(data, refetch) {
        if (data === null) return null;

        let ids = [];
        let description = "";

        if (data.length === undefined) {
            ids = this.findIds(data);
            description = data.children.length > 0 ? getText("You are going to delete all sub elements") : getText("It will be permanently deleted")
        } else {
            ids = map(filter(data, (node) => node.slotId !== undefined), 'slotId');
            description = getText("You are going to delete all sub elements");
        }

        return (
            <Drawer title={getText("Are you sure?")}
                    onClose={this.toggleDrawer}
                    visible={true}>

                <DeleteSlots
                    description={description}
                    visible={true}
                    ids={ids}
                    onComplete={(message) => this.onComplete(message, refetch)}
                    onCancel={this.toggleDrawer}/>
            </Drawer>
        )
    }

    onDeleteClick(ids, hasChildren) {
        const description = hasChildren ? getText("You are going to delete all sub elements") : getText("It will be permanently deleted");
        this.setState({delete_dialog_visible: true, delete_description: description, ids: ids})
    }

    handleRowCheck(data) {
        this.setState({selectedNodes: data});
    }

    checkAddAllowed(addresses) {
        const {selectedNodes} = this.state;

        if (selectedNodes === null && addresses.length === 1) {
            return true;
        } else if (selectedNodes !== null) {
            const highestNode = minBy(selectedNodes, 'level');

            if (highestNode && highestNode.children.length === 0) {
                return false;
            }

            // if other selected with different parent => false  else true
            if (filter(selectedNodes, (n) => n.parent !== highestNode.id && n.id !== highestNode.id).length > 0) {
                return false;
            }
            return true;
        }
        return false;
    }


    render() {
        const {session} = this.props;

        return (
            <Query query={GET_COMPANY_ADDRESSES} variables={{companyId: session.companyId}}
                   notifyOnNetworkStatusChange={true} fetchPolicy={'cache-and-network'}>
                {({loading, error, refetch, data}) => {

                    if (loading) return <LoadingIndicator/>;
                    if (error) return <ErrorIndicator description={error.message}/>;

                    const treeNodes = convertSlotsToTree(data);
                    const compoundAddresses = data.companyAddresses.filter(address => address.compound);
                    const slotsCount = sumBy(compoundAddresses, (ca) => ca.address.slots.length);
                    const {selectedNodes} = this.state;

                    const addSlotDisabled = !this.checkAddAllowed(compoundAddresses);

                    return (
                        <React.Fragment>

                            <HeaderToolbar title={getText("Compound configuration")}
                                           subtitle={`${compoundAddresses.length} ${(compoundAddresses.length > 1) ? getText("addresses") : getText("address")} - ${slotsCount} ${getText("slots")}`}
                            />

                            <TableToolbar title={getText("Slots by location")}>
                                <Button disabled={addSlotDisabled}
                                        onClick={(e) => this.toggleDrawer(DrawerTypes.ADD_SLOT, (selectedNodes === null) ? treeNodes[0] : minBy(selectedNodes, 'level'))}>
                                    {getText("Add slot")}
                                </Button>
                                {selectedNodes !== null && selectedNodes.length > 0 &&
                                <Button onClick={() => this.toggleDrawer(DrawerTypes.EDIT_SLOTS, selectedNodes)}>
                                    {getText("Edit slots")}
                                </Button>}
                                {selectedNodes !== null && selectedNodes.length > 0 &&
                                <Button type="reject"
                                        onClick={() => this.toggleDrawer(DrawerTypes.DELETE_SLOTS, selectedNodes)}>
                                    {getText("Delete all")}
                                </Button>                               }
                            </TableToolbar>

                            <TreeTable
                                data={treeNodes}
                                options={{
                                    treeProperty: "name",
                                    treeHeader: "Location",
                                    treeCell: this.renderTreeCell,
                                    width: 500,
                                    columns: [
                                        {
                                            property: 'disabled',
                                            cell: this.renderThreeStateCell,
                                            header: () => this.renderHeader('Disabled'),
                                            width: 150,
                                        },
                                        {
                                            property: 'print',
                                            cell: this.renderThreeStateCell,
                                            header: () => this.renderHeader('Print?'),
                                            width: 150,
                                        },
                                        {
                                            property: '',
                                            header: '',
                                        }
                                    ],
                                    onRowCheck: this.handleRowCheck,
                                    checkboxes: true,
                                }}
                            />

                            {this.renderDrawer(refetch)}

                        </React.Fragment>
                    )
                }}
            </Query>
        )
    }
}

export default withSession(SlotsConfiguration);

