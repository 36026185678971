import React, {Component} from 'react';
import {languages, getLanguageByLocale} from "./index";
import {Animated} from "react-animated-css/lib/index";
import {SESSION_SET_LOCALE, withSession} from '../project/session';
import {compose} from "recompose";
import {withMutation} from "../general";

const animationIn = "fadeIn";
const animationOut = "fadeOut";
const animationDuration = 150; // in ms

class LanguagePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false
        }
    }

    toggleHover() {
        this.setState({isVisible: !this.state.isVisible})
    }

    setLanguage(language) {
        const {mutation}  = this.props;
        mutation.mutate({
            variables: {
                locale: language.code,
            }
        }).then(() => {this.setState({isVisible: false});});
    }

    render() {
        const defaultLanguage = getLanguageByLocale(this.props.session.locale);


        return (
            <div className="flex flex-col justify-end text-white relative w-32" onMouseEnter={() => this.toggleHover()} onMouseLeave={() => this.toggleHover()}>
                <div className="flex flex-row items-center justify-end cursor-pointer" style={{zIndex: 50}}>
                    <img className="mr-2" src={defaultLanguage.flag} style={{maxHeight: 10}} alt={defaultLanguage.name}/>
                    {defaultLanguage.name}
                    <i className="fas fa-caret-down ml-1"/>
                </div>
                <div className="w-32 absolute right-0 bg-transparent" style={{height: 20, top: 10}}/>
                <Animated className="absolute right-0 w-32 bg-gray-100 flex flex-col rounded shadow" animationIn={animationIn} animationOut={animationOut} animationInDuration={animationDuration}
                          animationOutDuration={animationDuration} isVisible={this.state.isVisible} animateOnMount={false} style={{top: 20, zIndex: 51}}>
                    {languages.filter(lang => lang.code !== defaultLanguage.code).map(lang => {
                        return (
                            <div key={lang.code}
                                 className="spin-animation-hover flex flex-row cursor-pointer items-center text-gray-600 px-5 py-2 border-b border-solid border-gray-200 hover:text-gray-900"
                                 onClick={() => this.setLanguage(lang)}>
                                <img className="mr-2" src={lang.flag} style={{maxHeight: 10}} alt={lang.name}/>{lang.name}
                            </div>
                        )
                    })}

                </Animated>
            </div>
        );
    }
}


export default compose(
    withSession,
    withMutation({mutation: SESSION_SET_LOCALE}),
)(LanguagePicker)

