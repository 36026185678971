import React from 'react';
import {Button, HeaderToolbar, withQuery, TableToolbar} from '../../../general'
import gql from 'graphql-tag';
import Details from './Details';
import AddressDetail from './AddressDetail';
import AddAddress from './AddAddress';
import {map, orderBy, head, filter} from 'lodash';
import GoogleMapReact from 'google-map-react';
import {withSession} from "../../../project/session";
import {compose} from "recompose";
import {getText} from "../../../translations";


const GET_COMPANY = gql`
query Company {
  company {
        id
        name
        vatNumber
        primaryEmail
        primaryMobile
        primaryTelephone
        companyaddressSet{
            id
            name
            default
            compound            
            address {
                id
                addressFull
                address
                address1
                address2
                countryCode
                city
                postCode                
                latitude
                longitude
            }
        }
    }
  countries {
     code
     name
  }
}
`;


class MapMarker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {highLight: false};
    }

    render() {
        const {ca, onClick} = this.props;
        const icon = 'far fa-map-marker-alt';
        const color = ca.default ? 'text-blue-600' : 'text-gray-600';

        return (
            <div style={{position: 'relative'}}
                 onClick={onClick}
                 onMouseLeave={() => this.setState({highLight: false})}
                 onMouseOver={() => this.setState({highLight: true})}>
                <i className={`${icon} ${color} ${(this.state.highLight) ? 'text-4xl' : 'text-3xl'}`}/>
            </div>
        )
    }
}


class Company extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            drawerVisible: false,
            type: 'personal',
            ca: null,
        };
    }

    getDrawerForm(type, data) {
        const {data: {company, countries}} = this.props;

        switch (type) {
            case "personal":
                return <Details company={company}
                                onClose={() => this.setState({drawerVisible: false})}/>;
            case "address":
                const ca = head(filter(company.companyaddressSet, (ca) => ca.id === this.state.ca));
                return <AddressDetail companyAddress={ca} countries={countries}
                                      onClose={() => this.setState({drawerVisible: false})}/>;

            case "address-add":
                return <AddAddress countries={countries}
                                   onClose={() => this.setState({drawerVisible: false})}/>;
            default:
                return null;
        }
    }


    toggleDrawer(e, type) {
        e.stopPropagation();
        this.setState({drawerVisible: (this.state.type === type) ? !this.state.drawerVisible : true, type: type})

    }

    handleAddressClick(companyAddress) {
        this.setState({drawerVisible: true, type: 'address', ca: companyAddress.id});
    }

    render() {
        const {drawerVisible} = this.state;

        const {data: {company}} = this.props;

        return (
            <div className="flex flex-col flex-1">
                <HeaderToolbar title={getText('Company settings')} subtitle={getText("Manage your company information")}/>


                <div className="flex">

                    <div className="flex flex-col" style={{minWidth: 400, height: 'calc(100vh - 162px)'}}>

                        <div className="ml-2 mr-2 max-w-md cursor-pointer flex bg-white p-3"
                             onClick={(e) => this.toggleDrawer(e, 'personal')}>

                            <div className="flex-1 text-gray-800 pl-2">
                                <div className="text-xl font-medium"> {company.name}</div>
                                <div className="text-xs mr-2 pt-1">{company.vatNumber}</div>

                                <div className="mt-4">
                                    {/*<span className="fal fa-envelope mr-2 text-center text-base" style={{fontSize: 16, width: 20}}/>*/}
                                    <span className="text-xs mr-2 "><i className="fal fa-envelope" /></span>
                                    <span className="text-sm mr-2 font-medium">{company.primaryEmail}</span>
                                </div>

                                <div className="pt-1">
                                    {/*<span className="fal fa-envelope mr-2 text-center text-base" style={{fontSize: 16, width: 20}}/>*/}
                                    <span className="text-xs mr-2 "><i className="fal fa-phone"/></span>
                                    <span className="text-sm mr-2 font-medium">{company.primaryTelephone}</span>
                                </div>

                                <div className="pt-1">
                                    {/*<span className="fal fa-mobile mr-2 text-center font-base" style={{fontSize: 16, width: 20}}/>*/}
                                    <span className="text-xs mr-2 "><i className="fal fa-mobile"/></span>
                                    <span className="text-sm mr-2 font-medium">{company.primaryMobile}</span>

                                </div>
                            </div>

                        </div>

                        <div className="m-2 bg-white pl-3 pr-3 flex flex-col flex-grow mb-0">
                            <TableToolbar title="Addresses">
                                <Button active={this.state.drawerVisible && this.state.type === "address_add"} onClick={() => this.setState({drawerVisible: true, type: 'address-add'})}>
                                    {getText("Add new")}
                                </Button>
                            </TableToolbar>

                            {map(orderBy(company.companyaddressSet, ['default'], ['desc']), (ca, i) => {
                                const icon = ca.compound ? 'fal fa-parking' : 'far fa-map-marker-alt';
                                return (
                                    <div key={i}
                                         className={`flex ${(ca.id === this.state.ca) ? 'bg-gray-300' : 'bg-gray-100'} py-4 px-2 cursor-pointer mb-1`}
                                         onClick={() => this.handleAddressClick(ca)}>
                                        <div className="flex text-gray-800 max-w-sm">
                                        <span
                                            className={`${icon} ${(ca.default) ? 'text-blue-600' : 'text-gray-600'} text-4xl`}
                                        />
                                        </div>

                                        <div className="flex-1 text-gray-800 pl-3">
                                            <div className="text-xl font-small"> {ca.address.addressFull}</div>
                                            <span
                                                className="text-xs mr-2">{ca.address.postCode} {ca.address.city}</span>
                                        </div>

                                    </div>

                                )
                            })}
                        </div>
                    </div>


                    <div className="mr-2 mb-2" style={{width: '100%', height: '100%'}}>

                        <GoogleMapReact
                            bootstrapURLKeys={{key: 'AIzaSyCuuvq9FOg5q4deZ_TmcGMhX1SadPmdkII'}}
                            defaultCenter={{
                                lat: 51.20,
                                lng: 4.47
                            }}
                            defaultZoom={8}
                            options={{streetViewControl: true}}
                        >

                            {map(company.companyaddressSet, (ca, i) =>
                                <MapMarker key={i}
                                           ca={ca}
                                           lat={ca.address.latitude}
                                           lng={ca.address.longitude}
                                           onClick={() => this.handleAddressClick(ca)}
                                />
                            )}

                        </GoogleMapReact>
                    </div>


                </div>


                {drawerVisible &&
                this.getDrawerForm(this.state.type)
                }
            </div>
        );
    }
}

export default compose(
    withSession,
    withQuery({query: GET_COMPANY, variables: (props) => ({companyId: props.session.companyId})})
)(Company);
