import gql from "graphql-tag";

const GET_VIN_SUGGESTIONS = gql`
query GetVinSuggestions ($vin: String!){
    vinLookup (vin: $vin){
        vin
    }
}`;

const GET_CREDITS = gql`
query VehicleCredit{
    vehicleCredit{
        id
        type
        price
        total
    }
}`;


export const queries = {
    GET_VIN_SUGGESTIONS: GET_VIN_SUGGESTIONS,
    GET_CREDITS: GET_CREDITS
};