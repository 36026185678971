import React from 'react';
import Buttons from "./Buttons";
import Summary from "./Summary";
import WizardAnimation from "./WizardAnimation";
import {map, orderBy} from "lodash";
import {vehicleSetCatalog, vehicleSetVersion} from "../vehicleData";
import {LoadingIndicator, ErrorIndicator} from "../../../../general";
import {useTranslate} from "../../../../translations";
import ConstructorInformation from "../components/ConstructorInformation";
import moment from "moment";

const Version = ({onNext, onPrev, onStepClick, wizard, data: {dispatch, inspection, versions, dataFetching, dataFetchError, resetGeneralInfo}}) => {
    const {getText} = useTranslate();
    const currentVersion = inspection.vehicle.versionId;

    const all_versions = (inspection.vehicleVersions.length > 0) ? inspection.vehicleVersions : map(versions, (version) => ({

        versionId: version.id,
        versionText: version.name,
        co2: version.co2,
        seats: version.seats,
        doors: version.doors,
        powerKw: version.kw,
        powerHp: version.hp,
        start: version.start,
        stop: version.stop,
        fuelId: version.fuelType.id,
        fuelText: version.fuelType.description,
        gearboxId: version.gearboxType.id,
        gearboxText: version.gearboxType.description,
        engineId: version.engine.id,
        engineText: version.engine.description,
        bodyId: version.bodyType.id,
        bodyText: version.bodyType.description,
        wheelFormulaId: version.wheelFormula.id,
        wheelFormulaText: version.wheelFormula.description,
        emissionNormId: version.euroNorm.id,
        emissionNormText: version.euroNorm.description,
    }));

    const manual = inspection.manual;

    return (
        <div className="flex h-full w-full">
            <div className="w-1/4">
                <Summary data={wizard} onStepClick={onStepClick}/>
            </div>

            <div className="w-3/4 flex flex-col flex-1 bg-white p-2 relative">
                <div className="flex flex-1 overflow-hidden ">

                    {dataFetching &&
                    <LoadingIndicator/>}

                    {dataFetchError &&
                    <ErrorIndicator description={"Error fetching data, please try again"}/>}

                    {!dataFetching && !dataFetchError &&
                    <WizardAnimation>
                        <div className="flex w-full flex-col">


                            <div className="flex row flex-1 overflow-hidden ">
                                <div className="w-1/2 flex flex-col flex-1 h-full overflow-scroll ">

                                    <div
                                        className="flex flex-row justify-between p-2 pl-0 border border-gray-300 border-t-0 border-l-0 border-r-0  font-medium mb-2">

                                    <span
                                        onClick={() => (manual || inspection.vehicle.versionId > 0) ? onPrev() : null}
                                        className={`${(manual || inspection.vehicle.versionId > 0) ? 'cursor-pointer' : ''}`}>

                                        {(manual || inspection.vehicle.versionId > 0) &&
                                        <i className="fal fa-chevron-left mr-2 text-xs font-medium cursor-pointer"/>}
                                        {getText('Select version')}

                                    </span>

                                        <i onClick={resetGeneralInfo} className="fal fa-bolt cursor-pointer"
                                           style={{fontSize: 14}}/>
                                    </div>


                                    {map(orderBy(all_versions, 'name'), (version) => {
                                        return <div
                                            key={`power_${version.versionId}`}
                                            className={`${(version.versionId === currentVersion) ? 'bg-blue-400 text-white' : 'bg-gray-100 text-gray-900 hover:bg-blue-400 hover:text-white'} flex flex-col px-2 py-2 mb-1 mr-1 cursor-pointer group`}
                                            onClick={() => {
                                                if (currentVersion !== version.versionId)
                                                    vehicleSetVersion(dispatch, version);
                                                onNext();
                                            }}>


                                            <div>
                                                <span
                                                    className={`font-medium text-sm  mr-1 ${(version.versionId === currentVersion) ? 'text-white' : 'text-blue-500 group-hover:text-white'}`}>
                                                    {version.versionText}
                                                </span>
                                            </div>
                                            <div
                                                className={`text-xs font-medium mt-1 ${(version.versionId === currentVersion) ? 'text-blue-200' : ' text-gray-600 group-hover:text-blue-200'}`}>
                                                <span>
                                                    {getText('From')} {moment(version.start).format('MM/YYYY')}
                                                </span>
                                                {version.stop &&
                                                <span>
                                                    <i className="fal fa-arrow-right font-medium ml-1 mr-1"></i>
                                                    {moment(version.stop).format('MM/YYYY')}
                                                </span>}
                                            </div>
                                            <div className="mt-1">
                                                <span
                                                    className={`text-xxs ${(version.versionId === currentVersion) ? 'text-blue-200' : ' text-gray-600 group-hover:text-blue-200'}`}>

                                                    {version.powerKw} kW  ({version.powerHp} hp) | {`${version.doors} drs. ${version.seats} pl.`} | {version.gearboxText}
                                                </span>
                                            </div>

                                        </div>
                                    })}

                                </div>

                                <div className="w-1/2 flex flex-col flex-1 h-full">
                                    <div
                                        className="flex p-2 pl-0 border border-gray-300 border-t-0 border-l-0 border-r-0 font-medium mb-2 ml-2">
                                        {getText('Constructor information')}
                                    </div>

                                    <ConstructorInformation vin={inspection.vehicle.vin}
                                                            onDataFetched={(data) => {
                                                                if (data !== null && data !== undefined) {
                                                                    vehicleSetCatalog(dispatch, data.catalogPrice, data.catalogPriceWithOptions);
                                                                }
                                                            }}
                                    />
                                </div>
                            </div>

                        </div>

                    </WizardAnimation>}
                </div>

                {!dataFetching && !dataFetchError && (manual || inspection.vehicle.versionId > 0) &&
                <Buttons onNext={onNext}
                         onPrev={onPrev}
                         data={wizard}
                         nextDisabled={inspection.vehicle.versionId === 0}
                />}

            </div>
        </div>
    );
};

export default Version;