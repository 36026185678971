import React from 'react';
import Buttons from "./Buttons";
import Summary from "./Summary";
import {Checks as ChecksOverview} from '../components/checks';
import WizardAnimation from "./WizardAnimation";


const Checks = ({onNext, onPrev, onStepClick, checks, wizard, data}) => {

    return (
        <div className="flex h-full w-full">
            <div className="w-1/4">
                <Summary data={wizard} onStepClick={onStepClick}/>
            </div>

            <div className="w-3/4 flex flex-col flex-1 bg-white p-2">
                <div className="flex flex-1 overflow-scroll ">
                    <WizardAnimation>
                        <ChecksOverview value={data.inspection.vehicle.checks}
                                        checkGroups={data.checkGroups}
                                        dispatch={data.dispatch}
                        />
                    </WizardAnimation>
                </div>

                <Buttons onNext={() => onNext(checks)}
                         onPrev={() => onPrev(checks)}
                         data={wizard}
                />
            </div>
        </div>
    )
};

export default Checks;