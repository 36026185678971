import React from "react";
import PropTypes from "prop-types";

class CollapsibleHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: false,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.forceOpen && this.state.collapsed) {
            this.setState({collapsed: false})
        }
    }

    render() {
        const {title, children, collapsible} = this.props;
        const {collapsed} = this.state;

        return (
            <div className='flex flex-1 flex-col cursor-pointer collapsible-panel' >
                <div
                    className="flex flex-row justify-between p-2 pl-0 border border-gray-400 border-t-0 border-l-0 border-r-0  font-medium"
                    onClick={() => {
                        if (collapsible) {
                            this.setState({collapsed: !collapsed})
                        }
                    }}>
                    <span>{title}</span>
                    {collapsible && collapsed && <i style={{fontSize: 16}} className="fal fa-plus-square" />}
                    {collapsible && !collapsed && <i style={{fontSize: 16}} className="fal fa-minus-square" />}
                </div>

                {!collapsed &&
                <React.Fragment>
                    {children}
                </React.Fragment>}
            </div>
        )
    }
}

CollapsibleHeader.propTypes = {
    forceOpen: PropTypes.bool,
    collapsible: PropTypes.bool,
    isOpen: PropTypes.bool,
};

CollapsibleHeader.defaultProps = {
    forceOpen: false,
    collapsible: true,
    isOpen: true,
};

export default CollapsibleHeader;