import React, {useState} from 'react';
import {default as BaseTextInput} from "../base/TextInput";
import * as PropTypes from "prop-types";
import LabelContainer from "./LabelContainer";

const TextInput = ({name, label, value, required, placeholder, disabled, onChange, className, inputClassName, showBorder, error, labelContainerClassName, valueContainerClassName, textClassName}) => {
    const [hasFocus, setHasFocus] = useState(false);

    return (
        <LabelContainer label={label}
                        disabled={disabled}
                        className={className}
                        error={error}
                        hasError={error !== ""}
                        hasValue={value !== ""}
                        onFocus={() => setHasFocus(true)}
                        onBlur={() => setHasFocus(false)}
                        labelContainerClassName={labelContainerClassName}
                        valueContainerClassName={valueContainerClassName}
                        textClassName={textClassName}>

            <BaseTextInput name={name}
                           value={value}
                           required={required}
                           placeholder={placeholder}
                           disabled={disabled}
                           onChange={onChange}
                           className={inputClassName}
                           showBorder={showBorder}
                           hasError={error !== ""}
                           hasFocus={hasFocus}
            />
        </LabelContainer>

    );
};

TextInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    value: PropTypes.string.isRequired,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    showBorder: PropTypes.bool,
    error: PropTypes.string,
};

TextInput.defaultProps = {
    name: "TextInput",
    label: "Label",
    value: "",
    required: false,
    placeholder: "",
    disabled: false,
    onChange: null,
    className: "",
    inputClassName: "",
    showBorder: true,
    error: "",

};

export default TextInput;


