export const INITIAL_FEATURES = [];


const FEATURE_TYPES = {
    FEATURES_SET_FEATURES: 'FEATURES_SET_FEATURES',
};

export const featuresReducer = (state, action) => {
    switch (action.type) {
        case FEATURE_TYPES.FEATURES_SET_FEATURES:
            return [...action.payload.values];

        default:
            return state;
    }
};

export const featuresSetFeatures = (dispatch, features) => {
    dispatch({
        type: FEATURE_TYPES.FEATURES_SET_FEATURES,
        payload: {
            values: features
        },
    });
};

