import gql from "graphql-tag";



export const TEMP_INSPECTION_SAVE = gql`
 mutation($data: TempInspectionSaveCommand!) {
    tempInspectionSaveHandler(data: $data) {
      result {
        transactionId   
        tempInspectionId
        version
        success
        errors {
          code
          message
        }
      }
    }
 }        
`;

export const TEMP_INSPECTION_CONTEXT_SAVE = gql`
 mutation($data: TempInspectionContextSaveCommand!) {
    tempInspectionContextSaveHandler(data: $data) {
      result {
        transactionId
        success
        errors {
          code
          message
        }
      }
    }
 }        
`;


export const TEMP_INSPECTION_TO_INSPECTION = gql`
 mutation($data: TempInspectionConvertToInspectionCommand!) {
    tempInspectionConvertToInspectionHandler(data: $data) {
        result {
            transactionId   
            inspectionId
            success
            errors {
              code
              message
            }
        }
    } 
 }
`;


export const TEMP_INSPECTION_INACTIVATE = gql`
 mutation($data: TempInspectionInactivateCommand!) {
    tempInspectionInactivateHandler(data: $data) {
        result {
            transactionId
            tempInspectionId
            success
            errors {
              code
              message
            }
        }
    } 
 }
`;



