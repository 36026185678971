import React, {Component} from 'react';
import {some, sortBy} from "lodash";
import FilterItem from "./FilterItem";
import {Button} from "../../../general";
import {SearchField} from "../../../general/base";
import {getText} from "../../../translations";

const transitionDuration = 50;
const defaultWidth = 250;
const collapsedWidth = 50;

class Filters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: defaultWidth,
            isCollapsed: false,
            filter: {
                roles: [],
                states: [],
                searchTerm: ""
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.filters !== undefined && nextProps.filters !== null && this.state.filter !== nextProps.filters){
            this.setState({filter: nextProps.filters})
        }
    }

    handleChange(e){
        const {onFilterChanged} = this.props;
        const value = e.target.value;
        this.setState({filter: {
                ...this.state.filter,
                searchTerm: value
            }}, () => onFilterChanged(this.state.filter))
    }

    clearSearch(e){
        const {onFilterChanged} = this.props;
        this.setState({filter: {
                ...this.state.filter,
                searchTerm: ""
            }}, () => onFilterChanged(this.state.filter))
    }

    renderStates(states) {
        const {onFilterChanged} = this.props;
        return (
            <div>
                <div className="mt-2 py-2 text-xs font-medium capitalize">{getText('States')}</div>
                {states.map(item => <FilterItem text={item.name}
                                                value={item.id}
                                                count={item.count}
                                                key={item.name}
                                                selected={some(this.state.filter.states, state => state.id === item.id)}
                                                onClick={(value) => {
                                                    const stateExists = some(this.state.filter.states, state => state.id === item.id);
                                                    const states = stateExists ? this.state.filter.states.filter(state => state.id !== item.id) : [...this.state.filter.states, {
                                                        id: value,
                                                        name: item.name,
                                                        count: item.count
                                                    }];


                                                    this.setState({
                                                        filter: {
                                                            ...this.state.filter,
                                                            states: states
                                                        }
                                                    }, () => onFilterChanged(this.state.filter))
                                                }}/>
                )}
            </div>
        )
    }

    renderRoles(roles) {
        const {onFilterChanged} = this.props;

        return (
            <div>
                <div className="mt-2 py-2 text-xs font-medium capitalize">{getText('Roles')}</div>
                {roles.map(item => <FilterItem text={item.name}
                                               value={item.id}
                                               count={item.count}
                                               key={item.name}
                                               selected={some(this.state.filter.roles, state => state.id === item.id)}
                                               onClick={(value) => {
                                                   const roleExists = some(this.state.filter.roles, role => role.id === item.id);
                                                   const roles = roleExists ? this.state.filter.roles.filter(role => role.id !== item.id) : [...this.state.filter.roles, {
                                                       id: value,
                                                       name: item.name,
                                                       count: item.count
                                                   }];

                                                   this.setState({
                                                       filter: {
                                                           ...this.state.filter,
                                                           roles: roles
                                                       }
                                                   }, () => onFilterChanged(this.state.filter))
                                               }}/>
                )}
            </div>
        )
    }

    renderFilters() {
        const {filterData: {states, roles}} = this.props;
        return (
            <React.Fragment>
                <SearchField onChange={this.handleChange} value={this.state.filter.searchTerm} clearSearch={this.clearSearch} className="mt-4"/>
                {this.renderStates(sortBy(states, 'name'))}
                {this.renderRoles(sortBy(roles, 'name'))}
            </React.Fragment>
        )
    }

    render() {
        const {width, isCollapsed} = this.state;

        return (
            <div className="filters-container flex bg-white p-2 sticky h-full flex-col" style={{width: width, top: "4rem", transition: `width ${transitionDuration}ms ease-in-out`}}>
                <div className="flex flex-row items-center justify-between border-b border-gray-200 pb-2">
                    {!isCollapsed && <div className="text-xs font-extrabold">Filters</div>}
                    {!isCollapsed && <Button style={{marginRight: 0}} onClick={() => this.setState({width: collapsedWidth, isCollapsed: true})}><i className="fal fa-times"/></Button>}
                    {isCollapsed && <Button style={{marginRight: 0}} onClick={() => this.setState({width: defaultWidth, isCollapsed: false})}><i className="fal fa-arrow-to-right"/></Button>}
                </div>

                {!isCollapsed && this.renderFilters()}

            </div>
        );
    }
}

export default Filters;