import React, {useReducer, useState} from 'react';
import {
    Drawer, withQuery,
} from '../../../general';
import {map, toUpper} from 'lodash';
import gql from "graphql-tag";
import moment from "moment";
import Summary from "./components/Summary";
import Home, {GET_TEST_LEAD_STATE} from "./components/home/Home";
import Customer from "./components/customer/Customer";
import Inspection from "./components/inspection/Inspection";
import History from "./components/home/components/history/History";
import B2B from "./components/B2B";
import Valuation from "./components/Valuation";
import Logistics from "./components/Logistics";
import {LEAD_DETAIL_QRY} from "./queries";
import {useApolloClient, useQuery} from "@apollo/react-hooks";


const tabs = {
    HOME: {
        id: 'HOME',
        icon: 'fas fa-random'
    },
    CUSTOMER: {
        id: 'CUSTOMER',
        icon: 'fas fa-clipboard-user'
    },
    INSPECTION: {
        id: 'INSPECTION',
        icon: 'fas fa-car',
    },
    HISTORY: {
        id: 'HISTORY',
        icon: 'fas fa-history',
    },
    BENCHMARKS: {
        id: 'BENCHMARKS',
        icon: 'fas fa-analytics',
    },
    B2B: {
        id: 'B2B',
        icon: 'fas fa-user-tie'
    },
    LOGISTICS: {
        id: 'LOGISTICS',
        icon: 'fas fa-warehouse'

    },
};


export const GET_TEST_ROLE = gql`
  {
    testIsManager @client
  }
`;

const Detail = ({onClose, data: {lead}}) => {
    const [currentTab, setCurrentTab] = useState(tabs.HOME.id);
    const {inspection, customer} = lead;
    const {vehicle} = inspection;

    // TODO REMOVE
    const client = useApolloClient();
    const {data, loading} = useQuery(GET_TEST_ROLE);


    if (loading) {
        return <div>loading</div>
    }

    const setIsManager = (isManager) => {
        client.writeData({data: {testIsManager: isManager}})
    };

    const testIsManager = data.testIsManager;

    // END REMOVE

    const title = () => {


        let compList = [];
        if (vehicle.vin !== "") {
            compList.push(<span>{vehicle.vin}</span>)
        }
        if (vehicle.firstRegistration !== null) {
            compList.push(<span>{moment(vehicle.firstRegistration).format('DD/MM/YYYY')}</span>)
        }

        return (
            <div className="flex flex-grow justify-between items-center" style={{height: 33}}>

                <div className="flex flex-row">

                    <div className="flex flex-col justify-center mr-2">
                        <span className="bg-gray-200 text-gray-900 p-2 px-4 text-sm rounded">{lead.state}</span>
                    </div>

                    <div className="flex flex-col">
                        <div>
                                <span className="text-blue-500 mb-1 mr-1">
                                    {`${toUpper(vehicle.make)} ${toUpper(vehicle.model)}`}
                                </span>
                            <span className="text-gray-900 text-xs">{vehicle.version}</span>
                        </div>

                        {/*<span className="text-gray-500 text-xs">*/}
                        {/*       {map(compList, (comp, i) => {*/}

                        {/*           return (*/}
                        {/*               <React.Fragment key={i}>*/}
                        {/*                   {comp}*/}
                        {/*                   {(i < (compList.length - 1)) ? <span className="ml-1 mr-1">|</span> : ""}*/}
                        {/*               </React.Fragment>*/}
                        {/*           )*/}
                        {/*       })}*/}
                        {/*    </span>*/}

                        <span className="text-gray-500 text-xs">
                            {customer.displayName}
                            </span>
                    </div>
                </div>


                <div className="flex">
                    {testIsManager ? <span className="text-xs" onClick={() => setIsManager(!testIsManager)}>Default User</span> : <span className="text-xs" onClick={() => setIsManager(!testIsManager)}>Manager</span>}

                </div>

            </div>
        )
    };

    return (
        <Drawer size="lg"
                title={title}
                onClose={onClose}
                visible={true}
                contentStyle={{margin: 0, padding: 0}}
        >


            <div className="flex flex-col w-full h-full overflow-hidden">

                <div className="flex flex-row bg-white w-full p-2" style={{height: 155}}>
                    <Summary vehicle={vehicle}/>
                </div>

                <div className="flex flex-row w-full h-full">

                    <div className="w-16 bg-gray-1 h-full ">
                        {map(tabs, (tab) => {
                            return (
                                <div key={tab.id} onClick={() => setCurrentTab(tab.id)}
                                     className={`${(currentTab === tab.id) ? 'text-blue-500  bg-white border-blue-500' : 'text-gray-600  hover:bg-white hover:text-blue-500 border-gray-1 hover:border-blue-500 '} p-4 text-xl text-center border-4 border-t-0 border-r-0 border-b-0 cursor-pointer`}>
                                    <i className={tab.icon}/>
                                </div>
                            )
                        })}
                    </div>


                    <div className="w-full bg-white overflow-scroll">

                        {currentTab === tabs.HOME.id &&
                        <Home lead={lead} isManager={testIsManager}/>}

                        {currentTab === tabs.CUSTOMER.id &&
                        <Customer customerId={customer.id}/>}

                        {currentTab === tabs.INSPECTION.id &&
                        <Inspection inspectionId={inspection.id}/>}

                        {currentTab === tabs.HISTORY.id &&
                        <History/>}

                        {currentTab === tabs.BENCHMARKS.id &&
                        <Valuation/>}

                        {currentTab === tabs.B2B.id &&
                        <B2B/>}

                        {currentTab === tabs.LOGISTICS.id &&
                        <Logistics/>}


                    </div>
                </div>

            </div>
        </Drawer>
    )
};
export default withQuery({
    query: LEAD_DETAIL_QRY,
    variables: (props) => {
        return {
            leadId: props.lead.id
        }
    }
})(Detail);

