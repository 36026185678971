import React from 'react';

const BuyCredits = ({onCancel}) => {

    return (
        <div className="flex h-full w-full" >

           Todo control for buying credits

            <button onClick={onCancel}>CLOSE</button>
        </div>
    )
};

export default BuyCredits;