import React from "react";
import {default as BaseSelect} from "../base/Select";
import LabelContainer from "./LabelContainer";

const Select = ({name, label, options, value, required, placeholder, disabled, onChange, className, inputClassName, showBorder, error, hasError, searchable}) => {

    return (
        <LabelContainer label={label}
                        disabled={disabled}
                        className={className}
                        error={error}
                        hasError={hasError}
                        hasValue={value !== ""}>

            <BaseSelect name={name}
                        placeholder={placeholder}
                        value={value}
                        options={options}
                        disabled={disabled}
                        onChange={onChange}
                        searchable={searchable}
                        required={required}
                        inputClassName={inputClassName}
                        showBorder={showBorder}
                        hasError={hasError}
            />

        </LabelContainer>
    );
};

export default Select;


