import gql from 'graphql-tag';

const userProfileQuery = gql`
query UserProfile {
  userProfile {
    user {
      id,
      firstName
      lastName
      email
       groups{
        name
      }
    }
    id
    title
    telephone
    mobile    
    smsActive
  }
}
`;


const personalInformationChangeMutation = gql`
  mutation UserProfileUpdateHandler($data: UserProfileUpdateCommand!) {
    userProfileUpdateHandler(data: $data) {
        result {
         transactionId
            success
            errors {
                code
                message
            }
        }
    }
  }
`;

const passwordResetMutation = gql`
  mutation UserPasswordChangeHandler($data: UserPasswordChangeCommand!) {
    userPasswordChangeHandler(data: $data) {
        result {
         transactionId
            success
            errors {
                code
                message
            }
        } 
    }
  }
`;

const userProfileChangedSubscription = gql`
    subscription onUserProfileChanged {
      userProfileChanged {
        payload {
          transactionId
          userProfile {
            user {
              id
              firstName
              lastName
              email
              groups {
                name
              }
            }
            id
            title
            telephone
            mobile
            smsActive
          }
        }
      }
    }
`;

const smsNotificationsChangeMutation = gql`
  mutation UserToggleSmsNotificationsHandler($data: UserToggleSmsNotificationCommand!) {
    userToggleSmsNotificationsHandler(data: $data) {
        result {
            transactionId   
            success
            errors {
                code
                message
            }
        }
    }
 }
`;



export const queries = {
    userProfile: userProfileQuery,
};

export const mutations = {
    personalInformationChange: personalInformationChangeMutation,
    passwordReset: passwordResetMutation,
    smsNotificationsChange: smsNotificationsChangeMutation,
};

export const subscriptions = {
    userProfileChanged: userProfileChangedSubscription
};