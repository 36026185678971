import {configureCache} from "./apollo";
import gatherConfig from "./applications";

let config = {

};

// function addDefaultRoutes() {
//     config.routes = [
//         <Route exact path='/login' component={Login}/>,
//         <Route exact path='/register' component={Register}/>,
//         <Route exact path='/pw-forget' component={Login}/>,
//         <Route exact path='/' component={Login}/>,
//         <Route exact path='/:application/:block' render={(props) => <App {...props} config={config}/>}/>,
//
//     ];
// }


function runOverModules() {

}


function fillConfig() {
    // addDefaultRoutes();
    runOverModules();
    config.modules = gatherConfig();
    configureCache(config.modules);

    return config;
}



export default fillConfig;
