import {find, get, map, orderBy, values} from "lodash";
import moment from "moment";

export const dataToLeadCardData = (tempInspections, leads) => {

    const leadsData = map(leads, (lead) => {
        return mapDataToProps(
            lead.id,
            "lead",
            lead.inspection.vehicle.pictureThumbnailUrl,
            lead.inspection.vehicle.vin,
            lead.inspection.vehicle.make,
            lead.inspection.vehicle.model,
            lead.inspection.vehicle.version,
            (lead.inspection.vehicle.firstRegistration !== null) ? moment(lead.inspection.vehicle.firstRegistration).format('DD/MM/YYYY') : "",
            lead.inspection.vehicle.kilometers,
            `${lead.businessUnit.name} - ${lead.brand.name}`,
            `${lead.user.lastName} ${lead.user.firstName}`,
            lead.state,
            0,
            "",
            0,
            0,
            lead.inspection.expectationDateFrom,
            lead.inspection.expectationKm,
            lead.customer.displayName,
        )
    });

    const tempInspectionsData = map(tempInspections, (tempInspection) => {
        return mapDataToProps(
            tempInspection.id,
            "tempInspection",
            tempInspection.thumbnailPicture,
            tempInspection.vehicle.vin,
            tempInspection.vehicle.make,
            tempInspection.vehicle.model,
            tempInspection.vehicle.version,
            (tempInspection.vehicle.firstRegistration !== null) ? moment(tempInspection.vehicle.firstRegistration,).format('DD/MM/YYYY') : "",
            tempInspection.vehicle.kilometers,
            `${tempInspection.subsidiary.name} - ${tempInspection.brand.name}`,
            tempInspection.user.fullName,
            "INITIAL",
            0,
            "",
            0,
            0,
            "",
            "",
            tempInspection.customer.name,
        )
    });

    const allData = [...leadsData, ...tempInspectionsData];

    return orderBy(allData, 'updatedAt');

};


const mapDataToProps = (id, type, pictureUrl, vin, make, model, version, firstRegistration, kilometers, dealerShipName, sellerName, status, highestBid, highestBidder, managerBid, totalBids, expectedDate, expectedKm, customerName) => ({
    id: id,
    type: type,
    vehiclePictureUrl: pictureUrl,
    vehicleVin: vin,
    vehicleMake: make,
    vehicleModel: model,
    vehicleVersion: version,
    vehicleFirstRegistration: firstRegistration,
    vehicleKilometers: kilometers,

    dealerShipName: dealerShipName,
    sellerName: sellerName,
    status: status,
    highestBid: highestBid,
    highestBidder: highestBidder,
    managerBid: managerBid,
    totalBids: totalBids,
    expectedDate: expectedDate,
    expectedKm: expectedKm,

    customerName: customerName,

});

