import React from 'react';
import {Animated} from "react-animated-css";

const animationIn = "fadeIn";
const animationOut = "fadeOut";
const animationDuration = 600; // in ms


const WizardAnimation = ({children, className}) => {
    return (
        <Animated animationIn={animationIn} animationOut={animationOut} animationInDuration={animationDuration} animationOutDuration={animationDuration}
                  isVisible={true} className={`flex h-full w-full ${className}`}>
            {children}
        </Animated>
    )
};

WizardAnimation.defaultProps = {
    className: ""
}

export default WizardAnimation;