import React, {Component} from 'react';
import Template from '../common/Template';
import {Form} from 'react-final-form'
import InputField from '../common/InputField';
import {email, required} from '../../general/forms/validators';
import gql from "graphql-tag";
import {withMutation} from '../../general'
import {withNavigation} from '../navigation';
import {compose} from "recompose";

const RESET_PASSWORD_REQUEST = gql`
mutation ResetPasswordRequest($input: ResetPasswordRequestInput!) {
    resetPasswordRequest(input: $input) {
        ok 
    }
  }
`;


class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(input) {
        const {mutation, navigate} = this.props;
        return mutation.mutate({
            variables: {
                input: {
                    email: input.email,
                }
            }
        }, (data => {

            if (data.success) {
                navigate.login();
            }
        }))
    };

    render() {
        const {navigate} = this.props;
        return (
            <Template title="Reset password" subtitle="With your e-mail address">
                <Form
                    onSubmit={this.handleSubmit}

                    render={({handleSubmit, pristine, invalid}) => (
                        <form onSubmit={handleSubmit}>
                            <div className="mt-6">
                                <InputField name="email"
                                            component="input"
                                            placeholder="Your email"
                                            validators={[required, email]}
                                />
                            </div>


                            <div className="text-base mt-6 flex flex-row justify-center items-center">
                                <button className="w-full p-4 rounded bg-blue-500 text-white outline-none shadow-oultine button">
                                    Confirm
                                </button>
                            </div>
                            <div className="text-base mt-6 flex flex-row justify-center items-center">
                                Don’t have an account? &nbsp; <span className="cursor-pointer"
                                                                 onClick={() => navigate.register()}>Sign up here</span>
                            </div>
                            <div className="text-base mt-6 flex flex-row justify-center items-center">
                                Already have an account? &nbsp; <span className="cursor-pointer"
                                                                   onClick={() => navigate.login()}>Sign in</span>
                            </div>


                        </form>
                    )}
                />
            </Template>
        )
    }
}

export default compose(
    withNavigation,
    withMutation({
        mutation: RESET_PASSWORD_REQUEST,
        noAuthentication: true,
        successMessage: "Check your mailbox for further instructions"
    })
)(ForgotPassword);
