import gql from "graphql-tag";

export const QRY_SALES_SEARCH = gql`
  query SalesSearch($firstName: String $lastName: String $postalCode: String $companyName: String) {
    salesSearch(firstName: $firstName lastName: $lastName postalCode: $postalCode companyName: $companyName) {
        individuals {
          key
          origin
          title
          lastName
          firstName
          email
          phone          
          mobile          
          address {
            street
            number
            box
            locality {
              postalCode
              city
            }
            country {
              code
              name
            }
          }
        }
        companies {
          key
          origin
          name
          vat                 
          address {
            street
            number
            box
            locality {
              postalCode
              city
            }
            country {
              code
              name
            }
          }
        }
        errors 
    }
  }
`;

export const QRY_SALES_PROCESS_SEARCH = gql`
  query SalesProcessSearch($individualKey: String! $companyKey: String!) {
    salesProcessSearch(individualKey: $individualKey, companyKey: $companyKey) {
     processes {
        key
        origin
        brand
        leadSalesMan
        status
        date
    }
    errors    
    }
  }
`;

export const QRY_VAT_CHECK = gql`
  query SalesSearch($countryCode: String! $vatNumber: String!) {
    vatCheck(countryCode: $countryCode, vatNumber: $vatNumber) {
    company {
        name
        vat
        address {
            street
            number
            box
            locality {
                postalCode
                city
            }
            country {
                code
                name
            }
        }
    }
    errors
    }
  }
`;

