import React, {useEffect} from 'react';
import DragAndDrop from "./DragAndDrop";
import Image from './Image';
import {map} from 'lodash';
import {useTranslate} from "../../../../../translations";
import {pictureSetUrl} from "../../../components/pictures/data";

let fileSelector;

const DropZone = ({pictures, canDrop, highlightOnHover, className, onDrop, onDropZoneClick, onDragPicture, onDragPictureEnd, onClickPicture, onDeletePicture, onDataFetched}) => {
    const {getText} = useTranslate();

    useEffect(() => {
        fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('multiple', 'multiple');

        fileSelector.addEventListener('change', (e) => {
            onDrop(e.target.files)
        });
    });

    const showFileSelector = (e) => {
        e.preventDefault();
        fileSelector.click();
    };

    return (
        <div>
            <DragAndDrop canDrop={canDrop}
                         handleDrop={onDrop}
                         onClick={onDropZoneClick}
                         highlightOnHover={highlightOnHover}
                         className={`mb-2 bg-gray-100 ${className}`}>

                {pictures.length < 1 &&
                <div
                    className={`flex flex-1 flex-row items-center p-1 ${pictures.length < 1 ? "justify-center" : "justify-start"} `}
                    style={{height: 90}}>

                    <div className="flex flex-col justify-center text-gray-900 items-center">
                        <div className="flex justify-center">
                                    <span className="fa fa-cloud-upload text-blue-500" style={{fontSize: 30}}>
                                </span>
                        </div>
                        <div className="mt-2">
                            {getText('Drop your pictures here or')}  <span className="text-blue-500 font-medium cursor-pointer"
                                                             onClick={(e) => showFileSelector(e)}>{getText('browse')}</span>
                        </div>
                    </div>
                </div>}


                {pictures.length > 0 &&
                <div className="p-1 flex items-center"
                     style={{overflow: 'auto', whiteSpace: 'nowrap', height: 90}}>
                    {
                        map(pictures, (picture) =>
                            <div id={picture.guid} key={picture.guid} style={{display: 'inline-block'}}>
                                <Image picture={picture}
                                       key={picture.id}
                                       onDrag={(e) => onDragPicture(e, picture.guid)}
                                       onDragEnd={onDragPictureEnd}
                                       onRemove={(id) => onDeletePicture(id)}
                                       className="mr-1"
                                       onClick={(e, id) => {
                                           e.stopPropagation();
                                           onClickPicture(id);
                                       }}
                                       selectAllowed={true}
                                       onDataFetched={onDataFetched}
                                />
                            </div>
                        )
                    }
                </div>}
            </DragAndDrop>
        </div>
    )
};

DropZone.defaultProps = {
    pictures: {},
    canDrop: true,
    highlightOnHover: true,
    className: "",

};

export default DropZone;
