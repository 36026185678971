import React from 'react';
import * as PropTypes from "prop-types";

const TextLabel = ({value, className, showBorder, textClassName, suffix, suffixClassName, bgColor, prefix, prefixClassName}) => {
    const borderClass = (showBorder) ? `border border-gray-200 border-t-0 border-l-0 border-r-0` : '';

    return (
        <React.Fragment>
            <span className={`w-full font-normal text-md pt-1 pb-1 ${bgColor} ${borderClass} ${className} ${(textClassName !== "") ? textClassName : 'text-gray-900'}`}>
                {prefix &&
                <span className={`font-normal text-sm pt-1 pb-1 pr-1 ${borderClass} ${bgColor} ${prefixClassName}`}>{prefix}</span>                }
                {value}
            </span>
            {suffix &&
            <div className={`font-normal text-md pt-1 pb-1 pr-1 ${borderClass} ${bgColor} ${suffixClassName}`}>{suffix}</div>}
        </React.Fragment>
    )
};

TextLabel.propTypes = {
    value: PropTypes.string.isRequired,
    className: PropTypes.string,
    showBorder: PropTypes.bool,
    textClassName: PropTypes.string,
};

TextLabel.defaultProps = {
    value: "",
    className: "",
    showBorder: true,
    textClassName: "",
    suffix: undefined,
    suffixClassName: "",
    bgColor: "bg-white",
    prefix: undefined,
    prefixClassName: ""
};

export default TextLabel;


