import UUID from "uuid";

const LOCAL_STORAGE_KEY = 'AUTRALIS_CENTER';

export const session_default = {
    __typename: 'Session',
    id: UUID.v4(),
    token: null,
    companyId: 0,
    subsidiaryId: 0,
    brandId: 0,
    modules: ['master', 'expert', 'operations', 'sales', 'remarketing'],
    roles: ['admin'],
    locale: 'nl'
};

export const sessionFromStorage = () => {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
};

export const sessionToStorage = (session) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(session));
};

export const clearStorage = () => {
    try {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    } catch (error) {
        localStorage.clear();
    }
};

export const initSession = () => {
    const session_from_storage = sessionFromStorage();
    if (session_from_storage !== null) {
        return session_from_storage;
    } else {
        return session_default;
    }
};
