import React from 'react';
import {useTranslate} from "../../../../../../translations";
import {map, findIndex} from 'lodash';
import './StateIndicator.css'

const StateIndicator = ({states, current}) => {
    const {getText} = useTranslate();
    const activeIndex = findIndex(states, (item) => item.key === current);

    return (
        <div className="flex h-10 bg-white mr-2">
            {map(states, (state, i) => {
                    const active = state.key === current;
                    const colorClass = `${(active) ? 'bg-blue-500 text-white' : 'bg-white text-gray-900'}`;
                    const brClass = `${(i !== states.length - 1) ? 'border-r-0' : ''}`;
                    const blClass = `${(i !== 0) ? 'border-l-0' : ''}`;

                    return (
                        <div className="flex flex-1 p-2 bg-gray-100 ml-1">
                            <div className="pr-1 border border-t-0 border-l-0 border-b-0 text-gray-500 float-left">{i + 1}</div>
                            <div className="pl-2 text-gray-600">
                                <span className="text-sm">  {getText(state.value)}</span>
                                <div className="text-3xs">Add stuff</div>
                            </div>
                        </div>
                    )
                }
            )}
        </div>)
};

export default StateIndicator;