import React from 'react'
import {map} from 'lodash';
import {useLeadStates} from "../hooks";

const Statuses = ({activeStatus, onStatusClick}) => {
    const {leadStates} = useLeadStates();

    return (
        <div className="flex flex-row">
            {map(leadStates, (status) => (
                <StatusBlock key={status.key}
                             code={status.key}
                             title={status.value}
                             count={0}
                             icon=""
                             isActive={activeStatus === status.code}
                             onClick={onStatusClick}
                />
            ))}
        </div>
    )

};


const StatusBlock = ({code, title, count, icon, onClick, isActive}) => {
    return (
        <div className={`w-38 p-2 ml-1 ${isActive ? "bg-blue-400" : "bg-blue-500 hover:bg-blue-400 cursor-pointer"}`}
             onClick={() => onClick(code)}>
            <div className="flex flex-row justify-between">
                <div className="text-xl text-white font-medium"> {count}</div>
                <div className="text-xl text-white font-medium"><i className={icon}/></div>
            </div>
            <div className="text-white mt-2 capitalize">{title}</div>
        </div>
    );
};

export default Statuses;