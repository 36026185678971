import React from 'react';
import {default as BaseCheckBox} from "../base/CheckBox";
import {Field} from "react-final-form";
import {composeValidators} from '../forms/validators';


const CheckBox = ({name, label, validators, isIndeterminate = false}) => {
    return (
        <Field name={name} type="checkbox" validate={(validators) ? composeValidators(...validators) : false}>
            {({input, meta}) => (
                <div className="pretty p-icon p-smooth p-curve p-has-indeterminate">

                    <BaseCheckBox {...input}
                                  indeterminate={isIndeterminate}
                    />

                    <div className="state">
                        <i className="icon fal fa-check" />
                        <label className="text-gray-800">{label}</label>
                        {/*{meta.touched && meta.error &&  <p className="text-red text-xs italic mt-1">{meta.error}</p>}*/}
                    </div>
                    <div className="state p-is-indeterminate">
                        <i className="icon fal fa-minus" />
                        <label className="text-gray-800">{label}</label>
                        {/*{meta.touched && meta.error &&  <p className="text-red text-xs italic mt-1">{meta.error}</p>}*/}
                    </div>

                </div>
            )}
        </Field>
    );
};

export default CheckBox;

