import React, {useEffect, useState} from 'react';
import {withAuthorization} from '../authentication';
import {withNavigation} from '../navigation';
import {compose} from "recompose";
import {useMutation, useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {ErrorIndicator, LoadingIndicator} from "../../general";
import Template from "../common/Template";
import SelectionList from "../common/SelectionList";
import SignOutButton from "./SignOutButton";
import {find, map} from "lodash";
import {SESSION_SET_COMPANY_SESSION} from "../session/tags";


export const GET_USER_COMPANIES = gql`
  query CompaniesForUser($applicationId: Int!) {
     companiesForUser(applicationId: $applicationId) {
        id
        name
        businessUnits {
          id
          name
          brands {
            id
            name
          }
        }
      }     
  }
`;


const CompanySelector = ({navigate}) => {
    const [companyId, setCompanyId] = useState(0);
    const [subsidiaryId, setSubsidiaryId] = useState(0);
    const {data: companiesData, loading: companiesLoading, error: companiesError} = useQuery(GET_USER_COMPANIES, {
        variables: {applicationId: 1},
    });

    const [setCompanySession, {loading: setCompanySessionLoading, error: setCompanySessionError}] = useMutation(SESSION_SET_COMPANY_SESSION);



    const getTitle = () => {
        if (companyId === 0) {
            return 'Select a company'
        } else if (subsidiaryId === 0) {
            return 'Select a subsidiary'
        } else {
            return 'Select a brand'
        }
    };

    const getItems = () => {
        if (companyId === 0) {
            return map(companiesData.companiesForUser, (company) => ({
                id: company.id,
                name: company.name
            }));
        } else if (subsidiaryId === 0) {
            const company = find(companiesData.companiesForUser, (c) => c.id === companyId);
            return map(company.businessUnits, (bu) => ({
                id: bu.id,
                name: bu.name
            }));
        } else {
            const company = find(companiesData.companiesForUser, (c) => c.id === companyId);
            const businessUnit = find(company.businessUnits, (bu) => bu.id === subsidiaryId);
            return map(businessUnit.brands, (brand) => ({
                id: brand.id,
                name: brand.name
            }));
        }
    };

    const setState = (id) => {
        if (companyId === 0) {
            setCompanyId(id)
        } else if (subsidiaryId === 0) {
            setSubsidiaryId(id)
        } else {
            setSession(companyId, subsidiaryId, id);

        }
    };

    const setSession = (companyId, subsidiaryId, brandId) => {
        setCompanySession({
            variables: {
                company: companyId,
                subsidiary: subsidiaryId,
                brand: brandId,
            }
        }).then((result) => {
            navigate.master();
        });
    };


    return (
        <Template title={getTitle()} subtitle="">

            {(companiesLoading || setCompanySessionLoading) &&
            <div className="relative mt-8"><LoadingIndicator/></div>}

            {(companiesError || setCompanySessionError) &&
            <ErrorIndicator description={companiesError.message}/>}

            {!companiesLoading && !companiesError && companiesData !== undefined && !setCompanySessionLoading &&
            < SelectionList items={getItems()}
                            onSelect={(item) => setState(item.id)}
            />}
            <SignOutButton/>
        </Template>
    )

};


CompanySelector.displayName = "CompanySelector";

export default compose(
    withNavigation,
    withAuthorization(() => true),
)(CompanySelector);
