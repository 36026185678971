import React from 'react';
import {difference, map, filter, orderBy, capitalize, toLower, slice} from 'lodash';
import SelectItem from "./SelectItem";
import {useTranslate} from "../../../../translations";

const getLogo = (name) => {
    let logo;
    try {
        logo = require(`../../../../PUBLIC/brands/${name}.svg`);
    } catch (e) {
        logo = require(`../../../../PUBLIC/brands/undefined.svg`);
    }
    return logo;
};


const MakeSelector = ({makes, value, onValueChanged}) => {
    const topMakes = slice(orderBy(filter(makes, (make) => make.priority !== null), 'priority'), 0, 5);
    const otherMakes = orderBy(difference(makes, topMakes), 'name');
    const {getText} = useTranslate();

    return (
        <div className="flex flex-col flex-1">
            <div
                className="flex flex-row justify-between p-2 pl-0 border border-gray-300 border-t-0 border-l-0 border-r-0  font-medium mb-2">
                {getText('Top makes')}
            </div>
            <div className="clearfix pb-2">

                {map(topMakes, (make, i) => {
                    return (
                        <div className="float-left w-1/5" key={`${make.id}_top_${i}`}>
                            <SelectItem
                                checked={make.id === value}
                                onClick={() => onValueChanged(make)}>
                                <div
                                    className="flex flex-col items-center py-3 text-gray-900 cursor-pointer pa-2"
                                    style={{width: 100}}>

                                    <img src={getLogo(toLower(make.name))}
                                         style={{height: 35}} alt={make.id}/>
                                    <span className="mt-1">{capitalize(make.name)}</span>

                                </div>

                            </SelectItem>
                        </div>
                    )
                })}
            </div>
            <div
                className="flex flex-row justify-between p-2 pl-0 border border-gray-300 border-t-0 border-l-0 border-r-0  font-medium mb-2">
                {getText('Other makes')}
            </div>
            <div className="flex flex-row flex-wrap">
                {map(otherMakes, (make, i) => {
                    return (
                        <div
                            key={`${make.id}_top_${i}`}
                            className="w-1/3"
                            onClick={() => onValueChanged(make)}>
                            <div
                                className={`${(make.id === value) ? 'bg-blue-400 text-white' : 'bg-gray-100 text-gray-900 hover:bg-blue-400 hover:text-white'} flex flex-1  mr-1 mb-1 cursor-pointer px-2 py-2`}>
                                {capitalize(make.name)}
                            </div>

                        </div>)
                })}

            </div>
        </div>
    )
};

export default MakeSelector;