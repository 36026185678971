import React from 'react';
import {map, range, slice} from "lodash";
import {useTranslate} from "../../../../../../translations";

const Damages = ({damages, onPictureClick}) => {
    const {getText} = useTranslate();

    return (
        <div className="flex flex-col w-full">
            <div className="flex items-center text-white bg-blue-500 pl-2 text-md h-8 py-2">
                {getText("Damages")}
            </div>

            <div className="pt-2">

                {map(damages, (damage) => {
                    const emptyDamagePictures = (damage.pictures.length < 5) ? (5 - damage.pictures.length) : 0;

                    return (
                        <div key={damage.id} className="flex flex-row mb-2">
                            <div className="flex flex-col p-2 bg-gray-100"
                                 style={{width: 230, height: 88}}>
                                <div
                                    className="mb-1 text-gray-900 font-medium text-xs">{damage.location}</div>
                                <div className="mb-1 text-gray-900 text-xs">
                                    {damage.type}
                                </div>
                                <div className="text-gray-900 text-xs">
                                    {damage.severity}
                                </div>
                            </div>
                            <div className="flex flex-row flex-1">

                                {map(slice(damage.pictures, 0, 4), (pic, index) => {
                                    const overlay = (damage.pictures.length > 4 && index === 3);

                                    return (
                                        <React.Fragment key={pic.id}>
                                            <div className={`${(overlay) ? 'relative' : ''}`}>
                                                <img src={pic.url}
                                                     style={{
                                                         width: 116,
                                                         height: 88,
                                                         marginRight: 2,
                                                         marginBottom: 1
                                                     }} alt={pic.guid}
                                                     className="cursor-pointer"
                                                     onClick={(e) => {
                                                         e.stopPropagation();
                                                         onPictureClick(pic.id);
                                                     }}>
                                                </img>

                                                {overlay &&
                                                <div
                                                    className="absolute top-0 right-0 flex items-center justify-center"
                                                    style={{
                                                        width: 116,
                                                        height: 88,
                                                        marginRight: 2,
                                                        marginBottom: 1,
                                                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                                                    }}>
                                                    <span className="text-white text-3xl">
                                                        + {damage.pictures.length - 5}
                                                    </span>
                                                </div>}
                                            </div>

                                        </React.Fragment>
                                    )
                                })}

                                {map(range(emptyDamagePictures), (index) => {
                                    return (
                                        <React.Fragment key={`dummy_picture_${index}`}>
                                            <div>
                                                <div
                                                    className="flex items-center justify-center border border-gray-200"
                                                    style={{
                                                        width: 116,
                                                        height: 88,
                                                        marginRight: 2,
                                                        marginBottom: 1
                                                    }}>
                                                    <i className="fal fa-image text-5xl text-gray-200"/>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })}


                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )


};

export default Damages;