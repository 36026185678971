import React from 'react';
import * as PropTypes from "prop-types";

const CheckBox = ({checked, disabled, indeterminate, onChange}) => {
    return (
        <div className="pretty p-icon p-round mr-0 p-has-indeterminate">
            <input
                type="checkbox"
                ref={el => el && (el.indeterminate = indeterminate)}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
            />
            <div className="state p-success">
                <i className="icon fal fa-check" />
                <label />
            </div>

            <div className="state p-is-indeterminate p-danger">
                <i className="icon fal fa-minus" />
                <label />
            </div>
        </div>
    );
};

CheckBox.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    indeterminate: PropTypes.bool,
    onChange: PropTypes.func,
};

CheckBox.defaultProps = {
    checked: false,
    disabled: false,
    indeterminate: false
};

export default CheckBox;