import gql from "graphql-tag";

export const LEADS_QRY = gql`
  query getTakeOverData($leadFilters: LeadFilters!) {
     leads(filters: $leadFilters)  {
        id
        state
        expectationKm
        expectationDateFrom
        expectationDateTo
        updatedAt
        createdAt
    
        businessUnit {
          name
        }
        brand {
          name
        }
        user {
          firstName
          lastName
        }       
        
        inspection {
          id      
          vehicle {
            id
            state            
            make
            model
            version
            kilometers
            firstRegistration
            pictureThumbnailUrl,
          }
          
        }
        customer {
          displayName
        }
      }
  }
`;


export const TEMP_INSPECTIONS_QRY = gql`
  query TempInspections {
     tempInspections {
        id        
        converted
        createdAt
        updatedAt
        company {
          name
        }    
         subsidiary {
          name
        }        
        brand {     
          name
        }    
        user {     
          fullName
        }
        vehicle {    
          id
          vin   
          make      
          model     
          version
          firstRegistration     
          kilometers     
        }       
        customer {     
          id
          name
        }   
        thumbnailPicture    
     }
  }
`;

export const LEAD_DETAIL_QRY = gql`
query LeadDetailData($leadId: Int!) {
    lead(id: $leadId)  {        
        id
        state
        expectationPrice
        expectationKm
        expectationDateFrom
        expectationDateTo
        inspection {
            id
            vehicle {
              id    
              make
              model
              version          
              vin
              firstRegistration
              registration
              reference
              kilometers
              vatDeductible
              pictureThumbnailUrl     
            }
        }
        customer {
          displayName
          id          
        }
      }

   settings {
    customerSettings
  }
}`;