import React, {useState} from 'react';
import {find, lowerCase} from 'lodash';
import Select from 'react-select';
import * as PropTypes from "prop-types";

const BaseSelect = ({name, options, value, onChange, placeholder, searchable, required, disabled, showBorder, hasError,}) => {
    const [hasFocus, setHasFocus] = useState(false);
    const selectedOption = find(options, (option) => lowerCase(option.value) === lowerCase(value));

    const customStyles = {
        control: () => ({
            display: "flex",
            flexDirection: "row",
            height: 20,
            marginLeft: 0,
            paddingLeft: 0,
        }),
        container: (styles) => ({
            ...styles,
            paddingTop: 2,
            cursor: 'pointer',
            paddingBottom: 4,
            borderBottom: (showBorder) ? (hasError) ? "1px solid #F56566" :  (hasFocus) ? "1px solid #3e97c7" : (required && !value) ? '1px solid #bee3f8' : '1px solid #edf2f7' : "",
        }),
        input: (styles) => ({
            ...styles,
            paddingTop: 2,
        }),
        indicatorsContainer: (styles) => ({
            ...styles,
            paddingTop: 2
        }),
        valueContainer: styles => ({...styles, padding: 0}),
        dropdownIndicator: styles => ({...styles}),
        singleValue: styles => ({...styles, color: "#1c3d5a", fontSize: "1rem", fontWeight: 400, margin: 0}),
        menu: styles => ({...styles, marginTop: 4}),
        option: (styles, {data, isDisabled, isFocused, isSelected}) => ({
            ...styles,
            ':hover': {
                backgroundColor: "#f8fafc",
                color: "#444444"
            },

            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? "#6cb2eb"
                    : isFocused
                        ? null
                        : null,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? "white"
                    : "#444444",
            cursor: isDisabled ? 'not-allowed' : 'pointer',


        }),
    };
    return (
        <div className="w-full" style={{height: 30}}>
            <Select name={name}
                    onFocus={() => setHasFocus(true)}
                    onBlur={() => setHasFocus(false)}
                    options={options.map(option => ({value: lowerCase(option.value), label: option.label, ...option}))}
                    placeholder={placeholder}
                    value={(selectedOption) ? selectedOption : lowerCase(value)}
                    onChange={(option) => onChange(option.value)}
                    styles={customStyles}
                    isLoading={false}
                    noOptionsMessage={() => "No options"}
                    isSearchable={searchable}
                    isDisabled={disabled}
            />
        </div>
    )
};

BaseSelect.propTypes = {
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    searchable: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    showBorder: PropTypes.bool,
};

BaseSelect.defaultProps = {
    name: "Select",
    options: [],
    value: "",
    onChange: null,
    placeholder: '',
    searchable: true,
    required: false,
    disabled: false,
    showBorder: true,
};

export default BaseSelect;