/*global google*/
import React, {useState} from 'react'
import WizardAnimation from "../../../inspection/wizard/WizardAnimation";
import {Select, TextInput} from "../../../../../general/forms";
import {
    companySetBox, companySetByViesData,
    companySetCity,
    companySetCountry,
    companySetLegalForm,
    companySetMail,
    companySetName,
    companySetNumber,
    companySetPostalCode,
    companySetStreet,
    companySetTelephone,
    companySetVat, individualSetAddress,
    individualSetBox,
    individualSetCity,
    individualSetCountry,
    individualSetFirstName,
    individualSetLanguage,
    individualSetLastName,
    individualSetMail,
    individualSetMobile,
    individualSetNumber,
    individualSetPostalCode,
    individualSetStreet,
    individualSetTelephone,
    individualSetTitle, taxationHasBelgianRegistration,
    taxationIsCompany,
    taxationIsDiplomat,
    taxationIsHandicapped,
    taxationIsTaxable,
    taxationSetTaxablePercent
} from "../data";
import {capitalize, map, toUpper} from "lodash";
import PlacesAutocomplete, {geocodeByAddress} from "react-places-autocomplete";
import {NumericInput} from "../../../../../general/base";
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import {QRY_VAT_CHECK} from "../queries";
import {useTranslate} from "../../../../../translations";
import LoadingIndicator from "../../../../../general/indicators/LoadingIndicator";
import gql from "graphql-tag";
import Heading from "../../../lead/components/home/components/Heading";


const searchOptions = {
    location: new google.maps.LatLng(50.84315, 4.36505),
    radius: 500,
    types: ['address']
};


const getFieldFromData = (data, fieldType) => {
    for (let i = 0; i < data.address_components.length; i++) {
        for (let j = 0; j < data.address_components[i].types.length; j++) {
            if (data.address_components[i].types[j] === fieldType) {
                return {
                    long: data.address_components[i].long_name,
                    short: data.address_components[i].short_name,
                };
            }
        }
    }
    return null;
};

export const COUNTRIES = gql`
query Countries{
    countries {
        id
        code
        name              
    }
}`;


export const LEGAL_FORMS = gql`
query LegalForms{
    legalForms {
        id
        description                      
    }
}`;

const CustomerForm = ({customer, dispatch, errors, showAllErrors, onReset}) => {
    const [type, setType] = useState((customer.company.vat !== "") ? 2 : 1);
    const {getText} = useTranslate();

    const {data: countriesData, loading: countriesFetchLoading, error: countriesFetchError} = useQuery(COUNTRIES, {fetchPolicy: 'cache-first'});
    const {data: legalFormsData, loading: legalFormsFetchLoading, error: legalFormsFetchError} = useQuery(LEGAL_FORMS, {fetchPolicy: 'cache-first'});

    const [checkVat, {loading: vatCheckLoading}] = useLazyQuery(
        QRY_VAT_CHECK,
        {
            fetchPolicy: 'network-only',
            onCompleted: result => {
                if (result && result.vatCheck.company && result.vatCheck.errors.length === 0) {
                    companySetByViesData(dispatch,
                        result.vatCheck.company.name,
                        result.vatCheck.company.address.street,
                        result.vatCheck.company.address.number,
                        result.vatCheck.company.address.box,
                        result.vatCheck.company.address.locality.postalCode,
                        result.vatCheck.company.address.locality.city,
                        result.vatCheck.company.address.country.code
                    );
                }
            }
        }
    );

    const handleVatCheck = () => {
        checkVat({
            variables: {
                countryCode: 'BE',
                vatNumber: customer.company.vat,
            }
        });
    };

    const handleAddressSearchComplete = (address) => {
        geocodeByAddress(address)
            .then(results => {
                const street = getFieldFromData(results[0], 'route');
                const number = getFieldFromData(results[0], 'street_number');
                const postal = getFieldFromData(results[0], 'postal_code');
                const city = getFieldFromData(results[0], 'locality');
                const country = getFieldFromData(results[0], 'country');


                individualSetAddress(dispatch,
                    (street) ? street.long : "",
                    (number) ? number.long : "",
                    "",
                    (postal) ? postal.long : "",
                    (city) ? city.long : "",
                    (country) ? country.short : "",
                )
            })
            .then(latLng => console.log('Success', latLng))
            .catch(error => console.error('Error', error));
    };

    if (countriesFetchLoading || legalFormsFetchLoading) {
        return <LoadingIndicator/>
    }

    return (
        <div className="flex flex-col relative">

            {vatCheckLoading &&
            <LoadingIndicator/>}


            <div className="flex flex-row">
                <div className="w-2/3 h-full border border-gray-200 border-t-0 border-b-0 border-l-0 pr-2 ">
                    <div className="flex flex-1 flex-col h-full overflow-scroll ">

                        <Heading title={getText("Individual")}/>
                        <React.Fragment>
                            <div className="mb-3 pl-2 pr-2">
                                <div className="flex flex-row">
                                    <Select label={getText("Title")}
                                            value={customer.individual.title}
                                            placeholder={getText("(Choice)")}
                                            options={[
                                                {
                                                    label: getText('Mr.'),
                                                    value: 'Mr'
                                                },
                                                {
                                                    label: getText('Mrs.'),
                                                    value: 'Mrs'
                                                },
                                                {
                                                    label: getText('Miss'),
                                                    value: 'Miss'
                                                }
                                            ]}
                                            onChange={(id) => individualSetTitle(dispatch, id)}
                                            className="mr-4"
                                    />

                                    <Select label={getText("Language")}
                                            value={customer.individual.language}
                                            placeholder={getText("(Choice)")}
                                            options={[
                                                {
                                                    label: getText("English"),
                                                    value: "EN"
                                                },
                                                {
                                                    label: getText("Français"),
                                                    value: "FR"
                                                },
                                                {
                                                    label: getText("Nederlands"),
                                                    value: "NL"
                                                },
                                                {
                                                    label: getText("Deutsch"),
                                                    value: "DE"
                                                }
                                            ]}
                                            onChange={(id) => individualSetLanguage(dispatch, id)}
                                            required={true}
                                            hasError={(errors['individual.language'] && customer.individual.language !== "") || (errors['individual.language'] && showAllErrors)}
                                            error={(errors['individual.language']) ? errors['individual.language'].message : ""}
                                    />
                                </div>

                                <div className="flex flex-row">

                                    <TextInput value={customer.individual.firstName}
                                               onChange={e => individualSetFirstName(dispatch, e.target.value)}
                                               label={getText('First name')}
                                               className="mr-4"

                                    />

                                    <TextInput value={customer.individual.lastName}
                                               onChange={e => individualSetLastName(dispatch, e.target.value)}
                                               label={getText('Last name')}
                                               required={true}
                                               hasError={(errors['individual.lastName'] && customer.individual.lastName !== "") || (errors['individual.lastName'] && showAllErrors)}
                                               error={(errors['individual.lastName']) ? errors['individual.lastName'].message : ""}
                                    />
                                </div>
                            </div>

                            <div className="font-normal text-sm pb-2 text-blue-500">
                                <i className="fad fa-map-marked-alt text-lg text-blue-500 mr-2"/> {toUpper(getText("Address"))}
                            </div>
                            <div className="mb-3 pl-2 pr-2">
                                <div className="flex flex-row">
                                    <PlacesAutocomplete
                                        searchOptions={searchOptions}
                                        value={customer.individual.street}
                                        onChange={(street) => {

                                            individualSetStreet(dispatch, street)
                                        }}
                                        onSelect={handleAddressSearchComplete}
                                        shouldFetchSuggestions={(customer.individual.street && customer.individual.street !== "") ? customer.individual.street.length > 3 : false}
                                    >
                                        {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                                            <div className="flex flex-col w-full relative mr-4">
                                                <TextInput
                                                    {...getInputProps({
                                                        label: getText('Street'),
                                                        inputClassName: "location-search-input",
                                                        required: true,
                                                        hasError: (errors['individual.street'] && customer.individual.street !== "") || (errors['individual.street'] && showAllErrors),
                                                        error: (errors['individual.street']) ? errors['individual.street'].message : "",

                                                    })}
                                                />
                                                <div className="absolute left-0 right-0 bg-white"
                                                     style={{top: 45, zIndex: 10}}>
                                                    {loading && <div>Loading...</div>}
                                                    {suggestions.map(suggestion => {
                                                        const className = `suggestion-item ${(suggestion.active) ? 'suggestion-item--active' : ''}`;
                                                        return (
                                                            <div {...getSuggestionItemProps(suggestion, {className})} >
                                                                <strong>
                                                                    {suggestion.formattedSuggestion.mainText}
                                                                </strong>{' '}
                                                                <small>
                                                                    {suggestion.formattedSuggestion.secondaryText}
                                                                </small>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>

                                    <div className="mr-4" style={{width: 80}}>
                                        <TextInput value={customer.individual.number}
                                                   onChange={e => individualSetNumber(dispatch, e.target.value)}
                                                   label={getText('Number')}
                                                   required={true}
                                                   hasError={(errors['individual.number'] && customer.individual.number !== "") || (errors['individual.number'] && showAllErrors)}
                                                   error={(errors['individual.number']) ? errors['individual.number'].message : ""}
                                        />
                                    </div>

                                    <div style={{width: 80}}>
                                        <TextInput value={customer.individual.box}
                                                   onChange={e => individualSetBox(dispatch, e.target.value)}
                                                   label={getText('Box')}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-row">
                                    <div className="mr-4" style={{width: 120}}>
                                        <TextInput value={customer.individual.postalCode}
                                                   onChange={e => individualSetPostalCode(dispatch, e.target.value)}
                                                   label={getText('Postal')}
                                                   required={true}
                                                   hasError={(errors['individual.postalCode'] && customer.individual.postalCode !== "") || (errors['individual.postalCode'] && showAllErrors)}
                                                   error={(errors['individual.postalCode']) ? errors['individual.postalCode'].message : ""}
                                        />
                                    </div>

                                    <TextInput value={customer.individual.city}
                                               onChange={e => individualSetCity(dispatch, e.target.value)}
                                               label={getText('City')}
                                               required={true}
                                               hasError={(errors['city'] && customer.individual.city !== "") || (errors['city'] && showAllErrors)}
                                               error={(errors['city']) ? errors['city'].message : ""}
                                    />
                                </div>

                                <Select label={getText("Country")}
                                        value={customer.individual.country}
                                        placeholder={getText("(Choice)")}
                                        options={map(countriesData.countries, (country) => ({
                                            label: capitalize(country.name) + ` (${country.code})`,
                                            value: country.code
                                        }))}
                                        onChange={(id) => individualSetCountry(dispatch, id)}
                                        required={true}
                                        hasError={(errors['individual.country'] && customer.individual.postalCode !== "") || (errors['individual.country'] && showAllErrors)}
                                        error={(errors['individual.country']) ? errors['individual.country'].message : ""}
                                />
                            </div>

                            <div className="font-normal text-sm pb-2 text-blue-500">
                                <i className="fad fa-comments text-lg text-blue-500 mr-2"/> {toUpper(getText("Communication"))}
                            </div>
                            <div className="pl-2 pr-2">
                                <div className="flex flex-row">

                                    <TextInput value={customer.individual.mobile}
                                               onChange={e => individualSetMobile(dispatch, e.target.value)}
                                               label={getText('Mobile')}
                                               className="mr-4"
                                    />


                                    <TextInput value={customer.individual.telephone}
                                               onChange={e => individualSetTelephone(dispatch, e.target.value)}
                                               label={getText('Telephone')}
                                    />
                                </div>

                                <TextInput value={customer.individual.email}
                                           onChange={e => individualSetMail(dispatch, e.target.value)}
                                           label={getText('E-mail')}
                                />
                            </div>


                        </React.Fragment>

                        <Heading title={getText("Company")}/>
                        <React.Fragment className="flex flex-row w-full">
                            <div className="mb-3 pl-2 pr-2">

                                <div className="flex flex-row">

                                    <div style={{width: 250}}>
                                        <TextInput value={customer.company.vat}
                                                   onChange={e => companySetVat(dispatch, e.target.value)}
                                                   label={getText('Vat number')}
                                                   hasError={(errors['vat'])}
                                                   error={(errors['vat']) ? errors['vat'].message : ""}
                                        />
                                    </div>
                                    <span className="flex underline mt-4 cursor-pointer"
                                          onClick={handleVatCheck}> {getText("Check vat")}</span>
                                </div>


                                <TextInput value={customer.company.name}
                                           onChange={e => companySetName(dispatch, e.target.value)}
                                           label={getText('Company name')}
                                           required={true}
                                           hasError={(errors['company.name'] && customer.company.name !== "") || (errors['company.name'] && showAllErrors)}
                                           error={(errors['company.name']) ? errors['company.name'].message : ""}
                                />

                                <Select label={getText("Legal form")}
                                        value={customer.company.legalForm}
                                        placeholder={getText("(Choice)")}
                                        options={map(legalFormsData.legalForms, (lf) => ({
                                            label: lf.description,
                                            value: lf.id
                                        }))}
                                        onChange={(id) => companySetLegalForm(dispatch, id)}
                                        required={true}
                                        hasError={(errors['company.legalForm'] && customer.company.legalForm !== "") || (errors['company.legalForm'] && showAllErrors)}
                                        error={(errors['company.legalForm']) ? errors['company.legalForm'].message : ""}
                                />
                            </div>

                            <div className="font-normal text-sm pb-2 text-blue-500">
                                <i className="fad fa-map-marked-alt text-lg text-blue-500 mr-2"/> {toUpper(getText("Address"))}
                            </div>
                            <div className="mb-3 pl-2 pr-2">
                                <div className="flex flex-row">
                                    <PlacesAutocomplete
                                        searchOptions={searchOptions}
                                        value={customer.company.street}
                                        onChange={(street) => {
                                            companySetStreet(dispatch, street)
                                        }}
                                        onSelect={handleAddressSearchComplete}
                                        shouldFetchSuggestions={(customer.street && customer.street !== "") ? customer.street.length > 3 : false}
                                    >
                                        {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                                            <div className="flex flex-col w-full relative mr-4">
                                                <TextInput
                                                    {...getInputProps({
                                                        label: getText('Street'),
                                                        inputClassName: "location-search-input",
                                                        required: true,
                                                        hasError: (errors['company.street'] && customer.company.street !== "") || (errors['company.street'] && showAllErrors),
                                                        error: (errors['company.street']) ? errors['company.street'].message : "",

                                                    })}
                                                />
                                                <div className="absolute left-0 right-0 bg-white"
                                                     style={{top: 45, zIndex: 10}}>
                                                    {loading && <div>Loading...</div>}
                                                    {suggestions.map(suggestion => {
                                                        const className = `suggestion-item ${(suggestion.active) ? 'suggestion-item--active' : ''}`;
                                                        return (
                                                            <div {...getSuggestionItemProps(suggestion, {className})} >
                                                                <strong>
                                                                    {suggestion.formattedSuggestion.mainText}
                                                                </strong>{' '}
                                                                <small>
                                                                    {suggestion.formattedSuggestion.secondaryText}
                                                                </small>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>

                                    <div className="mr-4" style={{width: 80}}>
                                        <TextInput value={customer.company.number}
                                                   onChange={e => companySetNumber(dispatch, e.target.value)}
                                                   label={getText('Number')}
                                                   required={true}
                                                   hasError={(errors['company.number'] && customer.company.number !== "") || (errors['company.number'] && showAllErrors)}
                                                   error={(errors['company.number']) ? errors['company.number'].message : ""}
                                        />
                                    </div>

                                    <div style={{width: 80}}>
                                        <TextInput value={customer.company.box}
                                                   onChange={e => companySetBox(dispatch, e.target.value)}
                                                   label={getText('Box')}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row">

                                    <div className="mr-2" style={{width: 120}}>
                                        <TextInput value={customer.company.postalCode}
                                                   onChange={e => companySetPostalCode(dispatch, e.target.value)}
                                                   label={getText('Postal')}
                                                   required={true}
                                                   hasError={(errors['company.postalCode'] && customer.company.postalCode !== "") || (errors['company.postalCode'] && showAllErrors)}
                                                   error={(errors['company.postalCode']) ? errors['company.postalCode'].message : ""}
                                        />
                                    </div>

                                    <TextInput value={customer.company.city}
                                               onChange={e => companySetCity(dispatch, e.target.value)}
                                               label={getText('City')}
                                               required={true}
                                               hasError={(errors['company.city'] && customer.company.city !== "") || (errors['company.city'] && showAllErrors)}
                                               error={(errors['company.city']) ? errors['company.city'].message : ""}
                                    />
                                </div>

                                <Select label={getText("Country")}
                                        value={customer.company.country}
                                        placeholder={getText("(Choice)")}
                                        options={map(countriesData.countries, (country) => ({
                                            label: capitalize(country.name) + ` (${country.code})`,
                                            value: country.code
                                        }))}
                                        onChange={(id) => companySetCountry(dispatch, id)}
                                        required={true}
                                        hasError={(errors['company.country'] && customer.company.country !== "") || (errors['company.country'] && showAllErrors)}
                                        error={(errors['company.country']) ? errors['company.country'].message : ""}
                                />
                            </div>

                            <div className="font-normal text-sm pb-2 text-blue-500">
                                <i className="fad fa-comments text-lg text-blue-500 mr-2"/> {toUpper(getText("Communication"))}
                            </div>
                            <div className="pl-2 pr-2">
                                <TextInput value={customer.company.telephone}
                                           onChange={e => companySetTelephone(dispatch, e.target.value)}
                                           label={getText('Telephone')}
                                />

                                <TextInput value={customer.company.email}
                                           onChange={e => companySetMail(dispatch, e.target.value)}
                                           label={getText('E-mail')}
                                />
                            </div>


                        </React.Fragment>


                    </div>
                </div>

                <div className="w-1/3 p-2 pt-0 h-full">
                    <Heading title={getText("Taxation")} />

                    <div className="pl-2 pr-2">

                        <div className="flex flex-row items-center justify-between mb-2">
                            <span>{getText("Taxable")}</span>
                            <YesNo value={customer.taxation.isTaxable}
                                   hasError={(errors['taxation.isTaxable'] && customer.taxation.isTaxable !== null) || (errors['taxation.isTaxable'] && showAllErrors)}
                                   onChange={(value) => taxationIsTaxable(dispatch, value)}
                            />
                        </div>

                        {customer.taxation.isTaxable &&
                        <div className="flex flex-row items-center justify-between mb-2">
                            <span>{getText("Corporation")}</span>
                            <YesNo value={customer.taxation.isCompany}
                                   hasError={(errors['taxation.isCompany'] && customer.taxation.isCompany !== null) || (errors['taxation.isCompany'] && showAllErrors)}
                                   onChange={(value) => taxationIsCompany(dispatch, value)}
                            />
                        </div>}

                        {customer.taxation.isTaxable && customer.taxation.isCompany &&
                        <div className="flex flex-row items-center justify-between mb-2">
                            <span>{getText("% taxable")}</span>
                            <YesNoText yesText="100%"
                                       noText="50%"
                                       width={80}
                                       value={(customer.taxation.taxablePercent === 0) ? null : (customer.taxation.taxablePercent === 100) ? true : false}
                                       onChange={(value) => taxationSetTaxablePercent(dispatch, (value) ? 100 : 50)}
                                       hasError={(errors['taxation.taxablePercent'] && customer.taxation.taxablePercent !== 0) || (errors['taxation.taxablePercent'] && showAllErrors)}
                            />
                        </div>}

                        {customer.taxation.isTaxable && (customer.taxation.isCompany !== null && !customer.taxation.isCompany) &&
                        <div className="flex flex-row items-center justify-between mb-2">
                            <span>{getText("% taxable")}</span>
                            <div style={{width: 50}}>
                                <NumericInput min={1}
                                              max={100}
                                              value={customer.taxation.taxablePercent}
                                              onChange={e => taxationSetTaxablePercent(dispatch, e.target.value)}
                                              hasError={(errors['taxation.taxablePercent'] && customer.taxation.taxablePercent !== 0) || (errors['taxation.taxablePercent'] && showAllErrors)}
                                />
                            </div>
                        </div>}

                        {customer.taxation.isTaxable !== null && !customer.taxation.isTaxable &&
                        <div className="flex flex-row items-center justify-between mb-2">
                            <span>{getText("Disabled person")}</span>
                            <YesNo value={customer.taxation.isHandicapped}
                                   onChange={(value) => taxationIsHandicapped(dispatch, value)}
                                   hasError={(errors['taxation.isHandicapped'] && customer.taxation.isHandicapped !== null) || (errors['taxation.isHandicapped'] && showAllErrors)}
                            />
                        </div>}

                        {customer.taxation.isTaxable !== null && !customer.taxation.isTaxable &&
                        <div className="flex flex-row items-center justify-between mb-2">
                            <span>{getText("Diplomatic")}</span>
                            <YesNo value={customer.taxation.isDiplomat}
                                   onChange={(value) => taxationIsDiplomat(dispatch, value)}
                                   hasError={(errors['taxation.isDiplomat'] && customer.taxation.isDiplomat !== null) || (errors['taxation.isDiplomat'] && showAllErrors)}
                            />
                        </div>}

                        {customer.taxation.isTaxable !== null && !customer.taxation.isTaxable &&
                        <div className="flex flex-row items-center justify-between mb-2">
                            <span>{getText("Belgian registration")}</span>
                            <YesNo value={customer.taxation.hasBelgianLicensePlate}
                                   onChange={(value) => taxationHasBelgianRegistration(dispatch, value)}
                                   hasError={(errors['taxation.hasBelgianLicensePlate'] && customer.taxation.hasBelgianLicensePlate !== null) || (errors['taxation.hasBelgianLicensePlate'] && showAllErrors)}
                            />
                        </div>}

                    </div>
                </div>
            </div>

        </div>
    )
};

export default CustomerForm;


const YesNo = ({value, onChange, hasError}) => {
    return (
        <div
            className={`flex flex-row ${(hasError) ? 'border border-red-500 ' : "border border-white"} border-t-0 border-r-0 border-l-0`}
            style={{width: 50, minWidth: 50, minHeight: 24}}>

            <div onClick={() => onChange(false)}
                 className={`w-1/2 p-1 flex flex-col items-center justify-center cursor-pointer ${((value !== null && value === false) ? "border border-blue-500 bg-blue-500 text-white" : "border border-gray-200 bg-gray-100 text-gray-900")}`}>
                <i className="far fa-times text-sm"></i>
            </div>
            <div onClick={() => onChange(true)}
                 className={`w-1/2 p-1 flex flex-col items-center justify-center cursor-pointer ${((value !== null && value === true) ? "border border-blue-500 bg-blue-500 text-white" : "border border-gray-200 bg-gray-100 text-gray-900")} `}>
                <i className="far fa-check text-sm"></i>
            </div>
        </div>
    )
};


const YesNoText = ({value, onChange, yesText, noText, width = 50, hasError}) => {
    return (
        <div
            className={`flex flex-row ${(hasError) ? 'border border-red-500 ' : "border border-white"} border-t-0 border-r-0 border-l-0`}
            style={{minWidth: 50, width: width, minHeight: 24}}>

            <div onClick={() => onChange(false)}
                 className={`w-1/2 p-1 flex flex-col items-center justify-center cursor-pointer ${((value !== null && value === false) ? "border border-blue-500 bg-blue-500 text-white" : "border border-gray-200 bg-gray-100 text-gray-900")}`}>
                <span className="text-xs">{noText}</span>
            </div>
            <div onClick={() => onChange(true)}
                 className={`w-1/2 p-1 flex flex-col items-center justify-center cursor-pointer ${((value !== null && value === true) ? "border border-blue-500 bg-blue-500 text-white" : "border border-gray-200 bg-gray-100 text-gray-900")} `}>
                <span className="text-xs">{yesText}</span>
            </div>
        </div>

    )
};