import React, {useState, useRef, useEffect} from 'react';
import Calendar from 'react-calendar';
import {formatDate} from "../utils";
import moment from 'moment';
import {useOnClickOutside} from '../../general/hooks'
import './DatePicker.css';

const DatePicker = ({name, required, disabled, placeholder, hasError, className, style, value, onChange, showBorder, hasFocus, defaultView, activeStartDate, minDate, maxDate, calendarLocation}) => {
    const inputRef = useRef(null);
    const calendarRef = useRef(null);
    const [inputHasFocus, setInputHasFocus] = useState(hasFocus);
    const [containerWidth, setContainerWidth] = useState(0);
    const [pickerVisible, setPickerVisible] = useState(false);
    useOnClickOutside(calendarRef, () => setPickerVisible(false));

    const borderClass = (showBorder) ? `border ${(hasError) ? "border-red-500" : ((inputHasFocus) ? "border-blue-500" : (required && !value) ? "border-blue-200" : "border-gray-200")} border-t-0 border-l-0 border-r-0` : '';
    const valueString = formatDate(value);

    useEffect(() => {
        if (hasFocus) {
            inputRef.current.focus();
            setPickerVisible(true);
        }

    }, [hasFocus, inputHasFocus]);

    useEffect(() => {
        setContainerWidth(inputRef.current ? inputRef.current.offsetWidth : 0);
    }, []);

    const handleKeyDown = (e) => {
        if (e.keyCode === 27) {
            setPickerVisible(false)
        }
    };

    const handleChangeDate = (date) => {
        onChange(moment(date).format('YYYY-MM-DD'));
        setPickerVisible(false);
    };

    const defaultViewMap = (value) => {
        switch (value) {
            case 'day':
                return 'month';
            case 'month':
                return "year";
            case 'year':
                return 'decade';
            default:
                return 'month';
        }
    };

    const calcPosition = (location, width) => {
        switch (location) {
            case 'left':
                return {left: 0};
            case 'right':
                return {right: 0};
            default:
                return {left: `calc(-175px + ${width / 2}px)`}
        }
    }

    return (
        <div className="w-full relative" onKeyDown={handleKeyDown}>
            <input type="text"
                   onFocus={() => setInputHasFocus(true)}
                   onBlur={() => setInputHasFocus(false)}
                   ref={inputRef}
                   value={valueString}
                   name={name}
                   placeholder={placeholder}
                   disabled={disabled}
                   readOnly={true}
                   onClick={(e) => {
                       e.stopPropagation();
                       setPickerVisible(true);
                   }}
                   className={`w-full font-normal text-md cursor-pointer pt-1 pb-1 text-gray-900 reg-picker ${borderClass} ${className}`}
                   style={style}/>

            {pickerVisible &&
            <div className={`flex flex-row date-picker date-picker-${calendarLocation}  border border-gray-200 `}
                 style={{width: 350, ...calcPosition(calendarLocation, containerWidth)}}
                 ref={calendarRef} onKeyDown={handleKeyDown}
            >
                <Calendar
                    onChange={handleChangeDate}
                    value={(value && value !== "") ? new Date(value) : undefined}
                    format={"dd-MM-y"}
                    view={(value && value !== "") ? 'month' : defaultViewMap(defaultView)}
                    activeStartDate={activeStartDate}
                    maxDate={maxDate}
                    minDate={minDate}
                />
            </div>}
        </div>
    )
};

DatePicker.defaultProps = {
    required: false,
    disabled: false,
    placeholder: "",
    hasError: false,
    className: "",
    style: {},
    defaultView: 'month', // day | month | year
    activeStartDate: new Date(),
    minDate: undefined,
    maxDate: undefined,
    calendarLocation: 'center',  // left | right | center
};

export default DatePicker;