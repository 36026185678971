import React from 'react';
import {emptyModuleConfig} from "../common";

import {Panel as SettingsPanel } from './settings/Panel';
import {Content as SettingsContent} from './settings/Content';
import {Content as DashboardContent} from './dashboard/Content';

const MODULE_NAME = 'operations';

export function gatherConfig() {
    const config = emptyModuleConfig(MODULE_NAME);
    config.setMainMenu("Operations", "#1f5370");

    config.addBlock("Dashboard", "fal fa-tachometer-alt", "manager", 0, null, <DashboardContent />);
    config.addBlock("Settings","fal fa-cogs", "manager", 0, <SettingsPanel/>, <SettingsContent/>);

    return config;
}


