import React from 'react';
import {Link} from 'react-router-dom'
import './styles.css';
import {getText} from "../../../translations";

const pages = {
    "compounds": "compounds",
    "documents": "documents",
    "maps": "maps",
    "makes": "makes",
    "main": "settings"
};

export class Panel extends React.Component {
    linkStyles(link, current_page) {
        return link === current_page || (link === pages.compounds && current_page === pages.main) ? "bg-blue-500 text-white hover:bg-blue-600" : "text-black bg-gray-100 hover:bg-gray-200";
    }

    renderArrow(link, current_page){
        return link === current_page || (link === pages.compounds && current_page === pages.main) ? <i className="fal fa-angle-right float-right" style={{fontSize: 16}}/> : ""
    }

    render() {
        const {match} = this.props;
        const current_page = window.location.href.split("/").slice(-1).pop();

        return (

            <div className="mt-0">
                <ul className="lex flex-col panel-nav">
                    <li className="flex-1">
                       <Link to={`${match.url}/${pages.compounds}`} className={`text-left block py-2 px-4 mb-1 ${this.linkStyles(pages.compounds, current_page)}`}>
                           <i className="fal fa-bars mr-2" />{getText('Compounds')} {this.renderArrow(pages.compounds, current_page)}
                       </Link>
                    </li>
                </ul>
            </div>
        );
    }
}
