import React, {Component} from 'react';
import withToolTip from '../withToolTip';
import BaseButton from './BaseButton';

class ToggleButton extends Component {

    render() {
        const {active, mutation, loading} = this.props;
        const bgColor = (active) ? 'green' : 'gray';
        // const hoverBgColor = (active) ? 'gray-200' : 'green';
        const textColor = (active) ? 'white' : "black";
        // const hoverTextColor = (active) ? 'black' : "white";



        return (
            <BaseButton
                {...this.props}
                textColor={textColor}
                textHoverColor={textColor}
                borderColor={bgColor + '-400'}
                borderHoverColor={bgColor + '-500'}
                iconColor={textColor}
                bgColor={bgColor + '-400'}
                bgHoverColor={bgColor + '-500'}
                icon={(active) ? 'fal fa-check' : 'fal fa-times'}
                loading={loading}
            />
        );
    }
}

export default withToolTip(ToggleButton);

