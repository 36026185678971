import React from 'react';
import Buttons from "./Buttons";
import MakeSelector from '../components/MakeSelector';
import Summary from "./Summary";
import WizardAnimation from "./WizardAnimation";
import {vehicleSetMake} from '../vehicleData';
import {map, find, toLower} from 'lodash';

const MakeStep = ({onUpdate, onNext, onPrev, onStepClick, data: {inspection, dispatch, makes, makePriorities, prevStep}, wizard}) => {

    const makesWithPrio = map(makes, (make) => {
        const prioMake = find(makePriorities, (prio) => toLower(prio.name) === toLower(make.name))
        return ({
            ...make,
            priority: (prioMake !== undefined) ? prioMake.priority : null,
        })
    });

    return (
        <div className="flex h-full w-full">
            <div className="w-1/4">
                <Summary data={wizard} onStepClick={onStepClick}/>
            </div>

            <div className="w-3/4 flex flex-col flex-1 bg-white p-2">
                <div className="flex flex-1 overflow-scroll ">
                    <WizardAnimation>
                        <MakeSelector value={inspection.vehicle.makeId}
                                      makes={makesWithPrio}
                                      onValueChanged={(value) => {
                                          if (inspection.vehicle.makeId !== value.id)
                                              vehicleSetMake(dispatch, value);
                                          onNext()
                                      }}/>
                    </WizardAnimation>
                </div>

                <Buttons onNext={onNext}
                         onPrev={onPrev}
                         data={wizard}
                         prevDisabled={prevStep === 0}
                         nextDisabled={inspection.vehicle.makeId === 0}
                />

            </div>
        </div>
    )
};

export default MakeStep;