const LANDING = '/';
const LOGIN = '/login';
const SELECT_COMPANY = '/select-company';
const REGISTER = '/register';
const REGISTER_WITH_PARAMS = '/register/:companyKey?';
const REGISTRATION_WIZARD = '/register/wizard';
const FORGOT_PASSWORD = '/forgot-pw';
const RESET_PASSWORD = '/reset-pw/:requestId';
const APPLICATION = '/:application/:block';
const MASTER = '/master/home';

export const paths = {
    LANDING,
    LOGIN,
    SELECT_COMPANY,
    REGISTER,
    REGISTRATION_WIZARD,
    REGISTER_WITH_PARAMS,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    APPLICATION,
    MASTER,
};

