import React, {useEffect, useState} from 'react';
import {useTranslate} from "../../../../../translations";
import {useLeadStates} from "../../hooks";
import StateIndicator from "./components/StateIndicator";
import {values} from 'lodash'
import Proposal from "./components/proposal/Proposal";
import Activity from "./components/activity/Activity";
import Heading from "./components/Heading";
import Publish from "./components/Publish";


const Home = ({lead: leadFromProps, isManager}) => {
    const {getText} = useTranslate();
    const {leadStates, displayStateByLeadState} = useLeadStates();

    // TODO REMOVE
    const [lead, setLead] = useState(leadFromProps);

    React.useEffect(() => {
        setLead(lead)
    }, [lead]);

    const changeState = (state) => {
        const copy = {...lead};
        copy.state = state;

        setLead(copy);
    };
    // END REMOVE


    return (
        <div className="ml-2 h-full">

            <StateIndicator states={values(leadStates)}
                            current={displayStateByLeadState(lead.state)}/>

            <div className="flex flex-row h-full mr-2 mt-2">
                <div
                    className={`flex flex-1 flex-col mr-2 p-2 pt-0 ${(displayStateByLeadState(lead.state) === leadStates.new.key) ? 'bg-white' : 'bg-gray-100'}`}>

                    {displayStateByLeadState(lead.state) === leadStates.new.key ?
                        <React.Fragment>
                            <Heading title={getText('Publish to network')}/>
                            <Publish lead={lead}
                                     onSend={(id) => changeState('BUFFER')}
                            />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Heading title="Network activity"/>
                            <Activity onUnPublish={() => changeState('CONFIRMED')} isManager={isManager}/>
                        </React.Fragment>
                    }
                </div>


                <div
                    className={`flex flex-1 flex-col ml-2 p-2 pt-0   ${(displayStateByLeadState(lead.state) !== leadStates.new.key) ? 'bg-white' : 'bg-gray-100'}`}>
                    <Proposal onAccept={() => changeState('ACCEPTED')}
                              onRefuse={() => changeState('REFUSED')}
                              onWithDraw={() => changeState('SELLER')}
                              grayed={(displayStateByLeadState(lead.state) === leadStates.new.key)}
                              lead={lead}
                    />
                </div>

            </div>
        </div>

    )
};

export default Home
