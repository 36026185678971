import React, {useState, useEffect, useRef} from 'react';
import {map, filter, values, keys, includes, isEqual, isEmpty, keyBy, forEach, indexOf, find} from 'lodash';
import LocationThumbnail from "./LocationThumbnail";
import DropZone from "../../inspection/components/common/DropZone";
import Detail from "./Detail";
import DamageRow from "./DamageRow";
import {Animated} from "react-animated-css";
import {ConfirmationBox} from "../confirmation";
import CustomSvg from "./CustomSvg";
import UUID from "uuid";

import cogoToast from 'cogo-toast';
import {
    picturesFromFiles,
    picturesAdd,
    pictureSetSelected,
    pictureDelete,
    pictureSetUrl,
    picturesSetRef, pictureSetFileId,
} from '../pictures/data'

import {
    damageCreate,
    damageChangeLocation,
    damageDelete,
    damageSetDamageType,
    damageSetSeverity,
    damageSetComment, setNoDamages,
    setDetailDamageId,
} from './data';
import {Switch} from "../../../../general/base";
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import {useTranslate} from "../../../../translations";
import {UPLOAD_FILE} from "../../../common/mutations";
import {UPLOADED_FILE} from "../../../common/queries";
import * as Sentry from "@sentry/browser";

const animationIn = "fadeIn";
const animationOut = "fadeOut";
const animationDuration = 400; // in ms

const location_thumbs = ["left", "front", "back", "right", "top", "inside-front", "inside-back", 'parts'];

const getDamageLocationById = (locations, id) => {
    return find(locations, (loc) => loc.id === id);
};


const Damages = ({damages, noDamages, pictures, dispatch, locations, damageDetailsRequired, detailDamageId}) => {
    const [currentSvg, setCurrentSvg] = useState((detailDamageId !== null) ? location_thumbs[indexOf(location_thumbs, damages[detailDamageId].item)] : location_thumbs[0]);
    const [currentDraggedPictures, setCurrentDraggedPictures] = useState([]);
    const {getText} = useTranslate();

    const [confirmation, setConfirmation] = useState(null);
    // const [otherClickedLocation, setOtherClickedLocation] = useState(null);
    const [listView, setListView] = useState(false);
    const [height, setHeight] = useState(0);
    const containerRef = useRef();
    const [uploadFiles] = useMutation(UPLOAD_FILE);
    const [uploadFailure, setUploadFailure] = useState(false);

    const [uploadedFileQuery] = useLazyQuery(
        UPLOADED_FILE,
        {
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                const {uploadIdentification, fullUrl, thumbnailUrl} = data.uploadedFile;

                pictureSetUrl(dispatch,
                    uploadIdentification,
                    fullUrl,
                    thumbnailUrl,
                );
            }
        });

    useEffect(() => {
        // document.getElementById('takeoverOLD-wizard-buttons').style.display = (detailDamageId === null) ? 'block' : 'none';
        if (isEmpty(damages)) {
            setListView(false)
        }
        return () => {
            // document.getElementById('takeoverOLD-wizard-buttons').style.display = (detailDamageId === null) ? 'block' : 'none';
        }
    }, [detailDamageId, damages]);

    useEffect(() => {

        if (noDamages && confirmation) {
            setConfirmation(null)
        }
    }, [noDamages, confirmation]);


    const handleResize = () => {
        const height = containerRef.current.clientHeight;
        setHeight(height)
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        const height = containerRef.current.clientHeight;
        setHeight(height);

        return () => {
            window.removeEventListener('resize', handleResize);
            setHeight(0);
        }
    }, []);


    const handleNewFiles = (files, refId = null, refType = "") => {
        const new_pictures = picturesFromFiles(files, refId, refType);

        if (uploadFailure) {
            setUploadFailure(false);
        }

        picturesAdd(dispatch, new_pictures);

        forEach(new_pictures, (picture) => {
            uploadFiles({
                variables: {
                    data: {
                        transactionId: UUID.v4(),
                        file: picture.file,
                        guid: picture.guid
                    }
                }
            }).then((result) => {
                const {fileUploadHandler} = result.data;
                if (fileUploadHandler.result.success) {
                    const {fileId} = fileUploadHandler.result;

                    pictureSetFileId(dispatch, picture.guid, fileId);

                    uploadedFileQuery({
                        variables: {
                            id: fileId
                        }
                    })
                } else {
                    pictureDelete(dispatch, picture.guid);
                    setUploadFailure(true);
                }
            }).catch((error) => {
                Sentry.captureException(error);
                pictureDelete(dispatch, picture.guid);
                setUploadFailure(true);
            });
        });
    };


    const onDragPicture = (event, pictureId) => {
        const selectedPictures = map(filter(pictures, (p) => p.selected), (p) => p.guid);

        if (selectedPictures.length > 0) {
            if (currentDraggedPictures.length === 0 || !isEqual(currentDraggedPictures.sort(), selectedPictures.sort())) {
                setCurrentDraggedPictures(selectedPictures);
            }
        } else {
            if (!includes(currentDraggedPictures, pictureId)) {
                setCurrentDraggedPictures([pictureId]);
            }
        }
    };

    const handleDropOnLocationByClick = (locationId, x = 0, y = 0) => {
        const selectedPictures = map(filter(pictures, (p) => p.selected), (p) => p.guid);

        if (selectedPictures.length > 0 && locationId) {
            handleDrop(locationId, null, selectedPictures, null, x, y);
        } else {
            if (detailDamageId !== locationId) {
                const location = getDamageLocationById(locations, locationId);
                damageCreate(dispatch, location, currentSvg, x, y);
            } else {
                setDetailDamageId(dispatch, null);
            }
        }
    };

    const handleDropOnLocationByDrag = (locationId, files, x = 0, y = 0) => {
        let detailsValid = false;
        if (damageDetailsRequired && detailDamageId) {
            detailsValid = isDamageValid(damages[detailDamageId]);
        }

        if (detailDamageId === null || (detailDamageId && damageDetailsRequired && detailsValid) || (detailDamageId && !damageDetailsRequired)) {
            handleDrop(locationId, files, currentDraggedPictures, null, x, y);
        } else {
            showToast(getText("Not allowed"));
        }
    };

    const handleDropMoreByDrag = (damage, files) => {
        handleDrop(damage.locationId, files, currentDraggedPictures, (damage) ? damage.guid : detailDamageId)
    };
    const handleDropMoreByClick = (damage) => {
        const selectedPictures = map(filter(pictures, (p) => p.selected), (p) => p.guid);

        if (selectedPictures.length > 0 && damage.locationId) {
            handleDrop(damage.locationId, null, selectedPictures, (damage.guid) ? damage.guid : detailDamageId)
        }
    };

    const handleDropOnBullsEye = (damageId, x, y) => {
        if (detailDamageId === damageId || (detailDamageId !== damageId && damageDetailsRequired && isDamageValid(damages[detailDamageId])) || (detailDamageId !== damageId && !damageDetailsRequired)) {
            handleDrop(null, null, currentDraggedPictures, damageId, x, y);
        }
    };
    const handleBullsEyeClick = (damageId) => {
        if (detailDamageId === null || (detailDamageId && damageDetailsRequired && isDamageValid(damages[detailDamageId])) || (detailDamageId && !damageDetailsRequired)) {
            setDetailDamageId(dispatch, damageId);
        }
    };
    const handleOnSvgLocationClick = (locationId, x, y) => {
        if (checkClickOrDropAllowed()) {
            handleDropOnLocationByClick(locationId, x, y);
        }
    };

    const handleDrop = (locationId, files, pictureIds, damageId = null, x = 0, y = 0) => {
        if ((locationId !== null || damageId !== null) && (pictureIds.length > 0 || files)) {
            let _damageId = damageId;
            if (damageId === null) {
                const location = getDamageLocationById(locations, locationId);
                const damage = damageCreate(dispatch, location, currentSvg, x, y);
                _damageId = damage.guid;
            }

            if (files) {
                handleNewFiles(files, _damageId, 'damage');
            } else {
                picturesSetRef(dispatch, pictureIds, _damageId, 'damage');
            }

            if (!listView) {
                setDetailDamageId(dispatch, _damageId);
            }

            setCurrentDraggedPictures([]);
        }

        // Back to block view if there are no damages in the list
        if (damages.length === 0) {
            setListView(false)
        }

    };

    const handleChangeDamageLocation = (damageId, locationId, x, y,) => {
        const location = getDamageLocationById(locations, locationId);
        damageChangeLocation(dispatch, damageId, location, x, y);
    };
    const handleDeleteDamage = (damage) => {
        damageDelete(dispatch, damage.guid);

        const damagePictures = map(filter(pictures, (p) => p.refId === damage.guid && p.refType === 'damage'), (p) => p.guid);
        picturesSetRef(dispatch, damagePictures, null, "");

    };
    const handleDeleteDamagePicture = (damageId, pictureId) => {
        picturesSetRef(dispatch, [pictureId], null, "");
    };

    const handleThumbnailClickOrDragOver = (svg) => {
        if (!detailDamageId) {
            setCurrentSvg(svg)
        } else {
            if ((detailDamageId && damageDetailsRequired && isDamageValid(damages[detailDamageId])) || (detailDamageId && !damageDetailsRequired)) {
                setCurrentSvg(svg);
                setDetailDamageId(dispatch, null)
            } else {
                const onConfirmationYes = () => {
                    damageDelete(dispatch, detailDamageId);

                    setConfirmation(null);
                    setCurrentSvg(svg);
                    setDetailDamageId(dispatch, null)
                };

                const onConfirmationNo = () => {
                    setConfirmation(null);
                };

                const onConfirmationCancel = () => {
                    setConfirmation(null);
                };

                setConfirmation({
                    title: getText('Change location'),
                    description: getText('Are you sure? By doing this your current damage will be deleted.'),
                    yesText: getText('Change'),
                    type: 'warning',
                    onYes: onConfirmationYes,
                    onNo: onConfirmationNo,
                    onCancel: onConfirmationCancel,
                });
            }
        }
    };
    const handleChangeView = () => {
        if (detailDamageId === null || (detailDamageId && damageDetailsRequired && isDamageValid(damages[detailDamageId])) || (detailDamageId && !damageDetailsRequired)) {
            setListView(listView => !listView);

        } else {
            const onConfirmationYes = () => {
                damageDelete(dispatch, detailDamageId);
                setConfirmation(null);
                setListView(listView => !listView);
            };

            const onConfirmationNo = () => {
                setConfirmation(null);
            };

            const onConfirmationCancel = () => {
                setConfirmation(null);
            };

            setConfirmation({
                title: getText('Change to list view'),
                description: getText('Are you sure? By doing this your current damage will be deleted.'),
                yesText: getText('Change view'),
                type: 'warning',
                onYes: onConfirmationYes,
                onNo: onConfirmationNo,
                onCancel: onConfirmationCancel,
            });
        }
    };


    const isDamageValid = (damage) => {
        if (damageDetailsRequired && detailDamageId) {
            if (damage.typeId > 0 && damage.severityId > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            if (!damageDetailsRequired) {
                return true
            }

            return false;
        }
    };


    const handleCloseDetails = () => {

        if ((detailDamageId && damageDetailsRequired && !isDamageValid(damages[detailDamageId]))) {
            const onConfirmationYes = () => {
                damageDelete(dispatch, detailDamageId);
                setConfirmation(null);
            };

            const onConfirmationNo = () => {
                setConfirmation(null);
            };

            const onConfirmationCancel = () => {
                setConfirmation(null);
            };

            setConfirmation({
                title: getText('Close damage details'),
                description: getText('Are you sure? By doing this your current damage will be deleted.'),
                yesText: getText('Close'),
                type: 'warning',
                onYes: onConfirmationYes,
                onNo: onConfirmationNo,
                onCancel: onConfirmationCancel,
            });
        } else {
            setDetailDamageId(dispatch, null);
        }
    };

    const checkClickOrDropAllowed = () => {
        const selectedPictures = map(filter(pictures, (p) => p.selected), (p) => p.guid);
        return (detailDamageId === null ||
            (detailDamageId && !damageDetailsRequired && selectedPictures.length > 0) ||
            (detailDamageId && damageDetailsRequired && isDamageValid(damages[detailDamageId]) && selectedPictures.length > 0) ||
            (detailDamageId && damageDetailsRequired && isDamageValid(damages[detailDamageId])));

    };
    const checkClickAllowedForHover = () => {
        return checkClickOrDropAllowed();
    };
    const checkDropAllowedForHover = () => {
        return (detailDamageId === null || (detailDamageId && !damageDetailsRequired) || (detailDamageId && damageDetailsRequired && isDamageValid(damages[detailDamageId])));
    };

    const locationTranslations = keyBy(map(locations, (location) => {
            let text = location.name;

            if (location.translations) {
                try {
                    const parsed = JSON.parse(location.translations);
                    text = (parsed['nl']) ? parsed['nl'] : location.name;
                } catch (ex) {
                }
            }

            return ({
                id: location.id,
                name: location.name,
                text: text
            })
        }
    ), 'id');

    const showToast = (message) => {
        cogoToast.error(message, {hideAfter: 5});
    };

    const handleNoDamages = () => {

        if (keys(damages).length === 0) {
            setNoDamages(dispatch, !noDamages);
        } else {
            const onConfirmationYes = () => {
                setNoDamages(dispatch, !noDamages);
                setConfirmation(null);
            };

            const onConfirmationNo = () => {
                setConfirmation(null);
            };

            const onConfirmationCancel = () => {
                setConfirmation(null);
            };

            setConfirmation({
                title: getText('Damages'),
                description: getText('You have defined some damages, all damages will be lost. Are you sure?'),
                yesText: getText('Yes'),
                type: 'warning',
                onYes: onConfirmationYes,
                onNo: onConfirmationNo,
                onCancel: onConfirmationCancel,
            });
        }
    };

    const handleFetchedData = (data) => {
        const {uploadIdentification, fullUrl, thumbnailUrl} = data;

        pictureSetUrl(dispatch,
            uploadIdentification,
            fullUrl,
            thumbnailUrl,
        );
    };

    const detailHeight = 285;
    const svgContainerHeight = height - detailHeight;
    const thumbnailsHeight = 8 * 60 > height ? height : "100%";

    return (
        <div className="w-full h-full overflow-hidden">

            <div>
                <DropZone pictures={filter(values(pictures), (p) => p.refId === null)}
                          canDrop={currentDraggedPictures.length === 0}
                          highlightOnHover={currentDraggedPictures.length === 0}
                          onDrop={(files) => handleNewFiles(files)}
                          onDropZoneClick={() => null}
                          onDragPicture={onDragPicture}
                          onDragPictureEnd={() => setCurrentDraggedPictures([])}
                          onClickPicture={(pictureId) => pictureSetSelected(dispatch, pictureId)}
                          onDeletePicture={(pictureId) => pictureDelete(dispatch, pictureId)}
                          onDataFetched={handleFetchedData}
                />
            </div>

            {uploadFailure &&
            <div
                className="bg-orange-200 border border-orange-400 text-orange-800 h-6 pl-2 flex items-center mb-2 text-xs font-medium">
                {getText('Picture upload failed, please try again')}
            </div>}


            {!listView &&
            <React.Fragment>
                <div className="flex flex-row z-50 bg-white" style={{height: "calc(100% - 103px)"}}>
                    <div className="flex flex-col w-1/10 overflow-scroll" style={{height: thumbnailsHeight}}>
                        {location_thumbs.map(location_thumb => {
                            return (
                                <LocationThumbnail key={location_thumb}
                                                   uniqueId={location_thumb}
                                                   onClick={handleThumbnailClickOrDragOver}
                                                   onDragOver={handleThumbnailClickOrDragOver}
                                                   svgFileName={`${location_thumb}.svg`}
                                                   damages={damages}
                                />
                            )
                        })}
                    </div>


                    <div className={`flex flex-col w-9/10 h-full justify-center relative`} ref={containerRef}>

                        <div className="flex flex-col w-full "
                             style={{height: (damages[detailDamageId]) ? svgContainerHeight : "100%"}}>

                            <div className="flex flex-row  justify-between pt-2">
                                <div className="flex items-center ">
                                    <div className="flex flex-1 mr-2">
                                        <span>{getText("This vehicle has no damages")}</span>
                                    </div>
                                    <div style={{width: 50}}>
                                        <Switch checked={noDamages}
                                                onChange={handleNoDamages}/>
                                    </div>
                                </div>


                                <div className="flex ">

                                    {keys(damages).length > 0 &&
                                    <span
                                        className="inline-block bg-gray-200 rounded-full px-3 py-1 text-xs font-medium text-gray-900 mr-4 cursor-pointer">
                                        {keys(damages).length} <span onClick={handleChangeView}
                                                                     className="text-xs font-hairline text-gray-700">{(keys(damages).length === 1) ? getText('damage') : getText('damages')}</span>
                                 </span>}


                                    {/*<div>*/}
                                    {/*    <i className="fal fa-question-circle text-xl hover:text-gray-500 text-gray-900 mr-4 "/>*/}
                                    {/*</div>*/}

                                    <div>
                                        <i className="fas fa-list text-xl hover:text-gray-500 text-gray-900 cursor-pointer "
                                           onClick={handleChangeView}/>
                                    </div>
                                </div>


                            </div>


                            <div className={`flex flex-1 h-full w-full flex-row justify-between overflow-hidden`}>
                                <div className="flex flex-col items-center justify-center w-full h-full ">

                                    <CustomSvg uniqueId={currentSvg}
                                               path={require(`../../../../PUBLIC/damages/${currentSvg}.svg`)}
                                               items={damages}
                                               currentItem={(damages[detailDamageId]) ? damages[detailDamageId] : null}
                                        // clickedItem={otherClickedLocation}
                                               onClick={handleOnSvgLocationClick}
                                               onDrop={handleDropOnLocationByDrag}
                                               onBullsEyeMove={handleChangeDamageLocation}
                                               onBullsEyeClick={handleBullsEyeClick}
                                               onBullsEyeDrop={handleDropOnBullsEye}
                                               clickAllowed={checkClickAllowedForHover()}
                                               dropAllowed={checkDropAllowedForHover()}
                                               translations={locationTranslations}
                                    />

                                </div>
                            </div>
                        </div>


                        {(damages[detailDamageId]) &&
                        <div className="flex items-center justify-center damage-detail w-full bg-white relative"
                             style={{height: 280}}>


                            <Detail damage={damages[detailDamageId]}
                                    pictures={filter(values(pictures), (p => p.refId === detailDamageId))}
                                    locations={locations}
                                    onDropMorePictures={handleDropMoreByDrag}
                                    onDropMorePicturesByClick={handleDropMoreByClick}
                                    onDeletePicture={handleDeleteDamagePicture}
                                    onPictureClick={() => {
                                    }}
                                    onDragPicture={(e, pic) => onDragPicture(e, pic)}
                                    onDragPictureEnd={() => setCurrentDraggedPictures([])}
                                    onChangeType={(damageId, type) => {
                                        damageSetDamageType(dispatch, damageId, type);
                                    }}
                                    onChangeSeverity={(damageId, severity) => {
                                        damageSetSeverity(dispatch, damageId, severity);
                                    }}
                                    onChangeComment={(damageId, comment) => {
                                        damageSetComment(dispatch, damageId, comment);
                                    }}
                                    uploadedPictures={pictures}
                                    onClose={handleCloseDetails}
                                    onDelete={() => handleDeleteDamage(damages[detailDamageId])}
                                    onImageDataFetched={handleFetchedData}
                            />


                        </div>
                        }
                    </div>

                    {confirmation && <ConfirmationBox title={confirmation.title}
                                                      type={confirmation.type}
                                                      description={confirmation.description}
                                                      onYes={() => confirmation.onYes()}
                                                      textYes={confirmation.yesText}
                                                      onNo={() => confirmation.onNo()}
                                                      onCancel={() => confirmation.onCancel()}/>}

                </div>
            </React.Fragment>}

            {listView &&
            <React.Fragment>
                <div className="flex text-right w-full justify-end mb-2">

                    <div className="flex">
                        {keys(damages).length > 0 &&

                        <span
                            className="inline-block bg-gray-200 rounded-full px-3 py-1 text-xs font-medium text-gray-900 mr-4">
                                {keys(damages).length} <span
                            className="text-xs font-hairline text-gray-700">{(keys(damages).length === 1) ? getText('damage') : getText('damages')}</span>
                                 </span>}

                        <i className="fas fa-th text-2xl hover:text-blue-500 text-gray-900 cursor-pointer"
                           onClick={handleChangeView}/>
                    </div>
                </div>

                {map(values(damages), (damage, i) => {
                    return (
                        <Animated key={damage.guid} animationIn={animationIn} animationOut={animationOut}
                                  animationInDuration={animationDuration} animationOutDuration={animationDuration}
                                  isVisible={true}>
                            <DamageRow
                                key={i}
                                damage={damage}
                                pictures={filter(values(pictures), (p => p.refId === damage.guid))}
                                locations={locations}
                                uploadedPictures={pictures}
                                onDragPicture={(e, pic) => onDragPicture(e, pic.guid)}
                                handlePictureClick={(id) => {
                                }}
                                handleDropMoreByDrag={handleDropMoreByDrag}
                                handleDropMoreByClick={handleDropMoreByClick}
                                handleDeleteDamagePicture={handleDeleteDamagePicture}
                                handleDeleteDamage={(damage_id) => handleDeleteDamage(damages[damage_id])}
                                onEditDamage={(damageId) => {
                                    setDetailDamageId(dispatch, damageId);
                                    setListView(false)
                                }}
                            />
                        </Animated>
                    )
                })}
            </React.Fragment>}
        </div>)
};


Damages.defaultProps = {
    damageDetailsRequired: true,
};

export default Damages;

