import React, {useEffect, useReducer, useRef} from "react";
import {
    inspectionContainerReducer,
    resetAutoSave,
    setConfirmation,
    setInspection,
    setLastUpdate,
    toggleAutoSave,
    toggleAutoSaveFailure,
    AUTO_SAVE_NUM_RETRIES,
    generate_initial_state,
    setDataVersion
} from "./inspectionContainerData";
import {Drawer, ErrorBoundary, LoadingIndicator, ErrorIndicator} from "../../../general";
import {InspectionWizard} from "./index";
import {ConfirmationBox} from "../components/confirmation";
import {forEach, omit, toUpper, map} from 'lodash';
import {useTranslate} from "../../../translations";
import {useMutation, useQuery, useSubscription} from "@apollo/react-hooks";
import {GET_TEMP_INSPECTION} from "./queries";
import {
    TEMP_INSPECTION_TO_INSPECTION,
    TEMP_INSPECTION_SAVE,
    TEMP_INSPECTION_INACTIVATE,
    TEMP_INSPECTION_CONTEXT_SAVE
} from "./mutations";
import {TEMP_INSPECTIONS_QRY} from "../lead/queries";
import {useInterval} from '../../../general/hooks';
import moment from "moment";

import {inspectionSetId} from "./inspectionData";
import {
    ContextMapper,
    inspectionStateToContext, inspectionStateToInspectionData,
    StateToContext,
    TempInspectionToState
} from "./mappers";
import {TEMP_INSPECTION_DETAIL_SUBSCRIPTION} from "./subscriptions";
import UUID from "uuid";
import cogoToast from "cogo-toast";


const InspectionContainer = ({id, onClose}) => {
    const {getText} = useTranslate();
    const [state, dispatch] = useReducer(inspectionContainerReducer, generate_initial_state(id));
    const inspectionRef = useRef;

    const {loading: tempInspectionFetchLoading, error: tempInspectionFetchError} = useQuery(GET_TEMP_INSPECTION, {
        skip: id === null,
        fetchPolicy: 'network-only',
        variables: {
            tempInspectionId: id,
        },
        onCompleted: data => {

            let context = null;
            if (data && data.tempInspectionContext) {
                context = ContextMapper(JSON.parse(data.tempInspectionContext.data))
            }

            const parsed_data = TempInspectionToState(data.tempInspection, context);
            setInspection(dispatch, data.tempInspection.id, parsed_data, data.tempInspection.version);
        }
    });

    const [tempInspectionSaveMutation, {loading: tempInspectionSaveLoading, error: tempInspectionSaveError}] = useMutation(TEMP_INSPECTION_SAVE);
    const [tempInspectionContextSaveMutation] = useMutation(TEMP_INSPECTION_CONTEXT_SAVE);
    const [tempInspectionConvertToInspectionMutation, {loading: tempInspectionConvertToInspectionLoading}] = useMutation(TEMP_INSPECTION_TO_INSPECTION);
    const [tempInspectionInactivateMutation, {loading: tempInspectionInactivateLoading}] = useMutation(TEMP_INSPECTION_INACTIVATE, {
        update: (cache, {data: {tempInspectionInactivateHandler}}) => {

            const {tempInspectionId, success} = tempInspectionInactivateHandler.result;

            if (success) {
                const {tempInspections} = cache.readQuery({
                    query: TEMP_INSPECTIONS_QRY
                });

                cache.writeQuery({
                    query: TEMP_INSPECTIONS_QRY,
                    data: {tempInspections: tempInspections.filter(ti => ti.id !== tempInspectionId)},
                });
            }
        }
    });

    const {data: subscriptionData, loading: subscriptionLoading} = useSubscription(TEMP_INSPECTION_DETAIL_SUBSCRIPTION, {
        variables: {tempInspectionIds: [id]},
        onSubscriptionData: ({subscriptionData: {data}}) => {
            if (data.tempInspectionChanged.tempInspection.version > state.version) {
                const context = StateToContext(state.inspection);
                const parsed_data = TempInspectionToState(data.tempInspectionChanged.tempInspection, context);
                setInspection(dispatch, data.tempInspectionChanged.tempInspection.id, parsed_data, data.tempInspectionChanged.tempInspection.version);
            }
        }
    });

    useEffect(() => {
        return function saveContext() {
            if (inspectionRef.current) {
                const variables = {
                    data: {
                        transactionId: UUID.v4(),
                        tempInspectionContext: {
                            applicationId: "WEB/INSPECTOR/3",
                            tempInspectionId: inspectionRef.current.id,
                            data: JSON.stringify(inspectionStateToContext(inspectionRef.current))
                        }
                    }
                };
                tempInspectionContextSaveMutation({variables})
            }
        };
    }, []);
    useEffect(() => {
        if ((state.inspection !== null && state.inspection.initial) ||
            (state.inspection !== null && state.prevInspection !== null && state.inspection.guid === state.prevInspection.guid)) {

            inspectionRef.current = state.inspection; // fix for saving context in useEffect on unmount. Otherwise state.inspection is equal to the initial state
            const currentInspection = inspectionStateToInspectionData(state.inspection);
            const prevInspection = inspectionStateToInspectionData(state.prevInspection);

            if (JSON.stringify(currentInspection) !== JSON.stringify(prevInspection)) {
                setLastUpdate(dispatch, Date.now())
            }
        }
    }, [state.prevInspection, state.inspection]);
    useInterval(() => {
        const inactive = new Date(Date.now() - 1000).getTime();
        if (state.lastUpdate !== null && state.lastUpdate < inactive && (state.lastSave === null || (state.lastSave !== null && state.lastSave < state.lastUpdate)) && state.saveErrCount < AUTO_SAVE_NUM_RETRIES) {

            if (state.inspection !== null) {
                handleTempInspectionSave(state.inspection, state.version);
            }
        }
    }, state.saveCheckInterval);

    const handleTempInspectionSave = (inspection, version) => {
        toggleAutoSave(dispatch);

        const variables = {
            data: {
                transactionId: UUID.v4(),
                id: (inspection.id) ? inspection.id : 0,
                version,
                data: inspectionStateToInspectionData(inspection),
            }
        };

        tempInspectionSaveMutation({variables})
            .then((result) => {
                    const {tempInspectionId, version, success, errors} = result.data.tempInspectionSaveHandler.result;
                    if (success) {
                        if (id === null) {
                            inspectionSetId(dispatch, tempInspectionId)
                        }
                        setDataVersion(dispatch, version)
                    } else if (errors.length > 0) {
                        toggleAutoSaveFailure(dispatch)
                    }
                }
            )
            .catch((ex) => {
                toggleAutoSaveFailure(dispatch)
            });
    };
    const handleTempInspectionConvertToInspection = (id, guid) => {
        tempInspectionConvertToInspectionMutation({
            variables: {
                data: {
                    transactionId: UUID.v4(),
                    id: state.inspection.id,
                    guid: state.inspection.guid,
                }
            }
        }).then((result) => {
            const {transactionId, inspectionId, success, errors} = result.data.tempInspectionConvertToInspectionHandler.result;

            if (success) {
                cogoToast.success("Inspection confirmed")
            }
        });
    };
    const handleTempInspectionInactivate = (id) => {
        tempInspectionInactivateMutation({
            variables: {
                data: {
                    transactionId: UUID.v4(),
                    id: id,
                    guid: state.inspection.guid,
                }
            }
        }).then((result) => {
            if (result.data.tempInspectionInactivateHandler.result.success) {
                setConfirmation(dispatch, null);
                onClose();
            }
        });
    };

    const handleOnTrashClick = () => {
        const onConfirmationYes = (conf) => {
            setConfirmation(dispatch, null);
            handleTempInspectionInactivate(state.inspection.id);
        };

        const onConfirmationNo = () => {
            setConfirmation(dispatch, null);
        };

        const onConfirmationCancel = () => {
            setConfirmation(dispatch, null);
        };

        setConfirmation(dispatch, {
            title: getText('Delete inspection'),
            description: getText('Are you sure.'),
            yesText: getText('Yes, delete'),
            type: 'warning',
            onYes: onConfirmationYes,
            onNo: onConfirmationNo,
            onCancel: onConfirmationCancel,
        });
    };


    const title = () => {
        const {inspection} = state;

        let compList = [];

        if (inspection !== null) {
            if (inspection.vehicle.vin !== "") {
                compList.push(<span>{inspection.vehicle.vin}</span>)
            }
            if (inspection.vehicle.firstRegistration !== null) {
                compList.push(<span>{moment(inspection.vehicle.firstRegistration).format('DD/MM/YYYY')}</span>)
            }
        }


        return (
            <div className="flex flex-grow justify-between items-center" style={{height: 33}}>

                <div className="flex flex-row">

                    {inspection !== null && (inspection.manual || inspection.vehicle.vin !== '') &&
                    <div className="flex flex-col justify-center mr-2">
                        <span className="bg-gray-200 text-gray-900 p-2 px-4 text-sm rounded">Initial</span>
                    </div>}

                    {inspection !== null &&
                    <div className="flex flex-col">
                        <div>
                                <span className="text-blue-500 mb-1 mr-1">
                                    {`${toUpper(inspection.vehicle.makeText)} ${toUpper(inspection.vehicle.modelText)}`}
                                </span>
                            <span className="text-gray-900 text-xs">{state.inspection.vehicle.versionText}</span>
                        </div>

                        <span className="text-gray-500 text-xs">
                               {map(compList, (comp, i) => {

                                   return (
                                       <React.Fragment key={i}>
                                           {comp}
                                           {(i < (compList.length - 1)) ? <span className="ml-1 mr-1">|</span> : ""}
                                       </React.Fragment>
                                   )
                               })}
                            </span>
                    </div>}
                </div>


                <div className="flex">

                    <div className="flex justify-center items-center text-gray-901 text-xs mr-4"
                         style={{marginRight: 44}}>

                        {tempInspectionSaveLoading &&
                        <span>
                            {`${getText('Saving')}...`}
                        </span>}

                        {!tempInspectionSaveLoading && state.saveErrCount === 0 && state.lastSave !== null &&
                        <span>
                           {getText('Saved')}
                        </span>}

                        {!tempInspectionSaveLoading && state.saveErrCount !== 0 &&
                        <span className="text-red-500 font-medium">
                            {getText('Saved failed!')}
                        </span>}
                    </div>

                    {inspection !== null && inspection.id > 0 &&
                    <div className="spin-animation-hover">
                        <i onClick={handleOnTrashClick}
                           className="fad fa-trash-alt text-red-500 hover:text-red-800 mr-3 cursor-pointer"
                           style={{fontSize: 16}}/>
                    </div>}
                </div>

            </div>
        )
    };

    const isLoading = tempInspectionFetchLoading || tempInspectionInactivateLoading || tempInspectionConvertToInspectionLoading;


    return (
        <Drawer size="lg"
                title={title}
                onClose={onClose}
                visible={true}>

            <ErrorBoundary>

                {isLoading &&
                <LoadingIndicator overlay={tempInspectionInactivateLoading}/>}

                {tempInspectionFetchError &&
                <ErrorIndicator description={tempInspectionSaveError.message}/>
                }

                {state.saveErrCount === AUTO_SAVE_NUM_RETRIES &&
                <div className='flex items-center justify-between bg-orange-200 p-2' style={{height: 35}}>
                        <span
                            className="text-orange-600  text-xs font-semibold">{getText('The auto-save function does not work well. Your changes are not saved!')} </span>
                    <span className="text-orange-900 font-medium cursor-pointer"
                          onClick={() => resetAutoSave(dispatch)}>{getText("Try again")}</span>
                </div>}

                {state.inspection !== null && !tempInspectionFetchLoading &&
                <InspectionWizard
                    inspection={state.inspection}
                    dispatch={dispatch}
                    flow={state.wizardFlow}
                    onComplete={handleTempInspectionConvertToInspection}
                />}

                {state.confirmation && <ConfirmationBox title={state.confirmation.title}
                                                        type={state.confirmation.type}
                                                        description={state.confirmation.description}
                                                        onYes={state.confirmation.onYes}
                                                        textYes={state.confirmation.yesText}
                                                        progressText={state.confirmation.progressText}
                                                        inProgress={state.confirmation.inProgress}
                                                        onNo={state.confirmation.onNo}
                                                        onCancel={() => state.confirmation.onCancel()}/>}

            </ErrorBoundary>

        </Drawer>
    )
};

export default InspectionContainer;

