import cogoToast from 'cogo-toast';

export function mapNodes(connection, fn) {
    return connection.edges.map(({node}) => fn(node));
}

export function loadMore(data, path) {
    return data.fetchMore({
        variables: {
            cursor: data.data[path].pageInfo.endCursor,
        },
        updateQuery(prev, {fetchMoreResult}) {

            if (!fetchMoreResult[path]) {
                return prev;
            }

            return {
                [path]: {
                    edges: [...prev[path].edges, ...fetchMoreResult[path].edges],
                    pageInfo: fetchMoreResult[path].pageInfo,
                    __typename: fetchMoreResult[path].__typename
                },
            };

        },
    });
}


export function handleMutation(mutate, data, info, cb) {
    const test = {...data,  public: false};
    return mutate(test)
        .then((res) => {
            if (res.data[Object.keys(res.data)[0]].ok && info.successMessage !== "") {
                cogoToast.success(info.successMessage, {hideAfter: 5});
            }

            if (cb)
                cb({success: true, data: res});
        })
        .catch(err => {
            if(err.networkError &&  (err.networkError.statusCode === 500 || err.networkError.statusCode === '500'))
                cogoToast.error('Connection problems, pleasy try again later');
            if (err.graphQLErrors.length > 0 && err.graphQLErrors[0].message) {
                cogoToast.error(err.graphQLErrors[0].message, {hideAfter: 5});
            }
            if (cb)
                cb({success: false, data: err});
        });
}
