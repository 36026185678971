import React, {useState, useEffect} from 'react';
import moment from 'moment'

const calculateTimeLeft = (date) => {
    const seconds = moment(date).diff(moment(), "seconds");
    const timeLeft = moment.duration(seconds, 'seconds');
    return timeLeft;
};


function CountDown({date}) {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(date));

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft(date));
        }, 1000);
    });

    return (
        <div className="flex items-center" style={{minWidth: 72}}>
            {`${String('00' + timeLeft.days()).slice(-2)}d ${String('00' + timeLeft.hours()).slice(-2)}:${String('00' + timeLeft.minutes()).slice(-2)}:${String('00' + timeLeft.seconds()).slice(-2)}`}
        </div>

    );
}

export default CountDown;
