import React, {useState} from 'react';
import {default as BaseTextArea} from "../../../../../../../general/base/TextArea";
import {default as BaseNumericInput} from "../../../../../../../general/base/NumericInput";
import {Button} from "../../../../../../../general";
import {useTranslate} from "../../../../../../../translations";

const ProposalForm = ({price : priceFromProps, comment: commentFromProps, onCancel, onPropose}) => {
    const {getText} = useTranslate();
    const [price, setPrice] = useState(priceFromProps);
    const [comment, setComment] = useState(commentFromProps);
    const [priceHasFocus, setPriceHasFocus] = useState(true);

    const handlePropose = () => {
        const proposal = {
            price,
            comment
        };

        onPropose(proposal);
    };

    return (
        <div className="p-2 border border-gray-200 border-solid border-r-0 border-l-0 border-t-0 bg-white cursor-pointer">
            <div className="flex flex-row">
                <div className="w-2/3" style={{marginLeft: 30}} >
                    <BaseTextArea value={comment}
                                  placeholder={getText("Comment")}
                                  onChange={(e) => setComment(e.target.value)}
                                  showSelectionBorder={true}
                                  required={false}
                                  rows={1}
                    />
                </div>
                <div className="w-1/3 flex justify-end">
                    <div className="flex flex-row items-center">
                        <span className="flex items-center font-medium text-md justify-center mr-2 pt-2">€</span>
                        <BaseNumericInput
                            placeholder={getText("Price")}
                            value={price}
                            min={1}
                            max={999999}
                            onChange={(value) => setPrice(value)}
                            className="text-right font-medium"
                            required={true}
                            hasError={false}
                            hasFocus={priceHasFocus}
                            onEnter={handlePropose}
                            onEscape={onCancel}
                            onBlur={() => setPriceHasFocus(false)}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-row justify-end mt-2">
                <Button className="mr-2" onClick={onCancel}>{getText('Cancel')}</Button>
                <Button type="primary" onClick={handlePropose}>{getText('Propose to seller')}</Button>
            </div>
        </div>
    )
};

export default ProposalForm;
