import React from 'react';


const getColorForStatus = (status) => {
    switch (status.toLowerCase()) {
        case "accepted":
            return "bg-green-500";
        case "buffer":
            return "bg-orange-500";
        case "confirmed":
            return "bg-blue-400";
        default:
            return "bg-gray-500"
    }
};

const SellerCard = ({id, type, dealerShipName, sellerName, status, highestBid, highestBidder, vehicleVin, vehiclePictureUrl, vehicleFirstRegistration, vehicleKilometers, vehicleMake, vehicleModel, vehicleVersion, managerBid, customerName, totalBids, expectedDate, expectedKm, onClick}) => {
    const statusBgColor = getColorForStatus(status);

    return (
        <div className="h-108 overflow-hidden vehicle items-start mb-2 mr-2 cursor-pointer bg-white" style={{minWidth: 285, maxWidth:285}}
             onClick={() => {
                 onClick(id, type)
             }}>
            <div className="border border-gray-500 border-solid">
                <div className="flex flex-row justify-between w-full bg-gray-800">
                    <div className=" p-1 px-2 text-white">{dealerShipName}</div>
                    <div className=" p-1 px-2 text-white">{sellerName}</div>
                </div>
                <div className="w-full h-48"
                     style={{background: `url('${vehiclePictureUrl}') center center / cover no-repeat`}}>
                    <div className="h-full flex flex-col justify-between">
                        <div className="flex flex-row justify-between w-full">
                            <div
                                className={`p-1 px-2 text-white rounded border border-white border-solid capitalize mt-2 ml-2 ${statusBgColor}`}>
                                {status}
                            </div>

                            {highestBid > 0 &&
                            <div className="mt-2 mr-2 p-1 px-2 text-white bg-blue-500 rounded font-bold"><i
                                className="fal fa-gavel"/> {highestBid} €
                            </div>}
                        </div>
                        <div className="flex flex-row justify-between w-full"
                             style={{background: "rgba(0, 0, 0, 0.5)"}}>
                            <div
                                className=" p-1 px-2 text-white opacity-100 font-bold">{vehicleFirstRegistration}</div>
                            <div className=" p-1 px-2 text-white opacity-100 font-bold">{vehicleKilometers} km</div>
                        </div>
                    </div>
                </div>
                <div className="h-1 bg-gray-200 w-full">
                    <div className="h-1"/>
                </div>
                <div className="flex flex-row items-end p-2">
                    <div className="text-blue-500 font-bold text-lg">{vehicleMake}</div>
                    <div className="ml-2 text-blue-500 text-lg">{vehicleModel}</div>
                </div>
                <div className="flex flex-row items-end -mt-1 px-2 pb-2 w-full border-b border-gray-100 border-solid">
                    <div className="text-blue-800">{vehicleVersion}</div>
                </div>
                <div
                    className="flex flex-row justify-between p-2 w-full border-b border-gray-100 border-solid items-center">
                    <div className="flex flex-row">
                        <div className="text-blue-800 text-xl"><i className="fas fa-user-tie"/></div>
                        <div className="flex items-center ml-4 text-grey-100">Manager</div>
                    </div>
                    <div className="text-blue-600 text-xl font-bold">{managerBid} €</div>
                </div>
                <div
                    className="flex flex-row justify-between p-2 w-full border-b border-gray-100 border-solid text-blue-800 ">
                    <div className="text-xl"><i className="fal fa-gavel"/></div>
                    {totalBids > 0&&
                    <div className="rounded p-1 bg-blue-400 text-white font-bold">{totalBids}</div>}
                    <div className="p-1">{highestBidder}</div>
                    <div className="text-xl">{highestBid} €</div>
                </div>
                <div className="flex flex-row p-1 w-full border-b border-gray-100 border-solid">
                    <div className="text-blue-800 w-full text-center font-bold">
                        <div className="p-1">{customerName}</div>
                    </div>
                </div>
                <div className="flex justify-between p-1 w-full border-b border-gray-100 border-solid">
                    <div className="flex justify-center">
                        <div className="text-blue-500 text-lg"><i className="fal fa-tachometer-alt"/></div>
                        <div className="ml-2 p-1 text-blue-500">{expectedKm} km</div>
                    </div>
                    <div className="flex">
                        <div className="text-blue-500 text-lg"><i className="fal fa-clock"/></div>
                        <div className="ml-2 p-1 text-blue-500">{expectedDate}</div>
                    </div>
                </div>
            </div>
        </div>


    )
};


SellerCard.defaultProps = {
    dealerShipName: 'Garage X - VW',
    sellerName: 'Verkoper X',
    status: 'EXAMPLE',
    highestBid: 8000,
    highestBidder: 'Cars on the web',
    vehicleVin: 'WOAE546548648465456',
    vehiclePictureUrl: "https://autralis.blob.core.windows.net/qathumbnails/1024x768/42/04/37/42043712215601941158382531437084349455543374349189033035076111043562400263360.jpg",
    vehicleFirstRegistration: '2016-05-01',
    vehicleKilometers: "68000",
    vehicleMake: 'Volkswagen',
    vehicleModel: 'Polo',
    vehicleVersion: '1.2 Confortline',
    managerBid: 9500,
    customerName: 'Klant xyz',
    totalBids: 5,
    expectedDate: '2019-12-31',
    expectedKm: '75000'
};

export default SellerCard;