import gql from "graphql-tag";

export const CUSTOMER_FULL = gql`
query Customer($customerId: Int!) {
    customer(id: $customerId) {
        id        
        origin {
            key
            origin
        }
        individual {
            title
            culture
            firstName
            lastName
            address {
                street
                number
                box
                postalCode
                city
                countryCode
            }
            telephone
            mobile
            email
        }
        company {
            name
            vatNumber
            legalFormId
            address {
                street
                number
                box
                postalCode
                city
                countryCode
            }
            telephone
            email    
        }
        taxation {
            isTaxable
            isCorporation
            taxablePercent
            isDiplomatic
            isDisabledPerson
            hasBelgianRegistration
        }        
    }
}`;



