import React from 'react';
import {filter, map, orderBy, uniqBy, slice} from 'lodash';
import CollapsibleHeader from "../common/CollapsibleHeader";
import TriButton from "./TriButton";
import TriSelect from "./TriSelect";
import {checkReset, checkSetValue} from "./data";


const Overview = ({value, checkGroups, dispatch}) => {

    const getControl = (check, checkValue) => {
        switch (check.type) {

            case ("Boolean" || "TriState"):
                return (
                    <div style={{width: 30}}>
                        <TriButton value={(checkValue === null) ? 0 : (checkValue === 'true') ? 1 : 2}
                                   onChange={(value) => {
                                       if (value > 0) {
                                           checkSetValue(dispatch, check.id, (value === 1) ? 'true' : 'false');
                                       } else {
                                           checkReset(dispatch, check.id, value);
                                       }
                                   }}/>
                    </div>
                );
            case "TrafficLight":
                return (
                    <TriSelect value={checkValue}
                               items={[
                                   {name: 'bad', color: 'red', placeholder: '-', value: 'red'},
                                   {name: 'middle', color: 'orange', placeholder: '0', value: 'orange'},
                                   {name: 'good', color: 'green', placeholder: '+', value: 'green'}
                               ]}
                               onChange={(value) => checkSetValue(dispatch, check.id, value)}
                    />
                );
            case "REGEX":
            case "NUMERIC":
            case "DATE":
            case "DATETIME":
            case "CHOICE":
            case "MULTICHOICE":
            case "SCALE":
                return (
                    <div>Not implemented yet</div>
                );
            default:
                break;
        }
    };

    const renderItem = (check) => {
        const checkValue = value[check.id] ? value[check.id].value : null;
        const mandatory = check.mandatory === 1;

        return (

            <div key={check.id}
                 className="flex flex-row items-center justify-between w-full"
                 style={{minHeight: 30}}>

                <div className="flex flex-1 items-center cursor-pointer" onClick={() => checkReset(dispatch, check.id)}>

                    <div className="flex justify-center items-center mr-3 relative"
                         style={{width: 22, height: 22}}>

                        <div className="flex justify-center items-center"
                             style={{width: 20, height: 20}}>
                            <i className={`icon-${check.icon} text-gray-900 text-xl`}/>
                        </div>

                        {mandatory && checkValue === null &&
                        <div
                            className={`flex justify-center items-center bg-blue-200 border border-blue-300 text-gray-700 rounded-full absolute font-medium`}
                            style={{
                                width: 14,
                                height: 14,
                                top: -8,
                                right: -8,
                                fontSize: 8,

                            }}>
                            <i className="fas fa-asterisk text-blue-500 "/>
                        </div>}


                    </div>

                    <span className={`${(mandatory && checkValue === null) ? 'text-blue-500' : 'text-gray-900'}`}>
                           {check.description}</span>
                </div>
                <div className="cursor-pointer flex pr-4"
                     style={{minHeight: 20, maxHeight: 20}}>
                    {getControl(check, checkValue)}
                </div>

            </div>

        )
    };

    const filteredAndSorted = orderBy(filter(checkGroups, (cg) => cg.checks.length > 0), "description");

    return (
        <div className="w-full overflow-scroll">
            {map(filteredAndSorted, (group) => {

                const sortedChecks = orderBy(group.checks, 'description');
                const halfwayPoint = Math.ceil(sortedChecks.length / 2);
                const columnA = slice(sortedChecks, 0, halfwayPoint);
                const columnB = slice(sortedChecks, halfwayPoint);

                return (
                    <CollapsibleHeader key={`group_${group.id}`} title={group.description}
                                       forceOpen={false} collapsible={false}>
                        <div className="flex flex-1 flex-row mt-3 mb-3">
                            <div className="flex flex-1 flex-col mr-8">
                                {map(columnA, (check, index) => {
                                    return renderItem(check);
                                })}
                            </div>
                            <div className="flex flex-1 flex-col">
                                {map(columnB, (check, index) => {
                                    return renderItem(check, false);
                                })}
                            </div>
                        </div>
                    </CollapsibleHeader>
                )
            })}
        </div>
    )
};

export default Overview;