import {GET_SESSION} from "./tags";
import {useQuery} from "@apollo/react-hooks";
import {session_default} from "./common";

const useSession = () => {
    const {data} = useQuery(GET_SESSION);

    if(data && data.session) {
        return data.session;
    }
    else {
        return session_default;
    }
}

export default useSession;