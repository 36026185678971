import React from 'react';
import {Mutation} from 'react-apollo';
import gql from 'graphql-tag';
import {LoadingIndicator, ErrorIndicator, Button} from '../../../../general';
import {getText} from "../../../../translations";

const TOGGLE_DISABLE = gql`
  mutation toggleDisable($isDisabled: Boolean!, $ids: [ID]!) {
    toggleDisable(isDisabled: $isDisabled, ids: $ids) {
        ok
    }
  }
`;

export class DisableSlot extends React.Component {

    handleToggleDisable(ids, isDisabled, toggleDisable, isArray) {
        const {onComplete} = this.props;
        toggleDisable({variables: {isDisabled: isDisabled, ids: ids}})
            .then(res => {
                if (res.data.toggleDisable.ok) {
                    onComplete(isDisabled, isArray)
                }
            })
            .catch(err => {
                let error_msg = err.message.replace("GraphQL error: ", '');
                throw(error_msg);
            })
    }

    renderDisabledButtons(data, ids, toggleDisable) {
        let isArray = Array.isArray(data);

        if (isArray || data.disabled === 2) {
            // if its a multiple selection we check if all are enabled or disabled otherwise is an mix
            let allDisabled = isArray ? data.every(child => child["disabled"] === 1) : false;
            let allEnabled = isArray ? data.every(child => child["disabled"] === 0) : false;

            // Its mix
            return <div className="flex flex-row">
                <div className={`${isArray && allDisabled ? "hidden" : "block"}`}><Button type="reject" onClick={() => this.handleToggleDisable(ids, true, toggleDisable, isArray)}>{getText('Disable all')}</Button></div>
                <div className={`${isArray && allEnabled ? "hidden" : "block"}`}> <Button type="accept" onClick={() => this.handleToggleDisable(ids, false, toggleDisable, isArray)}>{getText('Enable all')}</Button></div>
            </div>
        } else {
            // They are all disabled or enabled
            const type = (data.disabled === 1) ? 'accept' : 'reject';
            const text = data.disabled === 1 ? "Enable" : "Disable";
            const value = data.disabled === 0;
            return <Button type={type} onClick={() => this.handleToggleDisable(ids, value, toggleDisable, isArray)}>
                <span>{text}</span>
            </Button>;
        }
    }

    render() {
        const {ids, data} = this.props;

        return (
            <Mutation mutation={TOGGLE_DISABLE}>
                {(toggleDisable, {loading, error}) => {
                    if (loading) return <div className="absolute inset-0 mt-10 z-50 " style={{height: "100vh"}}><LoadingIndicator/></div>;
                    return (
                        <div>
                            {this.renderDisabledButtons(data, ids, toggleDisable)}
                            {error && <div className="absolute inset-0 mt-10 z-50"><ErrorIndicator/></div>}
                        </div>
                    )
                }}

            </Mutation>
        )
    }
}

export default DisableSlot;
