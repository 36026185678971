export function emptyModuleConfig(moduleName) {
    return {
        moduleName: moduleName,
        mainMenuName: "",
        mainMenuColor: '#d7eae7',
        blocks: {},
        panelComponent: null,
        contentComponent: null,
        cacheQuery: null,

        setMainMenu: function (name, color) {
            this.mainMenuName = name;
            this.mainMenuColor = color;
        },

        addBlock: function (name, icon, securityRoles, badgeCount, panelComponent, contentComponent) {
            this.blocks = {
                ...this.blocks,
                [name.toLowerCase()]: {
                    name: name.toLowerCase(),
                    title: name,
                    icon: icon,
                    security: securityRoles,
                    badgeCount: badgeCount,
                    panelComponent: panelComponent,
                    contentComponent: contentComponent,
                },
            };
        },

        addPanelMenu: function(component) {
            this.panelComponent = component;
        },

        setCacheQuery: function(query) {
            this.cacheQuery = query
        }
    }
}
