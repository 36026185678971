import React, {useState} from 'react';
import General from './components/General';
import ImageGallery from 'react-image-gallery';
import Features from "./components/Features";
import {useQuery} from "@apollo/react-hooks";
import {INSPECTION_FULL} from "./queries";
import LoadingIndicator from "../../../../../general/indicators/LoadingIndicator";
import Checks from "./components/Checks";
import Damages from "./components/Damages";
import {filter, find, findIndex, map, forEach} from "lodash";


const Inspection = ({inspectionId}) => {
    const [picturesForViewer, setPicturesForViewer] = useState([]);
    const [pictureViewerStartIndex, setPictureViewerStartIndex] = useState(0);

    const {data: inspectionData, loading: inspectionLoading, error: inspectionError} = useQuery(INSPECTION_FULL, {
        fetchPolicy: 'network-only',
        variables: {inspectionId: inspectionId}
    });

    const handleShowAllPictures = (startPictureId) => {
        const inspectionPictures = [...inspectionData.inspection.pictures];
        forEach(inspectionData.inspection.damages, (damage) => {
            forEach(damage.pictures, (damage_picture) => {
                inspectionPictures.push(damage_picture)
            })
        });

        let allPictures = map(filter(inspectionPictures, (p) => p.refId !== 0), (picture) => {

            return {
                id: picture.id,
                original: picture.url,
                thumbnail: picture.thumbnailUrl,
                description: picture.description,
            }
        });

        const index = findIndex(allPictures, (p) => p.id === startPictureId);
        setPictureViewerStartIndex(index);
        setPicturesForViewer(allPictures);
    };


    if (inspectionLoading) {
        return <LoadingIndicator/>
    }

    const {checkGroups} = inspectionData;
    const {vehicle, damages, pictures, features, checks} = inspectionData.inspection;

    return (
        <div className="pl-2 pr-2 pb-2">
            <General {...vehicle}
                     pictures={pictures}
                     onPictureClick={handleShowAllPictures}
            />

            <Features features={features}/>

            <Checks allChecksByGroup={checkGroups} inspectionChecks={checks}/>

            <Damages damages={damages} onPictureClick={handleShowAllPictures}/>

            {picturesForViewer.length > 0 &&
            <div className="fixed top-0 left-0 right-0 bottom-0 bg-black text-white" style={{zIndex: 999}}>

                <div className="flex flex-row justify-end mt-2 mr-2 ">
                    <i onClick={() => setPicturesForViewer([])}
                       className="fal fa-times cursor-pointer text-white"
                       style={{fontSize: 30}}/>
                </div>

                <div className='flex h-full items-center justify-center'>
                    <div style={{width: '60%'}}>
                        <ImageGallery
                            showPlayButton={false}
                            showBullets={true}
                            startIndex={pictureViewerStartIndex}
                            items={picturesForViewer}/>
                    </div>
                </div>

            </div>
            }

        </div>
    )
};

export default Inspection

//
// import React, {useState} from 'react';
// import Buttons from "./Buttons";
// import Summary from "../Summary";
// import WizardAnimation from "./WizardAnimation";
// import {map, find, filter, isEmpty, orderBy, uniqBy, range, slice, includes, keyBy, values, findIndex} from 'lodash';
// import moment from "moment";
// import {wizardSummaryGroups} from "../inspectionData";
// import withSession from "../../../../../project/session/withSession";

// import 'react-image-gallery/styles/css/image-gallery.css'
// import {useTranslate} from "../../../../../translations";
// import {TextArea} from "../../../../../general/forms";
// import {vehicleSetCommentNegative, vehicleSetCommentPositive} from "../vehicleData";
//
//
// const Confirmation = ({onNext, onPrev, onComplete, onStepClick, wizard, data: {inspection, features, damageLocations, inspectionChecks, pictureTypes, dispatch}, session}) => {
//
//
//     const emptyVehiclePictures = (vehiclePictures.length < 6) ? (6 - vehiclePictures.length) : 0;
//     const vehiclePicturesErrors = filter(values(VehicleErrorsByField), (err) => err.field.startsWith('pictureType_'));
//     const vehiclePicturesErrorMessage = (vehiclePicturesErrors.length > 0) ? vehiclePicturesErrors[0].message : "";
//
//     const vehicleFeatures = orderBy(map(inspection.inspection.featureIds, (id) => {
//         const feature = find(features, (f) => f.id === id);
//         const translations = JSON.parse(feature.data);
//         return {
//             id: feature.id,
//             description: translations[session.locale]
//         };
//     }), 'description');
//     const triplePoint = Math.ceil(vehicleFeatures.length / 3);
//     const featuresColumnA = slice(vehicleFeatures, 0, triplePoint);
//     const featuresColumnB = slice(vehicleFeatures, triplePoint, (triplePoint * 2));
//     const featuresColumnC = slice(vehicleFeatures, (triplePoint * 2));
//
//
//     const uniqueCheckGroups = orderBy(uniqBy(map(inspectionChecks, (c) => c.checkGroup), (g) => g.id), 'name');
//     const vehicleChecks = map(inspectionChecks, (check) => {
//         const checkValue = find(inspection.inspection.checks, (i_ch) => i_ch.checkId === check.id)
//         return {
//             ...check,
//             value: (checkValue) ? checkValue.value : null,
//         }
//     });
//
//     const errorClass = "bg-orange-100 cursor-pointer";
//     const defaultClass = "bg-gray-100 cursor-default";
//
//     const handleShowAllPictures = (startPictureId) => {
//         let allPictures = map(filter(inspection.inspection.pictures, (p) => p.refId !== 0), (pic, i) => {
//             let description = "";
//
//             if (pic.refType === 'pictureType') {
//                 const pictureType = find(pictureTypes, (pt) => pt.id === pic.refId);
//                 if (pictureType) {
//                     description = pictureType.name;
//                 }
//             } else if (pic.refType === 'damage') {
//                 if (inspection.inspection.damages.damagesById[pic.refId]) {
//                     const damage = inspection.inspection.damages.damagesById[pic.refId];
//                     const damageLocation = find(damageLocations, (dl) => dl.id === damage.locationId);
//                     const damageType = (damage.typeId > 0) ? find(damageLocation.damageTypes, (dt) => dt.id === damage.typeId) : null;
//                     const damageSeverity = (damage.severityId > 0) ? find(damageType.severities, (sev) => sev.id === damage.severityId) : null;
//                     description = `${(damageType !== null) ? damageType.name : ""} - ${(damageSeverity !== null) ? damageSeverity.name : ""}`;
//                 }
//             }
//             return {
//                 id: pic.id,
//                 original: pic.url,
//                 thumbnail: pic.thumbnailUrl,
//                 description: description,
//             }
//         });
//
//         const index = findIndex(allPictures, (p) => p.id === startPictureId);
//         setPictureViewerStartIndex(index);
//         setPicturesForViewer(allPictures);
//     };
//
//
//     return (
//         <div className="flex h-full w-full">
//
//
//

//
//
//
//
//
//             </div>
//
//
//         </div>
//     )
// };
//
//
// export default withSession(Confirmation);