import React, {useEffect, useRef, useState} from 'react';
import * as PropTypes from "prop-types";
import {formatNumber, stringToFloat} from "../utils";

const NumericInput = ({name, value, min, max, required, placeholder, disabled, onChange, className, showBorder, hasError, hasFocus, showSpinners, showZeroValue, onEnter, onEscape, onBlur, suffix, suffixClassName, prefix, prefixClassName}) => {
    const inputRef = useRef(null);
    const [inputHasFocus, setInputHasFocus] = useState(hasFocus);

    useEffect(() => {
        if (hasFocus) {
            inputRef.current.focus();
            inputRef.current.select()
        }

    }, [hasFocus, inputHasFocus]);


    const handleKeyDown = (e) => {
        if (e.keyCode === 69 || e.keyCode === 190)
            e.preventDefault();

        if (e.keyCode === 13 && onEnter) {
            onEnter(value)
        } else if (e.keyCode === 27 && onEscape) {
            onEscape()
        }
    };

    const handleOnChange = (e) => {
        const value = stringToFloat(e.target.value);
        onChange(value);
    };

    const borderClass = (showBorder) ? `border ${(hasError) ? "border-red-500" : ((inputHasFocus) ? "border-blue-500" : (required && !value) ? "border-blue-200" : "border-gray-200")} border-t-0 border-l-0 border-r-0` : '';

    return (
        <React.Fragment>
            {prefix &&
            <div className={`font-medium text-md p-1 ${borderClass} ${(!disabled) ? 'bg-white' : ''} ${prefixClassName}`}>{prefix}</div>}

            <input name={name}
                   ref={inputRef}
                   onFocus={() => setInputHasFocus(true)}
                   onBlur={() => {
                       setInputHasFocus(false);
                       if (onBlur) {
                           onBlur();
                       }
                   }}
                   type="text"
                   value={(!showZeroValue && value === 0) ? "" : formatNumber(value)}
                   min={min}
                   max={max}
                   placeholder={placeholder}
                   disabled={disabled}
                   onChange={handleOnChange}
                   className={`w-full font-normal text-md pt-1 pb-1 text-gray-900 bg-white ${borderClass} ${className} ${(!showSpinners) ? "hide-spinners" : '"'}`}
                   onKeyDown={handleKeyDown}
            />
            {suffix &&
            <div className={`font normal text-md pt-1 pb-1 pr-1 ${borderClass} ${(!disabled) ? 'bg-white' : ''} ${suffixClassName}`}>{suffix}</div>}
        </React.Fragment>
    )
};

NumericInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    showBorder: PropTypes.bool,
    hasError: PropTypes.bool,
    hasFocus: PropTypes.bool,
    showSpinners: PropTypes.bool,
    suffix: PropTypes.string,
    suffixClassName: PropTypes.string,
    prefix: PropTypes.string,
    prefixClassName: PropTypes.string,

};

NumericInput.defaultProps = {
    name: 'NumericInput',
    value: "",
    min: 0,
    max: 10,
    required: false,
    placeholder: "",
    disabled: false,
    inputRef: null,
    onChange: null,
    className: "",
    showBorder: true,
    hasError: false,
    hasFocus: false,
    showSpinners: false,
    showZeroValue: false,
    suffix: undefined,
    suffixClassName: "",
    prefix: undefined,
    prefixClassName: "",

};

export default NumericInput;


