import React from 'react';
import {Drawer, withQuery} from "../../../general";

import {MASTER_MAIN_CONFIG} from "./queries";


const MainConfig = ({onClose, data: {}}) => {

    let editor;

    const exportHtml = () => {
        editor.exportHtml(data => {
            const { design, html } = data;
            console.log('exportHtml', html)
        });
    };

    return (
        <Drawer size="xl"
                title={() => <button onClick={exportHtml}>Export HTML</button> }
                onClose={onClose}
                visible={true}
                contentStyle={{margin: 0, padding: 0}}>
            <div className="flex flex-col w-full h-full">
                Content here
            </div>
        </Drawer>
    )
};

export default withQuery({query: MASTER_MAIN_CONFIG, variables: (props) => ({companyId: 1})})(MainConfig);


