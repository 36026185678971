import React, {useState} from 'react';
import MainDrawer from "./MainDrawer";
import {useTranslate} from "../../../translations";
import {DrawerManager} from "../../../general";


export const DRAWER_TYPES = {
    MAIN_CONFIG: "MAIN_CONFIG",
};

const DrawerManagerConfig = {
    [DRAWER_TYPES.MAIN_CONFIG]: {component: MainDrawer},
};

const Overview = (props) => {
    const [currentDrawer, setCurrentDrawer] = useState(null);

    const handleNewTakeOver = () => {
        console.log('bam');
        setCurrentDrawer({
            name: DRAWER_TYPES.MAIN_CONFIG,
            params: {
                id: null,
            }
        });
    };

    return (
        <div className="w-full p-2">
            <div className="p-4 bg-blue-600 text-white" onClick={handleNewTakeOver}>Content</div>

            {currentDrawer !== null &&
            <DrawerManager config={DrawerManagerConfig}
                           current={currentDrawer.name}
                           params={currentDrawer.params}
                           onCurrentDrawerClose={() => setCurrentDrawer(null)}
            />}

        </div>
    )
};

export default Overview;
