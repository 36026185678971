import React, {useEffect, useState} from 'react';
import {SearchField} from "../../../../../general/base";
import {head, filter, findIndex} from 'lodash';
import {useQuery,} from "@apollo/react-hooks";
import ProgressBar from "../../components/ProgressBar";
import {queries} from './queries';
import {useTranslate} from "../../../../../translations";

const autocomplete_height = 250;

const VinSearch = ({onBuildManual, onBuildByVin, onBuyCredits, hasVinCheck, hasDivCheck, infiniteCredits}) => {
    const [shouldExecuteQuery, executeQuery] = useState(false);
    const [autocompleteVisible, setAutocompleteVisible] = useState(false);
    const [selectedVin, setSelectedVin] = useState("");
    const [input, setInput] = useState("");
    const {getText} = useTranslate();

    const {data: vinSuggestions, loading: fetchVinSuggestionsLoading, error: fetchVinSuggestionsError} = useQuery(queries.GET_VIN_SUGGESTIONS, {
        skip: !shouldExecuteQuery, // fix because useLazyQuery does not work good with 'network-only"' fetch policy
        fetchPolicy: 'network-only',
        variables: {
            vin: input,
        },
        onCompleted: data => {
            setAutocompleteVisible(true);
        }
    });

    useEffect(() => {
        if (input.length >= 6) {
            executeQuery(true) // fix because useLazyQuery does not work good with 'network-only"' fetch policy
        } else if (input.length < 6 && autocompleteVisible) {
            executeQuery(false);
            setAutocompleteVisible(false);
        }
    }, [input, autocompleteVisible]);

    //const {data: credits_data} = useQuery(queries.GET_CREDITS);
    const credits_data = undefined;
    const vin_credits = credits_data !== undefined && head(filter(credits_data.vehicleCredit, vc => vc.type === "VIN"));
    const licence_plate = credits_data !== undefined && head(filter(credits_data.vehicleCredit, vc => vc.type === "DIV"));

    // const [useCredits, {loading: mutationLoading, error: mutationError}] = useMutation(mutations.USE_CREDITS);
    //
    // const onUseVin = (vin) => {
    //     setInput(vin);
    //     //   setAutocompleteVisible(false);
    //     useCredits({variables: {id: vin_credits.id, vin: vin}}).then(({data: {useCredits}}) => {
    //         mutationCompleted(useCredits, vin)
    //     })
    // };
    //
    // const onUseLicencePlate = (licence_plate) => {
    //     setInput(licence_plate);
    //     //   setAutocompleteVisible(false);
    //     useCredits({variables: {id: licence_plate.id, vin: licence_plate}}).then(({data: {useCredits}}) => {
    //         mutationCompleted(useCredits, licence_plate)
    //     })
    // };

    const modifyViewport = (key_pressed, current_vin) => {
        const selected_elem = document.getElementById(current_vin);
        if(selected_elem !== null){
            document.getElementById("autocomplete").scrollTop = selected_elem.offsetTop - 35;
        }
    };

    const onKeyDown = (e) => {
        const enter_pressed = e.key === "Enter";
        const arrow_down_pressed = e.key === "ArrowDown";
        const arrow_up_pressed = e.key === "ArrowUp";
        if (vinSuggestions !== undefined && vinSuggestions.vinLookup.length === 1) {
            if(enter_pressed === "Enter"){
                const vin = head(vinSuggestions.vinLookup) !== undefined ? head(vinSuggestions.vinLookup).vin : undefined;
                if (vin !== undefined) {
                    onBuildByVin(vin)
                }
            }
        }

        if(vinSuggestions !== undefined && vinSuggestions.vinLookup.length > 1){
            let new_vin = "";
            if(selectedVin !== ""){
                const current_index = findIndex(vinSuggestions.vinLookup, v => v.vin === selectedVin);
                if(arrow_down_pressed){
                    //Check next
                    const next_elem = vinSuggestions.vinLookup[current_index + 1];
                    new_vin = next_elem !== undefined ? next_elem.vin : selectedVin
                }

                if(arrow_up_pressed){
                    //check prev
                    const prev_elem = vinSuggestions.vinLookup[current_index - 1];
                    new_vin = prev_elem !== undefined ? prev_elem.vin : selectedVin
                }
            }else{
                if(arrow_down_pressed){
                    new_vin = vinSuggestions.vinLookup[0].vin
                }
            }


            if(selectedVin !== new_vin){
                if(enter_pressed){
                    onBuildByVin(selectedVin)
                }

                modifyViewport(e.key, new_vin);
                setSelectedVin(new_vin)
            }
        }

    };

    return (
        <div
            className="flex flex-col justify-center absolute bg-white border-t-4 border-blue-500 border-l-0 border-r-0 border-b-0 shadow-lg"
            style={{width: 420, height: 165, top: 25, left: 455}}>


            <div className="flex flex-1 flex-col p-4 pb-0">
                <div className="font-medium text-xl text-black mb-2">{getText('New Takeover')}</div>

                <div className="relative ">
                    <SearchField
                        onChange={e => setInput(e.target.value)}
                        clearSearch={() => setInput('')}
                        value={input}
                        clearIcon="fal fa-times"
                        placeholder={getText("Search VIN or license plate")}
                        withButton={false}
                        buttonStyles={{borderLeftWidth: 0}}
                        onClick={e => alert("Vin: " + input)}
                        onKeyDown={onKeyDown}
                        inputClassName="border-blue-400 bg-gray-100 placeholder-blue-500 placeholder-xl placeholder-bold text-sm text-blue-500 font-medium"
                        iconClass="text-md text-blue-700 font-medium"
                        className="bg-gray-100 border border-gray-300 rounded"
                        loading={fetchVinSuggestionsLoading}
                    />

                    {autocompleteVisible &&
                    <div className="absolute left-0 right-0 bg-white border border-gray-100 p-2" style={{top: 35, minHeight: 85, zIndex: 99, maxHeight: 300}}>

                        <div id="autocomplete" className="overflow-y-scroll" style={{maxHeight: autocomplete_height}}>
                            {fetchVinSuggestionsError !== undefined &&
                            <div
                                className="bg-gray-100 p-2 text-center text-gray-700">{getText("Something went wrong.")}</div>
                            }

                            {vinSuggestions.vinLookup.length < 1 && <div className="bg-gray-100 p-2 text-center text-gray-700">{getText("No matches found")}</div>}

                            {vinSuggestions.vinLookup.length > 0 && vinSuggestions.vinLookup.map((v) => {
                                return <div key={v.vin}
                                            id={v.vin}
                                            className={`p-2 mb-1 cursor-pointer hover:bg-gray-200 ${selectedVin === v.vin ? "bg-blue-100 text-blue-500" : "text-gray-700 bg-gray-100"}`}
                                            onClick={() => onBuildByVin(v.vin)}>
                                    {v.vin}
                                </div>
                            })}
                        </div>


                        <div
                            className={`flex mt-1 ${(vinSuggestions.vinLookup.length === 0 ? 'justify-between' : 'justify-center')}`}>
                            {vinSuggestions.vinLookup.length === 0 &&
                            <span
                                className="flex  text-blue-400 hover:text-blue-700 my-2 cursor-pointer">{getText("Looking for licence plate?")}</span>
                            }

                            <span className="flex justify-center text-blue-400 hover:text-blue-700 my-2 cursor-pointer"
                                  onClick={onBuildManual}>{getText('Build your car manually')}?
                            </span>
                        </div>

                    </div>}

                </div>


                <span className="flex justify-end text-gray-900 hover:text-blue-700 my-2 cursor-pointer pt-1"
                      onClick={onBuildManual}>{getText('Build your car manually')}?
                            </span>
                {/*<Button type="primary" className="w-full text-center mt-2" onClick={onBuildManual}>{getText('Build your car manually')}</Button>*/}
            </div>


            <div className="flex flex-row items-center  bg-gray-100 p-2 pl-4 pr-4 ">

                <div
                    className={`${(infiniteCredits) ? "w-1/2" : 'w-2/5'} border-gray-300 border-r border-t-0 border-l-0 border-b-0 pr-2`}>
                    <div className="flex justify-between mb-1">
                        <span className="font-medium text-gray-900">
                            {(infiniteCredits) ? getText("Unlimited") : vin_credits.total}
                        </span>
                        <span className="text-3xs text-gray-600 self-end">
                            {getText('Chassisnumber')}
                        </span>
                    </div>
                    <ProgressBar percentage={(infiniteCredits) ? 100 : 75} height={8} color="#1FF50A"/>
                </div>


                <div
                    className={`${(infiniteCredits) ? "w-1/2" : 'w-2/5 pr-2'} pl-2`}>
                    <div className="flex justify-between mb-1">
                        <span className="font-medium text-gray-900">
                             {(infiniteCredits) ? getText("Unlimited") : licence_plate.total}
                        </span>
                        <span className="text-3xs text-gray-600 self-end">
                            {getText('License plate')}
                        </span>
                    </div>
                    <ProgressBar percentage={(infiniteCredits) ? 100 : 50} height={8} color="#F6C309"/>
                </div>

                {!infiniteCredits &&
                <div
                    className="w-1/5 flex justify-center pl-2 border-gray-300 border-l  border-t-0 border-r-0 border-b-0">
                    <span className="text-gray-900 self-center cursor-pointer text-3xs font-medium"
                          onClick={onBuyCredits}> {getText('Buy more')} </span>
                </div>}
            </div>
        </div>
    )
};

VinSearch.defaultProps = {
    infiniteCredits: false,
};

export default VinSearch;
