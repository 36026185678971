import React from 'react';
import {Drawer, withMutation} from '../../general';
import {Form, TextInput, FormValidators, FormActionButton} from '../../general/forms-final-form';
import UUID from 'uuid';
import gql from 'graphql-tag';

const formId = UUID.v4();


const MUTATION = gql`
  mutation Mutation($input: mutationInput!) {
    mutation(input: $input) {
        ok    
    }
  }
`;


class DrawerTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
    }

    submit(data) {
        const {mutation} = this.props;

        alert(data.field);

        mutation.mutate({
            variables: {
                input: {
                    var_x: 'value',
                }
            }
        }, (data => {
            // Optional format form after submit
            if (data.success) {
                this.formReset()
            }
        }))
    }

    render() {
        const {onClose, mutation: {loading, error}, drawerParams} = this.props;

        const title = () => (
            <div className="flex flex-grow justify-between items-center">
                <div>
                    <FormActionButton formId={formId}>Save</FormActionButton>
                </div>

            </div>
        );
        return (
            <Drawer size="lg"
                    title={title}
                    onClose={onClose}
                    visible={true}>

                <Form id={formId}
                      onSubmit={this.submit}
                      initialValues={{field: drawerParams !== undefined ? drawerParams.field : ""}}
                      indicators={{
                          loading: loading,
                          error: (error && error.graphQLErrors.length > 0) ? error.graphQLErrors[0].message : ''
                      }}
                      render={({values, form}) => {
                          if (!this.formReset) {
                              this.formReset = form.reset;
                          }

                          return (
                              <React.Fragment>
                                  <TextInput name="field"
                                         label="label field"
                                         placeholder="Field placeholder"
                                         validators={[FormValidators.required]}
                                  />
                              </React.Fragment>

                          )
                      }}
                />
            </Drawer>
        );
    }
}

export default withMutation({
    mutation: MUTATION,
    successMessage: "Mutation successfully"
})(DrawerTemplate);
