import React from 'react';
import {default as BaseTextLabel} from "../base/TextLabel";
import * as PropTypes from "prop-types";
import LabelContainer from "./LabelContainer";

const TextLabel = ({label, value, className, inputClassName, showBorder, labelTextClassName, textClassName, marginBottom}) => {
     return (
        <LabelContainer label={label}
                        disabled={false}
                        className={className}
                        error=""
                        hasError={false}
                        hasValue={value !== ""}
                        textClassName={labelTextClassName}
                        marginBottom={marginBottom}
        >

            <BaseTextLabel value={value}
                           className={inputClassName}
                           showBorder={showBorder}
                           textClassName={textClassName}
            />
        </LabelContainer>
    );
};

TextLabel.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    value: PropTypes.string.isRequired,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    showBorder: PropTypes.bool,
    labelTextClassName: PropTypes.string,
    textClassName: PropTypes.string,
    marginBottom: PropTypes.number,
};

TextLabel.defaultProps = {
    label: "Label",
    value: "",
    className: "",
    inputClassName: "",
    showBorder: true,
    labelTextClassName: "",
    textClassName: "",
    marginBottom: 4,
};

export default TextLabel;


