import React from "react";
import "./ApplicationsMenu.css";
import {filter, includes, values} from 'lodash';
import gql from "graphql-tag";
import {withQuery} from "../../general";
import {withNavigation} from '../navigation';
import {compose} from "recompose";

const GET_DATA = gql`
  {
    applications @client {      
      name
      title
      color
      blocks {
        id
        name
        title
        icon
        security
        badgeCount
      }       
    } 
    
    currentApp @client {
      name
      title 
      color      
   }
   
    session @client {
        modules
    }
  }
`;

class ApplicationsMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = (app) => {
        if (this.state.open && app !== null) {
            this.props.navigate.application(app.name, app.blocks[0].name);
        }
        this.setState({
            open: !this.state.open
        });
    };


    render() {
        const {open} = this.state;
        const {data: {applications, session, currentApp}} = this.props;

        const width = open ? "220px" : "7px";
        const opacity = open ? 1 : 0;
        const selectedBgColor = (open) ? currentApp.color : 'transparent';

        const applicationsForUser = values(filter(applications, (application) => includes(session.modules, application.name) && application.name !== currentApp.name));
        const selectedWidth = (open) ? 265 : (286 - (applicationsForUser.length * 7));

        return (
            <div className="app-menu">
                <div className="selected-menu-item" style={{backgroundColor: selectedBgColor, width: selectedWidth}}
                     onClick={() => this.handleClick(null)}>
                    <div className="menu-item-title">Autralis.</div>
                    <div className="menu-item-app">{currentApp.title}</div>
                </div>

                {applicationsForUser.map((app, id) =>
                    <div key={id} className="item"
                         style={{width: width, backgroundColor: app.color, paddingLeft: (this.state.open) ? 20 : 0}}
                         onClick={() => this.handleClick(app)}>
                        <div className="menu-item-title" style={{opacity: opacity}}>Autralis.</div>
                        <div className="menu-item-app" style={{opacity: opacity}}>{app.title}</div>
                    </div>)}
            </div>
        );
    }
}

export default compose(
    withNavigation,
    withQuery({query: GET_DATA})
)(ApplicationsMenu);

