import React, {useState} from 'react';
import Buttons from "./Buttons";
import DateSelector from "../components/DateSelector";
import Summary from "./Summary";
import {vehicleSetFirstRegistration} from "../vehicleData";

const Registration = ({data, onNext, onPrev, onUpdate, onStepClick, wizard}) => {
    const {firstRegistration} = data.inspection.vehicle;
    const [edit] = useState(firstRegistration !== null);

    const year = (firstRegistration !== null) ? parseInt(firstRegistration.split('-')[0]) : null;
    const month = (firstRegistration !== null) ? parseInt(firstRegistration.split('-')[1]) : null;
    const day = (firstRegistration !== null) ? parseInt(firstRegistration.split('-')[2]) : null;

    return (
        <div className="flex h-full w-full">
            <div className="w-1/4">
                <Summary data={wizard} onStepClick={onStepClick}/>
            </div>

            <div className="w-3/4 flex flex-col flex-1  bg-white p-2">

                <div className="flex flex-1 overflow-scroll ">
                    <DateSelector onPrev={onPrev}
                                  onReset={data.resetGeneralInfo}
                                  value={{
                                      day,
                                      month,
                                      year
                                  }}
                                  onValueChanged={(day, month, year) => {
                                      const date = new Date(`${year}/${month}/${day} UTC`).toISOString().substring(0, 10);
                                      vehicleSetFirstRegistration(data.dispatch, date);
                                      onNext();
                                  }}/>
                </div>


                <Buttons onNext={onNext}
                         onPrev={onPrev}
                         data={wizard}
                         nextDisabled={!edit}
                />
            </div>
        </div>
    )
};

export default Registration;