import React from 'react';
import {find} from 'lodash';
import {wizardSteps} from "../inspectionData";

const Wizard = ({onComplete, dataForChildren, flow, flowProgress, currentStepId, lastCompletedStepId, navigateToStep, onNext, onPrev, onStepClick}) => {

   // const current = find(children, (child) => child.props.id === currentStepId);
    const current = find(wizardSteps, (step) => step.id === currentStepId);

    return (
        <React.Fragment>
            {
                React.cloneElement(
                    current.component,
                    {
                        onNext: () => {
                            onNext(currentStepId)
                        },
                        onPrev: () => {
                            onPrev(currentStepId)
                        },
                        onStepClick: (group) => {
                            onStepClick(group, currentStepId)
                        },
                        onComplete: () => {
                            onComplete();
                        },
                        id: current.id,
                        data: {...dataForChildren},
                        wizard: {
                            currentStepId,
                            currentStep: find(wizardSteps, (s) => s.id === currentStepId),
                            lastCompletedStepId,
                            flow,
                            flowProgress,
                            navigateToStep,
                            canPrev: () => {}
                        }
                    }
                )
            }
        </React.Fragment>
    )
};

export default Wizard;