
export const required = value => value ? undefined : 'Required';
export const requiredTriState = value => value === "" || value === undefined ? 'Required' : undefined;

export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const email = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined;

const minLength = min => value => value && value.length < min ? `Must be ${min} characters or more` : undefined;
const maxLength = max => value => value && value.length > max ? `Can be max ${max} characters long` : undefined;



export const minLength6 = minLength(6);
export const minLength10 = minLength(10);
export const minLength16 = minLength(16);
export const maxLength5 = maxLength(5);
export const maxLength16 = maxLength(16);

const minValue = min => value => value && value < min ? `Must be at least ${min}` : undefined;

export const minValue6 = minValue(6);

export const hasNumber = value => value && !/\d/.test(value) ? 'Must contain a number' : undefined;

export const hasLowerCase = value => {
    if(value.toUpperCase() !== value) {
        return undefined;
    }
    return 'Must contain a lowercase character';
};

export const hasUpperCase = value => {
    if(value.toLowerCase() !== value) {
        return undefined;
    }
    return 'Must contain a uppercase character';
};


export const isEqualOrLower = max_value => value => {
    let result = undefined;
    if(value !== null && max_value !== null){
        if(parseInt(value, 10) <= parseInt(max_value, 10)) {
            result = 'Must be greater than start value';
        }
    }
    return result;
};

export const isEqual = comp_value => value => {
    if(value === null || comp_value === null)
        return undefined;

    if(value !== comp_value)
        return "Fields are not equal";

    return undefined;
};

export const passwordsEqual = password_confirm => password => {
    if(password === null || password_confirm === null)
        return undefined;

    if(password !== password_confirm)
        return "Passwords are not equal";

    return undefined;
};

export const minOneSelected = comp_value => values => {
    if(values === null || comp_value === null)
        return undefined;

    if(Object.keys(values).every(key => !values[key].checked))
        return "At least one record needs to be selected.";

    return undefined;
};


// composeValidators(required, mustBeNumber, minValue(18))
export const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined);
