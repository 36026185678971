import React from 'react';
import {default as BaseColorListPicker} from "../base/ColorListPicker";
import * as PropTypes from "prop-types";
import LabelContainer from "./LabelContainer";

const ColorListPicker = ({label, value, items, required, disabled, onSelect, className, inputClassName, showBorder, error, hasError, description}) => {

    return (
        <LabelContainer label={label}
                        disabled={disabled}
                        className={className}
                        error={error}
                        hasError={hasError}
                        hasValue={value !== ""}>

            <div className="w-full">
                {description !== null && description !== "" &&
                <div className="font-medium text-xs pt-1 mt-1">
                    <span>{description}</span>
                </div>}

                <BaseColorListPicker
                    value={value}
                    items={items}
                    required={required}
                    disabled={disabled}
                    onSelect={onSelect}
                    className={inputClassName}
                    showBorder={showBorder}
                    hasError={hasError}
                />


            </div>

        </LabelContainer>
    );
};

ColorListPicker.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    value: PropTypes.string.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onSelect: PropTypes.func,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    showBorder: PropTypes.bool,
    error: PropTypes.string,
    hasError: PropTypes.bool,
    description: PropTypes.string,
};

ColorListPicker.defaultProps = {
    label: "Label",
    value: "",
    required: false,
    disabled: false,
    onSelect: null,
    className: "",
    inputClassName: "",
    showBorder: true,
    error: "",
    hasError: false,
    description: "",
};

export default ColorListPicker;


