import React, {Component} from 'react';

export default class TableToolbar extends Component {

    render() {
        const {title, children} = this.props;

        return (
            <div className="p-2 bg-white flex flex-col z-10 border-b border-gray-200 relative">
                <div className="flex flex-row items-center relative">
                    <div className="max-w-xs text-xs font-extrabold pr-4 border-r border-gray-600">{title}</div>
                    <div className="flex-grow ml-2">
                        {children}
                    </div>
                    <div className="max-w-md">
                        {/*default table actions/*/}
                    </div>
                </div>
            </div>

        );
    }
}



