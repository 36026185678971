import {paths} from "./common";
import {createBrowserHistory} from "history";

export const history = createBrowserHistory();

history.listen(() => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistration().then((reg) => {
            if (!reg) {
                return null;
            }
            reg.update();
        })
    }
});


export const handleNavigation = (route, params) => {
    let path = '';

    switch (route) {
        case paths.APPLICATION:
            path = `/${params.application}/${params.block}`;
            break;
        case paths.REGISTER:
            path = (params.companyKey) ?`/${params.companyKey}`: '';
            break;
        default:
            path = route;
    }

    history.push({
        pathname: path,
    });
};

export const navigate = {
    root: () => handleNavigation(paths.LANDING),
    login: () => handleNavigation(paths.LOGIN),
    selectCompany: () => handleNavigation(paths.SELECT_COMPANY),
    register: (companyKey=null) => handleNavigation(paths.REGISTER_WITH_PARAMS,{companyKey}),
    registrationWizard: () => handleNavigation(paths.REGISTRATION_WIZARD),
    forgotPassword: () => handleNavigation(paths.FORGOT_PASSWORD),
    resetPassword: () => handleNavigation(paths.RESET_PASSWORD),

    dashboard: () => handleNavigation(paths.APPLICATION, {application: 'master', block: 'dashboard'}),
    master: () => handleNavigation(paths.MASTER),
    application: (application, block) => handleNavigation(paths.APPLICATION, {application ,block}),

};
