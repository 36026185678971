import React from 'react';
import {orderBy, map, find, filter, slice, get, includes} from 'lodash';
import {useTranslate} from "../../../../../../translations";

const Checks = ({allChecksByGroup, inspectionChecks}) => {
    const {getText} = useTranslate();

    const allChecksByGroupOrdered = orderBy(filter(allChecksByGroup, (group) => group.checks.length > 0), 'description');

    const renderCheck = (check, data) => {

        const inspectionCheck = find(data, {checkTypeId: check.id});
        const value = get(inspectionCheck, "value");

        return (
            <div key={check.id}
                 className="flex w-full justify-between mb-2">
                <div
                    className="text-gray-900 text-xs">{check.description}</div>

                {check.type === "Boolean" && value !== null &&
                <i className={`${(value === 'true') ? 'fa fa-check' : 'fa fa-times'} text-sm text-gray-900`}/>
                }
                {check.type === "TrafficLight" && value !== null &&
                <div>
                    <i className={`${(value === "red") ? 'fas fa-circle text-red-500' : 'far fa-circle text-gray-900'} text-sm mr-1`}></i>
                    <i className={`${(value === "orange") ? 'fas fa-circle text-orange-500' : 'far fa-circle text-gray-900'}  text-sm mr-1`}></i>
                    <i className={`${(value === "green") ? 'fas fa-circle text-green-500' : 'far fa-circle text-gray-900'} text-sm `}></i>
                </div>
                }
            </div>
        )
    }


    return (
        <div className="flex flex-col">
            <div className="flex items-center pl-2 text-white bg-blue-500 text-md  h-8 py-2">
                {getText("Checks")}
            </div>

            <div className="flex flex-row flex-wrap w-full">

                <div className="w-full overflow-scroll mb-1">
                    {map(allChecksByGroupOrdered, (group) => {

                        const inspectionCheckIds = map(inspectionChecks, (check) => check.checkTypeId);
                        const checks = orderBy(filter(group.checks, (check) => includes(inspectionCheckIds, check.id)), 'description');

                        if(checks.length === 0){
                            return;
                        }

                        const halfwayPoint = Math.ceil(checks.length / 2);
                        const columnA = slice(checks, 0, halfwayPoint);
                        const columnB = slice(checks, halfwayPoint);

                        return (
                            <div key={`checkgroup_${group.id}`}
                                 className={`flex flex-1 flex-col collapsible-panel bg-gray-100 cursor-default mt-2 mb-2 `}>

                                <div className="flex flex-row  p-2 font-medium text-xs ">
                                    <span className="text-xs">
                                        {group.description}
                                    </span>
                                </div>

                                <div className="flex flex-1 flex-row p-2 pt-0">
                                    <div className="flex flex-1 flex-col mr-8 ">
                                        {map(columnA, (check) => {
                                            return renderCheck(check, inspectionChecks)
                                        })}
                                    </div>
                                    <div className="flex flex-1 flex-col">
                                        {map(columnB, (check) => {
                                            return renderCheck(check, inspectionChecks)
                                        })}
                                    </div>
                                </div>
                            </div>

                        )
                    })}
                </div>

            </div>
        </div>
    )
};

export default Checks;