import React from 'react';
import {withMutation} from "../../../../general";
import gql from "graphql-tag";
import {
    Form,
    TextInput,
    Select,
    CheckBox,
    FormValidators,
} from '../../../../general/forms-final-form'

import Overlay from "./Overlay";
import {getText} from "../../../../translations";


const UPDATE_SLOT = gql`
  mutation updateSlot($input: SlotInput!, $slotProperty: String!, $ids: [ID]!) {
    updateSlot(input: $input, slotProperty: $slotProperty, ids: $ids) {
        ok
    }
  }
`;


export class EditSlot extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            saved: false,
            hasError: false,
            errorMessage: ""
        }

        this.updateSlot = this.updateSlot.bind(this);
    }

    updateSlot(data) {
        const {onComplete, ids, mutation} = this.props;
        const {slotProperty} = this.props.data;
        const address_id = this.props.data.slot.address.id;

        const slot = {
            ...data,
            address: address_id
        };

        mutation.mutate({variables: {input: slot, slotProperty: slotProperty, ids: ids}})
            .then(res => {
                if (res.data.updateSlot.ok) {
                    this.setState({
                        hasError: false,
                        errorMessage: "",
                        saved: true
                    }, () => onComplete(getText('The changes are saved successfully!')));
                }
            })
            .catch(err => {
                let error_msg = err.message.replace("GraphQL error: ", '');
                this.setState({hasError: true, saved: false, errorMessage: error_msg});
                throw(error_msg);
            })
    }


    getDisplayStyle(property) {
        const {slotProperty, children} = this.props.data;
        const hasChildren = children.length > 0;
        return hasChildren ? hasChildren && slotProperty === property ? "block" : "none" : "block";
    }


    render() {
        const {data} = this.props;

        const {address: {addressFull, postCode, city}} = data.slot;
        const {disabled, print, slot} = data;

        const initValues = {
            area: slot.area || null,
            zone: slot.zone || null,
            aisle: slot.aisle || null,
            side: slot.side || null,
            col: slot.col || null,
            level: slot.level || null,
            position: slot.position || null,
            printEntry: data.print === 1,
            disabled: data.disabled === 1,
        };


        // const disabledIndeterminate = disabled === 2;
        const printEntryIndeterminate = print === 2;
        const hasChildren = data.children.length > 0;
        const {loading, error, success} = this.props.mutation;

        return (
            <Form id="edit-slot"
                  onSubmit={this.updateSlot}
                  initialValues={initValues}
                  indicators={{
                      loading: loading,
                      error: (error && error.message) ? error.message : '',
                      success: success
                  }}
                  render={() => (
                      <div className="w-full">
                          {/*Show overlay if its disabled*/}
                          <Overlay disabled={disabled === 1} description={getText("This slot is disabled.")}/>

                          <div
                              className="font-medium bg-white text-lg mb-4 border-b border-t border-gray-200 pt-2 pb-2 pl-2 ">
                              <i className="fal fa-map-marker-check mr-2"/>{addressFull}, {postCode} {city}
                          </div>

                          <div className="p-2">

                              <div style={{display: this.getDisplayStyle("area")}}>
                                  <TextInput name="area"
                                             label={getText("Area")}
                                             placeholder={getText("Area")}
                                             disabled={loading}
                                  />
                              </div>

                              <div style={{display: this.getDisplayStyle("zone")}}>
                                  <TextInput name="zone"
                                             label={`${getText("Zone")}*`}
                                             placeholder={getText('Zone')}
                                             disabled={loading}
                                             validators={[FormValidators.required, FormValidators.maxLength5]}
                                  />
                              </div>

                              <div style={{display: this.getDisplayStyle("aisle")}}>
                                  <TextInput name="aisle"
                                             label={getText("Aisle")}
                                             placeholder={getText("Aisle")}
                                             disabled={loading}
                                  />
                              </div>

                              <div style={{display: this.getDisplayStyle("side")}}>
                                  <Select name="side"
                                          label={getText("Side")}
                                          placeholder={getText("Side")}
                                          disabled={loading}
                                          options={[
                                              {
                                                  value: "",
                                                  label: getText("Not applicable")
                                              },
                                              {
                                                  value: "N",
                                                  label: getText('North')
                                              },
                                              {
                                                  value: "E",
                                                  label: getText("East")
                                              },
                                              {
                                                  value: "W",
                                                  label: getText('West')
                                              },
                                              {
                                                  value: "S",
                                                  label: getText("South")
                                              },
                                          ]}
                                  />
                              </div>

                              <div style={{display: this.getDisplayStyle("col")}}>
                                  <TextInput name="col"
                                             label={getText("Column")}
                                             placeholder={getText("Column")}
                                             disabled={loading}
                                  />
                              </div>

                              <div style={{display: this.getDisplayStyle("level")}}>
                                  <TextInput name="level"
                                             label={getText("Level")}
                                             placeholder={getText("Level")}
                                             disabled={loading}
                                  />
                              </div>

                              <div style={{display: this.getDisplayStyle("position")}}>
                                  <TextInput name="position"
                                             label={getText("Position")}
                                             placeholder={getText("Position")}
                                             disabled={loading}
                                  />
                              </div>

                              <div className="bg-blue-100 p-2 my-4 pl-4 -mx-2"
                                   style={{display: hasChildren ? "block" : "none"}}>
                                  {getText("Edit common fields")}
                              </div>

                              <div className="flex flex-col">
                                  <CheckBox name="printEntry"
                                            label={getText("Print at entry")}
                                            disabled={loading}
                                            isIndeterminate={printEntryIndeterminate}
                                  />

                              </div>
                          </div>

                      </div>
                  )}
            />
        )
    }
}

export default withMutation({
    mutation: UPDATE_SLOT,
})(EditSlot);

