import React from 'react';
import {
    Drawer,
    withMutation,
    MutationButton,
} from "../../../general";
import {
    Form,
    TextInput,
    Select,
    Switch,
    SelectionList,
    FormValidators,
    FormActionButton
} from '../../../general/forms-final-form'
import gql from "graphql-tag";
import UUID from "uuid";
import {keyBy, map} from 'lodash';
import {Animated} from "react-animated-css";
import {compose} from "recompose";
import {withSession} from "../../../project/session";
import {getText} from "../../../translations";

const animationIn = "fadeIn";
const animationOut = "fadeOut";
const animationDuration = 600; // in ms

const formId = UUID.v4();


const UPDATE_COMPANY_USER = gql`
  mutation UpdateCompanyUserProfile($input: CompanyUserProfileInput!) {
    updateCompanyUserProfile(input: $input) {

        ok    
        companyUser {
            id
             state  
             company {
                id
             }   
             userProfile {
              id,
              title,
              telephone,
              mobile,
              smsActive,
              cultureMajor
              offerTax,
              user {         
                id,
                username
                lastName,
                firstName,
                email,  
                lastLogin
                groups {
                  id,
                  name
                }
              }
            }      
          }
         
        
    }
  }
`;

const CHANGE_USER_STATE = gql`
  mutation ChangeUserState($input: ChangeUserStateInput!) {
    changeUserState(input: $input) {
       ok
      companyUser {
        id
        state
      }
    }
  }
`;


class EditUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {defaultFormValues: this.getDefaultValues(props)};

        this.updateCompanyUser = this.updateCompanyUser.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.drawerParams.companyUser.id !== nextProps.drawerParams.companyUser.id) {
            this.setState({defaultFormValues: this.getDefaultValues(nextProps)});
        }
    }

    getDefaultValues(props) {
        const {drawerParams: {companyUser, groups}} = props;
        const allGroups = keyBy(map(groups, (group) => ({
            name: group.name,
            id: group.id,
            checked: companyUser.userProfile.user.groups.some(ug => ug.id === group.id)
        })), 'id');

        return {...companyUser, groups: allGroups};
    }

    updateCompanyUser(data) {
        const {mutation, session, onClose} = this.props;
        mutation.mutate({
            variables: {
                input: {
                    userProfileId: parseInt(data.userProfile.id),
                    title: data.userProfile.title,
                    telephone: data.userProfile.telephone,
                    mobile: data.userProfile.mobile,
                    smsActive: data.userProfile.smsActive,
                    offerTax: data.userProfile.offerTax,
                    cultureMajor: data.userProfile.cultureMajor,
                    user: {
                        id: parseInt(data.userProfile.user.id),
                        username: data.userProfile.user.username,
                        firstName: data.userProfile.user.firstName,
                        lastName: data.userProfile.user.lastName,
                        email: data.userProfile.user.email,
                    },
                    company: {
                        id: session.companyId,
                    },
                    groups: Object.keys(data.groups).map(key => data.groups[key]).filter(group => group.checked)
                }
            }
        }, (result) => {
            if (result.success) {
                onClose()
                // this.setState({isSaving: false, hasError: false});
                // onComplete()
            } else {
                this.setState({isSaving: false, hasError: true});
            }
        })
    }


    render() {
        const {defaultFormValues} = this.state;
        const {mutation: {loading, error, success}, drawerParams: {companyUser}, onClose} = this.props;

        let company_user_state = <div className="text-green-600 font-medium">{companyUser.state.toLowerCase()}</div>;
        if (companyUser.state.includes("LOCKED_")) company_user_state =
            <div className="text-orange-600 font-medium">{getText('Locked')}</div>;
        if (companyUser.state === "INITIAL") company_user_state =
            <div className="text-gray-600 font-medium">{companyUser.state.toLowerCase()}</div>;

        const title = () => (
            <div className="flex flex-grow justify-between items-center">

                <div className="flex flex-row">
                    <FormActionButton formId={formId}>{getText("Save")}</FormActionButton>
                </div>

                <div className="items-center">
                    <div className="items-center">
                        {/*{this.renderStatesButtons(companyUser.state)}*/}


                        {companyUser.state === "INITIAL" &&
                        <React.Fragment>
                            <MutationButton query={CHANGE_USER_STATE}
                                            variables={{
                                                companyUserId: companyUser.id,
                                                newState: "LOCKED_INITIAL"
                                            }}
                                            onClick={onClose}
                            >
                                {getText('LOCK')}
                            </MutationButton>
                            <MutationButton query={CHANGE_USER_STATE}
                                            variables={{
                                                companyUserId: companyUser.id,
                                                newState: "APPROVED"
                                            }}
                                            onClick={onClose}
                            >
                                {getText('APPROVE')}
                            </MutationButton>
                        </React.Fragment>
                        }

                        {companyUser.state !== "INITIAL" &&
                        <MutationButton query={CHANGE_USER_STATE}
                                        variables={{
                                            companyUserId: companyUser.id,
                                            newState: (companyUser.state.includes('LOCKED_') ? companyUser.state.replace('LOCKED_', '') : `LOCKED_${companyUser.state}`)
                                        }}
                                        onClick={onClose}
                        >
                            {(companyUser.state.includes('LOCKED_')) ? "UNLOCK" : "LOCK"}
                        </MutationButton>}


                    </div>
                </div>
            </div>
        );


        return (

            <Drawer title={title}
                    onClose={onClose}
                    visible={true}
            >
                <Animated key={companyUser.id} animationIn={animationIn} animationOut={animationOut}
                          animationInDuration={animationDuration} animationOutDuration={animationDuration}
                          isVisible={true}>
                    <Form id={formId}
                          onSubmit={this.updateCompanyUser}
                          initialValues={defaultFormValues}
                          className="w-full"
                          indicators={{
                              loading: loading,
                              error: (error && error.message) ? error.message : '',
                              success: success
                          }}
                          render={({values}) => (
                              <div className="p-2">
                                  <div className="flex flex-row">
                                      <TextInput name="userProfile.user.username"
                                                 label={getText('Username')}
                                                 placeholder={getText("Username")}
                                                 disabled={loading}
                                                 validators={[FormValidators.required]}
                                                 className="w-4/5"
                                      />
                                      <div
                                          className="flex items-center justify-end p-2 border-gray-200 border-solid rounded-sm mb-1 bg-white capitalize"
                                          style={{width: 135}}>
                                          {company_user_state}
                                      </div>
                                  </div>

                                  <div className="flex flex-row flex-1 justify-between">
                                      <div className="w-full mr-4">
                                          <Select name="userProfile.cultureMajor"
                                                  label={getText('Language')}
                                                  placeholder={getText('Select language')}
                                                  disabled={loading}
                                                  style={{marginBottom: 0}}
                                                  options={[
                                                      {
                                                          label: getText('Dutch'),
                                                          value: "nl"
                                                      },
                                                      {
                                                          label: getText('English'),
                                                          value: "en"
                                                      },
                                                      {
                                                          label: getText('French'),
                                                          value: "fr"
                                                      },
                                                  ]}
                                          />
                                      </div>

                                      <div className="w-full">
                                          <Select name="userProfile.title"
                                                  label={getText('Title')}
                                                  placeholder={getText('Select title')}
                                                  disabled={loading}
                                                  validators={[FormValidators.required]}
                                                  options={[
                                                      {
                                                          label: getText('Mr'),
                                                          value: "Mr"
                                                      },
                                                      {
                                                          label: getText('Mrs'),
                                                          value: "Mrs"
                                                      },
                                                  ]}
                                          />
                                      </div>


                                  </div>

                                  <div className="flex flex-row justify-between">

                                      <div className="w-full mr-4">
                                          <TextInput name="userProfile.user.firstName"
                                                     label={getText('First name')}
                                                     placeholder={getText('First name')}
                                                     disabled={loading}
                                          />
                                      </div>

                                      <div className="w-full">
                                          <TextInput name="userProfile.user.lastName"
                                                     label={getText('Last name')}
                                                     placeholder={getText('Last name')}
                                                     disabled={loading}
                                          />
                                      </div>

                                  </div>

                                  <TextInput name="userProfile.user.email"
                                             label={getText('Email')}
                                             placeholder={getText('Email')}
                                             disabled={loading}
                                             validators={[FormValidators.required, FormValidators.email]}
                                  />


                                  <div className="flex flex-row justify-between">

                                      <div className="w-full mr-4">
                                          <TextInput name="userProfile.mobile"
                                                     label={getText('Mobile')}
                                                     disabled={loading}
                                                     placeholder={getText('Mobile')}
                                                     validators={[FormValidators.required]}
                                          />
                                      </div>

                                      <div className="w-full">
                                          <TextInput name="userProfile.telephone"
                                                     label={getText('Telephone')}
                                                     disabled={loading}
                                                     placeholder={getText('Telephone')}
                                                     validators={[FormValidators.required]}
                                          />
                                      </div>

                                  </div>

                                  <div className="flex flex-row justify-between">
                                      <div className="w-full mr-4">
                                          <Switch name="userProfile.offerTax"
                                                  label={getText('Tax included')}
                                                  placeholder={getText('Tax included')}
                                                  disabled={loading}

                                          />
                                      </div>

                                      <div className="w-full">
                                          <Switch name="userProfile.smsActive"
                                                  label={getText('SMS notifications')}
                                                  placeholder={getText('SMS notifications')}
                                                  disabled={loading}
                                          />
                                      </div>


                                  </div>

                                  <SelectionList
                                      name="groups"
                                      label={getText('Roles')}
                                      validators={[FormValidators.minOneSelected(values.groups)]}
                                  />
                              </div>

                          )}
                    />
                </Animated>

            </Drawer>

        )
    }
}


export default compose(
    withSession,
    withMutation({
        mutation: UPDATE_COMPANY_USER,
        successMessage: getText('Changes were made successfully')
    })
)(EditUser)


