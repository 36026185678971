import React, {useState} from 'react';
import * as PropTypes from "prop-types";

const TextArea = ({name, value, required, placeholder, disabled, onChange, className, showBorder, hasError, rows}) => {
    const [hasFocus, setHasFocus] = useState(false);

    const borderClass = (showBorder) ? `border ${(hasError) ? "border-red-500" : ((hasFocus) ? "border-blue-500" : (required && !value) ? "border-blue-200" : "border-gray-200")} border-t-0 border-l-0 border-r-0` : '';


    return (
        <textarea name={name}
                  onFocus={() => setHasFocus(true)}
                  onBlur={() => setHasFocus(false)}
                  value={value}
                  placeholder={placeholder}
                  disabled={disabled}
                  onChange={onChange}
                  rows={rows}
                  className={`w-full font-normal text-md pt-1 pb-1 text-gray-900 bg-white ${borderClass} ${className}`}
        />
    )
};

TextArea.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string.isRequired,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    showBorder: PropTypes.bool,
    hasError: PropTypes.bool,
};

TextArea.defaultProps = {
    name: 'TextArea',
    value: "",
    required: false,
    placeholder: "",
    disabled: false,
    inputRef: null,
    onChange: null,
    className: "",
    showBorder: true,
    hasError: false,
    rows: 2
};

export default TextArea;


