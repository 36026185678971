import React from 'react';
import {Drawer, withQuery} from "../../../general";

import {MASTER_MAIN_CONFIG} from "./queries";


const MainConfig = ({onClose, data: {}}) => {

    return (
        <Drawer size="lg"
                title={"Stuff"}
                onClose={onClose}
                visible={true}
                contentStyle={{margin: 0, padding: 0}}>


            <div className="flex flex-col w-full h-full">
                <div>Content my friend</div>
            </div>
        </Drawer>
    )
};

export default withQuery({query: MASTER_MAIN_CONFIG})(MainConfig);

