import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import UUID from 'uuid';
import ClipLoader from "react-spinners/ClipLoader";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {pictureSetUrl} from "../../../components/pictures/data";


export const UPLOADED_FILE = gql`
 query UploadedFile($id: ID!) {
        uploadedFile(id: $id) {              
            uploadIdentification          
            fullUrl
            thumbnailUrl
        }
   }
`;


const Image = ({onDrag, onDragEnd, onRemove, onDataFetched, picture: {guid, fileId, url, selected}, showDeleteButton, className, onDelete, onClick, height, width, selectAllowed}) => {
    const [showActions, setShowActions] = useState(false);
    const {loading: pictureDataLoading, error: pictureDataError} = useQuery(UPLOADED_FILE, {
        skip: fileId === null || url !== null,
        fetchPolicy: 'network-only',
        variables: {
            id: fileId,
        },
        onCompleted: (data) => {
            if (data && data.uploadedFile) {

                if(onDataFetched) {
                    onDataFetched(data.uploadedFile)
                }
            }
        }
    });

    const picture_url = url;
    const loading = picture_url === null || pictureDataLoading;

    return (
        <div draggable={onDrag && picture_url !== null}
             onDrag={onDrag}
             onDragEnd={onDragEnd}
             className={`flex flex-row items-center relative ${className} `}
             style={{
                 height: height,
                 minWidth: width,
                 maxWidth: width,
                 cursor: (picture_url !== null) ? ((selected) ? 'grab' : (selectAllowed) ? 'pointer' : 'default') : 'wait'
             }}
             key={guid}
             onClick={(e) => {
                 if (onClick && selectAllowed && picture_url !== null) {
                     onClick(e, guid);
                 }
             }}
             onMouseEnter={() => setShowActions(true)}
             onMouseLeave={() => setShowActions(false)}

        >

            <div style={{position: 'absolute', cursor: 'none', left: "43%"}}>
                <ClipLoader
                    sizeUnit={"px"}
                    size={25}
                    color={'black'}
                    loading={loading}
                />
            </div>

            <img src={(picture_url !== null) ? picture_url : require('../../../components/damages/nopic.jpeg')}
                 style={{
                     cursor: (picture_url !== null) ? ((selected) ? 'grab' : (selectAllowed) ? 'pointer' : 'default') : 'wait',
                     height: height,
                     width: width
                 }}
                 draggable={(onDrag !== undefined) && picture_url !== null}
                 alt={UUID.v4()}/>


            {picture_url !== null && !selected && showActions &&
            <div
                className="remove-image text-xs cursor-pointer hover:text-gray-400 text-white absolute px-2 py-1 rounded-full z-50"
                style={{background: "rgba(0, 0, 0, 0.5)", right: 5, top: 5}}
                onClick={e => {
                    e.stopPropagation();
                    onRemove(guid)
                }}>
                <i className="fal fa-times"/>
            </div>}

            {picture_url !== null && showDeleteButton && !selected && showActions &&
            <div
                className="spin-animation-hover text-xs cursor-pointer hover:text-gray-400 text-white absolute px-2 py-1 rounded-full z-50"
                style={{background: "rgba(0, 0, 0, 0.5)", right: 35, top: 5}}
                onClick={e => {
                    e.stopPropagation();
                    onDelete(guid)
                }}>
                <i className="fal fa-trash"/>
            </div>}

            {picture_url !== null && !selected && selectAllowed &&
            <div className="flex absolute items-center justify-center rounded-full z-50" style={{
                width: 18,
                height: 18,
                bottom: 5,
                right: 5,
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: 'white'
            }}>

            </div>}

            {selected && <div className="bg-blue-500 flex absolute items-center justify-center rounded-full z-50"
                              style={{
                                  width: 18,
                                  height: 18,
                                  bottom: 5,
                                  right: 5,
                                  borderStyle: 'solid',
                                  borderWidth: 1,
                                  borderColor: 'white'
                              }}>
                <span className="text-white fa fa-check" style={{fontSize: 10}}/>
            </div>}

        </div>
    )
};


Image.propTypes = {
    onRemove: PropTypes.func.isRequired,
};

Image.defaultProps = {
    className: "",
    showDeleteButton: false,
    height: 80,
    width: 106,
    selectAllowed: true,
};

export default Image;