import React from 'react';
import {map, range, slice} from "lodash";
import {useTranslate} from "../../../../../../translations";

const General = ({body, gearbox, seats, doors, engine, engineCc, emissionNorm, co2, wheelFormula, fuel, powerKw, powerHp, powerFiscal, catalogPrice, catalogPriceWithOptions, commentPositive, commentNegative, commentInternal, colorInterior, colorExterior, pictures, onPictureClick}) => {
    const {getText} = useTranslate();
    const defaultClass = "bg-gray-100 cursor-default";
    const emptyVehiclePictures = (pictures.length < 8) ? (8 - pictures.length) : 0;

    return (
        <div className="flex flex-col w-full">
            <div className="flex items-center pl-2 text-white bg-blue-500 text-md h-8 py-2 mb-2">
                <span>
                    {getText("Vehicle")}
                </span>
            </div>
            <div>
                {body} - {gearbox} - {`${doors} doors`} - {`${seats} seats`} - {engine} - {`${engineCc} cc`} <br />
                {emissionNorm} - {`${co2} co2`} - {wheelFormula} - {fuel} - {`${powerHp} HP`} - {`${powerKw} kw`} - {`${powerFiscal} fiscal`} <br />
                {`${catalogPrice} catalog`} - {`${catalogPriceWithOptions} catalog + options`}
            </div>
            <div className="flex flex-1 flex-col mb-2">
                <div className="block clearfix mb-2">
                    <div className="float-left w-5/7 pr-1">
                        <div className="w-1/2 float-left pr-1">
                            <div className={`p-2 ${defaultClass}`}>
                                    <span className="text-gray-600 flex flex-col justify-center text-xs w-1/3">{
                                        getText("Body")}:
                                    </span>
                                <span className="text-gray-900 flex flex-col justify-center text-sm font-medium">
                                    {body}
                                </span>
                            </div>
                        </div>

                        <div className="w-1/2 float-left pl-1">
                            <div className={`p-2 ${defaultClass}`}>
                                <span className="text-gray-600 flex flex-col justify-center text-xs w-1/3">
                                    {getText("Wheel formula")}:
                                </span>
                                <span className="text-gray-900 flex flex-col justify-center text-sm font-medium">
                                    {wheelFormula}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="float-left w-2/7 pl-1">
                        <div className="w-1/2 float-left pr-1">
                            <div className={`p-2 ${defaultClass}`}>
                                <span className="text-gray-600 flex flex-col justify-center text-xs w-1/3">
                                    {getText("Seats")}:
                                </span>
                                <span className="text-gray-900 flex flex-col justify-center text-sm font-medium">
                                    {seats}
                                </span>
                            </div>
                        </div>
                        <div className="w-1/2 float-left pl-1">
                            <div className={`p-2 ${defaultClass}`}>
                            <span className="text-gray-600 flex flex-col justify-center text-xs w-1/3">
                                {getText("Doors")}:
                            </span>
                                <span className="text-gray-900 flex flex-col justify-center text-sm font-medium">
                                {doors}
                            </span>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div className="block clearfix mb-2">*/}
                {/*    <div className="float-left w-1/2 pr-1">*/}
                {/*        <div className="float-left w-1/3 pr-1">*/}
                {/*            <div className={`p-2 ${defaultClass}`}>*/}
                {/*                <span className="text-gray-600 flex flex-col justify-center  text-xs w-1/3">*/}
                {/*                    {getText("Engine")}:*/}
                {/*                </span>*/}
                {/*                <span className="text-gray-900 flex flex-col justify-center text-sm font-medium">*/}
                {/*                    {engine}*/}
                {/*                </span>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="float-left w-1/3 pl-1 pr-1">*/}
                {/*            <div className={`p-2 ${defaultClass}`}>*/}
                {/*                <span className="text-gray-600 flex flex-col justify-center  text-xs w-1/3">*/}
                {/*                    {getText("EmissionNorm")}:*/}
                {/*                </span>*/}
                {/*                <span className="text-gray-900 flex flex-col justify-center text-sm font-medium">*/}
                {/*                    {emissionNorm}*/}
                {/*                </span>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="float-left w-1/3 pl-1 pr-1">*/}
                {/*            <div className={`p-2 ${defaultClass}`}>*/}
                {/*            <span className="text-gray-600 flex flex-col justify-center  text-xs w-1/3">*/}
                {/*                {getText("Co2")}:*/}
                {/*            </span>*/}
                {/*                <span className="text-gray-900 flex flex-col justify-center text-sm font-medium">*/}
                {/*                {co2}*/}
                {/*            </span>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="float-left w-1/2 pl-1">*/}
                {/*        <div className="float-left w-1/3 pr-1">*/}
                {/*            <div className={`p-2 ${defaultClass}`}>*/}
                {/*                <span className="text-gray-600 flex flex-col justify-center  text-xs w-1/3">*/}
                {/*                    {getText("Hp")}:*/}
                {/*                </span>*/}
                {/*                <span className="text-gray-900 flex flex-col justify-center text-sm font-medium">*/}
                {/*                    {powerHp}*/}
                {/*                </span>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="float-left w-1/3 pl-1 pr-1">*/}
                {/*            <div className={`p-2 ${defaultClass}`}>*/}
                {/*                <span className="text-gray-600 flex flex-col justify-center  text-xs w-1/3">*/}
                {/*                    {getText("Kw")}:*/}
                {/*                </span>*/}
                {/*                <span className="text-gray-900 flex flex-col justify-center text-sm font-medium">*/}
                {/*                    {powerKw}*/}
                {/*                </span>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="float-left w-1/3 pl-1 pr-1">*/}
                {/*            <div className={`p-2 ${defaultClass}`}>*/}
                {/*            <span className="text-gray-600 flex flex-col justify-center  text-xs w-1/3">*/}
                {/*                {getText("Fiscal Hp")}:*/}
                {/*            </span>*/}
                {/*                <span className="text-gray-900 flex flex-col justify-center text-sm font-medium">*/}
                {/*                {powerFiscal}*/}
                {/*            </span>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="block clearfix mb-2">*/}
                {/*    <div className="float-left w-1/2 pr-1">*/}
                {/*        <div className={`p-2 ${defaultClass}`}>*/}
                {/*            <span className="text-gray-600 flex flex-col justify-center text-xs w-1/3">*/}
                {/*                {getText("EmissionNorm")}:*/}
                {/*            </span>*/}
                {/*            <span className="text-gray-900 flex flex-col justify-center text-sm font-medium">*/}
                {/*                {emissionNorm}*/}
                {/*            </span>*/}
                {/*        </div>*/}
                {/*        <div className={`p-2 ${defaultClass}`}>*/}
                {/*            <span className="text-gray-600 flex flex-col justify-center text-xs w-1/3">*/}
                {/*                {getText("Co2")}:*/}
                {/*            </span>*/}
                {/*            <span className="text-gray-900 flex flex-col justify-center text-sm font-medium">*/}
                {/*                {co2}*/}
                {/*            </span>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="float-left w-1/2 pl-1">*/}
                {/*        <div className={`p-2 ${defaultClass}`}>*/}
                {/*            <span className="text-gray-600 flex flex-col justify-center text-xs w-1/3">*/}
                {/*                {getText("Fuel")}:*/}
                {/*            </span>*/}
                {/*            <span className="text-gray-900 text-sm font-medium">*/}
                {/*                {fuel}*/}
                {/*            </span>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="flex flex-1 mb-2">
                    <div className={`flex flex-1 p-2 mr-1 ${defaultClass}`}>
                        <span className="text-gray-600 flex flex-col justify-center text-xs w-1/3">
                            {getText("Seats")}:
                        </span>
                        <span className="text-gray-900 flex flex-col justify-center text-sm font-medium">
                            {seats}
                        </span>
                    </div>
                    <div
                        className={`flex flex-1 p-2 ml-1 ${defaultClass}`}>
                        <div className="text-gray-600 flex flex-col justify-center text-xs w-1/3">
                            {getText("Color")}:
                        </div>
                        <div className="flex flex-row text-gray-900 text-sm font-medium">
                            {colorInterior &&
                            <div
                                className="flex flex-row items-center mr-2">
                                {getText("In")}:
                                <div className="rounded-full ml-2" style={{
                                    width: 13,
                                    height: 13,
                                    backgroundColor: colorInterior
                                }}/>
                            </div>}

                            {colorExterior &&
                            <div className="flex flex-row items-center">
                                {getText("Out")}:
                                <div
                                    className="rounded-full ml-2" style={{
                                    width: 13,
                                    height: 13,
                                    backgroundColor: colorExterior
                                }}/>
                            </div>}
                        </div>
                    </div>
                </div>

                <div
                    className={`flex flex-col w-full h-12 pr-1 p-1 mb-2 ${defaultClass}`}>
                    <div className="flex text-green-400 mb-2">
                        <i className="fas fa-plus-circle mr-1"/>
                        <i className="fas fa-plus-circle mr-1"/>
                        <i className="fas fa-plus-circle"/>
                    </div>
                    <div
                        className="text-sm font-medium text-gray-900">{commentPositive}</div>
                </div>

                <div
                    className={`flex flex-col w-full h-12 pr-1 p-1 mb-2 ${defaultClass}`}>
                    <div className="text-red-400 mb-2">
                        <i className="fas fa-minus-circle mr-1"/>
                        <i className="fas fa-minus-circle mr-1"/>
                        <i className="fas fa-minus-circle"/>
                    </div>
                    <div
                        className="text-gray-900 text-sm font-medium">{commentNegative}</div>
                </div>

                <div
                    className={`flex flex-col w-full h-12 pr-1 p-1 mb-2 ${defaultClass}`}>
                    <div className="text-gray-600 mb-2">
                        <span>{getText("Comment (internal)")}</span>
                    </div>
                    <div
                        className="text-gray-900 text-sm font-medium">{commentInternal}</div>
                </div>


                <div className={`flex flex-row w-full  ${defaultClass}`}>
                    {map(slice(pictures, 0, 7), (pic, index) => {
                        const overlay = (pictures.length > 7 && index === 6);

                        return (
                            <React.Fragment key={pic.id}>
                                <div className={`${(overlay) ? 'relative' : ''}`}>
                                    <img src={pic.url}
                                         style={{
                                             width: 116,
                                             height: 88,
                                             marginRight: 2,
                                             marginBottom: 1
                                         }} alt={pic.id}
                                         className="cursor-pointer"
                                         onClick={(e) => {
                                             e.stopPropagation();
                                             onPictureClick(pic.id);
                                         }}>

                                    </img>


                                    {overlay &&
                                    <div
                                        className="absolute top-0 right-0 flex items-center justify-center"
                                        style={{
                                            width: 116,
                                            height: 88,
                                            marginRight: 2,
                                            marginBottom: 1,
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)'
                                        }}>
                                        <span className="text-white text-3xl">
                                            + {pictures.length - 7}
                                        </span>
                                    </div>}
                                </div>

                            </React.Fragment>
                        )
                    })}

                    {map(range(emptyVehiclePictures), (index) => {
                        return (
                            <React.Fragment key={`dummy_picture_${index}`}>
                                <div>
                                    <div
                                        className="flex items-center justify-center border border-gray-200"
                                        style={{
                                            width: 116,
                                            height: 88,
                                            marginRight: 2,
                                            marginBottom: 1
                                        }}>
                                        <i className="fal fa-image text-5xl text-gray-200"/>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    })}


                </div>
            </div>
        </div>

    )
};

General.defaultProps = {
    vehiclePictures: []
};

export default General;