import React from 'react';
import {Animated} from "react-animated-css";

const WithAnimation = ({children, className, animationIn, animationOut, animationDuration, isVisible, style}) => {
    return (
        <Animated animationIn={animationIn} animationOut={animationOut} animationInDuration={animationDuration} animationOutDuration={animationDuration} isVisible={isVisible} className={className} style={style}>
            {children}
        </Animated>
    )
};

WithAnimation.defaultProps = {
    className: "",
    animationIn: "fadeIn",
    animationOut: "fadeOut",
    animationDuration: 600,
    isVisible: true,
    style: {}
};

export default WithAnimation;