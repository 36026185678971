import {map, find, range} from "lodash";
import React, {useEffect, useState} from "react";
import {head, filter} from 'lodash';
import DragAndDrop from "../../inspection/components/common/DragAndDrop";
import Image from "../../inspection/components/common/Image";

import WithAnimation from "../../inspection/components/common/WithAnimation";
import {useTranslate} from "../../../../translations";
import gql from "graphql-tag";
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import LoadingIndicator from "../../../../general/indicators/LoadingIndicator";
import Logistics from "../../lead/components/Logistics";



export const DAMAGE_TYPES_BY_LOCATION = gql`
 query DamageTypesByLocation($damageLocationId: ID!) {
        damageTypes(damageLocationId: $damageLocationId) {  
            id
            description
              severities {
                  id
                  description
                }
        }
   }
`;


let fileSelector;
const Detail = ({damage, pictures, onDropMorePictures, onDropMorePicturesByClick, onDeletePicture, onPictureClick, onClose, onDelete, onChangeType, onChangeSeverity, onChangeComment, onImageDataFetched }) => {
        const [step, setStep] = useState((damage.typeId > 0 && damage.severityId > 0) ? 3 : 1);
        const [typeEditVisible, setTypeEditVisible] = useState(false);
        const [severityEditVisible, setSeverityEditVisible] = useState(false);
        const [comment, setComment] = useState(damage.comment);
        const {getText} = useTranslate();

        const complete = damage.typeId > 0 && damage.severityId > 0;



        const {data: damageTypesQueryData, loading: damageTypesQueryLoading, error: damageTypesQueryError} = useQuery(DAMAGE_TYPES_BY_LOCATION, {
            fetchPolicy: 'network-only',
            variables: {damageLocationId: damage.locationId}
        });


        useEffect(() => {
            if (damage.comment !== comment) {
                setComment(damage.comment);
            }

            if (damage.typeId > 0 && damage.severityId > 0) {
                setStep(3);
            } else if (damage.typeId > 0) {
                setStep(2);
            } else {
                setStep(1);
            }
        }, [damage, comment]);

        useEffect(() => {
            fileSelector = document.createElement('input');
            fileSelector.setAttribute('type', 'file');
            fileSelector.setAttribute('multiple', 'multiple');

            fileSelector.addEventListener('change', (e) => {
                onDropMorePictures(damage, e.target.files);
            });
        });

        if (damageTypesQueryLoading) {
            return <LoadingIndicator />
        }

        const severities = damage.typeId > 0 && find(damageTypesQueryData.damageTypes, (dt) => dt.id === damage.typeId).severities;

        return (
            <div className="flex flex-col flex-1 h-full w-full border border-gray-200 relative">
                <div className="flex flex-row justify-between items-center bg-gray-200 font-medium mb-4">
                    <div className={`flex items-center relative pl-3 ${complete ? "bg-blue-500" : "bg-blue-200"}`}
                         style={{width: 168, height: 35}}>
                <span className="arrow-right absolute"
                      style={{right: -20, borderLeft: complete ? "20px solid #3e97c7" : "20px solid #bee3f8"}}/>
                        <span style={{fontSize: 14}}
                              className={`${complete ? "text-white" : "text-gray-900"}`}>{damage.locationText}</span>
                    </div>
                    <div className="flex items-center flex-1 ml-8 mr-2 font-light text-gray-900">
                        {!complete && step === 1 && pictures.length === 0 && getText("Please drop pictures and select damage type")}
                        {!complete && step === 1 && pictures.length > 0 && getText("Please select damage type")}
                        {!complete && step === 2 && getText("Please select severity")}
                        {complete && getText("Complete, you can drag more pictures or edit the details")}
                    </div>
                    <div className="flex flex-row items-center pr-1">
                        <i onClick={onDelete}
                           className="fad fa-trash-alt text-red-500 hover:text-red-800 mr-3 cursor-pointer"
                           style={{fontSize: 16}}/>
                        <i onClick={onClose} className="fal fa-times cursor-pointer text-gray-600 hover:text-gray-900"
                           style={{fontSize: 22}}/>
                    </div>
                </div>


                <div className="flex flex-col flex-1 p-1 overflow-y-scroll pb-2" style={{height: 80}}>
                    <div className="flex flex-row">

                        <DragAndDrop highlightOnHover={true}
                                     handleDrop={(files) => onDropMorePictures(damage, files)}
                                     onClick={() => onDropMorePicturesByClick(damage)}
                                     className="mr-1 w-full"
                                     borderClasses="">

                            <div className="flex w-2/7 justify-center flex-col p-2 bg-gray-100 relative"
                                 style={{width: 180, height: 80}}>

                                <div
                                    className={`flex justify-between mb-3 text-gray-900 border-b border-gray-300 pb-1 ${damage.typeId > 0 ? "hover:border-blue-500 cursor-pointer" : ""}`}
                                    style={{minHeight: 20}}
                                    onClick={() => setStep(1)}
                                    onMouseEnter={() => setTypeEditVisible(true)}
                                    onMouseLeave={() => setTypeEditVisible(false)}>
                                    <span className="font-medium"> {damage.typeText} </span>
                                    {(damage.typeId > 0 && typeEditVisible) ?
                                        <span className="text-blue-500">{getText("edit")}</span> : ''}
                                </div>

                                <div
                                    className={`flex justify-between text-gray-900 border-b border-gray-300 pb-1 ${damage.severityId > 0 ? "hover:border-blue-500 cursor-pointer" : ""}`}
                                    style={{minHeight: 20}}
                                    onClick={() => setStep(2)}
                                    onMouseEnter={() => setSeverityEditVisible(true)}
                                    onMouseLeave={() => setSeverityEditVisible(false)}
                                >
                                    <span className="font-medium">{damage.severityText}</span>
                                    {(damage.severityId > 0 && severityEditVisible) ?
                                        <span className="text-blue-500">{getText("edit")}</span> : ""}
                                </div>

                            </div>
                            <div className="flex flex-row flex-1 w-5/7">

                                <div className="flex flex-row w-full flex-row flex-no-wrap overflow-x-scroll">
                                    {map([...pictures], (pic, i) => {
                                        return (
                                            <div key={i} className="ml-1" style={{flex: "0 0 auto"}}>
                                                <Image picture={pic}
                                                    // onDrag={(e) => onDragPicture(e, pic)}
                                                    // onDragEnd={onDragPictureEnd}
                                                       onClick={(e, id) => onPictureClick(id)}
                                                       onBrowse={(e, id) => fileSelector.click()}
                                                       onRemove={(id) => onDeletePicture(damage.guid, id)}
                                                       height={80}
                                                       width={106}
                                                       selectAllowed={false}
                                                       onDataFetched={onImageDataFetched}
                                                />
                                            </div>
                                        )
                                    })}

                                    {pictures.length < 5 &&
                                    range((5 - pictures.length)).map(item => {
                                        return (
                                            <div key={item}
                                                 onClick={() => fileSelector.click()}
                                                 className="flex items-center justify-center border border-gray-200 ml-1 cursor-pointer"
                                                 style={{
                                                     height: 80,
                                                     width: 98,
                                                     maxWidth: 158,
                                                     flex: "0 0 auto"
                                                 }}>
                                                <i className="fal fa-image text-5xl text-gray-200"/>
                                            </div>
                                        )
                                    })
                                    }

                                </div>
                            </div>

                        </DragAndDrop>

                    </div>


                    <div className="flex flex-row mt-4">


                        {step === 1 && damageTypesQueryData &&// Damage types
                        <WithAnimation className="flex flex-col">
                            <div className="flex items-center bg-blue-200 font-medium text-gray-900 pl-2 mb-2"
                                 style={{height: 25}}>{getText("Please select damage type")}:
                            </div>

                            <div className="flex flex-1 flex-row flex-wrap">
                                {map(damageTypesQueryData.damageTypes, (dt, i) => {
                                    return <div
                                        id={`damage-type-${dt.id}`}
                                        key={`${dt.id}_${i}`}
                                        className={`${(dt.id === damage.typeId) ? 'bg-blue-400 text-white' : 'bg-white text-gray-900 hover:bg-blue-400 hover:text-white'} w-1/5 px-2 py-2 cursor-pointer`}
                                        onClick={() => {
                                            if (damage.typeId !== dt.id) {
                                                if (damage.severityId > 0) {
                                                    onChangeSeverity(damage.guid, null)
                                                }
                                                onChangeType(damage.guid, dt);
                                            }
                                            setStep(2);
                                        }}>

                                        {dt.description}
                                    </div>
                                })}
                            </div>
                        </WithAnimation>}

                        {step === 2 && // Severities
                        <WithAnimation>
                            <div className="flex items-center bg-blue-200 font-medium  text-gray-900 pl-2 mb-2"
                                 style={{height: 25}}>{getText("Please select severity")}:
                            </div>
                            <div className="flex flex-1 flex-row flex-wrap">
                                {map(find(damageTypesQueryData.damageTypes, (dt) => dt.id === damage.typeId).severities, (s, i) => {
                                    return <div
                                        key={`severity_${s.id}_${i}`}
                                        className={`${(s.id === damage.severityId) ? 'bg-blue-400 text-white' : 'bg-white text-gray-900 hover:bg-blue-400 hover:text-white'} w-1/5 px-2 py-2 cursor-pointer`}
                                        onClick={() => {
                                            setStep(3);
                                            onChangeSeverity(damage.guid, s);
                                        }}>
                                        {s.description}
                                    </div>
                                })}
                            </div>
                        </WithAnimation>}

                        {step === 3 && // Additional info
                        <WithAnimation className="flex flex-col mb-2 w-full">
                            <div className="flex items-center bg-blue-200 font-medium text-gray-900 pl-2 mb-2f"
                                 style={{height: 25}}>
                                {getText("Additional information")} <span
                                className="font-hairline text-3xs ml-2 mt-1">({getText("optional")})</span>
                            </div>

                            <textarea
                                className="p-2 border border-gray-200 text-sm w-full text-gray-900 font-light w-full"
                                rows="4"
                                onChange={(e) => setComment(e.target.value)}
                                style={{minHeight: 88}}
                                onBlur={(e) => onChangeComment(damage.guid, comment)}
                                value={comment}
                            />


                        </WithAnimation>}

                    </div>
                </div>

            </div>
        )
    }
;

export default Detail;