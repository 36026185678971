import React from 'react';
import * as Sentry from '@sentry/browser';
import Button from "../action/Button";
import {withNavigation} from "../../project/navigation";
import {useServiceWorker} from "../../useServiceWorker";

//https://docs.sentry.io/platforms/javascript/react/


const ErrorBoundaryUpdateApp = () => {
    const {isUpdateAvailable, updateAssets} = useServiceWorker();

    if(!isUpdateAvailable) return null;

    return (
        <div>
            <span> There is a new update available of or app, that can fix your issue. </span>
            <Button>
                <Button type="primary"
                        className="mt-4"
                        onClick={updateAssets}>
                   INSTALL UPDATE
                </Button>
            </Button>
        </div>
    )
}



class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {eventId: null, message: ''};
    }

    static getDerivedStateFromError(error) {
        return {hasError: true, message: error.message};
    }

    componentDidCatch(error, errorInfo) {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({eventId});
        });
    }

    render() {
        if (this.state.hasError) {
            const {message} = this.state;
            return (
                <div className="flex justify-center mt-4">
                    <div className="flex justify-center items-center flex-col">
                        <h1 className="text-3xl mb-2">Whoops ... </h1>
                        <h2 className="text-xl">Something went wrong:</h2>
                        <div><span className="text-red-500">{message}</span></div>

                        <Button type="primary"
                                className="mt-4"
                                onClick={() => Sentry.showReportDialog({eventId: this.state.eventId})}>
                            Report feedback
                        </Button>


                        <Button type="primary"
                                className="mt-4"
                                onClick={() => {
                                    this.props.navigate.dashboard()
                                    window.location.reload(false);
                                }}>
                            Reload
                        </Button>


                        <ErrorBoundaryUpdateApp />


                        <div className="text-3xs mt-2">ps: i need some styling</div>
                    </div>
                </div>
            );
        }

        //when there's not an error, render children untouched
        return this.props.children;
    }
}

export default withNavigation(ErrorBoundary);
