import React, {useState} from "react";
import {useTranslate} from "../../../../translations";

const ListItem = ({feature, checked, onClick}) => {
    const [hovered, setHovered] = useState(false);

    return (
        <div key={feature.id}
             className="flex flex-row cursor-pointer w-full"
             onMouseEnter={() => setHovered(true)}
             onMouseLeave={() => setHovered(false)}
             onClick={() => onClick(feature)}>

            <div className="flex border-b border-white w-full">
                <div
                    className={`flex justify-between p-2 w-full ${(checked || hovered) ? "bg-gray-200" : "bg-gray-50"}`}>
                    <span className="text-gray-900"> {feature.description}</span>

                    {(checked || hovered) &&
                    <div
                        className={`${(checked) ? 'bg-blue-500 border-blue-500' : 'bg-gray-500 border-gray-500'} flex items-center justify-center`}
                        style={{width: 14, height: 14, borderRadius: 7}}>
                        <span className="fa fa-check text-white" style={{fontSize: 7}}/>
                    </div>}
                </div>
            </div>


        </div>
    )
};

export default ListItem;