import React from 'react';
import {Query} from 'react-apollo';
import LoadingIndicator from '../indicators/LoadingIndicator';
import ErrorIndicator from '../indicators/ErrorIndicator';
import {PropTypes} from 'prop-types';


class CustomQuery extends React.Component {
    render() {
        const {query, variables, fetchPolicy, onCompleted} = this.props;


        return (
            <Query query={query}
                   fetchPolicy={fetchPolicy}
                   variables={variables}
                   notifyOnNetworkStatusChange
                   onCompleted={onCompleted}
            >
                    {(data) => {
                        if ((data.loading && data.networkStatus === 1)) {
                            return <LoadingIndicator/>;
                        }

                        if (data.error) {
                            return <ErrorIndicator description={data.message}/>;
                        }


                        return (
                            <React.Fragment>
                                {this.props.children(data)}

                            </React.Fragment>
                        )
                    }}
            </Query>
        )
    }
}

CustomQuery.propTypes = {
    children: PropTypes.func.isRequired
};

export default CustomQuery;




