import React, {useState} from 'react';
import {map, orderBy} from 'lodash';
import {useTranslate} from "../../../../../../../translations";
import {Button} from "../../../../../../../general";
import CountDown from "../CountDown";
import moment from "moment";
import DealerOffer from "./DealerOffer";
import {formatKilometers, formatAmount} from "../../../../../../../general/utils";

import ManualOfferForm from "./ManualOfferForm";
import ManagerOffer from "./ManagerOffer";
import ManagerOfferWithForm from "./ManagerOfferWithForm";


const Activity = ({onUnPublish, isManager}) => {
    const {getText} = useTranslate();
    const [managerBid, setManagerBid] = useState(0);
    const [managerComment, setManagerComment] = useState("");
    const [manualBidVisible, setManualBidVisible] = useState(false);
    const [manualBidComment, setManualBidComment] = useState("");
    const [manualBid, setManualBid] = useState(0);
    const [offers, setOffers] = useState([
        {
            id: 1,
            date: '2020-01-01',
            dealerName: 'AlphaCars',
            dealerMail: 'alphacars@tracer.com',
            price: 3500,
            comment: ""
        },
        {
            id: 2,
            date: '2020-01-02',
            dealerName: 'BetaCars',
            dealerMail: 'betacars@trader.com',
            price: 4500,
            comment: ""
        },
        {
            id: 3,
            date: '2020-01-02',
            dealerName: 'SiniCars',
            dealerMail: 'SiniCars@trader.com',
            price: 7600,
            comment: ""
        },
        {
            id: 4,
            date: '2020-01-02',
            dealerName: 'KloddeCars',
            dealerMail: 'kloddecars@trader.com',
            price: 1500,
            comment: ""
        }
    ])

    const [winner, setWinner] = useState(null);

    const handleClick = (id) => {
        if (winner === id) {
            setWinner(null)
        } else {
            setWinner(id);
        }
    };



    const data = {
        channelName: 'Channel XYZ',
        expectation: {
            date: '11/05/2020',
            km: 188958,
            price: 25000,
        },
        highestOffer: 6700,
        medianOffers: 6500,
        managerBid: {
            name: 'Michael Devenijn',
            email: 'michael.devenijn@autralis.com',
            price: (managerBid !== 0) ? managerBid : 0,
            comment: managerComment
        },
        offers: offers,


    };

    const handleUnPublish = () => {
        onUnPublish();
    };

    const handleManagerProposal = (proposal) => {
        setManagerBid(proposal.price);
        setManagerComment(proposal.comment);
    };

    const handleMangerOffer = (offer) => {
        offers.push({
            id: 99,
            date: '2020-01-02',
            dealerName: "NV Van Lierde",
            dealerMail: 'vanlierde@trader.com',
            price: offer.price,
            comment: offer.comment
        });

        setManualBid(offer.price);
        setManualBidComment(offer.comment);
        setManualBidVisible(false)
    }


    return (
        <React.Fragment>
            <div className="flex flex-row bg-blue-200 p-2">
                <div className="bg-white mr-2" style={{width: 100, height: 50}}></div>
                <div className="flex flex-1 flex-row">
                    <div className="w-1/2 flex flex-col">
                        <span className="font-medium mb-2">{data.channelName}</span>
                        <div className="text-blue-600 text-xl ">
                            <CountDown date={moment().add(2, 'days')}/>
                        </div>
                    </div>
                    <div className="w-1/2 flex flex-col items-end justify-center">
                        <Button onClick={handleUnPublish}>{getText('De-publish')}</Button>
                    </div>
                </div>
            </div>

            <div className="flex flex-row p-2 pl-0 pr-0 text-xs font-medium items-center justify-center">
                {`${getText("Expected on")} ${data.expectation.date} ${getText("with")} ${formatKilometers(data.expectation.km)} - ${getText("gladly")} ${formatAmount(data.expectation.price)}`}
            </div>

            <div className="flex flex-row bg-orange-100 border border-orange-200 mt-1">
                <div
                    className="w-1/2  flex flex-col items-center p-2 border border-orange-200 border-t-0 border-b-0 border-l-0">
                    <div className="mb-1 text-orange-500">{getText("Highest offer")}</div>
                    <div className="text-md font-medium text-orange-500">{formatAmount(data.highestOffer)}</div>
                </div>
                <div className="w-1/2  flex flex-col items-center p-2">
                    <div className="mb-1 text-orange-500">{getText("Median of offers")}</div>
                    <div className="text-md font-medium text-orange-500">{formatAmount(data.medianOffers)}</div>
                </div>
            </div>


            {!isManager && data.managerBid.price !== 0 &&
            <ManagerOffer price={data.managerBid.price}
                          comment={data.managerBid.email}
            />
            }

            {isManager &&
            <ManagerOfferWithForm price={data.managerBid.price}
                                  comment={data.managerBid.comment}
                                  onPropose={handleManagerProposal}
                                  canPropose={winner === null}
            />}

            {map(orderBy(data.offers, 'price' , ['desc']), (offer, i) => (
                <DealerOffer key={`dealer_offer_${offer.id}`}
                    clickable={isManager && ((winner == null) || (offer.id === winner))}
                             checked={winner === offer.id && isManager}
                             dealerBid={offer.price}
                             dealerBidDate={offer.date}
                             dealerName={offer.dealerName}
                             dealerMail={offer.dealerMail}
                             onClick={() => handleClick(offer.id)}/>
            ))}

            {manualBidVisible &&
            <ManualOfferForm dealer={null}
                             price={manualBid}
                             comment={manualBidComment}
                             onCancel={() => setManualBidVisible(false)}
                             onOffer={handleMangerOffer}
            />
            }

            {isManager && !manualBidVisible &&
            <div className="flex justify-end mt-1 pt-1">
                <span className="text-blue-500 hover:underline cursor-pointer"
                      onClick={() => setManualBidVisible(true)}><i className="fal fa-plus text-2xs"></i> Add manual bid</span>
            </div>}

        </React.Fragment>

    )
};

export default Activity
