import React, {Component} from 'react';
import withToolTip from '../withToolTip';
import "./indicator.css";
import './BaseButton.css';

export const ButtonTypes = {
    default: "default",
    primary: 'primary',
    primary_outline: 'primary_outline',
    accept: "accept",
    accept_outline: "accept_outline",
    reject: "reject",
    reject_outline: "reject_outline",
    icon: "icon-only",
    toggle: 'toggle,'
};

export const ButtonSizes = {
    sm: 1,
    md: 2,
    lg: 3,
};

class BaseButton extends Component {
    render() {
        const {id, size, disabled, onClick, children, style, className, dataTip, dataFor, icon, bgColor, bgHoverColor, borderColor, borderHoverColor, textColor, textHoverColor, loading, font} = this.props;

        return (
            <button
                id={id}
                disabled={disabled}
                className={`shadow-lg bg-${bgColor} hover:bg-${bgHoverColor} border border-${borderColor} hover:border-${borderHoverColor} text-${textColor} hover:text-${textHoverColor} py-${ButtonSizes[size]} px-${ButtonSizes[size] * 2} ${font} rounded text-xs outline-none focus:outline-none ${(disabled) ? 'opacity-50 cursor-not-allowed' : ''} ${className}`}
                onClick={onClick}
                style={style}
                data-tip={dataTip}
                data-for={dataFor}
            >


                <div className="flex flex-row justify-center">
                    {icon && !loading &&
                    <div className="mr-2" style={{width: 12}}>
                        <span
                            className={`${icon} text-${textColor} hover:text-${textHoverColor} text-xs cursor-pointer justify-center`}/>
                    </div>
                    }

                    {loading &&

                    <div className={`lds-ring mr-2 ${(textColor === 'white') ? 'lds-ring-white' : 'lds-ring-black'}`}
                         style={{width: 12, borderColor: textColor}}>
                        <div></div>
                    </div>
                    }


                    {children}


                </div>


            </ button >
        );
    }
}

BaseButton.defaultProps = {
    textColor: 'blue-500',
    textHoverColor: 'white',
    borderColor: 'blue-500',
    borderHoverColor: 'blue-500',
    iconColor: 'blue-500',
    bgColor: 'white',
    bgHoverColor: 'blue-500',
    className: ""
};

export default withToolTip(BaseButton);

