import React from 'react';

const Overlay = ({disabled, description}) => {
    return (
        <div className={`edit-slot-overlay absolute inset-0 z-10 mt-10 ${disabled ? "flex items-center justify-center" : "hidden"}`} style={{background: "rgba(0,0,0,0.2)"}}>
            <div className="bg-gray-100 text-gray-900 border-t border-b border-gray-600 px-4 py-3 w-full">
                <p className="text-sm font-medium text-center">{description}</p>
            </div>
        </div>
    );
};

export default Overlay;



