import React from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import Pagination from "./Pagination";
import {isEqual} from 'lodash';
import {getText} from "../../../translations";

export function whichTransitionEvent(className) {
    let t;
    let el = document.getElementsByClassName(className)[0];
    let animations = {
        '-webkit-animation': 'webkitAnimationEnd',
        'animation': 'animationend'
    };

    for (t in animations) {
        if (el.style[t] !== undefined) {
            return animations[t];
        }
    }
}

export default class Table extends React.Component {
    componentDidMount() {
        this.toggleTableBodyAnimation();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!isEqual(this.props.data, nextProps.data)) {
            this.toggleTableBodyAnimation();
        }
    }

    toggleTableBodyAnimation() {
        const table_body_selector = document.getElementsByClassName("rt-tbody")[0];
        table_body_selector.classList.add("rt-tbody-fade");

        let transitionEvent = whichTransitionEvent("rt-tbody");
        transitionEvent && document.addEventListener(transitionEvent, function () {
            table_body_selector.classList.remove("rt-tbody-fade");
        });
    }

    render() {
        const {columns, data, showPagination, defaultSort, sortable, pageSize, className, onRowClick} = this.props;
        return (
                <ReactTable
                    PaginationComponent={Pagination}
                    columns={columns}
                    data={data}
                    showPagination={showPagination}
                    showPageSizeOptions={false}
                    className={`-highlight flex flex-1 w-full border-l-0 border-t-0 border-b-0 ${className !== undefined && className}`}
                    defaultSorted={defaultSort}
                    sortable={sortable}
                    filterable={false}
                    defaultPageSize={pageSize}
                    pageSize={pageSize}
                    previousText={getText('Prev')}
                    nextText={getText('Next')}
                    loadingText={getText('Loading...')}
                    noDataText={getText('No rows found')}
                    pageText={getText('Page')}
                    ofText={getText('of')}
                    rowsText={getText('rows')}
                    onPageChange={() => {
                        this.toggleTableBodyAnimation();
                        this.props.onPageChange();
                    }}
                    getTrProps={(state, rowInfo, column) => {
                        return {
                            onClick: (e, t) => {
                                onRowClick(e, t, rowInfo)
                            },
                            // style: {
                            //     background: rowInfo && rowInfo.row.selected ? 'green' : 'red'
                            // }
                        }
                    }}
                />
        )
    }
}

Table.defaultProps = {
    columns: [],
    data: [],
    showPagination: false,
    sortable: false,
    defaultSort: [],
    defaultPageSize: 15,
    pageSize: 15
};



