import React from 'react';
import {emptyModuleConfig} from "../common";

import Personal from './account/Personal';
import Company from './company/Company'
import Users from './users/Users';
import Configs from './config/Overview';
import Samples from './samples/Overview';


const MODULE_NAME = 'master';

export function gatherConfig() {
    const config = emptyModuleConfig(MODULE_NAME);
    config.setMainMenu("Home", '#8cc641');

    config.addBlock("Dashboard", "fal fa-tachometer-alt", "manager", 0, null, null);
    // config.addBlock("Settings","fal fa-cogs", "manager", 0, <RouteMenu routes={SettingsRoutes} />, <RouteProvider routes={SettingsRoutes} />);

    config.addBlock("Personal","fal fa-user", "manager", 0, null, <Personal />);
    config.addBlock("Company","fal fa-building", "manager", 0, null, <Company />);
    config.addBlock("Users","fal fa-users", "manager", 0, null, <Users  />);
    config.addBlock("Configuration","fad fa-cog", "manager", 0, null, <Configs />);

    return config;
}


// export const SettingsRoutes = [
//     {
//         name: 'Personal',
//         path: 'personal',
//         component: PersonalSettings,
//     },
//     {
//         name: 'Company',
//         path: 'company',
//         component: CompanySettings,
//     },
//     {
//         name: 'Users',
//         path: 'users',
//         component: UserConfiguration,
//     }
// ];
