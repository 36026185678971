import React from 'react';
import {withMutation} from "../../../../general";
import {
    Form,
    CheckBox,
} from '../../../../general/forms-final-form'
import gql from "graphql-tag";
import Overlay from "./Overlay";
import {getText} from "../../../../translations";

const UPDATE_SLOTS = gql`
  mutation updateSlots($disabled: Boolean!, $printEntry: Boolean!, $ids: [ID]!) {
    updateSlots(disabled: $disabled, printEntry: $printEntry, ids: $ids) {
        ok
    }
  }
`;


export class EditSlots extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            saved: false,
            hasError: false,
            errorMessage: ""
        }

        this.updateSlots = this.updateSlots.bind(this);
    }

    updateSlots(data) {
        const {ids, onComplete, mutation} = this.props;
        const {disabled, printEntry} = data;

        mutation.mutate({variables: {disabled: disabled, printEntry: printEntry, ids: ids}})
            .then(res => {
                if (res.data.updateSlots.ok) {

                    this.setState({
                        hasError: false,
                        errorMessage: "",
                        saved: true
                    }, () => onComplete(getText('The changes are saved successfully!')));
                }
            })
            .catch(err => {
                let error_msg = err.message.replace("GraphQL error: ", '');
                this.setState({hasError: true, saved: false, errorMessage: error_msg});
                throw(error_msg);
            })

    }

    render() {
        const {data, mutation: {loading, error, success}} = this.props;

        // const allPrintChecked = checked(data, ids, 'print', 1);
        // const allPrintUnchecked = checked(data, ids, 'print', 0);
        // const printIsIndeterminate = !allPrintChecked && !allPrintUnchecked;
        const allPrinted = data.every(child => child["print"] === 1);
        //const allNotPrinted = data.every(child => child["print"] === 0);
        // const printIsIndeterminate = !allPrinted && !allNotPrinted;

        const allDisabled = data.every(child => child["disabled"] === 1);
        // const allEnabled = data.every(child => child["disabled"] === 0);
        // const disabledIsIndeterminate = !allDisabled && !allEnabled;


        const initValues = {
            allPrinted: allPrinted,
            // disabled: allDisabled,
        };

        return (
            <Form id="edit-slots"
                  onSubmit={this.updateSlots}
                  initialValues={initValues}
                  indicators={{
                      loading: loading,
                      error: (error && error.message) ? error.message : '',
                      success: success
                  }}
                  render={({handleSubmit, form, submitting, pristine, values, meta, fieldState}) => (
                      <div className="w-full">

                          <Overlay disabled={allDisabled} description={getText("All slots are disabled.")}/>

                          <div className="bg-blue-100 p-2 mb-4 pl-4 -ml-4 -mr-4">
                              {getText("Edit common fields")}
                          </div>

                          <CheckBox name="printEntry"
                                    label={getText("Print at entry")}
                                    disabled={loading}
                          />

                      </div>
                  )}
            />
        )
    }
}

export default withMutation({
    mutation: UPDATE_SLOTS,
})(EditSlots);

