import gql from "graphql-tag";


export const GET_MODEL_GROUPS = gql`
  query getModelGroups($makeId: Int!) {
    modelGroups(makeId: $makeId) {
      id,
      name      
    }
  }
`;


export const GET_MODELS = gql` 
  query getModels($makeId: Int!, $modelGroupId: Int!, $firstRegistration: Date!) {
    models(makeId: $makeId, modelGroupId: $modelGroupId, firstRegistration: $firstRegistration) {
      id
      name
    }
  }
`;

export const GET_POWERS = gql`
  query getPowers($modelId: Int!, $firstRegistration: Date!) {
    powers(modelId: $modelId, firstRegistration: $firstRegistration) {   
      hp,
      kw,      
    }
  }
`;

export const GET_VERSIONS = gql`
  query getVersions($modelId: Int!, $firstRegistration: Date!, $kw: Int!) {
    versions(modelId: $modelId, firstRegistration: $firstRegistration, kw: $kw) {
         id
            name
            hp
            kw
            doors
            seats
            co2
            start
            stop
            gearboxType {
              id
              description
            }
            euroNorm {
              id
              description
            }
            fuelType {
              id
              description
            }
            bodyType {
              id
              description
            }
            engine {
              id
              description
            }
            wheelFormula {
              id
              description
            }                  
    }
  }
`;


export const GET_TEMP_INSPECTION = gql`
  query getTempInspection($tempInspectionId: Int!) {
        tempInspection(id: $tempInspectionId) {
    id
    guid
    version
    converted
    createdAt
    updatedAt
    brand {
      id
      name
    }
    user {
      id
      firstName
      lastName
      fullName
    }
    vehicle {
      id      
      makeId
      make
      modelGroupId
      modelGroup
      modelId
      model
      versionId
      version
      vin
      co2
      registration
      powerKw
      powerHp
      firstRegistration
      reference
      kilometers
      seats
      doors
      colorInteriorId
      colorInterior
      colorInteriorOrigin
      colorExteriorId
      colorExterior
      colorExteriorOrigin
      commentPositive
      commentNegative
      commentInternal
      title
      fuelId
      fuel
      gearboxId
      gearbox
      emissionNormId
      emissionNorm
      engineId
      engine
      bodyId
      body
      wheelFormulaId
      wheelFormula
      catalogPrice
      catalogPriceWithOptions
    }
    noDamages
    damages {
      id
      locationId
      location
      typeId
      type
      severityId
      severity
      item
      x
      y
    }
    checks {
      checkId
      value
    }
    pictures {
      id
      fileId
      refId
      refType
      url
      thumbnailUrl
    }
    features {
      featureId
      name
    }
    customer {
      id
      origin {
        origin
        key
      }
      individual {       
        title
        culture
        firstName
        lastName
        address {
          street
          number
          box
          postalCode
          city
          countryCode
        }
        telephone
        mobile
        email
      }
      company {        
        name
        vatNumber
        legalForm
        address {
          street
          number
          box
          postalCode
          city
          countryCode
        }
        telephone
        email
      }
      taxation {
        isTaxable
        isCorporation
        taxablePercent
        isDisabledPerson
        isDiplomatic
        hasBelgianRegistration
      }
    }
  }
  tempInspectionContext(applicationId: "WEB/INSPECTOR/3", inspectionId: $tempInspectionId) {
    data
  }
    
        
    
  }
`;

export const GET_ALL_DATA = gql`
query GetAllData {
    makes {
        id
        name
      }
      makePriorities {
        id
        name
        priority
      }
      checkGroups {
        id
        description
        checks {
          id
          description
          icon
          type
          mandatory
        }
      }
      interiorColors {
        id
        hex
        description
      }
      exteriorColors {
        id
        hex
        description
      }
      featureGroups {
        id
        description
        priority
        features {
          id
          priority
          description
        }
      }
      pictureTypes {
        id
        key
        description
        priority
        mandatory
      }
      fuels {
        id
        description
      }
      gearboxes {
        id
        description
      }
      emissionNorms {
        id
        description
      }
      engines {
        id
        description
      }
      bodyTypes {
        id
        description
      }
      wheelFormulas {
        id
        description
      }
      legalForms {
        id
        description
        priority
      }
      countries {
        name
        id
        code
      }
      settings {
        customerSettings
      }
      
     damageLocations {
        id
        description        
      }
   
}`;


export const GET_VEHICLE_DATA = gql`
  query GetVehicleData($vin: String!) {
    vehicleData(vin: $vin) {  
        makeId
        makeText
        modelGroupId
        modelId
        modelText
        powerKw
        powerHp
        versionId
        versionText
        vin
        co2       
        seats
        doors
        colorInteriorText
        colorExteriorText
        fuelId
        gearboxId
        engineId
        bodyId
        wheelFormulaId
        featureFilled
        firstRegistration
        emissionNormId
        gearboxText
        featureIds
        start
        stop
    }
  }
`;




