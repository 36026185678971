import React from 'react';
import Buttons from "./Buttons";
import Summary from "./Summary";
import {map, find, keyBy} from 'lodash';
import WizardAnimation from "./WizardAnimation";
import {withSession} from "../../../../project/session";
import {TextInput, NumericInput, TextArea, Select, DatePicker, ColorListPicker} from '../../../../general/forms';
import moment from 'moment';

import {
    vehicleSetColorExterior,
    vehicleSetColorInterior,
    vehicleSetCommentInternal,
    vehicleSetKilometers,
    vehicleSetReference,
    vehicleSetRegistration,
    vehicleSetSeats,
    vehicleSetVin,
    vehicleSetCO2,
    vehicleSetFuel,
    vehicleSetGearbox,
    vehicleSetEngine,
    vehicleSetBody,
    vehicleSetWheelFormula, vehicleSetEmissionNorm, vehicleSetDoors, vehicleSetFirstRegistration
} from "../vehicleData";
import {wizardSteps} from "../inspectionData";
import {useTranslate} from "../../../../translations";


const getValidationErrors = (flowProgress) => {
    const progressStep = find(flowProgress, (fp) => fp.stepId === wizardSteps.generalInfo.id);
    if (progressStep !== undefined) {
        return keyBy(progressStep.errors, 'field');
    }
    return {}
};

const General = ({onNext, onPrev, onStepClick, wizard, data: {dispatch, settings, inspection, fuels, gearboxes, emissionNorms, engines, bodyTypes, wheelFormulas, exteriorColors, interiorColors}}) => {
    const {getText} = useTranslate();
    const validationErrors = getValidationErrors(wizard.flowProgress);

    const handleOnSelectInteriorColor = (color_id) => {
        if (color_id !== inspection.vehicle.colorInteriorId) {
            const color = find(interiorColors, (ic) => ic.id === color_id);
            vehicleSetColorInterior(dispatch, color)
        } else {
            vehicleSetColorInterior(dispatch, "")
        }
    }

    const handleOnSelectExteriorColor = (color_id) => {
        if (color_id !== inspection.vehicle.colorExteriorId) {
            const color = find(exteriorColors, (ic) => ic.id === color_id);
            vehicleSetColorExterior(dispatch, color)
        } else {
            vehicleSetColorExterior(dispatch, "")
        }
    }

    return (
        <div className="flex h-full w-full">
            <div className="w-1/4">
                <Summary data={wizard} onStepClick={onStepClick}/>
            </div>

            <div className="w-3/4 flex flex-col flex-1 bg-white p-2">
                <div className="flex flex-1 overflow-scroll ">
                    <WizardAnimation>
                        <div className="flex flex-col w-full">

                            <div className="block clearfix mb-3">
                                <div className="w-2/5 float-left pr-4">
                                    <TextInput value={inspection.vehicle.vin}
                                               onChange={e => {
                                                   const value = e.target.value.replace("q", "")
                                                       .replace("Q", "")
                                                       .replace("O", "0")
                                                       .replace("o", "0")
                                                       .replace("I", "")
                                                       .replace("i", "");
                                                   vehicleSetVin(dispatch, value);
                                               }}
                                               label={getText('VIN')}
                                               className="w-full"
                                               required={true}
                                               hasError={(validationErrors['vin'] && inspection.vehicle.vin !== "") || (validationErrors['vin'] && inspection.showAllErrors)}
                                               error={(validationErrors['vin']) ? validationErrors['vin'].message : ""}
                                    />
                                </div>

                                <div className="w-3/5 float-left">
                                    <div className="w-1/3 float-left pr-4">
                                        <NumericInput value={inspection.vehicle.kilometers}
                                                      onChange={value => vehicleSetKilometers(dispatch, value)}
                                                      label={getText('Kilometers')}
                                                      className="float-left"
                                                      required={true}
                                                      min={1}
                                                      max={999999}
                                                      hasError={(validationErrors['kilometers'] && inspection.vehicle.kilometers !== "") || (validationErrors['kilometers'] && inspection.showAllErrors)}
                                                      error={(validationErrors['kilometers']) ? validationErrors['kilometers'].message : ""}
                                        />
                                    </div>
                                    <div className="w-1/3 float-left pr-4">
                                        <TextInput value={inspection.vehicle.registration}
                                                   onChange={e => vehicleSetRegistration(dispatch, e.target.value)}
                                                   label={getText('Registration')}
                                                   className="float-left"
                                                   required={settings.customerSettings.INSPECTION_ADDITIONAL_LICENSE_PLATE_REQUIRED}
                                                   hasError={(validationErrors['registration'] && inspection.vehicle.registration !== "" && settings.customerSettings.INSPECTION_ADDITIONAL_LICENSE_PLATE_REQUIRED) || (validationErrors['registration'] && inspection.showAllErrors && settings.customerSettings.INSPECTION_ADDITIONAL_LICENSE_PLATE_REQUIRED)}
                                                   error={(validationErrors['registration']) ? validationErrors['registration'].message : ""}
                                        />
                                    </div>
                                    <div className="w-1/3 float-left">
                                        <TextInput value={inspection.vehicle.reference}
                                                   onChange={e => vehicleSetReference(dispatch, e.target.value)}
                                                   label={getText('Reference')}
                                                   className="float-left"
                                                   required={settings.customerSettings.INSPECTION_ADDITIONAL_REFERENCE_REQUIRED}
                                                   hasError={(validationErrors['reference'] && inspection.vehicle.reference !== "" && settings.customerSettings.INSPECTION_ADDITIONAL_REFERENCE_REQUIRED) || (validationErrors['reference'] && inspection.showAllErrors && settings.customerSettings.INSPECTION_ADDITIONAL_REFERENCE_REQUIRED)}
                                                   error={(validationErrors['reference']) ? validationErrors['reference'].message : ""}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="block clearfix mb-3">
                                <div className="float-left w-5/7">
                                    <div className="float-left pr-4 w-1/2">
                                        <Select label={getText("Body")}
                                                value={inspection.vehicle.bodyId}
                                                placeholder={getText("Select body type")}
                                                options={map(bodyTypes, (body) => ({
                                                    label: body.description,
                                                    value: body.id
                                                }))}
                                                onChange={(id) => vehicleSetBody(dispatch, id)}
                                                required={true}
                                                hasError={(validationErrors['bodyId'] && inspection.vehicle.bodyId !== 0) || (validationErrors['bodyId'] && inspection.showAllErrors)}
                                                error={(validationErrors['bodyId']) ? validationErrors['bodyId'].message : ""}
                                        />
                                    </div>
                                    <div className="float-left w-1/2 pr-4">
                                        <Select label={getText("Wheel formula")}
                                                value={inspection.vehicle.wheelFormulaId}
                                                placeholder={getText("Select wheel formula")}
                                                options={map(wheelFormulas, (wf) => ({
                                                    label: wf.description,
                                                    value: wf.id
                                                }))}
                                                onChange={(id) => vehicleSetWheelFormula(dispatch, id)}
                                                required={true}
                                                hasError={(validationErrors['wheelFormulaId'] && inspection.vehicle.wheelFormulaId !== 0) || (validationErrors['wheelFormulaId'] && inspection.showAllErrors)}
                                                error={(validationErrors['wheelFormulaId']) ? validationErrors['wheelFormulaId'].message : ""}
                                        />
                                    </div>
                                </div>

                                <div className="float-left w-2/7">
                                    <div className="float-left pr-4 w-1/2">
                                        <Select value={inspection.vehicle.seats}
                                                label={getText('Seats')}
                                                options={map([2, 3, 4, 5, 6, 7, 8], (count) => ({
                                                    label: count,
                                                    value: count,
                                                }))}
                                                searchable={false}
                                                onChange={(count) => vehicleSetSeats(dispatch, count)}
                                                required={true}
                                                hasError={(validationErrors['seats'] && inspection.vehicle.seats !== 0) || (validationErrors['seats'] && inspection.showAllErrors)}
                                                error={(validationErrors['seats']) ? validationErrors['seats'].message : ""}
                                        />
                                    </div>

                                    <div className="float-left w-1/2">
                                        <Select value={inspection.vehicle.doors}
                                                label={getText('Doors')}
                                                options={map([2, 3, 4, 5], (count) => ({
                                                    label: count,
                                                    value: count,
                                                }))}
                                                searchable={false}
                                                onChange={(count) => vehicleSetDoors(dispatch, count)}
                                                required={true}
                                                hasError={(validationErrors['doors'] && inspection.vehicle.doors !== 0) || (validationErrors['doors'] && inspection.showAllErrors)}
                                                error={(validationErrors['doors']) ? validationErrors['doors'].message : ""}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="block clearfix mb-3">
                                <div className="float-left pr-4 w-1/2">
                                    <Select label={getText("Engine")}
                                            value={inspection.vehicle.engineId}
                                            placeholder={getText("Select engine")}
                                            options={map(engines, (engine) => ({
                                                label: engine.description,
                                                value: engine.id
                                            }))}
                                            onChange={(id) => vehicleSetEngine(dispatch, id)}
                                            className="mr-2"
                                            required={true}
                                            hasError={(validationErrors['engineId'] && inspection.vehicle.engineId !== 0) || (validationErrors['engineId'] && inspection.showAllErrors)}
                                            error={(validationErrors['engineId']) ? validationErrors['engineId'].message : ""}
                                    />
                                </div>
                                <div className="float-left w-1/2">
                                    <Select label={getText("Gearbox")}
                                            value={inspection.vehicle.gearboxId}
                                            placeholder={getText("Select gearbox")}
                                            options={map(gearboxes, (gearbox) => ({
                                                label: gearbox.description,
                                                value: gearbox.id
                                            }))}
                                            onChange={(id) => vehicleSetGearbox(dispatch, id)}
                                            required={true}
                                            hasError={(validationErrors['gearboxId'] && inspection.vehicle.gearboxId !== 0) || (validationErrors['gearboxId'] && inspection.showAllErrors)}
                                            error={(validationErrors['gearboxId']) ? validationErrors['gearboxId'].message : ""}
                                    />
                                </div>
                            </div>

                            <div className="block clearfix mb-3">
                                <div className="block float-left w-2/3">
                                    <div className="block float-left w-2/3">
                                        <div className="block float-left w-2/3 pr-4">
                                            <Select label={getText("Euro norm")}
                                                    value={inspection.vehicle.emissionNormId}
                                                    placeholder={getText("Select emission norm")}
                                                    options={map(emissionNorms, (norm) => ({
                                                        label: norm.description,
                                                        value: norm.id
                                                    }))}
                                                    onChange={(id) => vehicleSetEmissionNorm(dispatch, id)}
                                                    required={true}
                                                    hasError={(validationErrors['emissionNormId'] && inspection.vehicle.emissionNormId !== 0) || (validationErrors['emissionNormId'] && inspection.showAllErrors)}
                                                    error={(validationErrors['emissionNormId']) ? validationErrors['emissionNormId'].message : ""}
                                            />
                                        </div>

                                        <div className="block float-left w-1/3 pr-4">
                                            <NumericInput value={inspection.vehicle.co2}
                                                          onChange={(value) => vehicleSetCO2(dispatch, value)}
                                                          name="CO2"
                                                          label={getText('CO2')}
                                                          required={true}
                                                          hasError={(validationErrors['co2'] && inspection.vehicle.co2 !== "") || (validationErrors['co2'] && inspection.showAllErrors)}
                                                          error={(validationErrors['co2']) ? validationErrors['co2'].message : ""}
                                            />
                                        </div>
                                    </div>
                                    <div className="block float-left w-1/3 pr-4">
                                        <DatePicker value={inspection.vehicle.firstRegistration}
                                                    onChange={date => vehicleSetFirstRegistration(dispatch, date)}
                                                    label={getText('1st registration')}
                                                    placeholder="1st registration"
                                                    required={true}
                                                    hasError={(validationErrors['firstRegistration'] && inspection.vehicle.firstRegistration !== null) || (validationErrors['firstRegistration'] && inspection.showAllErrors)}
                                                    error={(validationErrors['firstRegistration']) ? validationErrors['firstRegistration'].message : ""}
                                                    defaultView="year"
                                                    minDate={new Date(moment().subtract(20, 'years').format('YYYY-MM-DD'))}
                                                    maxDate={new Date()}
                                        />
                                    </div>
                                </div>
                                <div className="block float-left w-1/3">
                                    <Select label={getText("Fuel")}
                                            value={inspection.vehicle.fuelId}
                                            placeholder={getText("Select fuel")}
                                            options={map(fuels, (fuel) => ({
                                                label: fuel.description,
                                                value: fuel.id
                                            }))}
                                            onChange={(id) => vehicleSetFuel(dispatch, id)}
                                            required={true}
                                            hasError={(validationErrors['fuelId'] && inspection.vehicle.fuelId !== 0) || (validationErrors['fuelId'] && inspection.showAllErrors)}
                                            error={(validationErrors['fuelId']) ? validationErrors['fuelId'].message : ""}
                                    />
                                </div>

                            </div>


                            <div className="mb-4">
                                <ColorListPicker label={getText('Exterior color')}
                                                 items={map(exteriorColors, (color) => ({
                                                     name: color.description,
                                                     color: color.hex,
                                                     value: color.id,
                                                 }))}
                                                 value={inspection.vehicle.colorExteriorId}
                                                 description={inspection.vehicle.colorExteriorText}
                                                 onSelect={handleOnSelectExteriorColor}
                                                 required={true}
                                                 hasError={(validationErrors['colorExterior'] && inspection.vehicle.colorExterior !== "") || (validationErrors['colorExterior'] && inspection.showAllErrors)}
                                                 error={(validationErrors['colorExterior']) ? validationErrors['colorExterior'].message : ""}
                                />
                            </div>


                            <div className="mb-4">
                                <ColorListPicker label={getText('Interior color')}
                                                 items={map(interiorColors, (color) => ({
                                                     name: color.description,
                                                     color: color.hex,
                                                     value: color.id,
                                                 }))}
                                                 value={inspection.vehicle.colorInteriorId}
                                                 description={inspection.vehicle.colorExteriorText}
                                                 onSelect={handleOnSelectInteriorColor}
                                                 required={true}
                                                 hasError={(validationErrors['colorInterior'] && inspection.vehicle.colorInterior !== "") || (validationErrors['colorInterior'] && inspection.showAllErrors)}
                                                 error={(validationErrors['colorInterior']) ? validationErrors['colorInterior'].message : ""}
                                />
                            </div>

                            <TextArea value={inspection.vehicle.commentInternal}
                                      onChange={e => vehicleSetCommentInternal(dispatch, e.target.value)}
                                      label={() => <div className="">
                                          Comment <span
                                          className="text-3xs">{getText("(Optional - only for internal use)")}</span>
                                      </div>}
                            />


                        </div>
                    </WizardAnimation>
                </div>

                <Buttons onNext={onNext}
                         onPrev={onPrev}
                         data={wizard}
                />
            </div>
        </div>
    )
};


export default withSession(General);