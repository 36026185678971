import React from 'react';
import {Form as FinalForm} from "react-final-form";
import FormIndicators from './Indicators';


class Form extends React.Component {

    render() {
        const {id, initialValues, onSubmit, mutators, onValidate} = this.props;
        const {loading, error} = this.props.indicators;

        return (

            <FinalForm onSubmit={onSubmit}
                       initialValues={initialValues}
                       mutators={mutators}
                       validate={onValidate}
                       render={(props) => {
                           const {handleSubmit, form} = props;

                           return (
                               <React.Fragment>

                                   <FormIndicators loading={loading}
                                                   error={error}
                                                   success={false}/>

                                   <form id={id}
                                         onSubmit={handleSubmit}
                                         className="w-full"
                                   >
                                       {this.props.render(props)}
                                   </form>
                               </React.Fragment>
                           )
                       }}
            />

        );
    }
}

Form.defaultProps = {
    indicators: {
        loading: false,
        error: '',
        success: false
    },
};

export default Form;