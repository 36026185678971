import React from 'react';
import {Field} from "react-final-form";
import {composeValidators} from '../forms/validators';
import {default as FormSelect} from "../forms/Select";

const Select = ({name, options, defaultValue, label, placeholder, validators, disabled, className, dataTip, dataFor, inputClassName, showBorder, searchable}) => {
    return (
        <Field name={name}
               validate={(validators) ? composeValidators(...validators) : false}
               data-tip={dataTip}
               data-for={dataFor}>

            {({input, meta}) => (
                <FormSelect name={name}
                            label={label}
                            options={options}
                            {...input}
                            value={(input.value === null || input.value === undefined || input.value === "") ? defaultValue : input.value}
                            onChange={input.onChange}
                            placeholder={placeholder}
                            disabled={disabled}
                            className={className}
                            inputClassName={inputClassName}
                            showBorder={showBorder}
                            error={meta.error}
                            hasError={meta.touched && meta.error !== undefined}
                            searchable={searchable}

                />
            )}
        </Field>
    );
};

export default Select;
