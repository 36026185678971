import React from 'react';
import {Drawer, withMutation} from '../../../general/';
import {Form, TextInput, FormValidators, FormActionButton} from '../../../general/forms-final-form';
import UUID from 'uuid';
import gql from 'graphql-tag';
import {getText} from "../../../translations";

const formId = UUID.v4();

const UPDATE_COMPANY = gql`
  mutation UpdateCompany($input: CompanyUpdateInput!) {
    updateCompany(input: $input) {
        ok
        company {
            id
            name
            vatNumber
            primaryEmail
            primaryMobile
            primaryTelephone
        }
    }
  }
`;

const Details = ( {company, mutation: {loading, error, success, mutate: saveMutation}, onClose}) => {
    const title = () => (
        <div className="flex flex-grow justify-between items-center">
            <div>
                <FormActionButton formId={formId}>{getText('Save')}</FormActionButton>
            </div>
        </div>
    );

    const handleSubmit = (data) =>  {
        saveMutation({
            variables: {
                input: {
                    id: company.id,
                    name: data.name,
                    vatNumber: data.vatNumber,
                    primaryEmail: data.primaryEmail,
                    primaryTelephone: data.primaryTelephone || "",
                    primaryMobile: data.primaryMobile || "",
                }
            }
        });
    };

    return (
        <Drawer size="sm"
                title={title}
                onClose={onClose}
                visible={true}>

            <Form id={formId}
                  onSubmit={handleSubmit}
                  initialValues={company}
                  indicators={{
                      loading: loading,
                      error: (error && error.message) ? error.message : '',
                      success: success
                  }}
                  render={() => (
                      <div className="p-2">
                          <TextInput name="name"
                                     label={getText("Company name")}
                                     placeholder={getText("Company name")}
                                     disabled={loading}
                                     validators={[FormValidators.required]}
                          />

                          <TextInput name="vatNumber"
                                     label={getText("VAT number")}
                                     placeholder={getText("VAT number")}
                                     disabled={loading}
                                     validators={[FormValidators.required]}
                          />

                          <TextInput name="primaryEmail"
                                     label={getText("Primary e-mail")}
                                     placeholder={getText("Primary e-mail")}
                                     disabled={loading}
                                     validators={[FormValidators.email]}
                          />

                          <div className="flex flex-row justify-between">

                              <div className="w-full mr-4">
                                  <TextInput name="primaryTelephone"
                                             label={getText("Telephone")}
                                             disabled={loading}
                                             placeholder={getText("Telephone")}
                                  />
                              </div>

                              <TextInput name="primaryMobile"
                                         label={getText("Mobile")}
                                         disabled={loading}
                                         placeholder={getText("Mobile")}
                              />

                          </div>
                      </div>
                  )}

            />
        </Drawer>
    )
};



export default withMutation({
    mutation: UPDATE_COMPANY,
    successMessage: getText("Changes were made successfully")
})(Details);

