import React, {Component} from 'react'

class DragAndDrop extends Component {
    dragCounter = 0;
    state = {
        drag: false
    };
    dropRef = React.createRef();

    handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation()
    };

    handleDragIn = (e,) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter++;

        const {canDrop} = this.props;

        if (canDrop && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({drag: true})
        }
    };

    handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter--;

        const {canDrop} = this.props;

        if (canDrop && this.dragCounter === 0) {
            this.setState({drag: false})
        }
    };

    handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({drag: false});

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.handleDrop(e.dataTransfer.files, e.clientX, e.clientY);
            e.dataTransfer.clearData();
            this.dragCounter = 0
        } else {

            this.dragCounter = 0;
            this.props.handleDrop(null,  e.clientX, e.clientY);

        }
    };

    componentWillReceiveProps(nextProps, nextContext) {
        let div = this.dropRef.current;
        if(this.props.canDrop && !nextProps.canDrop){
            div.removeEventListener('dragenter', this.handleDragIn);
            div.removeEventListener('dragleave', this.handleDragOut);
            div.removeEventListener('dragover', this.handleDrag);
            div.removeEventListener('drop', this.handleDrop);
        }else if(!this.props.canDrop && nextProps.canDrop){
            div.addEventListener('dragenter', this.handleDragIn);
            div.addEventListener('dragleave', this.handleDragOut);
            div.addEventListener('dragover', this.handleDrag);
            div.addEventListener('drop', this.handleDrop);
        }
    }

    componentDidMount() {
        const {canDrop} = this.props;
        if(canDrop){
            let div = this.dropRef.current;
            div.addEventListener('dragenter', this.handleDragIn);
            div.addEventListener('dragleave', this.handleDragOut);
            div.addEventListener('dragover', this.handleDrag);
            div.addEventListener('drop', this.handleDrop);
        }

    }

    componentWillUnmount() {
        let div = this.dropRef.current;
        div.removeEventListener('dragenter', this.handleDragIn);
        div.removeEventListener('dragleave', this.handleDragOut);
        div.removeEventListener('dragover', this.handleDrag);
        div.removeEventListener('drop', this.handleDrop);

    }


    render() {
        const isOver = this.state.drag && this.props.highlightOnHover;
        const {onClick, className, borderClasses, canDrop} = this.props;
        return (
            <div onClick={(canDrop) ? onClick : null}
                 className={`flex relative ${borderClasses} ${isOver ? "border border-dashed border-blue-300" : "border-gray-200"} ${className}`}
                 ref={this.dropRef}
            style={{width: "100%", overflowX: "scroll"}}>

                {isOver &&
                <div className="flex w-full absolute inset-0 items-center justify-center" style={{backgroundColor: 'rgba(255,255,255,.8)', zIndex: 9999}}>
                    <div className="text-2xl text-gray-600 ">Drop here</div>
                </div>
                }
                {this.props.children}
            </div>
        )
    }
}

DragAndDrop.defaultProps = {
    highlightOnHover: true,
    canDrop: true,
    className: "",
    borderClasses: "border border-dashed"
};

export default DragAndDrop
