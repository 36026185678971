import {generate_initial_inspection, inspectionReducer, wizardSteps} from "./inspectionData";

const DEFAULT_CHECK_INTERVAL = 1000;
export const AUTO_SAVE_NUM_RETRIES = 3;


export const generate_initial_state = (tempInspectionId) => {
    return {
        inspection: generate_initial_inspection(tempInspectionId),
        prevInspection: null,
        confirmation: null,
        lastSave: null,
        lastUpdate: null,
        saveErrCount: 0,
        saveCheckInterval: DEFAULT_CHECK_INTERVAL,
        version: 0,

        wizardFlow: [
            wizardSteps.vinSearch.id,
            wizardSteps.make.id,
            wizardSteps.registration.id,
            wizardSteps.modelGroup.id,
            wizardSteps.model.id,
            wizardSteps.power.id,
            wizardSteps.version.id,
            wizardSteps.generalInfo.id,
            wizardSteps.features.id,
            wizardSteps.checks.id,
            wizardSteps.pictures.id,
            wizardSteps.damages.id,
            wizardSteps.customer.id,
            wizardSteps.confirmation.id,
        ],
    }
};


const INSPECTION_CONTAINER_TYPES = {
    SET_INSPECTION: 'INSPECTION_CONTAINER_SET_INSPECTION',
    SET_VERSION: 'INSPECTION_CONTAINER_SET_VERSION',
    UPDATE_INSPECTION_FROM_SUBSCRIPTION: 'UPDATE_INSPECTION_FROM_SUBSCRIPTION',
    SET_CONFIRMATION: 'INSPECTION_CONTAINER_SET_CONFIRMATION',
    SET_LAST_UPDATE: 'INSPECTION_CONTAINER_SET_LAST_UPDATE',
    TOGGLE_AUTO_SAVE: 'INSPECTION_CONTAINER_TOGGLE_AUTO_SAVE',
    TOGGLE_AUTO_SAVE_FAILURE: 'INSPECTION_CONTAINER_TOGGLE_AUTO_SAVE_FAILURE',
    TOGGLE_AUTO_SAVE_MANUALLY: 'INSPECTION_CONTAINER_TOGGLE_AUTO_SAVE_MANUALLY',
};

export const inspectionContainerReducer = (state, action) => {
    switch (action.type) {
        case INSPECTION_CONTAINER_TYPES.SET_INSPECTION:
            return {
                ...state,
                version: action.payload.version,
                inspection: action.payload.inspection,
                prevInspection:  null,
            };
        case INSPECTION_CONTAINER_TYPES.SET_CONFIRMATION:
            return {
                ...state,
                confirmation: action.payload.confirmation,
            };
        case INSPECTION_CONTAINER_TYPES.SET_LAST_UPDATE:
            return {
                ...state,
                lastUpdate: action.payload.date,
            };
        case INSPECTION_CONTAINER_TYPES.SET_VERSION:
            return {
                ...state,
                version: action.payload.version,

            };
        case INSPECTION_CONTAINER_TYPES.TOGGLE_AUTO_SAVE:
            return {
                ...state,
                lastSave: state.lastUpdate,
                prevLastSave: state.lastSave
            };
        case INSPECTION_CONTAINER_TYPES.TOGGLE_AUTO_SAVE_FAILURE:
            return {
                ...state,
                lastSave: state.prevLastSave,
                prevLastSave: null,
                saveErrCount: state.saveErrCount + 1,
                saveCheckInterval: (state.saveErrCount + 1) === AUTO_SAVE_NUM_RETRIES ? null : state.saveCheckInterval
            };
        case INSPECTION_CONTAINER_TYPES.TOGGLE_AUTO_SAVE_MANUALLY:
            return {
                ...state,
                lastSave: null,
                prevLastSave: null,
                saveCheckInterval: DEFAULT_CHECK_INTERVAL,
                saveErrCount: 0,
            };
        default:
            return {
                ...state,
                inspection: inspectionReducer(state.inspection, action),
                prevInspection: state.inspection,
            };
    }
};

export const setInspection = (dispatch, id, data, version) => {
    dispatch({
        type: INSPECTION_CONTAINER_TYPES.SET_INSPECTION,
        payload: {
            id,
            inspection: data,
            version,
        }
    });
};

export const setConfirmation = (dispatch, confirmation) => {
    dispatch({
        type: INSPECTION_CONTAINER_TYPES.SET_CONFIRMATION,
        payload: {
            confirmation
        }
    });
};

export const setLastUpdate = (dispatch, date) => {
    dispatch({
        type: INSPECTION_CONTAINER_TYPES.SET_LAST_UPDATE,
        payload: {
            date,
        }
    });
};


export const setDataVersion= (dispatch, version) => {
    dispatch({
        type: INSPECTION_CONTAINER_TYPES.SET_VERSION,
        payload: {
            version,
        }
    });
};

export const toggleAutoSave = (dispatch) => {
    dispatch({
        type: INSPECTION_CONTAINER_TYPES.TOGGLE_AUTO_SAVE,
        payload: {}
    });
};

export const resetAutoSave = (dispatch) => {
    dispatch({
        type: INSPECTION_CONTAINER_TYPES.TOGGLE_AUTO_SAVE_MANUALLY,
        payload: {}
    });
};

export const toggleAutoSaveFailure = (dispatch) => {
    dispatch({
        type: INSPECTION_CONTAINER_TYPES.TOGGLE_AUTO_SAVE_FAILURE,
        payload: {}
    });
};

