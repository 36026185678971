import React from 'react'

const ProcessItem = ({process: {brand, date, status, leadSalesMan}, onClick}) => {
    return (
        <div onClick={onClick}
             className={`bg-gray-100 text-gray-900 hover:bg-blue-400 hover:text-white flex flex-row px-2 py-2 mb-1 mr-1 cursor-pointer group`}>

            <div className="flex items-center ml-2 mr-4">
                <i className="fas fa-cogs text-3xl"></i>
            </div>

            <div>
                <div className="font-medium text-sm  mr-1 text-blue-500 group-hover:text-white">
                    {brand}
                </div>
                <div>
                    {`${leadSalesMan}`}
                </div>
                <div>
                    {`${status}`}
                </div>
            </div>

            <div className="flex items-center ml-4">
                {date}
            </div>

        </div>
    )
};

export default ProcessItem;
