import React, {useState} from 'react';
import {Button, HeaderToolbar, DrawerManager} from '../../../general'
import ChangePassword from './ChangePassword';
import {map} from 'lodash';
import Gravatar from 'react-gravatar';
import {getText} from "../../../translations";
import {useMutation, useQuery, useSubscription} from "@apollo/react-hooks";
import LoadingIndicator from "../../../general/indicators/LoadingIndicator";
import UUID from "uuid";
import {queries, mutations, subscriptions} from './graphql';
import PersonalInformationForm from "./PersonalInformationForm";


export const DRAWER_TYPES = {
    USER_PROFILE_DETAIL: "USER_PROFILE_DETAIL",
    USER_CHANGE_PASSWORD: "USER_CHANGE_PASSWORD"
};
const DrawerManagerConfig = {
    [DRAWER_TYPES.USER_PROFILE_DETAIL]: {component: PersonalInformationForm},
    [DRAWER_TYPES.USER_CHANGE_PASSWORD]: {component: ChangePassword},
};

const Personal = () => {
    const [currentDrawer, setCurrentDrawer] = useState(null);
    const {data, called, loading} = useQuery(queries.userProfile);
    const [toggleSmsNotificationMutation, {called: mutationCalled, loading: mutationLoading}] = useMutation(mutations.smsNotificationsChange);
    const {loading: subscriptionLoading} = useSubscription(subscriptions.userProfileChanged, {
        onSubscriptionData: ({subscriptionData: {data}}) => {
            const {transactionId} = data.userProfileChanged.payload;
            if (transactionId === waitingTransaction) {
                setWaitingTransaction(undefined);
            }
        }
    });

    const [waitingTransaction, setWaitingTransaction] = useState(undefined);

    const handleClick = () => {
        setCurrentDrawer({
            name: DRAWER_TYPES.USER_PROFILE_DETAIL
        });
    };

    const handleChangePasswordClick = (e) => {
        e.stopPropagation();

        setCurrentDrawer({
            name: DRAWER_TYPES.USER_CHANGE_PASSWORD
        });
    };

    const handleToggleSmsNotifications = (e) => {
        e.stopPropagation();

        const transactionId = UUID.v4();
        setWaitingTransaction(transactionId);

        toggleSmsNotificationMutation({
            variables: {
                data: {
                    transactionId: transactionId,
                    active: !userProfile.smsActive
                },
            }
        }).then((result) => {
            const {userToggleSmsNotificationsHandler} = result.data;
            const {success} = userToggleSmsNotificationsHandler.result;
            if (!success) {
                setWaitingTransaction(undefined);
            }
        })
    };

    if (loading || (!called && !data.userProfile)) {
        return <LoadingIndicator/>
    }

    const {userProfile} = data;
    return (
        <div className="flex flex-col flex-1">

            <HeaderToolbar title={getText('Personal settings')}
                           subtitle={getText("Manage your personal information")}
            />

            <div className="ml-2 max-w-3xl cursor-pointer" onClick={handleClick}>

                <div className="flex bg-white p-3">

                    <div className="flex max-w-sm">
                        <Gravatar email={userProfile.user.email}
                                  size={100}
                                  default="mp"
                        />
                    </div>

                    <div className="flex-1 text-gray-800 pl-2">
                        <div className="text-xl font-medium">
                            {`${userProfile.user.lastName} ${userProfile.user.firstName}`}
                        </div>

                        <div className="pt-1">
                            {map(userProfile.user.groups, (group, i) =>
                                    <span key={i} className="text-xs mr-2">
                                {group.name}
                            </span>
                            )}
                        </div>

                        <div className="mt-4 flex flex-row items-baseline">
                            <span className="text-xs mr-2 ">{getText("E-mail")}:</span>
                            <span className="text-sm mr-2 font-medium">{userProfile.user.email}</span>
                        </div>

                        <div className="pt-1 flex flex-row items-baseline">
                            <span className="text-xs mr-2 ">{getText("Mobile")}:</span>
                            <span className="text-sm mr-2 font-medium">{userProfile.mobile}</span>
                        </div>
                    </div>


                    <div className="flex flex-row text-right justify-center h-8">

                        <Button className="mr-1" type="primary" onClick={handleChangePasswordClick}>
                            {getText("Change password")}
                        </Button>

                        <Button type='toggle'
                                active={userProfile.smsActive}
                                loading={waitingTransaction !== undefined}
                                onClick={handleToggleSmsNotifications}>
                            {getText("SMS Notifications")}
                        </Button>
                    </div>
                </div>
            </div>

            {currentDrawer !== null &&
            <DrawerManager config={DrawerManagerConfig}
                           current={currentDrawer.name}
                           params={currentDrawer.params}
                           onCurrentDrawerClose={() => setCurrentDrawer(null)}
            />}
        </div>
    )
};

export default Personal;

