import React from 'react';
import {withToolTip} from '../../general'

const InputFieldErrorIndicator = ({dataTip, dataFor}) => {
    return (
        <div className="absolute top-0 right-0 p-2 text-base cursor-pointer"
             data-tip={dataTip}
             data-for={dataFor}>

            <div className="bg-blue-200 text-blue-600 p-0 m-0 flex justify-center items-center" style={{width: 22, height: 22, borderRadius: 11}}>
                <i className="fas fa-info"></i>
            </div>

        </div>
    );
};


export default withToolTip(InputFieldErrorIndicator);

