import React from 'react';
import {default as BaseSwitch} from "../base/Switch";
import * as PropTypes from "prop-types";
import LabelContainer from "./LabelContainer";

const Switch = ({label, value, required, disabled, onChange, className, inputClassName, showBorder, error, hasError}) => {

    return (
        <LabelContainer label={label}
                        disabled={disabled}
                        className={className}
                        error={error}
                        hasError={hasError}
                        hasValue={value !== ""}>

            <BaseSwitch label=""
                        checked={value}
                        onChange={onChange}
                        required={required}
                        disabled={disabled}
                        className={inputClassName}
                        showBorder={showBorder}
                        hasError={hasError}
            />


        </LabelContainer>
    );
};

Switch.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    value: PropTypes.bool.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    showBorder: PropTypes.bool,
    error: PropTypes.string,
    hasError: PropTypes.bool,
};

Switch.defaultProps = {
    label: "Label",
    value: false,
    required: false,
    disabled: false,
    onChange: null,
    className: "",
    inputClassName: "",
    showBorder: true,
    error: "",
    hasError: false,
};

export default Switch;


