import React from 'react';
import {map} from 'lodash'
import {useTranslate} from "../../../../../translations";
import {formatAmount, formatDate, formatKilometers} from "../../../../../general/utils";

export enum Similarity {
    MEDIUM,
    HIGH,
    VERY_HIGH
}

type MarketVehicleType = {
    similarity: Similarity,
    stockDays: number,
    distance: number,
    dealerName: string,
    version: string,
    firstRegistration: string,
    kilometers: number,
    price: number,
}

const similarityToColor = (similarity: Similarity) => {
    switch (similarity) {
        case Similarity.VERY_HIGH:
            return 'green-300';
        case Similarity.HIGH:
            return 'orange-300';
        case Similarity.MEDIUM:
            return 'gray-300';
        default:
            return 'gray-300';
    }
};

const RadarValuation = ({marketVehicles}: { marketVehicles: Array<MarketVehicleType> }) => {
    const {getText} = useTranslate();

    return (
        <React.Fragment>
            <div className="flex flex-row bg-gray-100 p-2">
                <div className="w-1/5"><span>{getText('Similarity')}</span></div>
                <div className="w-4/5 flex flex-row">
                    <div className="flex flex-1 flex-row justify-end items-center">
                        <div className="flex flex-row mr-4 items-center">
                            <div style={{width: 8, height: 8, borderRadius: 4}} className="bg-green-300 mr-1"/>
                            <span>{getText("Very high")}</span></div>
                        <div className="flex flex-row mr-4 items-center">
                            <div style={{width: 8, height: 8, borderRadius: 4}} className="bg-orange-300 mr-1"/>
                            <span>{getText("High")}</span></div>
                        <div className="flex flex-row items-center">
                            <div style={{width: 8, height: 8, borderRadius: 4}} className="bg-gray-300 mr-1"/>
                            <span>{getText("Medium")}</span></div>
                    </div>
                </div>
            </div>

            {map(marketVehicles, (mv: MarketVehicleType, i: number) => {
                const {similarity, stockDays, distance, dealerName, version, firstRegistration, kilometers, price} = mv;
                const bgColor = ((i % 2) === 0) ? 'bg-white' : "bg-gray-100";

                return (
                    <div key={`radar_${i}`} className={`p-2 border border-gray-200 border-solid border-r-0 border-l-0 border-t-0 ${bgColor}`}>
                        <div className="flex flex-row">
                            <div className="flex flex-col justify-center mr-2 items-center" style={{width: 55}}>
                                <div style={{width: 14, height: 14, borderRadius: 7}} className={`bg-${similarityToColor(similarity)}`}/>
                                <div className="flex flex-row mt-2">
                                    <span className="font-medium text-xs">{`${stockDays} ${getText("days")}`}</span>
                                </div>
                            </div>
                            <div className="w-2/3 flex flex-col justify-center">
                                <div className="flex flex-row text-sm mb-1">
                                    <i className="fal fa-map-marker-alt mr-1"/>{formatKilometers(distance)} - <span className="font-medium">{dealerName}</span>
                                </div>
                                <div className="flex flex-row mb-1">
                                    <span className="text-gray-600">{version}</span>
                                </div>
                                <div className="flex flex-row mb-1">
                                    <span className="text-gray-600">{`${formatDate(firstRegistration)} | ${formatKilometers(kilometers)}`}</span>
                                </div>
                            </div>
                            <div className="w-1/3 flex flex-row items-center justify-end">
                                <span className="text-md font-medium">{formatAmount(price)}</span>
                            </div>
                        </div>
                    </div>
                )
            })}
        </React.Fragment>
    )
};

export default RadarValuation
