import React from 'react';
import ReactSVG from "react-svg/dist/index";
import {forEach, map, startsWith, find, filter} from 'lodash';
import DragAndDrop from "../../inspection/components/common/DragAndDrop";

const SVG_NS = "http://www.w3.org/2000/svg";

const DAMAGE_LOCATION_COLOR = 'red';
const DAMAGE_LOCATION_OPACITY = 0.6;
const DAMAGE_LOCATION_OPACITY_HOVER = 0.4;
const BULLS_EYE_CORRECTION = 33;
const KNOWN_PATH_NAMES = ['path', 'ellipse', 'circle', 'polygon'];

const CustomSvg = ({uniqueId, path, items, currentItem, clickedItem, onClick, onDrop, onBullsEyeMove, onBullsEyeClick, onBullsEyeDrop, translations, clickAllowed, dropAllowed}) => {

    const searchForLocation = (svg, id) => {
        const paths = getSvgElementsByTagNames(svg, KNOWN_PATH_NAMES);
        return find(paths, (path) => parseInt(path.id.split('-')[1]) === id);
    };

    const getSvgElementsByTagNames = (svg, tags) => {
        let elements = [];

        for (let i = 0, n = tags.length; i < n; i++) {
            elements = elements.concat(Array.prototype.slice.call(svg.getElementsByTagName(tags[i])));
            elements = filter(elements, (el) => startsWith(el.id, 'location-') || startsWith(el.id, 'part-') || startsWith(el.id, 'part-text-'))
        }

        return elements;
    };

    const calcSvgMousePosition = (svg, clientX, clientY) => {
        let CTM = svg.getScreenCTM();
        let x = (clientX - CTM.e) / CTM.a;
        let y = (clientY - CTM.f) / CTM.d;

        return {
            x: x,
            y: y
        }
    };

    const createBullsEye = (itemId, locationId, x, y, stroke) => {
        const bullsEye = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
        bullsEye.setAttribute('id', itemId);
        bullsEye.setAttribute('class', 'draggable');
        bullsEye.setAttribute('location', locationId);
        bullsEye.setAttribute("x", (x - BULLS_EYE_CORRECTION).toString());
        bullsEye.setAttribute("y", (y - BULLS_EYE_CORRECTION).toString());
        bullsEye.setAttribute("fill", 'transparent');

        bullsEye.setAttribute("stroke", stroke);
        bullsEye.setAttribute("stroke-width", "0.6");


        const circle1 = document.createElementNS("http://www.w3.org/2000/svg", 'circle');
        circle1.setAttribute("cx", '34');
        circle1.setAttribute("cy", '34');
        circle1.setAttribute("r", "3");
        circle1.setAttribute("fill", stroke);
        circle1.setAttribute("class", "bull");
        circle1.setAttribute('for', itemId);
        bullsEye.appendChild(circle1);

        const circle2 = document.createElementNS("http://www.w3.org/2000/svg", 'circle');
        circle2.setAttribute("cx", '34');
        circle2.setAttribute("cy", '34');
        circle2.setAttribute("r", "8");
        circle2.setAttribute("stroke", stroke);
        circle2.setAttribute("stroke-width", "0.6");
        circle2.setAttribute("fill", "#FFFFFF");
        circle2.setAttribute("fill-opacity", "0");
        circle2.setAttribute("class", "bull");
        circle2.setAttribute('for', itemId);
        bullsEye.appendChild(circle2);

        const circle3 = document.createElementNS("http://www.w3.org/2000/svg", 'circle');
        circle3.setAttribute("cx", '34');
        circle3.setAttribute("cy", '34');
        circle3.setAttribute("r", "13");
        circle3.setAttribute("stroke", stroke);
        circle3.setAttribute("stroke-width", "0.6");
        circle3.setAttribute("fill", "#FFFFFF");
        circle3.setAttribute("fill-opacity", "0");
        circle3.setAttribute("class", "bull");
        circle3.setAttribute('for', itemId);
        bullsEye.appendChild(circle3);

        const circle4 = document.createElementNS("http://www.w3.org/2000/svg", 'circle');
        circle4.setAttribute("cx", '34');
        circle4.setAttribute("cy", '34');
        circle4.setAttribute("r", "18");
        circle4.setAttribute("stroke", stroke);
        circle4.setAttribute("stroke-width", "0.6");
        circle3.setAttribute("fill", "#FFFFFF");
        circle4.setAttribute("fill-opacity", "0");
        circle4.setAttribute("class", "bull");
        circle4.setAttribute('for', itemId);
        bullsEye.appendChild(circle4);
        return bullsEye;
    };
    const createCrossHair = (itemId, locationId, x, y, stroke) => {
        const bullsEye = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
        bullsEye.setAttribute('id', itemId);
        bullsEye.setAttribute('class', 'draggable');
        bullsEye.setAttribute('location', locationId);
        bullsEye.setAttribute("x", (x - BULLS_EYE_CORRECTION).toString());
        bullsEye.setAttribute("y", (y - BULLS_EYE_CORRECTION).toString());
        bullsEye.setAttribute("fill", 'transparent');

        bullsEye.setAttribute("stroke", stroke);
        bullsEye.setAttribute("stroke-width", "0.6");


        const circle1 = document.createElementNS("http://www.w3.org/2000/svg", 'circle');
        circle1.setAttribute("cx", '34');
        circle1.setAttribute("cy", '34');
        circle1.setAttribute("r", "3");
        circle1.setAttribute("fill", "#372C2C");
        circle1.setAttribute("class", "bull");
        circle1.setAttribute('for', itemId);
        bullsEye.appendChild(circle1);

        const circle2 = document.createElementNS("http://www.w3.org/2000/svg", 'circle');
        circle2.setAttribute("cx", '34');
        circle2.setAttribute("cy", '34');
        circle2.setAttribute("r", "8");
        circle2.setAttribute("stroke", stroke);
        circle2.setAttribute("stroke-width", "0.6");
        circle2.setAttribute("fill", "#FFFFFF");
        circle2.setAttribute("fill-opacity", "0");
        circle2.setAttribute("class", "bull");
        circle2.setAttribute('for', itemId);
        bullsEye.appendChild(circle2);

        const circle3 = document.createElementNS("http://www.w3.org/2000/svg", 'circle');
        circle3.setAttribute("cx", '34');
        circle3.setAttribute("cy", '34');
        circle3.setAttribute("r", "13");
        circle3.setAttribute("stroke", stroke);
        circle3.setAttribute("stroke-width", "0.6");
        circle3.setAttribute("fill", "#FFFFFF");
        circle3.setAttribute("fill-opacity", "0");
        circle3.setAttribute("class", "bull");
        circle3.setAttribute('for', itemId);
        bullsEye.appendChild(circle3);

        const circle4 = document.createElementNS("http://www.w3.org/2000/svg", 'circle');
        circle4.setAttribute("cx", '34');
        circle4.setAttribute("cy", '34');
        circle4.setAttribute("r", "18");
        circle4.setAttribute("stroke", stroke);
        circle4.setAttribute("stroke-width", "0.6");
        circle3.setAttribute("fill", "#FFFFFF");
        circle4.setAttribute("fill-opacity", "0");
        circle4.setAttribute("class", "bull");
        circle4.setAttribute('for', itemId);
        bullsEye.appendChild(circle4);

        const line = document.createElementNS("http://www.w3.org/2000/svg", 'path');
        line.setAttribute("stroke", "#372C2C");
        line.setAttribute("stroke-width", "1.2");
        line.setAttribute("stroke-linecap", "square");
        line.setAttribute("d", "M34,13 L34,55");
        line.setAttribute("class", "bull");
        line.setAttribute('for', itemId);
        bullsEye.appendChild(line);

        const line2 = document.createElementNS("http://www.w3.org/2000/svg", 'path');
        line2.setAttribute("stroke", "#372C2C");
        line2.setAttribute("stroke-width", "1.2");
        line2.setAttribute("stroke-linecap", "square");
        line2.setAttribute("d", "M34,13 L34,55");
        line2.setAttribute("transform", "translate(34, 34) rotate(90.000000) translate(-34, -34) ");
        line2.setAttribute("class", "bull");
        line2.setAttribute('for', itemId);
        bullsEye.appendChild(line2);



        return bullsEye;
    };

    const addBullsEye = (damage, path, stroke = '#FFFFFF') => {
        if (damage.x === 0 && damage.y === 0) return;


        const bullsEye = createBullsEye(damage.guid, path.id, damage.x, damage.y, stroke);

        bullsEye.addEventListener('click', () => {
            onBullsEyeClick(damage.guid);
        });

        bullsEye.addEventListener('dragenter', (e) => {
            e.target.setAttribute('name', 'damage-location-for-drop')
        });

        bullsEye.addEventListener('dragleave', (e) => {
            e.target.setAttribute('name', '')
        });

        bullsEye.addEventListener('mouseover', (e) => {
            if (currentItem === null && (clickAllowed || dropAllowed)) {
                bullsEye.style.cursor = 'pointer';
            } else {
                bullsEye.style.cursor = 'not-allowed';
            }

        });



        path.parentNode.setAttribute('fill-opacity', "1");
        path.parentNode.appendChild(bullsEye);
    };

    const addCrossHair = (item, path, stroke = '#ebe134') => {
        if (item.x === 0 && item.y === 0) return;


        const crossHair = createCrossHair(item.id, path.id, item.x, item.y, stroke);

        // crossHair.addEventListener('click', () => {
        //     onBullsEyeClick(damage.id);
        // });

        crossHair.addEventListener('click', () => {
            onBullsEyeClick(item.guid);
        });

        crossHair.addEventListener('dragenter', (e) => {
            e.target.setAttribute('name', 'damage-location-for-drop')
        });

        crossHair.addEventListener('dragleave', (e) => {
            e.target.setAttribute('name', '')
        });

        crossHair.style.cursor = 'move';

        path.parentNode.setAttribute('fill-opacity', "1");
        path.parentNode.appendChild(crossHair);
    };

    const modifyPath = (path, opacity, currentItem = null) => {
        path.style.fill = DAMAGE_LOCATION_COLOR;



        if (opacity === 0) {
            const damaged = path.getAttribute('damaged');
            const clicked = path.getAttribute('clicked');

            if (clicked) {
                path.style.fillOpacity = DAMAGE_LOCATION_OPACITY_HOVER;
            } else if (damaged) {

                if (currentItem && currentItem.locationId === getLocationIdFromString(path.id)) {
                    path.style.fillOpacity = DAMAGE_LOCATION_OPACITY;
                } else {
                    path.style.fillOpacity = 0.6;
                }

            } else {
                path.style.fillOpacity = 0;
            }
        } else {
            path.style.fillOpacity = opacity;
        }


    };

    const getLocationForDrop = () => {
        return document.getElementsByName('damage-location-for-drop')[0];
    };

    const getLocationIdFromString = (idString) => {
        let id = 0;
        try {
            id = parseInt(idString.split('-')[1]);
        } catch (ex) {
        }
        return id;
    };

    const getLocationIdFromAttribute = (location) => {
        return parseInt(location.getAttribute('unique_id'));
    };

    const setCursor = (p) =>{
        if(!clickAllowed && !dropAllowed) {
            p.style.cursor = "not-allowed"
        }else {
            if(clickAllowed){
                p.style.cursor = "crosshair";
            }
            else if(dropAllowed){
                p.style.cursor = "not-allowed"

            }
            else {
                p.style.cursor = "not-allowed"
            }
        }
    };

    return (
        <DragAndDrop highlightOnHover={false}
                     handleDrop={(files, clientX, clientY) => {
                         const location = getLocationForDrop();
                         const position = calcSvgMousePosition(document.getElementById('damage-svg'), clientX, clientY);

                         if (location) {
                             if (location.classList.contains('bull')) {
                                 const itemId = location.getAttribute('for');
                                 onBullsEyeDrop(itemId, position.x, position.y)
                             } else {
                                 const locationId = getLocationIdFromAttribute(location);
                                 onDrop(locationId, files, position.x, position.y);
                             }
                         }

                     }}
                     className="border-none flex w-full h-full items-center justify-center">

            <ReactSVG src={path}
                      renumerateIRIElements={false}
                      className="flex w-full h-full svg-wrapper items-center justify-center"
                // style={{cursor: (clickAllowed) ? 'crosshair' : (dropAllowed) ?'drop' : 'default'}}
                      beforeInjection={svg => {
                          let selectedElement = null;

                          svg.setAttribute('id', 'damage-svg');
                          svg.setAttribute('width', "90%");
                          svg.setAttribute('height', '90%');
                          svg.setAttribute('max-width', "90%");
                          svg.setAttribute('max-height', '90%');



                          const title = document.createElementNS(SVG_NS, 'title');
                          title.textContent = "";
                          svg.appendChild(title);

                          const paths = getSvgElementsByTagNames(svg, KNOWN_PATH_NAMES);
                          map(paths, (p, i) => {

                              if(i === 1) {
                                  p.setAttribute('class', 'damages_vehicle_svg');
                              }

                              p.setAttribute('unique_id', getLocationIdFromString(p.id));

                              if (clickAllowed) {
                                  p.addEventListener('click', (e) => {
                                      const id = getLocationIdFromAttribute(e.target);
                                      const position = calcSvgMousePosition(document.getElementById('damage-svg'), e.clientX, e.clientY);
                                      onClick(id, position.x, position.y);
                                  });


                                  p.addEventListener('mouseover', (e) => {
                                      modifyPath(e.target, (currentItem && currentItem.locationId === getLocationIdFromString(e.target.id)) ? DAMAGE_LOCATION_OPACITY : DAMAGE_LOCATION_OPACITY_HOVER, currentItem);

                                  });
                              }


                              if (dropAllowed) {
                                  p.addEventListener('dragenter', (e) => {
                                      modifyPath(e.target, (currentItem && currentItem.locationId === getLocationIdFromString(e.target.id)) ? DAMAGE_LOCATION_OPACITY : DAMAGE_LOCATION_OPACITY_HOVER, currentItem);
                                      e.target.setAttribute('name', 'damage-location-for-drop')
                                  });

                                  p.addEventListener('dragleave', (e) => {
                                      modifyPath(e.target, 0, currentItem);
                                      e.target.setAttribute('name', '')
                                  });
                              }


                              if (clickAllowed || dropAllowed) {
                                  p.addEventListener('mouseleave', (e) => {
                                      modifyPath(e.target, 0, currentItem, clickedItem);
                                  });
                              }

                             setCursor(p);
                          });

                          const paths_parts_text = getSvgElementsByTagNames(svg, ['text']);

                          map(paths_parts_text, (p_text) => {
                              const locationId = parseInt(p_text.id.split('-')[2]);
                              if (translations) {
                                  const translation = translations[locationId];
                                  const origTextWords = translation.name.split(" ").length;
                                  const translatedWords = translation.text.split(" ").length;

                                  if(origTextWords > 1 && p_text.children.length > 1) {
                                      if(translatedWords === 1){
                                          p_text.firstElementChild.textContent =  translation.text;
                                          p_text.lastElementChild.textContent = "";
                                      }
                                      else {
                                          p_text.firstElementChild.textContent = translation.text.split(" ")[0];

                                          const index = translation.text.indexOf(' ');
                                          p_text.lastElementChild.textContent = translation.text.substring(index + 1, translation.text.length);
                                      }
                                  }
                                  else {
                                      p_text.firstElementChild.textContent = translation.text;
                                  }

                              }
                              p_text.style.fillOpacity = 1;
                          });

                          if (currentItem === null || currentItem === undefined) {

                              svg.addEventListener('drop', (e) => {
                                  e.preventDefault();
                              });


                              forEach(items, (item) => {
                                  try {
                                      const path = searchForLocation(svg, item.locationId);
                                      path.setAttribute('damaged', true);
                                      modifyPath(path, DAMAGE_LOCATION_OPACITY);
                                      if (item.item === uniqueId || item.item === '') {
                                          addBullsEye(item, path, '#090503');
                                      }
                                  } catch (ex) {
                                  }
                              });


                          } else {

                              if (clickedItem !== null) {
                                  const path = searchForLocation(svg, clickedItem.locationId);
                                  path.setAttribute('clicked', true);

                                  if (currentItem.locationId !== clickedItem.locationId) {
                                      modifyPath(path, DAMAGE_LOCATION_OPACITY_HOVER);
                                  }

                                  addCrossHair(clickedItem, path);

                              }

                              svg.addEventListener('mousedown', (evt) => {
                                  if (currentItem !== null || currentItem !== undefined) {
                                      if (currentItem.id === evt.target.parentNode.id && evt.target.classList.contains('bull')) {
                                          selectedElement = evt.target.parentElement;
                                      }
                                  }

                              });

                              svg.addEventListener('mouseup', () => {
                                  if (selectedElement !== null) {
                                      onBullsEyeMove(selectedElement.id, currentItem.locationId, (selectedElement.x.baseVal.value + BULLS_EYE_CORRECTION), (selectedElement.y.baseVal.value + 33));
                                      selectedElement = null;
                                  }
                              });

                              svg.addEventListener('mouseleave', () => {
                                  if (selectedElement !== null) {
                                      onBullsEyeMove(selectedElement.id, currentItem.locationId, (selectedElement.x.baseVal.value + BULLS_EYE_CORRECTION), (selectedElement.y.baseVal.value + 33));
                                      selectedElement = null;
                                  }
                              });


                              forEach(items, (item) => {
                                  try {
                                      const path = searchForLocation(svg, item.locationId);
                                      path.setAttribute('damaged', true);

                                      modifyPath(path, ((item.locationId === currentItem.locationId) ? DAMAGE_LOCATION_OPACITY : 0.3));
                                      if (item.item === uniqueId || item.item === "") {

                                          if (currentItem.id === item.id) {
                                              addCrossHair(item, path, "#372C2C")
                                          } else {
                                              addBullsEye(item, path, '#bf3530')
                                          }


                                      }


                                      if (currentItem.id === item.id) {
                                          path.addEventListener('mousemove', (evt) => {
                                              if (selectedElement) {

                                                  if (evt.currentTarget.id === selectedElement.getAttribute('location')) {
                                                      evt.preventDefault();

                                                      const {x, y} = calcSvgMousePosition(svg, evt.clientX, evt.clientY);
                                                      selectedElement.setAttributeNS(null, "x", (x - BULLS_EYE_CORRECTION).toString());
                                                      selectedElement.setAttributeNS(null, "y", (y - BULLS_EYE_CORRECTION).toString());
                                                  }
                                              }
                                          });
                                      }


                                  } catch (ex) {
                                  }
                              });
                          }
                      }}
            />

        </DragAndDrop>
    )
};

CustomSvg.defaultProps = {
    clickedItem: null,
    createNewAllowed: false,
};

export default CustomSvg;