import gql from "graphql-tag";

export const INSPECTION_FULL = gql`
query Inspection($inspectionId: Int!) {
    checkGroups {
        id
        description
        checks {
          id
          description
          icon
          type
          mandatory
        }
      }
      
    interiorColors {
        id
        hex
        description
      }
      exteriorColors {
        id
        hex
        description
    }


    inspection(id: $inspectionId) {        
        id
        inspectionDate
        vehicle {
          id
          makeId
          make
          modelGroupId
          modelGroup
          modelId
          model
          versionId
          version
          vin
          co2
          registration
          powerKw
          powerHp
          powerFiscal
          firstRegistration
          reference
          kilometers
          seats
          doors
          colorInteriorId
          colorInterior
          colorExteriorId
          colorExterior
          commentPositive
          commentNegative
          commentInternal
          fuelId
          fuel
          gearboxId
          gearbox
          emissionNormId
          emissionNorm
          engineId
          engine
          engineCc
          bodyId
          body
          wheelFormulaId
          wheelFormula
          catalogPrice
          catalogPriceWithOptions          
        }
        noDamages
        damages {
          id
          location
          locationId
          type
          typeId
          severity
          severityId
          item
          comment
          x
          y
          pictures {
            id
            thumbnailUrl
            url
          }
        }
        pictures {
          id
          url
          thumbnailUrl
          description
        }
        features {
          id
          description
        }
        checks {
          id
          checkTypeId
          value
        }
    }
}`;