import React from 'react';

const ProgressBar = ({percentage, color, width, height}) => {
    return (
        <div className="relative" style={{width: '100%', height: height, borderRadius: 50, backgroundColor: '#DDDACF'}}>
            <Filler percentage={percentage} color={color} />
        </div>
    )
};

const Filler = ({percentage, color}) => {
    return <div className="h-full"  style={{ width: `${percentage}%`, borderRadius: 'inherit', transition: 'width .2s ease-in', backgroundColor: color}} />
};

export default ProgressBar;
