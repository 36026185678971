import React, {useState} from 'react';
import {default as BaseMaskedInput} from "../base/MaskedInput";
import * as PropTypes from "prop-types";
import LabelContainer from "./LabelContainer";

const MaskedInput = ({name, label, value, required, placeholder, disabled, onChange, className, inputClassName, showBorder, error, hasError}) => {
    const [hasFocus, setHasFocus] = useState(false);

    return (
        <LabelContainer label={label}
                        disabled={disabled}
                        className={className}
                        error={error}
                        hasError={hasError}
                        hasValue={value !== ""}
                        onFocus={() => setHasFocus(true)}
                        onBlur={() => setHasFocus(false)}>

            <BaseMaskedInput name={name}
                             value={value}
                             required={required}
                             placeholder={placeholder}
                             disabled={disabled}
                             onChange={onChange}
                             className={inputClassName}
                             showBorder={showBorder}
                             hasError={hasError}
                             hasFocus={hasFocus}
            />
        </LabelContainer>

    );
};

MaskedInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    value: PropTypes.string.isRequired,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    showBorder: PropTypes.bool,
    error: PropTypes.string,
    hasError: PropTypes.bool,
};

MaskedInput.defaultProps = {
    name: "TextInput",
    label: "Label",
    value: "",
    required: false,
    placeholder: "",
    disabled: false,
    onChange: null,
    className: "",
    inputClassName: "",
    showBorder: true,
    error: "",
    hasError: false,
};

export default MaskedInput;


