import React, {Component} from 'react';
import BaseButton from './BaseButton';
import {ButtonTypes} from './BaseButton';

class TextButton extends Component {

    getColors = (type) => {
        const {active} = this.props;
        switch (type) {

            case ButtonTypes.primary:
                return {
                    bgColor: 'blue-500',
                    bgHoverColor: 'blue-400',
                    textColor: 'white',
                    textHoverColor: 'white',
                    borderColor: 'blue-500',
                    borderHoverColor: 'blue-400',
                    iconColor: 'white',
                    font: 'font-medium',
                };
            case ButtonTypes.primary_outline:
                return {
                    bgColor: 'white',
                    bgHoverColor: 'blue-500',
                    textColor: 'blue-500',
                    textHoverColor: 'white',
                    borderColor: 'blue-500',
                    borderHoverColor: 'blue-500',
                    iconColor: 'white',
                    font: 'font-medium',
                };
            case ButtonTypes.accept:
                return {
                    bgColor: 'green-400',
                    bgHoverColor: 'green-500',
                    textColor: 'white',
                    textHoverColor: 'white',
                    borderColor: 'green-400',
                    borderHoverColor: 'green-500',
                    iconColor: 'white',
                    font: 'font-medium',
                };
            case ButtonTypes.accept_outline:
                return {
                    bgColor: 'white',
                    bgHoverColor: 'green-500',
                    textColor: 'green-500',
                    textHoverColor: 'white',
                    borderColor: 'green-500',
                    borderHoverColor: 'green-500',
                    iconColor: 'white',
                    font: 'font-medium',
                };
            case ButtonTypes.reject:
                return {
                    bgColor: 'orange-200',
                    bgHoverColor: 'orange-300',
                    textColor: 'orange-700',
                    textHoverColor: 'orange-700',
                    borderColor: 'orange-200',
                    borderHoverColor: 'orange-200',
                    iconColor: 'white',
                    font: 'font-medium',
                };
            case ButtonTypes.reject_outline:
                return {
                    bgColor: 'white',
                    bgHoverColor: 'orange-700',
                    textColor: 'orange-700',
                    textHoverColor: 'white',
                    borderColor: 'orange-700',
                    borderHoverColor: 'orange-700',
                    iconColor: 'white',
                    font: 'font-medium',
                };
            default:
                return {
                    bgColor: (active) ? 'blue-400' : 'white',
                    bgHoverColor: (active) ? 'blue-400' : 'gray-200',
                    textColor: (active) ? 'white' : 'gray-800',
                    textHoverColor: (active) ? 'white' : 'black',
                    borderColor: (active) ? 'blue-400' : 'gray-400',
                    borderHoverColor: (active) ? 'blue-400' : 'gray-200',
                    iconColor: 'black',
                    font: 'font-thin',

                };
        }
    };

    render() {
        const {type} = this.props;
        const colors = this.getColors(type);

        return (
            <BaseButton {...this.props}
                // textColor={color}
                // textHoverColor="white"
                // borderColor={color}
                // borderHoverColor={color}
                // iconColor={color}
                // bgColor="white"
                // bgHoverColor={color}
                        bgColor={colors.bgColor}
                        bgHoverColor={colors.bgHoverColor}
                        textColor={colors.textColor}
                        textHoverColor={colors.textHoverColor}
                        borderColor={colors.borderColor}
                        borderHoverColor={colors.borderHoverColor}
                        iconColor={colors.iconColor}
                        font={colors.font}
                        loading={this.props.loading}

            />
        );
    }
}

export default TextButton;

