import React, {useState} from 'react';
import {DrawerManager, ErrorIndicator, LoadingIndicator} from "../../../general";
import {map, orderBy, find, values} from 'lodash';
import {InspectionDrawer} from "../inspection";
import {LEADS_QRY, TEMP_INSPECTIONS_QRY} from "./queries";
import DetailDrawer from "./Detail";
import {useMutation, useQuery, useSubscription} from "@apollo/react-hooks";


import Statuses from "./components/Statuses";
import {useTranslate} from "../../../translations";
import SellerCard from "./components/SellerCard";
import moment from "moment";

import {get} from 'lodash';
import {dataToLeadCardData} from "./dto";
import {TEMP_INSPECTION_OVERVIEW_SUBSCRIPTION} from "../inspection/subscriptions";
import {useLeadStates} from "./hooks";

export const DRAWER_TYPES = {
    INSPECTION_WIZARD: "INSPECTION_WIZARD",
    INSPECTION_DETAIL: "INSPECTION_DETAIL"
};
const DrawerManagerConfig = {
    [DRAWER_TYPES.INSPECTION_WIZARD]: {component: InspectionDrawer},
    [DRAWER_TYPES.INSPECTION_DETAIL]: {component: DetailDrawer},
};


const Overview = (props) => {
        const [currentDrawer, setCurrentDrawer] = useState(null);
        const {leadStates, leadStatesByDisplayState} = useLeadStates();
        const {getText} = useTranslate();

        const [filterState, setFilterState] = useState(leadStates.new.key);

        const {data: leadsData, loading: leadsFetchLoading, error: leadsFetchError} = useQuery(LEADS_QRY, {
            fetchPolicy: 'network-only',
            variables: {leadFilters: {onlyForMe: true, states: leadStatesByDisplayState(filterState)}}
        });

        const {data: tempInspectionsData, loading: tempInspectionsFetchLoading, error: tempInspectionsFetchError} = useQuery(TEMP_INSPECTIONS_QRY, {
            fetchPolicy: 'network-only',
            skip: filterState !== leadStates.new.key,
        });

        const {data: subscriptionData, loading: subscriptionLoading} = useSubscription(TEMP_INSPECTION_OVERVIEW_SUBSCRIPTION, {
            variables: {tempInspectionIds: [888]},

        });


        const handleNewTakeOver = () => {
            setCurrentDrawer({
                name: DRAWER_TYPES.INSPECTION_WIZARD,
                params: {
                    id: null,
                }
            });
        };

        const handleEditInspection = (id) => {
            setCurrentDrawer({
                name: DRAWER_TYPES.INSPECTION_WIZARD,
                params: {
                    id,
                }
            });
        };

        const handleEditLead = (id) => {
            const lead = find(leadsData.leads, (lead) => lead.id === id);
            setCurrentDrawer({name: DRAWER_TYPES.INSPECTION_DETAIL, params: {lead}});
        };


        const handleCardClick = (id, type) => {
            if (type === "lead") {
                handleEditLead(id)
            } else if (type === "tempInspection") {
                handleEditInspection(id)
            }
        };

        const renderData = () => {
            if (leadsFetchLoading || tempInspectionsFetchLoading) {
                return <LoadingIndicator/>;
            }

            const data = dataToLeadCardData((tempInspectionsData) ? tempInspectionsData.tempInspections : [], leadsData.leads);

            return map(data, (data) => {
                return (
                    <SellerCard key={data.id}
                                {...data}
                                onClick={handleCardClick}/>
                )
            })
        };


        return (
            <div className="w-full p-2">

                <div className="flex flex-row">
                    <div className="flex flex-col bg-green-500 text-white justify-center p-2 cursor-pointer"
                         onClick={handleNewTakeOver}>
                        <div className="flex justify-center text-2xl mb-1">
                            <i className="fal fa-plus"></i>
                        </div>
                        <div className="flex justify-center ">
                            {getText("Takeover")}
                        </div>
                    </div>

                    <Statuses activeStatus={filterState}
                              onStatusClick={(code) => setFilterState(code)}
                    />
                </div>

                <div className="flex flex-wrap mt-2">
                    {renderData()}
                </div>

                {currentDrawer !== null &&
                <DrawerManager config={DrawerManagerConfig}
                               current={currentDrawer.name}
                               params={currentDrawer.params}
                               onCurrentDrawerClose={() => setCurrentDrawer(null)}
                />}


            </div>
        )
    }
;

export default Overview;
