import React, {useEffect, useState} from 'react';
import DropZone from "../../inspection/components/common/DropZone";
import {filter, slice, includes, isEqual, forEach, map, find, values, sortBy} from 'lodash';
import DragAndDrop from "../../inspection/components/common/DragAndDrop";
import {
    pictureDelete,
    picturesAdd, pictureSetFileId,
    pictureSetSelected, pictureSetUrl,
    picturesFromFiles, picturesSetRef
} from "./data";

import Image from "../../inspection/components/common/Image";
import UUID from 'uuid';
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import {useTranslate} from "../../../../translations";
import gql from "graphql-tag";
import * as Sentry from "@sentry/browser";
import {UPLOAD_FILE} from "../../../common/mutations";
import {UPLOADED_FILE} from "../../../common/queries";


let fileSelector;


const Pictures = ({pictures, pictureTypes, dispatch}) => {
    const [fileSelectorBlock, setFileSelectorBlock] = useState(null);
    const [currentDraggedPictures, setCurrentDraggedPictures] = useState([]);
    const [uploadFiles] = useMutation(UPLOAD_FILE);
    const [uploadFailure, setUploadFailure] = useState(false);
    const {getText} = useTranslate();

    useEffect(() => {
        fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('multiple', 'multiple');

        fileSelector.addEventListener('change', (e) => {
            handleDropOnPictureTypeByDrag(e.target.files, fileSelectorBlock);
            setFileSelectorBlock(null);
        });
    });

    useEffect(() => {
        if (fileSelectorBlock !== null) {
            fileSelector.click();
        }
    }, [fileSelectorBlock]);

    const handleNewFiles = (files, refId = null, refType = "") => {
        const new_pictures = picturesFromFiles(files, refId, refType);

        if (uploadFailure) {
            setUploadFailure(false);
        }

        picturesAdd(dispatch, new_pictures);

        forEach(new_pictures, (picture) => {
            uploadFiles({
                variables: {
                    data: {
                        transactionId: UUID.v4(),
                        file: picture.file,
                        guid: picture.guid
                    }
                }
            }).then((result) => {
                const {fileUploadHandler} = result.data;
                if (fileUploadHandler.result.success) {
                    const {fileId} = fileUploadHandler.result;

                    pictureSetFileId(dispatch, picture.guid, fileId);

                } else {
                    pictureDelete(dispatch, picture.guid);
                    setUploadFailure(true);
                }
            }).catch((error) => {
                Sentry.captureException(error);
                pictureDelete(dispatch, picture.guid);
                setUploadFailure(true);
            });
        });
    };

    const onDragPicture = (event, pictureId) => {
        const selectedPictures = map(filter(pictures, (p) => p.selected), (p) => p.guid);

        if (selectedPictures.length > 0) {
            if (currentDraggedPictures.length === 0 || !isEqual(currentDraggedPictures.sort(), selectedPictures.sort())) {
                setCurrentDraggedPictures(selectedPictures);
            }
        } else {
            if (!includes(currentDraggedPictures, pictureId)) {
                setCurrentDraggedPictures([pictureId]);
            }
        }
    };


    const handleDropOnPictureTypeByDrag = (files, block) => {
        handleDrop(block, files, currentDraggedPictures)
    };

    const handleDropOnPictureTypeByClick = (block) => {
        const selectedPictures = map(filter(pictures, (p) => p.selected), (p) => p.guid);

        if (selectedPictures.length > 0 && block) {
            handleDrop(block, null, selectedPictures);
            //  addOtherToPictureTypes(locationId);
        }
    };

    const handleDrop = (block, files, pictureIds) => {
        if ((block.type.id !== null && (pictures.length !== 0 || files))) {


            if (files) {
                if (files.length > 0 && !block.multi) {
                    handleNewFiles([files[0]], block.type.id, 'pictureType');
                    handleNewFiles([...slice(files, 1, files.length)], 0, '');
                } else {
                    handleNewFiles(files, block.type.id, 'pictureType');
                }

            } else {
                const otherType = find(pictureTypes, (pt) => pt.key === 'other');

                if (block.type.id !== otherType.id) {
                    // Check if pictureType allready has a picture?
                    const currentPicture = find(pictures, (p) => (p.refId === block.type.id && p.refType === 'pictureType'));

                    //check if dropped picture has a picturetype
                    const droppedPicture = find(pictures, (p) => p.guid === pictureIds[0]);

                    if (currentPicture !== undefined && (droppedPicture !== undefined && droppedPicture.refId > 0 && droppedPicture.refType === 'pictureType')) {
                        picturesSetRef(dispatch, [currentPicture.guid], droppedPicture.refId, droppedPicture.refType);
                        picturesSetRef(dispatch, [droppedPicture.guid], currentPicture.refId, currentPicture.refType);
                    } else if (currentPicture !== undefined && (droppedPicture !== undefined && droppedPicture.refId === 0 && droppedPicture.refType === '')) {

                        picturesSetRef(dispatch, [currentPicture.guid], null, "");
                        picturesSetRef(dispatch, [droppedPicture.guid], block.type.id, 'pictureType');
                    } else {
                        picturesSetRef(dispatch, pictureIds, block.type.id, 'pictureType');
                    }
                } else {
                    // Check if pictureType allready has a picture?


                    //check if dropped picture has a picturetype
                    const dropPic = pictures[pictureIds[0]];

                    if (block.picture !== null) {
                        picturesSetRef(dispatch, [block.picture.guid], dropPic.refId, dropPic.refType);
                        picturesSetRef(dispatch, [dropPic.guid], block.type.id, 'pictureType');
                    } else {
                        picturesSetRef(dispatch, pictureIds, block.type.id, 'pictureType');
                    }
                }
            }
            setCurrentDraggedPictures([]);
        }
    };

    const handleDeletePicture = (pictureId) => {
        pictureDelete(dispatch, pictureId);
    };

    const handleRemovePictureFromPictureType = (pictureId) => {
        picturesSetRef(dispatch, [pictureId], null, "");
    };

    const createBlocksToRender = () => {
        const blocks = [];
        const otherType = find(pictureTypes, (pt) => pt.key === 'other');

        forEach(pictureTypes, (type) => {
            if (type.id !== otherType.id) {
                blocks.push({
                    uniqueId: UUID.v4(),
                    type: type,
                    picture: null,
                    multi: false,
                })
            }
        });

        const pics = filter(pictures, (p) => p.refId !== null && p.refType === 'pictureType');
        forEach(pics, (pic) => {
            if (pic.refId !== otherType.id) {
                const block = find(blocks, (b) => b.type.id === pic.refId);
                block.picture = pic;
            } else {
                blocks.push({
                    uniqueId: UUID.v4(),
                    type: otherType,
                    picture: pic,
                    multi: true,
                })
            }
        });


        blocks.push({
            uniqueId: UUID.v4(),
            type: otherType,
            picture: null,
            multi: true,
        });

        return sortBy(blocks, ['type.priority']);
    };

    const handleFetchedData = (data) => {
        const {uploadIdentification, fullUrl, thumbnailUrl} = data;

        pictureSetUrl(dispatch,
            uploadIdentification,
            fullUrl,
            thumbnailUrl,
        );
    };


    const otherType = find(pictureTypes, (pt) => pt.key === 'other');
    const blocks = createBlocksToRender();


    return (
        <div className="w-full h-full">

            <DropZone pictures={filter(values(pictures), (p) => p.refId === null)}
                      canDrop={currentDraggedPictures.length === 0}
                      highlightOnHover={currentDraggedPictures.length === 0}
                      onDrop={(files) => handleNewFiles(files)}
                      onDropZoneClick={() => null}
                      onDragPicture={onDragPicture}
                      onDragPictureEnd={() => setCurrentDraggedPictures([])}
                      onClickPicture={(pictureId) => pictureSetSelected(dispatch, pictureId)}
                      onDeletePicture={(pictureId) => pictureDelete(dispatch, pictureId)}
                      onDataFetched={handleFetchedData}
            />

            {uploadFailure &&
            <div
                className="bg-orange-200 border border-orange-400 text-orange-800 h-6 pl-2 flex items-center mb-2 text-xs font-medium">
                {getText('Picture upload failed, please try again')}
            </div>}


            <div className="flex flex-row flex-wrap">
                {map(blocks, (block, i) => {

                    const selectedPictures = filter(pictures, (p) => p.selected);
                    const canDrop = (selectedPictures.length <= 1 && block.type.id !== otherType.id) || (selectedPictures.length >= 0 && block.type.id === otherType.id);

                    return (
                        <div key={i} className="w-1/4 cursor-pointer" style={{maxHeight: 170}} onClick={(e) => {
                            const selectedPictures = filter(pictures, (p) => p.selected);
                            if (block.picture === null && selectedPictures.length === 0) {
                                e.preventDefault();
                                setFileSelectorBlock(block);
                            }
                        }}>
                            <div
                                className={`bg-white relative border border-white hover:border-gray-300 flex flex-1 flex-col ${(((i + 1) % 4) === 0) ? (i <= 3) ? 'mt-0' : 'mt-1' : (i <= 3) ? 'mt-0 mr-1' : 'mt-1 mr-1'}`}>
                                <div className="w-full " style={{height: 113}}>

                                    <DragAndDrop
                                        handleDrop={(files) => handleDropOnPictureTypeByDrag(files, block)}
                                        onClick={() => handleDropOnPictureTypeByClick(block)}
                                        className="justify-center items-center"
                                        canDrop={canDrop}>

                                        {block.picture !== null &&
                                        <Image picture={block.picture}
                                               onDrag={(e) => onDragPicture(e, block.picture.guid)}
                                               onDragEnd={() => setCurrentDraggedPictures([])}
                                               onClick={(e, id) => {
                                               }}
                                               onRemove={handleRemovePictureFromPictureType}
                                               showDeleteButton={true}
                                               onDelete={(id) => handleDeletePicture(id)}
                                               selectAllowed={false}
                                               height={110}
                                               width={156}
                                               onDataFetched={handleFetchedData}
                                        />}

                                        {block.picture === null &&
                                        <img src={require(`../../../../PUBLIC/picturetypes/${block.type.key}.svg`)}
                                             style={{maxHeight: 110}}
                                             alt={block.type.name}
                                             draggable={false} className="w-full"/>
                                        }

                                    </DragAndDrop>

                                </div>

                                {block.type.mandatory && block.picture === null &&
                                <div
                                    className={`flex justify-center items-center rounded-full absolute font-medium bg-blue-200 border border-blue-300 text-gray-700`}
                                    style={{
                                        width: 20,
                                        height: 20,
                                        top: 8,
                                        right: 8,
                                        fontSize: 10,

                                    }}>
                                    <i className="fas fa-asterisk text-blue-500 "/>
                                </div>}

                                <div className={`flex justify-center items-center p-2  bg-gray-200 text-gray-900`}
                                     style={{height: 25}}>
                                    {block.type.description}
                                </div>
                            </div>

                        </div>
                    )
                })}
            </div>

        </div>
    )
};


export default Pictures;

