import React from 'react';
import {Field} from "react-final-form";
import {composeValidators} from '../forms/validators';
import {default as FormSelectionList} from "../forms/SelectionList";

const SelectionList = ({name, label, options, validators, disabled, className, dataTip, dataFor, inputClassName, showBorder}) => {
    return (
        <Field name={name}
               validate={(validators) ? composeValidators(...validators) : false}
               data-tip={dataTip}
               data-for={dataFor}>

            {({input, meta}) => (<FormSelectionList name={name}
                                                    label={label}
                                                    options={input.value}
                                                    disabled={disabled}
                                                    onChange={input.onChange}
                                                    className={className}
                                                    inputClassName={inputClassName}
                                                    showBorder={showBorder}
                                                    error={meta.error}
                                                    hasError={meta.touched && meta.error !== undefined}
                                                    {...input}/>)


            }
        </Field>
    );
};

export default SelectionList;
