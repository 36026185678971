import React from 'react';
import {Field} from "react-final-form";
import {composeValidators} from '../../general/forms/validators';
import InputFieldErrorIndicator from './InputFieldErrorIndicator'
import InputFieldInfoIndicator from './InputFieldInfoIndicator'

const InputField = ({name, type, placeholder, validators, information}) => {
    return (
        <Field name={name}
               type={type}
               validate={(validators) ? composeValidators(...validators) : false}
        >

            {({input, meta}) => {
                return (
                    <div className="relative text-base mt-6 flex flex-row">
                        <input type={type}
                               {...input}
                               placeholder={placeholder ? placeholder : ""}
                               className={`border-2 p-2 border-solid ${(meta.touched && meta.error) ? 'border-red-400' : 'border-gray-500'} text-base w-full rounded`}
                        />

                        {information && (!meta.touched || !meta.error) &&
                        <div className="absolute text-blue-500 top-0 right-0 p-2 text-2xl cursor-pointer">
                            <InputFieldInfoIndicator tooltip={information}/>
                        </div>}

                        {meta.touched && meta.error &&
                        <InputFieldErrorIndicator tooltip={meta.error}/>
                        }

                    </div>

                )
            }


            }
        </Field>
    );
};

InputField.defaultProps = {
    type: 'text',
    information: false
};

export default InputField;

