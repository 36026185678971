import React from 'react';
import ReactTooltip from 'react-tooltip'
import UUID from 'uuid'

const withToolTip = (Component) => {
    class ToolTip extends React.Component {

        getContent(tooltip) {
            if (tooltip && typeof tooltip === 'function') {
                return tooltip();
            }
            return tooltip;
        }

        render() {
            const {tooltip} = this.props;

            if (tooltip === undefined || tooltip === null) {
                return <Component {...this.props }/>
            } else {
                const uniqueId = UUID.v4();

                return (
                    <React.Fragment>
                        <Component
                            {...this.props}
                            dataTip='tooltip'
                            dataFor={uniqueId}
                        />

                        <ReactTooltip id={uniqueId} place="bottom" type="dark" effect="solid">
                            { this.getContent(tooltip)}
                        </ReactTooltip>
                    </React.Fragment>
                );
            }
        }
    }
    return ToolTip;
};

export default withToolTip;
