import gql from "graphql-tag";

export const UPLOAD_FILE = gql`
 mutation FileUploadHandler($data: FileUploadCommand!) {
        fileUploadHandler(data: $data) {
        result {
            transactionId
            fileId         
            success
            errors {
              code
              message
            }
        }
      }
   }
`;
