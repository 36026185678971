import React, {FunctionComponent} from 'react';

type HeaderProps = {
    title: string,
}

const Heading: FunctionComponent<HeaderProps> = ({title, children}) => {
    return (
        <div className="flex flex-row justify-between p-2 border border-gray-200 bg-gray-100 text-blue-500 text-sm font-medium mb-2 items-center ">
            {title}

            <div className="">
                {children}
            </div>
        </div>
    )
};

export default Heading;
