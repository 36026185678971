import gql from "graphql-tag";

const USE_CREDITS = gql`
  mutation UseCredits($id: Int!, $vin: String!) {
    useCredits(id: $id, vin: $vin) {
        ok         
    }
  }
`;


export const mutations = {
    USE_CREDITS: USE_CREDITS,
};
