import React, {Component} from 'react';
import Template from '../common/Template';
import {withMutation} from '../../general'
import gql from "graphql-tag";
import {Form} from 'react-final-form'
import InputField from '../common/InputField';
import {email, required, minLength16, maxLength16 } from '../../general/forms/validators';
import {withNavigation} from "../navigation";
import {compose} from "recompose";

const REGISTER_NEW_USER = gql`
mutation RegisterUserByCompanyKey($input: RegisterUserByCompanyKeyInput!) {
    registerUserByCompanyKey(input: $input) {
        ok 
    }
  }
`;

class Registration extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(input) {
        const {mutation, navigate} = this.props;
        return mutation.mutate({
            variables: {
                input: {
                    companyKey: input.companyKey,
                    email: input.email,
                    firstName: input.firstName,
                    lastName: input.lastName,
                    password: input.password,

                }
            }
        }, (data => {

            if (data.success) {
                navigate.login();
            }
        }))
    };

    render() {
        const {companyKey} = this.props.match.params;
        const {loading} = this.props.mutation;
        const {navigate} = this.props;

        return (
            <Template title="Sign up" subtitle="With an invitation code">
                <Form
                    initialValues={{companyKey}}
                    onSubmit={this.handleSubmit}
                    validate={values => {
                        const errors = {};
                        if (values.password !== values.passwordConfirm) {
                            errors.password = "Passwords are not equal";
                            errors.passwordConfirm = "Passwords are not equal";
                        }
                        return errors;
                    }}
                    render={({handleSubmit, pristine, invalid}) => (
                        <form onSubmit={handleSubmit}>
                            <div className="mt-6">
                                <InputField name="companyKey"
                                            component="input"
                                            placeholder="16 Digit invitation"
                                            information="The unique company key"
                                            validators={[required, minLength16, maxLength16]}
                                />
                            </div>

                            <div className="mt-6">
                                <InputField name="email"
                                            component="input"
                                            placeholder="Your email"
                                            validators={[required, email]}
                                />
                            </div>

                            <div className="mt-6">
                                <InputField name="firstName"
                                            component="input"
                                            placeholder="First Name"
                                            validators={[required]}
                                />
                            </div>

                            <div className="mt-6">
                                <InputField name="lastName"
                                            component="input"
                                            placeholder="Last Name"
                                            validators={[required]}
                                />
                            </div>

                            <div className="mt-6">
                                <InputField name="password"
                                            component="input"
                                            type="password"
                                            placeholder="New password"
                                            validators={[required]}
                                />
                            </div>

                            <div className="mt-6">
                                <InputField name="passwordConfirm"
                                            component="input"
                                            type="password"
                                            placeholder="Confirm password"
                                            validators={[required]}
                                />
                            </div>
                            <div className="text-base mt-6 flex flex-row justify-center items-center">
                                <button type="submit"
                                        className="w-full p-4 rounded bg-blue-500 text-white outline-none shadow-oultine button">
                                    {loading &&
                                    <div className={`lds-ring mr-2 lds-ring-white`}
                                         style={{width: 14, borderColor: 'white'}}>
                                        <div></div>
                                    </div>
                                    }
                                    Sign Up
                                </button>
                            </div>
                            <div className="text-base ml-10 mt-6 mr-10 flex flex-row justify-center items-center">
                                Already have an account? &nbsp;
                                <span className="cursor-pointer" onClick={() => navigate.login()}>Sign in
                                    here</span>
                            </div>
                        </form>
                    )}
                />
            </Template>
        )
    }
}


export default compose(
   withNavigation,
    withMutation({
        mutation: REGISTER_NEW_USER,
        noAuthentication: true,
        successMessage: "Registration completed"
    })
)(Registration);