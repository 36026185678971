import React from 'react';
import './TreeTable.css'
import uuid from 'uuid'
import {CheckBox} from '../../../general/base';

class Header extends React.Component {


    renderCheckBoxesHeader() {
        const {options} = this.props;
        if (!options.checkboxes) return null;

        return (
            <th style={{width: 40, textAlign: 'center', padding: 0}} key={`header_${uuid.v4()}`}>
                <CheckBox disabled="true"></CheckBox>
            </th>
        );
    }

    renderTreeColumnHeader() {
        const {options} = this.props;

        if (options.treeHeader && typeof options.treeHeader === 'function') {
            return (
                <th style={{width: options.width}} key={`header_${uuid.v4()}`}>
                    {options.treeHeader()}
                </th>
            );
        }
        else {
            return (
                <th style={{width: options.width}} key={`header_${uuid.v4()}`}>
                    {options.treeHeader}
                </th>)
        }
    }

    renderColumnHeader(column){
        if (column.header && typeof column.header === 'function') {
            return (
                <th style={{width: column.width}} key={`header_${uuid.v4()}`}>
                    {column.header()}
                </th>)
        } else {
            return (
                <th style={{width: column.width}}  key={`header_${uuid.v4()}`}>
                    {column.header}
                </th>
            )
        }
    }

    render() {
        const {options} = this.props;
        return (
            <thead>
            <tr>

                {this.renderCheckBoxesHeader()}

                {this.renderTreeColumnHeader()}

                {options.columns.map((column) => {
                    return this.renderColumnHeader(column)
                })}

            </tr>
            </thead>
        )
    }
}


export default Header;