import React from 'react';
import {Switch, Route, Router} from 'react-router'
import Authentication from '../authentication/Authentication';
import CompanySelector from '../select-company/CompanySelector'
import Registration from "../registration/Registration";
import {Project} from "../index";
import ForgotPassword from '../pw-forgot/ForgotPassword';
import ResetPassword from '../pw-reset/ResetPassword'
import {paths} from './common';
import {history} from './utils'

const CustomRouter = ({config}) => {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path={paths.LOGIN} component={Authentication}/>,
                <Route exact path={paths.SELECT_COMPANY} component={CompanySelector}/>,
                <Route exact path={paths.REGISTER_WITH_PARAMS} component={Registration}/>,
                <Route exact path={paths.REGISTER_WITH_PARAMS} component={Registration}/>,
                <Route exact path={paths.FORGOT_PASSWORD} component={ForgotPassword}/>,
                <Route exact path={paths.RESET_PASSWORD} component={ResetPassword}/>,
                <Route path={paths.APPLICATION} render={(props) => <Project {...props} config={config}/>}/>
                <Route exact path={paths.LANDING} render={(props) => <Project {...props} config={config}/>}/>
            </Switch>
        </Router>

    )
};

export default CustomRouter;

