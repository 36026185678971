import React from 'react';
import {LoadingIndicator, ErrorIndicator, SuccessIndicator} from '../../../../general';
import gql from "graphql-tag";
import {Mutation} from "react-apollo";
import {getText} from "../../../../translations";

const DELETE_SLOTS = gql`
  mutation deleteSlots($ids: [ID]!) {
    deleteSlots(ids: $ids) {
        ok
    }
  }
`;

class DeleteSlots extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDeleted: false
        }
    }

    handleDelete(deleteSlots) {
        const {ids, onComplete} = this.props;
        deleteSlots({variables: {ids: ids}})
            .then(res => {
                if (res.data.deleteSlots.ok) {
                    this.setState({isDeleted: true}, () => onComplete(getText('"Deleted successfully!"')))
                }
            })
            .catch(err => {
                let error_msg = err.message.replace("GraphQL error: ", '');
                console.log(error_msg);
            })
    }

    render() {
        const {visible, description, onCancel} = this.props;
        if (!visible) return null;

        return (
            <Mutation mutation={DELETE_SLOTS}>
                {
                    (deleteSlots, {loading, error, data}) => {
                        return (
                            <div>
                                {/*<div className="bg-orange-100 border-t border-b border-orange-light text-orange-dark px-4 py-6 -mx-2" role="alert">*/}
                                    {/*<p className="text-sm">{description}</p>*/}
                                {/*</div>*/}


                                <div className="bg-white text-gray-800 border-t border-b border-gray-400 px-4 py-5 -mx-2">
                                    <p className="text-sm font-medium text-left">{description}</p>
                                </div>

                                <div className="text-right mt-6">

                                    <button className="mr-2 text-gray-600 hover:text-black cursor-pointer outline-none focus:outline-none" onClick={onCancel}>{getText("Cancel")}</button>
                                    <button
                                        className="bg-white border border-blue-500 hover:bg-blue-500 hover:text-white text-blue-500 py-2 px-4 rounded text-xs outline-none focus:outline-none"
                                        onClick={this.handleDelete.bind(this, deleteSlots)}>
                                        {loading ? "Deleting..." : this.state.isDeleted ? getText("Deleted") : getText("Yes, I'm sure.")}
                                    </button>
                                </div>
                                {loading && <LoadingIndicator/>}
                                {this.state.isDeleted && <div className="mt-6 -mx-2"><SuccessIndicator/></div>}
                                {error && <div className="mt-6 -mx-2"><ErrorIndicator description={error}/></div>}
                            </div>
                        )
                    }
                }
            </Mutation>
        );
    }
}

export default DeleteSlots;