import React, {Component} from 'react';
import {Drawer, withMutation} from "../../../general";
import {
    Form,
    FormActionButton,
    FormValidators,
    TextInput,
} from '../../../general/forms-final-form'
import UUID from "uuid";
import gql from "graphql-tag";
import arrayMutators from 'final-form-arrays';
import {FieldArray} from 'react-final-form-arrays';
import {getText} from "../../../translations";

const formId = UUID.v4();

const SEND_INVITATIONS = gql`
mutation SendInvitation($input: [SendInvitationInput]!) {
    sendInvitation(input: $input) {
        ok
    }
  }
`;

const initialState = [{email: ""}];

class SendInvitation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emails: initialState
        };

        this.sendInvitations = this.sendInvitations.bind(this);
    }

    sendInvitations({emails}) {
        const {mutation, onClose} = this.props;
        this.setState({emails: emails}, () => {
            mutation.mutate({
                variables: {
                    input: emails
                }
            }, (result) => {
                if (result.success) {
                    onClose()
                }
            })
        })


    }

    render() {
        const {onClose, mutation: {loading, error, success}} = this.props;
        const {emails} = this.state;

        const title = () => (
            <div className="flex flex-grow justify-between items-center">
                <div className="flex flex-row">
                    <FormActionButton formId={formId}>{getText('Send')}</FormActionButton>
                </div>
            </div>
        );


        return (
            <Drawer title={title}
                    onClose={onClose}
                    visible={true}
            >
                <Form id={formId}
                      onSubmit={this.sendInvitations}
                      className="w-full"
                      mutators={{...arrayMutators}}
                      initialValues={{emails}}
                      indicators={{
                          loading: loading,
                          error: (error && error.message) ? error.message : '',
                          success: success
                      }}
                      render={({form}) => {
                          return (

                              <div className="p-2">
                                  <FieldArray name="emails">
                                      {({fields}) => (
                                          fields.map((name, index) => {
                                              const {mutators: {push,}} = form;
                                              return (
                                                  <div className="flex flex-row w-full" key={index}>
                                                      <TextInput name={`${name}.email`}
                                                                 label={getText('Enter email')}
                                                                 placeholder={getText('Enter email where you want to send the invitation')}
                                                                 disabled={loading}
                                                                 validators={[FormValidators.required, FormValidators.email]}
                                                                 className="w-full"
                                                      />
                                                      <div className="flex items-center justify-center mb-1"
                                                           style={{width: "20%"}}>
                                                          <i className="fal fa-user-plus p-1 text-gray-600 cursor-pointer"
                                                             style={{fontSize: 15}}
                                                             onClick={() => push('emails', undefined)}/>
                                                          {fields.length > 1 &&
                                                          <i className="fal fa-user-minus p-1 text-gray-600 cursor-pointer"
                                                             style={{fontSize: 15}}
                                                             onClick={() => fields.remove(index)}/>}
                                                      </div>
                                                  </div>
                                              )
                                          })
                                      )
                                      }
                                  </FieldArray>
                              </div>
                          )
                      }}
                />
            </Drawer>
        );
    }
}

export default withMutation({
    mutation: SEND_INVITATIONS,
    successMessage: getText('Invitations send')
})(SendInvitation);