import React from 'react';
import {withRouter} from "react-router";
import {navigate} from './utils'

const withNavigation = (Component) => {
    class withNavigation extends React.Component {
        render() {
            return <Component navigate={navigate}  {...this.props} />
        }
    }
    return withRouter(withNavigation);
};

export default withNavigation;