import React, {useState} from "react";
import {useTranslate} from "../../../../../translations";
import {TextInput} from "../../../../../general/forms";
import Button from "../../../../../general/action/Button";

const Search = ({onSearch}) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [compPostalCode, setCompPostalCode] = useState("");
    const [formError, setFormError] = useState("");
    const {getText} = useTranslate();

    const handleSearchClick = () => {
        if ((firstName && firstName !== "") || (lastName && lastName !== "") || (companyName && companyName !== "") || (postalCode && postalCode !== "") || (compPostalCode && compPostalCode !== "")) {
            onSearch(firstName, lastName, companyName, (postalCode !== "") ? postalCode : (compPostalCode !== "") ? compPostalCode : "");
            setFormError("");
        } else {
            setFormError(getText("At least one field is required"))
        }
    };

    // const resetIndividual = () => {
    //     setFirstName("");
    //     setLastName("");
    //     setPostalCode("");
    //     setFormError("")
    // };
    //
    // const resetCompany = () => {
    //     setCompanyName("");
    //     setCompPostalCode("");
    //     setFormError("")
    // };

    return (
        <div className="flex flex-col w-full-h-full">

            {formError && formError !== "" &&
            <div className="flex flex-row w-full">
                <div className="bg-red-200 border border-red-500 text-red-800 p-2 mt-1 w-full">
                    {formError}
                </div>
            </div>}

            <div className="flex flex-row w-full">
                <div className="flex flex-col w-1/2 h-full border border-gray-200 border-t-0 border-b-0 border-l-0">

                    <div className="flex flex-row justify-center items-center mt-5">
                        <i className="fad fa-users text-5xl text-blue-500"></i>
                    </div>
                    <div className="flex flex-row justify-center items-center font-medium pt-1">
                        {getText("Individual")}
                    </div>


                    <div className="flex flex-col mt-8 ml-4 mr-4" style={{minHeight: 150}}>

                        <TextInput value={lastName}
                                   onChange={e => setLastName(e.target.value)}
                                   label={getText('Last name')}
                        />
                        <TextInput value={firstName}
                                   onChange={e => setFirstName(e.target.value)}
                                   label={getText('First name')}
                        />

                        <TextInput value={postalCode}
                                   onChange={e => setPostalCode(e.target.value)}
                                   label={getText('Postal code')}
                        />
                    </div>
                </div>

                <div className="flex flex-col w-1/2 h-full">

                    <div className="flex flex-row justify-center items-center mt-5">
                        <i className="fad fa-building text-5xl text-blue-500"></i>
                    </div>
                    <div className="flex flex-row justify-center items-center font-medium pt-1">
                        {getText("Company")}
                    </div>


                    <div className="flex flex-col mt-8 ml-4 mr-4" style={{minHeight: 180}}>
                        <TextInput value={companyName}
                                   onChange={e => setCompanyName(e.target.value)}
                                   label={getText('Company name')}
                        />

                        <TextInput value={compPostalCode}
                                   onChange={e => setCompPostalCode(e.target.value)}
                                   label={getText('Postal code')}
                        />
                    </div>

                </div>
            </div>
            <div className="flex flex-row justify-center mt-2">
                <div className="flex flex-row items-end justify-end ml-4 mr-4">
                    <Button onClick={handleSearchClick}
                            type="primary">
                        {getText('Search')}
                    </Button>
                </div>
            </div>


        </div>
    )


    // return (
    //     <div
    //         className="flex flex-col justify-center absolute bg-white border-t-4 border-blue-500 border-l-0 border-r-0 border-b-0 shadow-lg"
    //         style={{width: 420, height: 165, top: 25, left: 455}}>
    //
    //
    //         <div className="flex flex-1 flex-col p-4 pb-0">
    //             <div className="font-medium text-xl text-black mb-2">{getText('New Takeover')}</div>
    //
    //
    //
    //
    //             <span className="flex justify-end text-gray-900 hover:text-blue-700 my-2 cursor-pointer pt-1"
    //                  >{getText('Build your car manually')}?
    //                         </span>
    //             {/*<Button type="primary" className="w-full text-center mt-2" onClick={onBuildManual}>{getText('Build your car manually')}</Button>*/}
    //         </div>
    //
    //
    //         <div className="flex flex-row items-center  bg-gray-100 p-2 pl-4 pr-4 ">
    //
    //
    //         </div>
    //     </div>
    // )
};

export default Search;