import React, {useState} from 'react';
import {Button} from "../../../../../../../general";
import {useTranslate} from "../../../../../../../translations";
import {formatNumber} from "../../../../../../../general/utils";
import moment from "moment";
import {v4} from 'uuid';
import NumericInput from "../../../../../../../general/forms/NumericInput";
import DatePicker from "../../../../../../../general/forms/DatePicker";

const calcTakeOverPriceByProposal = (proposal) => {
    return calcTotalTakeOverPrice(proposal.amount, proposal.distributorPremium, proposal.marginTransfer);
};

const calcTotalTakeOverPrice = (takeOverPrice, distributorPremium, marginTransfer) => {
    return (takeOverPrice + distributorPremium) - marginTransfer
};

const SellerProposal = ({proposal, onPropose, onAccept, onRefuse, onWithDraw, grayed}) => {
    const {getText} = useTranslate();
    const [expDate, setExpDate] = useState((proposal) ? proposal.validationDate : moment().add(1, 'month').add(1, 'day').format('YYYY-MM-DD'));
    const [takeOverPrice, setTakeOverPrice] = useState((proposal) ? proposal.amount : 0);
    const [distributorPremium, setDistributorPremium] = useState((proposal) ? proposal.distributorPremium : 0);
    const [marginTransfer, setMarginTransfer] = useState((proposal) ? proposal.marginTransfer : 0);
    const [totalTakeOverPrice, setTotalTakeOverPrice] = useState((proposal) ? calcTakeOverPriceByProposal(proposal) : 0);
    const [inputDisabled, setInputDisabled] = useState((proposal) ? true : false);
    const [showValidationErrors, setShowValidationErrors] = useState(false);

    React.useEffect(() => {
        setExpDate((proposal) ? proposal.validationDate : moment().add(1, 'month').add(1, 'day').format('YYYY-MM-DD'));
        setTakeOverPrice((proposal) ? proposal.amount : 0);
        setDistributorPremium((proposal) ? proposal.distributorPremium : 0);
        setMarginTransfer((proposal) ? proposal.marginTransfer : 0);
        setTotalTakeOverPrice((proposal) ? calcTakeOverPriceByProposal(proposal) : 0);
        setInputDisabled((proposal) ? true : false)
    }, [proposal]);

    const handleTakeOverPriceChanged = (value) => {
        setTakeOverPrice(parseInt(value));
        setTotalTakeOverPrice(calcTotalTakeOverPrice(value, distributorPremium, marginTransfer))
    };

    const handleDistributorPremiumChanged = (value) => {
        setDistributorPremium(parseInt(value));
        setTotalTakeOverPrice(calcTotalTakeOverPrice(takeOverPrice, value, marginTransfer))
    };

    const handleMarginTransferChanged = (value) => {
        setMarginTransfer(parseInt(value));
        setTotalTakeOverPrice(calcTotalTakeOverPrice(takeOverPrice, distributorPremium, value))
    };

    const handlePropose = () => {
        if (takeOverPrice <= 0) {
            setShowValidationErrors(true);
        } else {
            const proposal = {
                id: v4(),
                validationDate: expDate,
                amount: takeOverPrice,
                distributorPremium: distributorPremium,
                marginTransfer: marginTransfer,
                status: 'pending',
            };
            setInputDisabled(!inputDisabled);
            onPropose(proposal);
            setShowValidationErrors(false);
        }
    };

    const handleAccept = () => {
        onAccept(proposal.id);
    };

    const handleRefuse = () => {
        onRefuse(proposal.id);
    };

    const handleWithdraw = () => {
        onWithDraw(proposal.id);
    };

    const handlePrint = (id) => {

    };

    const handlePrintConvention = (id) => {

    };


    return (

        <div className=''>

            <div className={`flex flex-col ${(grayed) ? 'bg-gray-100' : 'bg-white'}`}>
                <div className="flex flex-row w-full" style={{height: 30}}>

                    <DatePicker label={getText("Offer valid till")}
                                required={true}
                                onChange={(date) => setExpDate(date)}
                                value={expDate}
                                minDate={new Date()}
                                className={`flex flex-row w-2/3 items-center ${grayed ? 'bg-gray-100' : 'bg-white'}`}
                                showBorder={!inputDisabled}
                                disabled={inputDisabled}
                                valueContainerClassName="w-1/3"
                                textClassName="text-gray-900"
                                inputClassName={`text-right pr-1 ${grayed ? 'bg-gray-100' : 'bg-white'}`}
                                error=""
                                suffix='€'
                                marginBottom={2}
                                disabledOpacity="opacity-100"
                                calendarLocation="right"
                    />
                </div>

                <div className="flex flex-row w-full" style={{height: 30}}>
                    <NumericInput label={getText("Take over price incl.")}
                                  value={takeOverPrice}
                                  onChange={handleTakeOverPriceChanged}
                                  className={`flex flex-row w-2/3 items-center ${grayed ? 'bg-gray-100' : 'bg-white'}`}
                                  showBorder={!inputDisabled}
                                  disabled={inputDisabled}
                                  valueContainerClassName="w-1/3"
                                  textClassName="text-gray-900"
                                  inputClassName={`text-right pr-1 ${grayed ? 'bg-gray-100' : 'bg-white'}`}
                                  error=""
                                  suffix='€'
                                  suffixClassName={`${(grayed) ? 'bg-gray-100' : 'bg-white'}`}
                                  marginBottom={2}
                                  disabledOpacity="opacity-100"
                                  error={(showValidationErrors && takeOverPrice <= 0) ? getText('Take over price is required') : ""}

                    />
                </div>
                <div className="flex flex-row w-full" style={{height: 30}}>
                    <NumericInput label={getText("Distributor premium")}
                                  value={distributorPremium}
                                  onChange={handleDistributorPremiumChanged}
                                  className={`flex flex-row w-2/3 items-center ${grayed ? 'bg-gray-100' : 'bg-white'}`}
                                  showBorder={!inputDisabled}
                                  disabled={inputDisabled}
                                  valueContainerClassName="w-1/3"
                                  textClassName="text-gray-900"
                                  inputClassName={`text-right pr-1 ${grayed ? 'bg-gray-100' : 'bg-white'}`}
                                  error=""
                                  suffix='€'
                                  suffixClassName={`${(grayed) ? 'bg-gray-100' : 'bg-white'}`}
                                  prefix="+"
                                  prefixClassName={`${(grayed) ? 'bg-gray-100' : 'bg-white'}`}
                                  marginBottom={2}
                                  disabledOpacity="opacity-100"
                                  showZeroValue={inputDisabled}

                    />
                </div>
                <div className="flex flex-row w-full" style={{height: 30}}>
                    <NumericInput label={getText("Margin transfer")}
                                  value={marginTransfer}
                                  onChange={handleMarginTransferChanged}
                                  className={`flex flex-row w-2/3 items-center ${grayed ? 'bg-gray-100' : 'bg-white'}`}
                                  showBorder={!inputDisabled}
                                  disabled={inputDisabled}
                                  valueContainerClassName="w-1/3"
                                  textClassName="text-gray-900"
                                  inputClassName={`text-right pr-1 ${grayed ? 'bg-gray-100' : 'bg-white'}`}
                                  error=""
                                  suffix='€'
                                  suffixClassName={`${(grayed) ? 'bg-gray-100' : 'bg-white'}`}
                                  prefix="-"
                                  prefixClassName={`${(grayed) ? 'bg-gray-100' : 'bg-white'}`}
                                  marginBottom={2}
                                  disabledOpacity="opacity-100"
                                  showZeroValue={inputDisabled}
                    />
                </div>

                <div className="flex flex-row w-full bg-gray-100 border border-b-0 border-r-0 border-l-0 border-gray-400 mt-2 pt-2">
                    <div className={`w-full mb-2 flex`}>

                        <div className={`w-2/3 flex justify-end w-full items-center `}>
                            <span className="font-medium">{getText('Your proposal for customer')}</span>
                        </div>

                        <div className={`w-1/3 flex flex-row justify-end`} style={{width: 130}}>
                            <div className="text-base font-medium text-gray-800 pr-1">{(totalTakeOverPrice) ? formatNumber(totalTakeOverPrice) : 0}</div>
                            <div className="font normal text-base pr-1">€</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex bg-gray-100 p-2">
                <div className="w-1/2 flex flex-row justify-start">
                    {proposal &&
                    <Button className="mr-1" onClick={handleWithdraw} style={{width: 30}}>
                        <i className="fal fa-undo"/>
                    </Button>}

                    {proposal && proposal.status === 'pending' &&
                    <Button style={{width: 30}} onClick={handlePrint}>
                        <i className="fal fa-print"/>
                    </Button>}
                </div>

                <div className="flex flex-row w-1/2 justify-end">
                    {proposal && proposal.status === 'pending' &&
                    <React.Fragment>
                        <Button type="reject_outline"
                                className="mr-1"
                                onClick={handleRefuse}>{getText('Refused')}</Button>

                        <Button type="accept_outline"
                                onClick={handleAccept}>{getText('Accepted')}</Button>
                    </React.Fragment>}

                    {!proposal &&
                    <Button type="primary" className="w-full" onClick={handlePropose}>{getText("Propose")}</Button>}

                    {proposal && proposal.status === "accepted" &&
                    <Button type="primary" className="w-full" onClick={handlePrintConvention}> <i className="fal fa-print text-white mr-2 text-sm"/> {getText("Print convention")}</Button>}
                </div>
            </div>
        </div>


    )
};

export default SellerProposal;
