import {GET_SESSION} from "./tags";
import {sessionToStorage, session_default, clearStorage} from './common';

const writeToCache = (field, value, cache) => {
    const { session } = cache.readQuery({ query: GET_SESSION });
    const data = {
        ...session,
        __typename: 'Session',
        [field]: value,
    };
    cache.writeQuery({ query: GET_SESSION, data: {session: data} });
    sessionToStorage(data);
    return data;
};


export const setToken = (_, { token }, { cache }) => {
   return writeToCache('token', token, cache);
};

export const setCompany = (_, { company }, { cache }) => {
    return writeToCache('companyId', company, cache);
};

export const setSubsidiary = (_, { subsidiary }, { cache }) => {
    return writeToCache('subsidiaryId', subsidiary, cache);
};

export const setBrand = (_, { brand }, { cache }) => {
    return writeToCache('brandId', brand, cache);
};

export const setLocale = (_, { locale }, { cache }) => {
    return writeToCache('locale', locale, cache);
};

export const clearSession = (_, __, { cache }) => {
    cache.readQuery({ query: GET_SESSION });
    const data = {
       ...session_default
    };
    cache.writeQuery({ query: GET_SESSION, data: {session: data} });
    clearStorage(data);
    return data;
};

export const resolvers = {
    Mutation: {
        'setToken': setToken,
        'setCompany': setCompany,
        'setSubsidiary': setSubsidiary,
        'setBrand': setBrand,
        'setLocale': setLocale,
        'clearSession': clearSession,
    }
};
