import React from "react";
import {map, slice, orderBy} from "lodash";
import {useTranslate} from "../../../../../../translations";

const Features = ({features}) => {
    const {getText} = useTranslate();

    const featuresOrdered = orderBy(features, 'description');
    
    const triplePoint = Math.ceil(featuresOrdered.length / 3);
    const featuresColumnA = slice(featuresOrdered, 0, triplePoint);
    const featuresColumnB = slice(featuresOrdered, triplePoint, (triplePoint * 2));
    const featuresColumnC = slice(featuresOrdered, (triplePoint * 2));


    return (
        <div className="flex flex-col w-full">
            <div className="flex items-center text-white bg-blue-500 pl-2 text-md h-8 py-2">
                {getText("Features")}
            </div>

            <div className="flex flex-1 flex-row w-full pt-2 pb-2">
                <div className="flex flex-1 flex-col">
                    {features.length > 0 &&
                    map(featuresColumnA, (feature, i) => {
                        return (
                            <div key={feature.id}
                                 className={`flex flex-col justify-center p-1 ${i % 2 !== 0 ? "bg-white" : 'bg-gray-100'}`}
                                 style={{width: 200, height: 30, maxWidth: 200}}>
                                <div className="truncate"
                                     style={{width: 185, maxWidth: 185}}>
                                    <span className="text-gray-900 text-xs ">
                                        {feature.description}
                                    </span>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="flex flex-1 flex-col ">
                    {features.length > 0 &&
                    map(featuresColumnB, (feature, i) => {
                        return (
                            <div key={feature.id}
                                 className={`flex flex-col justify-center p-1 ${i % 2 !== 0 ? "bg-gray-100" : 'bg-white'}`}
                                 style={{width: 200, height: 30, maxWidth: 200}}>
                                <div className="truncate"
                                     style={{width: 185, maxWidth: 185}}>                                                           
                                    <span className="text-gray-900 text-xs "> 
                                    {feature.description}
                                     </span>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="flex flex-1 flex-col">
                    {features.length > 0 &&
                    map(featuresColumnC, (feature, i) => {
                        return (
                            <div key={feature.id}
                                 className={`flex flex-col justify-center  p-1 ${i % 2 !== 0 ? "bg-white" : 'bg-gray-100'}`}
                                 style={{width: 200, height: 30, maxWidth: 200}}>
                                <div className="truncate"
                                     style={{width: 185, maxWidth: 185}}>
                                    <span className="text-gray-900 text-xs ">                                                          
                                        {feature.description}                                                 
                                    </span>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>
        </div>
    )
};

export default Features;