import React, {Component} from 'react';
import {Drawer, MutationButton} from "../../../general";
import gql from "graphql-tag";
import Button from "../../../general/action/Button";
import {getText} from "../../../translations";

const RESET_COMPANY_USER_PASSWORD = gql`
mutation ResetCompanyUserPassword($input: [ResetCompanyUserPasswordInput]!) {
    resetCompanyUserPassword(input: $input) {
        ok
    }
  }
`;

class ResetPassword extends Component {
    render() {
        const {onClose, drawerParams: {users}} = this.props;
        const user_list = users.map(user => {
            return (
                <ul key={user.userId} className="list-disc" >
                    <li className="font-medium">{user.email}</li>
                </ul>
            )
        });

        return (
            <Drawer title="Reset password"
                    onClose={onClose}
                    visible={true}
            >
                <div className="py-4 text-sm">
                    {getText('You are going to reset password for the following users')}: <div className="py-2">{user_list}</div> {getText('Are you sure?')}
                    <div className="mt-4 flex items-end justify-end">
                        <Button onClick={onClose}>{getText('Cancel')}</Button>
                        <MutationButton query={RESET_COMPANY_USER_PASSWORD}
                                        type="accept"
                                        variables={users}
                                        onClick={onClose}
                                        message={getText('Password reset done successfully.')}
                        >
                            {getText('OK')}
                        </MutationButton>
                    </div>
                </div>
            </Drawer>
        );
    }
}

export default ResetPassword;