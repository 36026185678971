import {omit} from "lodash";

export const INITIAL_CHECKS = {

};

const CHECK_TYPES = {
    CHECKS_CHECK_SET_VALUE: 'CHECKS_CHECK_SET_VALUE',
    CHECKS_CHECK_RESET_VALUE: 'CHECKS_CHECK_RESET_VALUE'
};

export const checksReducer = (state, action) => {
    switch (action.type) {

        case CHECK_TYPES.CHECKS_CHECK_SET_VALUE:
            return {
                ...state,
                [action.payload.id]: {
                    checkId: action.payload.id,
                    value: action.payload.value,
                }
            };
        case CHECK_TYPES.CHECKS_CHECK_RESET_VALUE:
            return omit(state, action.payload.id);
        default:
            return state;
    }
};

export const checkSetValue = (dispatch, checkId, value) => {
    dispatch({
        type: CHECK_TYPES.CHECKS_CHECK_SET_VALUE,
        payload: {
            id: checkId,
            value,
        },
    });
};

export const checkReset= (dispatch, checkId) => {
    dispatch({
        type: CHECK_TYPES.CHECKS_CHECK_RESET_VALUE,
        payload: {
            id: checkId,
        },
    });
};