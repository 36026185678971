import React, {useState} from 'react';
import {map} from 'lodash';
import './TriSelect.css';
import UUID from "uuid";

const TriSelect = ({value, items, onChange}) => {
    const [id] = useState(UUID.v4());

    return (
        <div className="flex flex-1 flex-row hove" style={{minHeight: 20}}>
            {map(items, (item, i) => {
                    const is_selected = value === item.value;
                    const bg_color = is_selected ? `bg-${item.color}-400` : "";
                    const text_color = is_selected ? `text-${item.color}-600` : "text-gray-400";
                    let borderClass = "";
                    if(i === 0){
                        borderClass = `border rounded-l hover:border-${item.color}-400 ${is_selected ? `border-${item.color}-400` : "border-gray-400"}`
                    }else if(i === 1){
                        borderClass = `border-t border-b hover:border-${item.color}-400 ${is_selected ? `border-${item.color}-400` : "border-gray-400"}`
                    }else{
                        borderClass = `border rounded-r hover:border-${item.color}-400 ${is_selected ? `border-${item.color}-400` : "border-gray-400"}`
                    }
                    return (
                        <div key={`${id}-${item.name}`} id={`${id}-${item.name}`}
                             className={`flex items-center justify-center relative cursor-pointer ${text_color} hover:text-${item.color}-600 ${bg_color} hover:bg-${item.color}-400 ${borderClass}`}
                             style={{width: 35, float: 'left'}}
                             onClick={(e) => onChange(item.value)}
                        >
                            <div className="flex items-center justify-center" style={{fontWeight: 900, marginBottom: i === 1 ? 1 : 2}}>{item.placeholder}</div>
                        </div>
                    )
                }
            )}
        </div>
    )
};

TriSelect.defaultProps = {
    value: ""
};

export default TriSelect;