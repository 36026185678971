import React, {useState} from 'react';
import {VinSearch, BuyCredits} from "../components/vinSearch";
import {inspectionSetManualInput, inspectionSetVehicleData, wizardSteps} from "../inspectionData";
import {useLazyQuery} from "@apollo/react-hooks";
import {GET_VEHICLE_DATA} from "../queries";
import {LoadingIndicator, ErrorIndicator} from "../../../../general";
import {vehicleSetVin} from "../vehicleData";


const VinSearchStep = ({onNext, data: {dispatch, settings}}) => {
    const [buyCredits, setBuyCredits] = useState(false);
    const [vin, setVin] = useState("");

    const [fetchData, {called: fetchDataCalled, loading: fetchDataLoading, error: fetchDataError}] = useLazyQuery(
        GET_VEHICLE_DATA,
        {
            fetchPolicy: 'network-only',
            onCompleted: result => {

                if (result.vehicleData.length === 1) {
                    const vehicleData = result.vehicleData[0];

                    let stepId = wizardSteps.features.id;
                    if (vehicleData.versionId === 0) {
                        stepId = wizardSteps.version.id;
                    }

                    inspectionSetVehicleData(dispatch, stepId, vehicleData);

                } else if (result.vehicleData.length > 1) {

                    // Meerdere versies, keuze uit versie, maar merk, model, modelgroup zou in principe toch gelijk moeten zijn?
                    const vehicleData = result.vehicleData[0];
                    const data = {
                        vin: vehicleData.vin,
                        makeId: vehicleData.makeId,
                        makeText: vehicleData.makeText,
                        firstRegistration: vehicleData.firstRegistration,
                        modelGroupId: vehicleData.modelGroupId,
                        modelId: vehicleData.modelId,
                        modelText: vehicleData.modelText,
                        powerKw: vehicleData.powerKw,
                    };

                    let stepId = wizardSteps.version.id;
                    inspectionSetVehicleData(dispatch, stepId, data, result.vehicleData);
                    //TODO
                } else {
                    vehicleSetVin(dispatch, vin);
                    inspectionSetManualInput(dispatch);
                }


            }
        }
    );

    const handleBuildByVin = (vin) => {
        setVin(vin);
        fetchData({
            variables: {
                vin
            }
        })

    };

    const handleManualInput = () => {
        inspectionSetManualInput(dispatch);
        onNext();
    }

    if (fetchDataError) {
        return <ErrorIndicator description={fetchDataError.message}/>
    }

    if (fetchDataCalled || fetchDataLoading) {
        return <LoadingIndicator/>;
    }

    if (!buyCredits) {
        return (
            <div className="flex flex-col flex-1 h-full relative bg-white">
                <div className="flex flex-1 justify-center">
                    <img src={require('../svg/Takeover.svg')} alt="" style={{height: 500, marginTop: 50}}/>
                </div>

                <VinSearch onBuildManual={handleManualInput}
                           onBuildByVin={handleBuildByVin}
                           onBuyCredits={() => setBuyCredits(true)}
                    // hasVinCheck={settings.customerSettings.INSPECTION_VIN_DECODER  }
                    // hasDivCheck={settings.customerSettings.INSPECTION_DIV_CHECK}
                           infiniteCredits={settings.customerSettings.INSPECTION_INFINITE_CREDITS}
                           hasVinCheck={true}
                           hasDivCheck={false}
                />
            </div>
        )
    } else {
        return (
            <div className="flex flex-col flex-1 h-full bg-white">
                <BuyCredits onCancel={() => setBuyCredits(false)}/>
            </div>
        )
    }

};

export default VinSearchStep;
