import React, {useState} from 'react';
import {default as BaseNumericInput} from "../base/NumericInput";
import * as PropTypes from "prop-types";
import LabelContainer from "./LabelContainer";

const NumericInput = ({label, value, min, max, required, placeholder, disabled, onChange, className, inputClassName, showBorder, error, labelContainerClassName, valueContainerClassName, textClassName, suffix, suffixClassName, prefixClassName, marginBottom, onBlur, prefix, disabledOpacity, showZeroValue}) => {
    const [hasFocus, setHasFocus] = useState(false);

    return (
        <LabelContainer label={label}
                        disabled={disabled}
                        className={className}
                        error={error}
                        hasError={error !== ""}
                        hasValue={value !== ""}
                        onFocus={() => setHasFocus(true)}
                        onBlur={() => setHasFocus(false)}
                        labelContainerClassName={labelContainerClassName}
                        valueContainerClassName={valueContainerClassName}
                        textClassName={textClassName}
                        marginBottom={marginBottom}
                        disabledOpacity={disabledOpacity}
        >

            <BaseNumericInput
                value={value}
                required={required}
                placeholder={placeholder}
                disabled={disabled}
                onChange={onChange}
                className={inputClassName}
                showBorder={showBorder}
                hasError={error !== ""}
                hasFocus={hasFocus}
                suffix={suffix}
                suffixClassName={suffixClassName}
                onBlur={onBlur}
                prefix={prefix}
                prefixClassName={prefixClassName}
                showZeroValue={showZeroValue}
            />
        </LabelContainer>

    );
};

NumericInput.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    value: PropTypes.string.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    showBorder: PropTypes.bool,
    error: PropTypes.string
};

NumericInput.defaultProps = {
    label: "Label",
    value: "",
    min: 0,
    max: 10,
    required: false,
    placeholder: "",
    disabled: false,
    onChange: null,
    className: "",
    inputClassName: "",
    showBorder: true,
    error: ""
};

export default NumericInput;


