import React from 'react';
import UserHistory from "./UserHistory";
import TraderHistory from "./TraderHistory";
import Heading from "../Heading";
import {useTranslate} from "../../../../../../../translations";

const History = () => {
    const {getText} = useTranslate();

    return (
        <div className="h-full">

            <div className="flex flex-row h-full mr-2">
                <div className={`flex flex-1 flex-col mr-2 p-2 pt-0 bg-white`}>
                    <Heading title={getText('History by user')}/>
                    <UserHistory/>
                </div>

                <div className={`flex flex-1 flex-col ml-2 p-2 pt-0 bg-white`}>
                    <Heading title={getText('History by trader')}/>
                    <TraderHistory/>
                </div>

            </div>
        </div>
    )
};

export default History
