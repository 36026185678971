import React from "react";
import {useServiceWorker} from "../useServiceWorker";

const AppUpdate = () => {
    const {isUpdateAvailable, updateAssets} = useServiceWorker();

    if (!isUpdateAvailable) {
        return null;
    }

    return (
        <div>
            <i className="fas fa-sync text-white cursor-pointer text-xl" onClick={updateAssets}></i>
        </div>
    )
};

export default AppUpdate;