import {keyBy, map, omit} from "lodash";
import UUID from "uuid";

const PICTURE_TYPES = {
    PICTURES_SET: 'PICTURES_SET',
    PICTURES_ADD: 'PICTURES_ADD',
    PICTURES_SET_REF: 'PICTURES_SET_REF',

    PICTURE_SET_FILE_ID: "PICTURE_SET_FILE_ID",
    PICTURE_SET_SELECT: 'PICTURE_SET_SELECT',
    PICTURE_SET_URL: 'PICTURE_SET_URL',
    PICTURE_DELETE: 'PICTURE_DELETE',
};

export const EMPTY_PICTURE = {
    guid: "",
    file: null,
    fileId: null,
    url: null,
    selected: false,
    refId: null,
    refType: null,
    thumbnailUrl: null,
};

export const picturesFromFiles = (files, refId = null, refType = "") => {
    return keyBy(map(files, (f) => ({
        ...EMPTY_PICTURE,
        guid: UUID.v4(),
        file: f,
        refId: refId,
        refType: refType,
    })), 'guid');
};


export const pictureReducer = (state, action) => {
    let modified = "";
    switch (action.type) {


        case PICTURE_TYPES.PICTURES_SET:
            return {
                ...action.payload.pictures,
            };
        case PICTURE_TYPES.PICTURES_ADD:
            return {
                ...state,
                ...action.payload.pictures,
            };
        case PICTURE_TYPES.PICTURES_SET_REF:
            modified = {...state};
            map(action.payload.guids, (guid) => {
                modified = {
                    ...modified,
                    [guid]: {
                        ...state[guid],
                        refId: action.payload.refId,
                        refType: action.payload.refType,
                        selected: false,
                    }
                };
            });
            return modified;

        case PICTURE_TYPES.PICTURE_SET_URL:
            return {
                ...state,
                [action.payload.guid]: {
                    ...state[action.payload.guid],
                    url: action.payload.url,
                    thumbnailUrl: action.payload.thumbnailUrl,
                }
            };

        case PICTURE_TYPES.PICTURE_SET_FILE_ID:
            return {
                ...state,
                [action.payload.guid]: {
                    ...state[action.payload.guid],
                    fileId: action.payload.fileId,
                }
            };

        case PICTURE_TYPES.PICTURE_SET_SELECT:
            return {
                ...state,
                [action.payload.guid]: {
                    ...state[action.payload.guid],
                    selected: !state[action.payload.guid].selected,
                }
            };
        case PICTURE_TYPES.PICTURE_DELETE:
            return omit(state, action.payload.guid);
        default:
            return state;
    }
};


export const picturesAdd = (dispatch, pictures) => {
    dispatch({
        type: PICTURE_TYPES.PICTURES_ADD,
        payload: {
            pictures,
        }
    });
};
export const picturesSetRef = (dispatch, guids, refId, refType) => {
    dispatch({
        type: PICTURE_TYPES.PICTURES_SET_REF,
        payload: {
            guids,
            refId,
            refType,
        }
    });
};

export const pictureSetSelected = (dispatch, guid) => {
    dispatch({
        type: PICTURE_TYPES.PICTURE_SET_SELECT,
        payload: {
            guid,
        }
    });
};

export const pictureSetFileId = (dispatch, guid, fileId) => {
    dispatch({
        type: PICTURE_TYPES.PICTURE_SET_FILE_ID,
        payload: {
            guid,
            fileId,
        }
    });
};


export const pictureSetUrl = (dispatch, guid, url, thumbnailUrl) => {
    dispatch({
        type: PICTURE_TYPES.PICTURE_SET_URL,
        payload: {
            guid,
            url,
            thumbnailUrl,
        }
    });
};
export const pictureDelete = (dispatch, guid) => {
    dispatch({
        type: PICTURE_TYPES.PICTURE_DELETE,
        payload: {
            guid,
        }
    });
};








