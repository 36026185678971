import React from 'react';
import {useTranslate} from "../../../../../../../translations";
import {map, orderBy} from "lodash";
import {formatAmount} from "../../../../../../../general/utils";

const SellerProposalHistory = ({proposals}) => {
    const orderedProposals = orderBy(proposals, 'validationDate', ['desc']);

    return (
        <React.Fragment>
            {map(orderedProposals, (proposal, i) => {
                return (
                    <div key={`proposal_${proposal.id}`} className={`p-2 border border-gray-200 border-solid border-r-0 border-l-0 ${(i !== 0) ? 'border-t-0' : ''}`}>
                        <div className="flex flex-row">
                            <div className="w-1/5 flex flex-row items-center">
                                <div
                                    className={`p-1 px-2 text-white rounded border border-white border-solid capitalize bg-red-400`}>
                                    {proposal.status}
                                </div>
                            </div>
                            <div className="w-4/5 flex flex-row items-center justify-end">
                                <span className="text-md font-medium">{formatAmount((proposal.amount + proposal.distributorPremium) - proposal.marginTransfer)}</span>
                                {/*<div>*/}
                                {/*    <span>{formatDate(negotiation.validationDate)}</span>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                )
            })}
        </React.Fragment>
    )
};

export default SellerProposalHistory;
