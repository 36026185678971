import React from 'react';
import Compounds from './compound/SlotsConfiguration';

import {Route} from 'react-router'


export class Content extends React.Component {
    render() {
        const {match} = this.props;

        return (
            <div className="flex flex-col flex-1">
                <Route exact path={`${match.path}`}
                       render={(props) => <Compounds {...props} {...this.props}/>}/>
                <Route exact path={`${match.path}/compounds`}
                       render={(props) => <Compounds {...props} {...this.props}/>}/>
            </div>
        );
    }
}
