import React, {useState} from 'react';
import * as serviceWorker from './serviceWorker';
import {useInterval} from './general/hooks/useInterval';
import moment from "moment";

const ServiceWorkerContext = React.createContext(null);

// inspired by https://medium.com/@FezVrasta/service-worker-updates-and-error-handling-with-react-1a3730800e6a

export const ServiceWorkerProvider = ({children}) => {
    const [currentServiceWorker, setCurrentServiceWorker] = useState(null);
    const [waitingServiceWorker, setWaitingServiceWorker] = useState(null);
    const [isUpdateAvailable, setUpdateAvailable] = useState(false);
    const [isUpdateAvailableOnRegister, setUpdateAvailableOnRegister] = useState(false);


    useInterval(() => {
        console.log(moment().format("DD/MM/YYYY HH:mm:ss") + " Check every minute for new updates");
        if (currentServiceWorker) {
            currentServiceWorker.update()
        }
    }, 60000);

    React.useEffect(() => {
        serviceWorker.register({
            onRegister: registration => {
                setCurrentServiceWorker(registration);

                if (registration.waiting) {
                    setUpdateAvailableOnRegister(true);
                }
            },
            onUpdate: registration => {
                setWaitingServiceWorker(registration.waiting);
                setUpdateAvailable(true);
            },
            onWaiting: waiting => {
                setWaitingServiceWorker(waiting);
                setUpdateAvailable(true);
            }
        });
    }, []);


    React.useEffect(() => {
        if(isUpdateAvailableOnRegister && waitingServiceWorker !== null) {
            waitingServiceWorker.postMessage({type: 'SKIP_WAITING'});
        }
    }, [isUpdateAvailableOnRegister, waitingServiceWorker]);

    React.useEffect(() => {
        // We setup an event listener to automatically reload the page
        // after the Service Worker has been updated, this will trigger
        // on all the open tabs of our application, so that we don't leave
        // any tab in an incosistent state
        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener('statechange', event => {
                if (event.target.state === 'activated') {
                    window.location.reload();
                }
            });
        }
    }, [waitingServiceWorker]);

    const value = React.useMemo(() => ({
        isUpdateAvailable,
        updateAssets: () => {
            if (waitingServiceWorker) {
                // We send the SKIP_WAITING message to tell the Service Worker
                // to update its cache and flush the old one
                waitingServiceWorker.postMessage({type: 'SKIP_WAITING'});
            }
        }
    }), [isUpdateAvailable, waitingServiceWorker]);

    return (
        <ServiceWorkerContext.Provider value={value}>
            {isUpdateAvailableOnRegister && <div>
                Installing new version
            </div>}

            {!isUpdateAvailableOnRegister && children}
        </ServiceWorkerContext.Provider>
    );
}

// With this React Hook we'll be able to access `isUpdateAvailable` and `updateAssets`
export const useServiceWorker = () => {
    return React.useContext(ServiceWorkerContext);
};