import gql from "graphql-tag";

export const TEMP_INSPECTION_DETAIL_SUBSCRIPTION = gql`
  subscription OnTempInspectionChanged($tempInspectionIds: [Int]!) {
      tempInspectionChanged(tempInspectionIds: $tempInspectionIds) {
        tempInspection {
            id
            guid
            version
            converted
            createdAt
            updatedAt
            brand {
              id
              name
            }
            user {
              id
              firstName
              lastName
              fullName
            }
            vehicle {
              id      
              makeId
              make
              modelGroupId
              modelGroup
              modelId
              model
              versionId
              version
              vin
              co2
              registration
              powerKw
              powerHp
              firstRegistration
              reference
              kilometers
              seats
              doors
              colorInteriorId
              colorInterior
              colorInteriorOrigin
              colorExteriorId
              colorExterior
              colorExteriorOrigin
              commentPositive
              commentNegative
              commentInternal
              title
              fuelId
              fuel
              gearboxId
              gearbox
              emissionNormId
              emissionNorm
              engineId
              engine
              bodyId
              body
              wheelFormulaId
              wheelFormula
              catalogPrice
              catalogPriceWithOptions
            }
            noDamages
            damages {
              id
              locationId
              location
              typeId
              type
              severityId
              severity
              item
              x
              y
            }
            checks {
              checkId
              value
            }
            pictures {
              id
              fileId
              refId
              refType
              url
              thumbnailUrl
            }
            features {
              featureId
              name
            }
            customer {
              id
              origin {
                origin
                key
              }
              individual {       
                title
                culture
                firstName
                lastName
                address {
                  street
                  number
                  box
                  postalCode
                  city
                  countryCode
                }
                telephone
                mobile
                email
              }
              company {        
                name
                vatNumber
                legalForm
                address {
                  street
                  number
                  box
                  postalCode
                  city
                  countryCode
                }
                telephone
                email
              }
              taxation {
                isTaxable
                isCorporation
                taxablePercent
                isDisabledPerson
                isDiplomatic
                hasBelgianRegistration
              }
            }
        }
      }
}`;


export const TEMP_INSPECTION_OVERVIEW_SUBSCRIPTION = gql`
  subscription OnTempInspectionChanged($tempInspectionIds: [Int]!) {
      tempInspectionChanged(tempInspectionIds: $tempInspectionIds) {
        tempInspection {
            id        
            converted
            createdAt
            updatedAt
            company {
              id
              name
            }    
             subsidiary {
              id
              name
            }        
            brand {     
              id
              name
            }    
            user {
              id     
              fullName
            }
            vehicle {    
              id
              vin   
              make      
              model     
              firstRegistration     
              kilometers     
            }       
            customer {
              id     
              name
            }   
            thumbnailPicture    
        }        
      }
}`;
