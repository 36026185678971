import React from 'react';
import Buttons from "./Buttons";
import Summary from "./Summary";
import {FeaturesComponent} from "../../components";
import WizardAnimation from "./WizardAnimation";


const Features = ({onNext, onPrev, onStepClick, wizard, data}) => {
    return <div className="flex h-full w-full">
        <div className="w-1/4">
            <Summary data={wizard} onStepClick={onStepClick}/>
        </div>

        <div className="w-3/4 flex flex-col flex-1 bg-white p-2">
            <div className="flex flex-1 overflow-scroll ">
                <WizardAnimation>
                    <FeaturesComponent featureGroups={data.featureGroups}
                                       selectedFeatures={data.inspection.vehicle.featureIds}
                                       dispatch={data.dispatch}
                                       vin={data.inspection.vehicle.vin}
                    />
                </WizardAnimation>
            </div>

            <Buttons onNext={onNext}
                     onPrev={onPrev}
                     data={wizard}
            />
        </div>
    </div>
};

export default Features;