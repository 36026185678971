import {filter} from 'lodash';

const getApplicationByName = (data, name) => {
    const applications = filter(data, (app) => app.name === name);
    if(applications.length === 1)
        return applications[0];
    else
        return undefined;
};

const getBlockByName = (data, applicationName, blockName) => {
   const application = checkApplicationExists(data, applicationName);
   const blocks = filter(getApplicationByName(data, application.name).blocks, (block) => block.name === blockName);

    if(blocks.length === 1)
        return blocks[0];
    else
        return undefined;
};

export const checkApplicationExists = (applications, name) => {
    const application = getApplicationByName(applications, name);

    if (application !== undefined)
        return application;
    else
        return applications[0];
};


export const checkApplicationBlockExists = (applications, applicationName, blockName) => {
    const block = getBlockByName(applications, applicationName, blockName);

    if (block !== undefined)
        return block;
    else
        return applications[0].blocks[0];
};


