import React from 'react';
import {logout} from "../authentication";
import {useApolloClient} from "@apollo/react-hooks";

const SignOutButton = ({mutation}) => {
    const client = useApolloClient();

    return (
        <div className="mt-2 flex flex-row justify-end items-center cursor-pointer" onClick={() => logout(client)}>
            <React.Fragment>
                Sign Out
                <i className="fal fa-sign-out-alt ml-2"></i>
            </React.Fragment>
        </div>
    )
};

export default SignOutButton;
