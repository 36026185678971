import {gatherConfig as gatherConfigMaster} from './master/start';
import {gatherConfig as gatherConfigExpert} from './expert/start';
import {gatherConfig as gatherConfigOperations} from './operations/start';
import {gatherConfig as gatherConfigRemarketing} from './remarketing/start';
import {gatherConfig as gatherConfigSelfCheck} from './selfcheck/start';
import {gatherConfig as gatherConfigSales} from './sales/start';

export default function gatherConfig() {
    let configs = [];
    configs.push(gatherConfigMaster());
    configs.push(gatherConfigSales());
    configs.push(gatherConfigExpert());
    configs.push(gatherConfigOperations());
    configs.push(gatherConfigRemarketing());
    configs.push(gatherConfigSelfCheck());
    return configs;
}



