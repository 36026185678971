import React, {useState} from "react";

const SelectItem = ({checked, onClick, children}) => {

    const [hovered, setHovered] = useState(false);

    return (
        <div className="flex flex-1">
            <div className="flex  justify-center items-center bg-white ">

                <div className={`${(checked || hovered) ? 'border rounded border-blue-500' : 'border rounded border-white'} m-2 relative`} onMouseEnter={() => !checked ? setHovered(true) : null}
                     onMouseLeave={() => !checked ? setHovered(false) : null}
                     onClick={() => {
                         setHovered(false);
                         onClick();
                     }}>

                    {children}

                    {(checked || hovered) &&
                    <div
                        className={`${'bg-blue-500 border-blue-500'} flex absolute items-center justify-center`}
                        style={{
                            width: 18, height: 18,
                            borderRadius: 9,
                            top: -5,
                            right: -5
                        }}>
                        <span className="fa fa-check text-white" style={{fontSize: 10}}></span>
                    </div>}

                </div>
            </div>
        </div>
    )
};

export default SelectItem;