import {ApolloClient} from 'apollo-client';
import {ApolloLink} from 'apollo-link';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {map} from "lodash";
import {authMiddleware} from './project/authentication'
import UUID from 'uuid';
import gql from 'graphql-tag';
import {resolvers as sessionResolvers} from './project/session/resolvers';
import {initSession, sessionFromStorage} from "./project/session";
import {onError} from 'apollo-link-error';
import {logout} from './project/authentication'
import cogoToast from "cogo-toast";
import {WebSocketLink} from 'apollo-link-ws';
import {split} from 'apollo-link';
import {getMainDefinition} from 'apollo-utilities';
import {inMemoryToken} from "./project/authentication/auth";
import LogCache from 'apollo-cache-logger';

const {createUploadLink} = require('apollo-upload-client');

//export const cache = new LogCache(new InMemoryCache(), { logger: msg => console.log(msg) });
export const cache = new InMemoryCache();

let typeDefs = '';

let resolvers = {
    ...sessionResolvers,
};

export let initial_cache = null;


export function configureCache(config) {
    typeDefs = gql`
        type ApplicationModule {
            id: ID!
            name: String!
            title: String!
            icon: String!
            security: String!
            badgeCount: Int!
        }   
    
        type ApplicationBlock {
            id: ID!
            name: String!
            title: String!
            color: String!
            blocks: [Block!]!
        }            
        
        type Session {   
            id: ID!       
            token: String!    
            companyId: Int!,
            subsidiaryId: Int!,
            brandId: Int,        
            modules: [String]
            roles: [String]
            locale: String!
        }
    
        extend type Query {
            applications: [ApplicationModule]
            currentApp: ApplicationModule
            currentBlock: ApplicationBlock
            session: Session
        }    
    
    `;


    const applications = map(config, (application) => ({
        __typename: 'ApplicationModule',
        id: UUID.v4(),
        name: application.moduleName,
        title: application.mainMenuName,
        color: application.mainMenuColor,
        blocks: map(application.blocks, (block) => ({
            __typename: 'ModuleBlock',
            id: UUID.v4(),
            name: block.name,
            title: block.title,
            icon: block.icon,
            security: block.security,
            badgeCount: block.badgeCount,
        })),
    }));

    initial_cache = {
        applications,
        currentApp: applications[0],
        currentBlock: applications[0].blocks[0],
        session: initSession(),
        authenticated: false,
        testLeadState: 'CONFIRMED',
        testIsManager: false,
    };

    cache.writeData({
        data: initial_cache
    });
}

const httpLink = createUploadLink({
    uri: '/graphql/',
});

const getWsUri = () => {
    if (window.location.hostname === 'localhost') {
        return `ws://localhost:8000/graphql/`;
    } else {
        return `wss://${window.location.hostname}/graphql/`;
    }
};

const wsLink = new WebSocketLink({

    uri: getWsUri(),
    options: {
        reconnect: true,
        lazy: true,
        connectionParams: () => {
            const session = sessionFromStorage();
            let locale = "en";
            let CSB = "";

            if (session !== null && session !== undefined) {
                locale = session.locale;

                if (session.companyId !== null && session.subsidiaryId !== null && session.brandId !== null) {
                    CSB = `${session.companyId};${session.subsidiaryId};${session.brandId}`
                }
            }

            return {
                token: inMemoryToken.token,
                CSB: CSB,
                locale: locale,
            }
        }
    }
});

wsLink.subscriptionClient.on("connecting", () => {
    console.log("connecting");
});

wsLink.subscriptionClient.on("connected", () => {
    console.log("connected");
});

wsLink.subscriptionClient.on("reconnecting", () => {
    console.log("reconnecting");
});

wsLink.subscriptionClient.on("reconnected", () => {
    console.log("reconnected");
});

wsLink.subscriptionClient.on("disconnected", () => {
    console.log("disconnected");
});


const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
        graphQLErrors.map((error) => {
            if (error.code && error.code === "401") {
                logout(client, false).then(() => {
                    cogoToast.error('Authorization error', {hideAfter: 5});
                })
            } else if(error.message && error.message !== "") {
                cogoToast.error(error.message);
            }
        })
    }
    if (networkError) {

        // Here you may display a message to indicate network error
        console.log(`[Network error]: ${networkError}`);
    }
});

const link = split(
    // split based on operation type
    ({query}) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink,
);

export const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authMiddleware, link]),
    cache,
    typeDefs,
    resolvers,
    connectToDevTools: true

});





