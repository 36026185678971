import React from 'react';
import Button from '../action/Button';
import * as PropTypes from "prop-types";

const ContainerWithLabel = ({label, disabled, className, error, hasError, children, onFocus, onBlur, hasValue, textClassName, marginBottom, valueContainerClassName, disabledOpacity}) => {

    return (
        <div className={`w-full mb-${marginBottom} bg-white ${disabled ? disabledOpacity : ""} ${className}`}
             onFocus={onFocus}
             onBlur={onBlur}
        >

            <div className={`flex justify-between relative w-full `}>
                <div
                    className={`w-full text ${(textClassName === "") ? (hasValue && !hasError) ? "text-gray-500" : "text-gray-900" : textClassName}`}>
                    {(typeof label === 'function') ? label() : label}
                </div>

                {hasError &&
                <div className="absolute top-0 right-0 z-40">
                    <Button size="sm"
                            type="icon-only"
                            color="red"
                            icon="fal fa-exclamation-square"
                            tooltip={error}
                            className="mr-0"
                    />
                </div>}
            </div>

            <div className={`flex flex-row ${valueContainerClassName}`}>
                {children}
            </div>
        </div>

    );
};

ContainerWithLabel.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    disabled: PropTypes.bool,
    className: PropTypes.string,
    error: PropTypes.string,
    hasError: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    hasValue: PropTypes.bool,
    textClassName: PropTypes.string,
    marginBottom: PropTypes.number,
    valueContainerClassName: PropTypes.string,
    labelContainerClassName: PropTypes.string,
    disabledOpacity: PropTypes.string
};

ContainerWithLabel.defaultProps = {
    label: "Label",
    disabled: false,
    className: "",
    error: "",
    hasError: false,
    onFocus: null,
    onBlur: null,
    hasValue: false,
    textClassName: "",
    marginBottom: 4,
    labelContainerClassName: "",
    valueContainerClassName: "",
    disabledOpacity: 'opacity-60'
};

export default ContainerWithLabel;


