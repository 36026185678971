import React, {useEffect, useState} from 'react';
import {range} from "lodash";
import moment from "moment";
import WizardAnimation from "../wizard/WizardAnimation";
import {useTranslate} from "../../../../translations";

const DateSelector = ({value, onValueChanged, onPrev, onReset}) => {
    const [year, setYear] = useState((value.year !== null) ? value.year : null);
    const [month, setMonth] = useState((value.month !== null) ? value.month : null);
    const [day, setDay] = useState((value.day !== null) ? value.day : null);
    const [error, setError] = useState("");
    const {getText} = useTranslate();

    useEffect(() => {
        if (day !== null && month !== null && year !== null) {
            // let date = new Date(year, month, day);
            let date = new Date(`${year}/${month}/${day}`);

            if (moment(date).isValid()) {
                onValueChanged(day, month, year);
            } else {
                setError('invalid date');
            }
        }
    }, [day, month, year, onValueChanged]);

    const handleDay = (value) => {
        if (day !== value) {
            setDay(value);
        }
    };

    const handleMonth = (value) => {
        if (month !== value) {
            setMonth(value);
        }
    };

    const handleYear = (value) => {
        if (year !== value) {
            setYear(value);
        }
    };


    const days = range(1, 32);
    const months = range(1, 13);
    const years = range(new Date().getFullYear() -29, new Date().getFullYear() + 1).reverse();

    return (
        <div className="w-full flex-col">
            <React.Fragment>
                <div className="flex flex-row justify-between p-2 pl-0 border border-gray-300 border-t-0 border-l-0 border-r-0  font-medium mb-2">
                    <span onClick={onPrev} className="cursor-pointer">
                        <i className="fal fa-chevron-left mr-2 text-xs font-medium"/>{getText('Select year')}
                    </span>
                    <i onClick={onReset} className="fal fa-bolt cursor-pointer" style={{fontSize: 14}}/>
                </div>
                <div className="years flex flex-row w-full flex-wrap mt-4 mb-4">
                    <WizardAnimation className="flex-wrap">
                        {years.map(yr => {
                            const active = year === yr ? "bg-blue-400 border border-blue-400 text-white cursor-default" : "bg-gray-100 text-gray-900 hover:bg-blue-400 hover:text-white cursor-pointer";
                            return (
                                <div key={yr} className="pr-1 w-1/8 mb-1" onClick={() => handleYear(yr)}>
                                    <div className={`flex items-center justify-center p-2 hover:bg-blue-400 hover:border-blue-400 hover:text-white ${active}`}>{yr}</div>
                                </div>
                            )
                        })}
                    </WizardAnimation>
                </div>
            </React.Fragment>


            {year !== null &&

            <React.Fragment>
                <div className="flex flex-row justify-between p-2 pl-0 border border-gray-300 border-t-0 border-l-0 border-r-0  font-medium mb-2">
                    {getText('Select month')}
                </div>

                <div className="months flex flex-row w-full flex-wrap mt-4 mb-4">
                    <WizardAnimation className="flex-wrap">
                        {months.map(mnth => {
                            const active = mnth === month ? "bg-blue-400 border border-blue-400 text-white cursor-default" : "bg-gray-100 text-gray-900 hover:bg-blue-400 hover:text-white cursor-pointer";
                            return (
                                <div key={mnth} className="pr-1 w-1/12 mb-1" onClick={() => handleMonth(mnth)}>
                                    <div className={`flex items-center justify-center p-2 hover:bg-blue-400 hover:border-blue-400 hover:text-white ${active}`}>{mnth}</div>
                                </div>
                            )
                        })}
                    </WizardAnimation>
                </div>
            </React.Fragment>
            }


            {month !== null &&

            <React.Fragment>
                <div
                    className="flex flex-row justify-between p-2 pl-0 border border-gray-300 border-t-0 border-l-0 border-r-0  font-medium mb-2">
                    {getText('Select day')}
                </div>

                <div className="days flex flex-row w-full flex-wrap mt-4 mb-4">
                    <WizardAnimation className="flex-wrap">
                        {days.map(dy => {
                            const active = day === dy ? "bg-blue-400 border border-blue-400 text-white cursor-default" : "bg-gray-100 text-gray-900 hover:bg-blue-400 hover:text-white cursor-pointer";
                            return (
                                <div key={dy} className="pr-1 w-1/12 mb-1" onClick={() => handleDay(dy)}>
                                    <div className={`flex items-center justify-center p-2 hover:bg-blue-400 hover:border-blue-400 hover:text-white ${active}`}>{dy}</div>
                                </div>
                            )
                        })}
                    </WizardAnimation>
                </div>
            </React.Fragment>}

            {error}
        </div>
    )

};

export default DateSelector;