import {Button} from "../../../../general";
import React, {useEffect, useRef} from "react";
import WithAnimation from "../../inspection/components/common/WithAnimation";
import {getText, useTranslate} from "../../../../translations";

export const ConfirmationBox = ({title, type, description, onYes, onNo, onCancel, textYes, inProgress, progressText}) => {
    const {getText} = useTranslate();
    const refYes = useRef(null);
    let color = 'green';
    if(type === 'warning'){
        color = 'orange';
    }

    useEffect(() => {
        document.addEventListener("keydown", _handleKeyDow);
        return () => {
            document.removeEventListener("keydown", _handleKeyDow);
        };
    });

    const _handleKeyDow = (e) => {
        if(e.key === "Escape" || e.key === "Enter") onCancel();
    };

    const handleOnYes = () => {
        const confirmation = {
            title,
            type,
            description,
            onYes,
            onNo,
            onCancel,
            textYes,
            inProgress,
            progressText,
        };

        onYes(confirmation);
    };

    return (
        <WithAnimation className="z-50">
            <div className="flex justify-center items-center w-full absolute left-0 right-0" style={{backgroundColor: "rgba(0, 0, 0, 0.1)", top: "0", bottom: "0"}}>
                <div className={`flex flex-col justify-center bg-white border-4 border-${color}-400 border-l-0 border-r-0 border-b-0 shadow-lg`} style={{width: 300, maxWidth: 300}}>
                    <div className="p-2 pt-0">
                        <div className="py-4 font-medium">{title}</div>
                        <div className="mb-4">{description}</div>
                    </div>
                    <div className="flex flex-row justify-end items-center bg-gray-100 p-2">
                        <Button onClick={onCancel} type="success">{getText('Cancel')} </Button>
                        <Button onClick={handleOnYes} ref={refYes} className="ml-2 cursor-pointer text-center" type="reject"> {textYes}</Button>

                        {/*<Button aap={refProgressHidden} className="opacity-0 absolute" style={{zIndex: -2}}> {progressText}</Button>*/}
                        {/*<Button aap={refYesHidden} className="opacity-0 absolute" style={{zIndex: -2}}> {textYes}</Button>*/}
                    </div>
                </div>
            </div>
        </WithAnimation>
    )
};

ConfirmationBox.defaultProps = {
    textYes: getText('Yes'),
    progressText: getText("Loading..."),
    inProgress: false,
};

export default ConfirmationBox;