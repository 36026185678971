import {navigate} from "../navigation"
import jwt from 'jwt-decode'
import {TOKEN_REVOKE} from "./mutations";
import {GET_SESSION, sessionFromStorage} from "../session";
import {ApolloLink} from "apollo-link";
import {initial_cache} from "../../apollo";
import {clearStorage, session_default} from "../session/common";


export let inMemoryToken = null;

export const setMemoryToken = (token) => {
    if (token == null) {
        inMemoryToken = null;
    } else {
        const payload = jwt(token);

        // Store token in memory
        inMemoryToken = {
            token: token,
            expiry: payload.exp,
        };
    }
};

export const isTokenExpired = (token) => {
    if (inMemoryToken) {
        const expires = new Date(inMemoryToken.expiry * 1000);
        const lessMinute = new Date(expires - 1000 * 60);

        if (lessMinute <= Date.now()) {
            return true;
        }
        return false;
    }
    return null;
};

export const login = (client, user, token, refreshToken) => {
    const {session} = client.readQuery({query: GET_SESSION});

    setMemoryToken(token);

    const data = {
        session: {
            ...session,
            token: refreshToken
        },
        authenticated: true,
    };
    client.writeData({data: data});

    navigate.selectCompany();
};

export const logout = async (client, revokeToken=true) => {
     // clear access token from memory
    setMemoryToken(null);

    if (revokeToken) {
        // revoke refresh token
        const {session} = client.readQuery({query: GET_SESSION});
        const revoked = await client.mutate({
            variables: {
                refreshToken: session.token,
            },
            mutation: TOKEN_REVOKE
        });
    }

    client.clearStore().then(() => {
        navigate.login();
        const initial_data = {
            ...initial_cache,
            session: session_default
        };
        clearStorage();
        client.writeData({data: initial_cache});
    });

};

export const authMiddleware = new ApolloLink((operation, forward) => {

    const session = sessionFromStorage();
    let headers = null;

    if (inMemoryToken) {
        headers = {
            Authorization: `JWT ${inMemoryToken.token}`
        };
    }

    if (session !== null && session !== undefined) {
        const {locale} = session;

        headers = {
            ...headers,
            'Accept-Language': locale,
        };

        if (session.companyId !== null && session.subsidiaryId !== null && session.brandId !== null) {
            headers = {
                ...headers,
                CSB: `${session.companyId};${session.subsidiaryId};${session.brandId}`
            }
        }
    }

    if (headers !== null) {
        operation.setContext({
            headers
        });
    }
    return forward(operation);
});


