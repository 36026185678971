import React from 'react';
import DrawerTemplate from './DrawerTemplate';
import {includes, keys} from 'lodash';

class DrawerManager extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            drawerVisible: (props.current) ? true : false,
        };

        this.closeDrawer = this.closeDrawer.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.current !== "" && nextProps.current === '') {
            this.closeDrawer()
        } else if (this.props.current !== nextProps.current && nextProps.current !== "") {
            this.setState({drawerVisible: true})
        }
    }

    closeDrawer(){
        const {onCurrentDrawerClose} = this.props;
        this.setState({drawerVisible: false}, () => onCurrentDrawerClose(this.props.current))
    }

    getDrawerForm() {
        const {current, config, params} = this.props;
        const propsForComponent = (typeof config[current].params === 'function') ? config[current].params(params) : params;

        if (current !== null && current !== undefined && includes(keys(config), current)) {
            let Component = config[current].component;
            return <Component {...propsForComponent} drawerParams={ {...propsForComponent} } onClose={this.closeDrawer}/>
        } else if(current !== null && current !== undefined) {
            return <DrawerTemplate onClose={this.closeDrawer}/>;
        }
    }

    render() {
        const {drawerVisible} = this.state;
        if (!drawerVisible) return null;

        return (
            <React.Fragment>
                {this.getDrawerForm()}
            </React.Fragment>
        );
    }
}

export default DrawerManager

