import React, {useState} from "react";
import WithAnimation from "../../inspection/components/common/WithAnimation";
import {useTranslate} from "../../../../translations";


const ActiveItem = ({feature, onClick}) => {
    const animationDuration = 350;
    const [isVisible, setIsVisible] = useState(true);

    const onRemove = (feature) => {
        setIsVisible(false);
        setTimeout(() => onClick(feature), animationDuration);
    };

    return (
        <WithAnimation key={feature.id}
                       className="flex flex-1 flex-row mb-2 items-center"
                       animationIn="fadeInLeft"
                       animationOut="fadeOutLeft"
                       animationDuration={animationDuration}
                       isVisible={isVisible}>
            <div className="bg-blue-500 border-blue-500 flex items-center justify-center"
                 style={{width: 12, height: 12, minWidth: 12, borderRadius: 9}}>
                <span className="fa fa-check text-white" style={{fontSize: 6}}/>
            </div>

            <div className="flex flex-1" style={{marginLeft: 5, marginRight: 10}}>
                <div className="flex flex-1 w-full justify-between items-center">
                    <div className="feature-name cursor-default text-gray-900"
                         style={{marginRight: 5}}> {feature.description}</div>

                    <div onClick={() => onRemove(feature)}
                         className={`spin-animation-hover flex items-center justify-center cursor-pointer mr-1`}>
                        <span className="fa fa-times text-gray-500 hover:text-gray-700" style={{fontSize: 14}}/>
                    </div>
                </div>
            </div>
        </WithAnimation>
    )
}

export default ActiveItem;