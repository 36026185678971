import React from 'react';
import {Mutation} from 'react-apollo';
import {handleMutation} from './utils';

import {client} from '../../apollo';

const withMutation = injectedProps => WrappedComponent => {

    const WithMutation = props => {



        return (
            <Mutation
                mutation={(typeof injectedProps.mutation === 'function') ? injectedProps.mutation(props) : injectedProps.mutation}
                update={(injectedProps.update) ? injectedProps.update.bind(this, props) : null}
                awaitRefetchQueries={(injectedProps.awaitRefetchQueries) ? injectedProps.awaitRefetchQueries : false}
                refetchQueries={(injectedProps.refetchQueries) ? injectedProps.refetchQueries(props) : []}
                // client={(injectedProps.noAuthentication) ? publicClient : client}
                client={client}
            >
                {(mutate, data) => {
                    return <React.Fragment>

                        <WrappedComponent
                            mutation={{
                                mutate: (data, cb) => handleMutation(mutate, data, injectedProps, cb),
                                ...data,
                            }}
                            {...props}
                        />

                    </React.Fragment>
                }}
            </Mutation>
        )
    };

    return WithMutation;
};

withMutation.defaultProps = {
    noAuthentication: false,
};

export default withMutation;
