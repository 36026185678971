import React from 'react';

const Logistics = () => {

    return (
        <div>

            <h1>Logistics</h1>

            <p> to discuss</p>

        </div>
    )
};

export default Logistics