import React, {useEffect} from 'react';
import {withSession} from '../session';
import {compose} from "recompose";
import {withNavigation} from '../navigation';
import {logout, setMemoryToken} from "./auth";
import {useInterval} from "../../general/hooks";
import {LoadingIndicator} from "../../general";
import useSession from "../session/useSession";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {isTokenExpired} from './auth';
import gql from "graphql-tag";
import {TOKEN_REFRESH} from "./mutations";

const GET_AUTHENTICATED = gql`
  {
    authenticated @client
  }
`;

const withAuthorization = (securityCondition) => (Component) => {
    const WithAuthorization = (props) => {
        const session = useSession();
        const {data: {authenticated}, client} = useQuery(GET_AUTHENTICATED);
        const [refreshTokenMutation, {loading: refreshTokenLoading, error: refreshTokenError}] = useMutation(TOKEN_REFRESH);

        const tokenRefresh = (token) => {
            console.log("svli-token refresh");
            refreshTokenMutation({
                variables: {
                    refreshToken: token,
                }
            }).then((result) => {
                if (result && result.data && result.data.jwtTokenRefreshHandler) {
                    const token = result.data.jwtTokenRefreshHandler.token;
                    setMemoryToken(token);
                    client.writeData({data: {authenticated: true}})
                }
            })
        };

        useEffect(() => {
            if (!authenticated && session.token) {
                tokenRefresh(session.token)
            } else if (!authenticated && !session.token) {
                logout(client, false)
            }
        }, [authenticated]);

        useInterval(() => {
            const expired = isTokenExpired();

            if (expired) {
                console.log('silent token refresh')
                tokenRefresh(session.token);
            }
        }, 60000);

        return (authenticated && securityCondition(session)) ? <Component {...props} /> : <LoadingIndicator/>;
    };

    return compose(withSession, withNavigation)(WithAuthorization);

};

export default withAuthorization;