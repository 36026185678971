import gql from "graphql-tag";

export const CUSTOMER_CHANGE = gql`
 mutation($data: CustomerChangeCommand!) {
    customerChangeHandler(data: $data) {
      result {
        transactionId   
        customerId      
        success
        errors {
          code
          message
        }
      }
    }
 }        
`;