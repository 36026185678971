import React from 'react';
import {Route} from 'react-router'

function Dashboard () {
    return (
        <div>
           Dashboard full screen with no panel
        </div>
    )
}


export class Content extends React.Component {
    render() {
        const {match} = this.props;

         return (
            <div className="flex-row flex-1 bg-white">
                <Route exact path={`${match.path}`} render={(props) => <Dashboard />}/>

            </div>
        );
    }
}
