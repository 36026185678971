import React, {Component} from 'react';
import withToolTip from '../withToolTip';


class IconButton extends Component {

    render() {
        const {icon, onClick, size: propSize, dataTip, dataFor, className, color} = this.props;

        const size = (propSize === "md") ? 'base' : propSize;

        return (
            <button className={`items-center outline-none focus:outline-none text-${color}-600 hover:text-${color}-800 cursor-pointer ${className}`} onClick={onClick}  data-tip={dataTip}
                    data-for={dataFor}>
                <i className={`${icon} text-${size}`} />
            </button>
        );
    }
}

IconButton.defaultProps = {
    color: 'blue',
    className: ""
};

export default withToolTip(IconButton);

