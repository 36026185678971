import React, {useState} from 'react';
import {withSession} from "../../../../project/session";
import {map, includes, filter, find, orderBy, flatten} from 'lodash';
import {SearchField} from "../../../../general/base";
import ActiveItem from './ActiveItem';
import ListItem from './ListItem';
import FeaturesTabView from "./FeaturesTabView";
import GridItem from "./GridItem";
import {featuresSetFeatures} from "./data";
import {useTranslate} from "../../../../translations";
import ConstructorInformation from "../../inspection/components/ConstructorInformation";

const modes = {
    grid: 0,
    list: 1,
};

const FeatureSelector = ({featureGroups, selectedFeatures, dispatch, vin,}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [featuresHidden, setFeaturesHidden] = useState(false);
    const [constructorInfoHidden, setConstructorInfoHidden] = useState(true);
    const [mode, setMode] = useState(modes.grid);
    const [modeBeforeSearch, setModeBeforeSearch] = useState(modes.grid);
    const {getText} = useTranslate();

    const handleClick = (feature) => {
        let new_checked_features = [...selectedFeatures];
        if (includes(selectedFeatures, feature.id)) {
            new_checked_features = filter(selectedFeatures, (id) => id !== feature.id)
        } else {
            new_checked_features = [...selectedFeatures, feature.id]
        }

        featuresSetFeatures(dispatch, new_checked_features);

        if (selectedFeatures < 1) {
            setFeaturesHidden(false);
        }
    };

    const allFeatures = flatten(map(featureGroups, (group) => group.features));

    const filteredGroups = featureGroups.map(group => ({
        ...group,
        features: group.features.filter(feature => feature.description.toLowerCase().includes(searchTerm.toLowerCase()))
    })).filter(group => group.features.length);

    const filteredFeatures =  flatten(map(filteredGroups, (group) => group.features));

    const sortedChecked = orderBy(map(selectedFeatures, (id) => {
        return find(allFeatures, (f) => f.id === id)
    }), 'description');

    return (
        <div className="w-full">
            <div className="flex flex-row h-full">

                <div
                    className={`features-col flex flex-col border-gray-200 overflow-hidden ${!featuresHidden ? "border-r" : ""}`}
                    style={{width: featuresHidden ? 0 : "66.66667%"}}>
                    <div className="flex flex-row pb-2 sticky bg-white z-50 mr-1" style={{top: 0}}>
                        <SearchField
                            placeholder={getText("Search for features")}
                            onChange={e => {
                                const beforeSearch = searchTerm.length < 1 ? mode : modeBeforeSearch;
                                setModeBeforeSearch(beforeSearch);
                                setSearchTerm(e.target.value);
                                setMode(e.target.value.length > 0 ? modes.list : beforeSearch);
                            }}
                            clearSearch={() => {
                                setSearchTerm("");
                                setMode(modeBeforeSearch);
                            }}
                            value={searchTerm}
                            style={{marginTop: 0}}
                            inputStyles={{border: "none", borderBottom: "1px solid #edf2f7"}}
                        />

                        <div>
                            <span
                                className={`${(mode === modes.grid) ? 'fa fa-list' : 'fa fa-th-large'} text-xl hover:text-gray-500 text-gray-900 ml-1 mt-1 mb-1 p-1 cursor-pointer`}
                                onClick={() => {
                                    setMode((mode === modes.grid) ? modes.list : modes.grid);
                                    setModeBeforeSearch((mode === modes.grid) ? modes.list : modes.grid);
                                }}/>
                        </div>

                    </div>


                    <div className={`${featuresHidden ? "hidden" : "flex"}`} style={{height: "calc(100% - 50px)"}}>
                        {mode === modes.grid &&

                        <div className="mr-2 relative h-full overflow-y-scroll">
                            <FeaturesTabView featuresByGroup={filteredGroups}
                                             handleClick={handleClick}
                                             checkedFeatures={selectedFeatures}/>
                        </div>
                        }

                        {mode === modes.list &&
                        <div className="mr-2 flex flex-col w-full overflow-y-scroll">
                            {map(orderBy(filteredFeatures, 'description'), (feature) => {
                                return (
                                    <ListItem feature={feature}
                                              checked={includes(selectedFeatures, feature.id)}
                                              onClick={handleClick}
                                              key={feature.id}
                                    />
                                )
                            })}
                        </div>}
                    </div>

                </div>


                <div className={`pl-2 h-full sticky ${constructorInfoHidden && "overflow-hidden"} `}
                     style={{alignSelf: "flex-start", top: 8, width: featuresHidden ? "100%" : "33.33333%"}}>

                    <div className="constructor-info absolute right-0 left-0 bottom-0 bg-white z-10"
                         style={{top: 0, left: constructorInfoHidden ? "100%" : 0}}>
                        {!constructorInfoHidden &&
                        <div className="flex flex-col h-full">
                            <div className="spin-animation-hover p-1 flex items-center justify-end">
                                <i className="fal fa-times text-xl hover:text-gray-500 text-gray-900 cursor-pointer"
                                   onClick={() => setConstructorInfoHidden(true)}/>
                            </div>
                            <div className="overflow-y-scroll h-full">
                                <ConstructorInformation vin={vin} general={false} finances={false} textSize="text-3xs"/>
                            </div>
                        </div>
                        }
                    </div>

                    <div className="flex flex-row justify-between" style={{height: 44}}>
                        <div className="flex items-center justify-start mb-2">
                            <i className={`${featuresHidden ? "fal fa-arrow-to-right" : "fal fa-arrow-to-left"} text-xl hover:text-gray-500 text-gray-900 cursor-pointer`}
                               style={{fontSize: 20}}
                               onClick={() => setFeaturesHidden(featuresHidden => !featuresHidden)}/>
                        </div>
                        {!featuresHidden && <div className="flex items-center justify-start mb-2">
                            <i className="fal fa-receipt text-xl hover:text-gray-500 text-gray-900 cursor-pointer"
                               onClick={() => setConstructorInfoHidden(false)}/>
                        </div>}
                    </div>

                    {selectedFeatures.length === 0 &&
                    <div className="flex flex-1 text-gray-900 justify-center items-center">
                        {getText('No features selected')}
                    </div>}

                    {featuresHidden && <div className="flex flex-row flex-wrap content-start overflow-scroll"
                                            style={{height: "calc(100% - 50px)"}}>
                        {map(sortedChecked, (feature) => {
                            return (
                                <div key={feature.id} style={{width: 105, height: 113}}>
                                    <GridItem feature={feature}
                                              checked={includes(selectedFeatures, feature.id)}
                                              onClick={handleClick}
                                              width=""
                                    />
                                </div>
                            )
                        })}
                    </div>}

                    {!featuresHidden && <div className="overflow-y-scroll" style={{height: "calc(100% - 50px)"}}>
                        {
                            map(sortedChecked, (feature) => {
                                return (
                                    <div key={feature.id}>
                                        <ActiveItem feature={feature}
                                                    checked={includes(selectedFeatures, feature.id)}
                                                    onClick={handleClick}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>}
                </div>
            </div>
        </div>
    )
};

export default withSession(FeatureSelector);