import React from 'react'
import Row from './Row'
import UUID from 'uuid'
import {map, forEach, filter, head, cloneDeep, every, pickBy} from 'lodash';

class Body extends React.Component {

    constructor(props) {
        super(props);

        this.handleRowCheck = this.handleRowCheck.bind(this);
        this.handleRowExpand = this.handleRowExpand.bind(this);
        this.recursiveCheck = this.recursiveCheck.bind(this);

        this.state = {
            dataToDisplay: [],
        }
    }

    componentDidMount() {
        const {data} = this.props;
        this.setState({dataToDisplay: this.generateDataArray(data, 1)});
    }

    generateDataArray(data, depth, parent, returnArray) {

        parent = parent || {};
        returnArray = returnArray || [];

        const level = parent._level === undefined ? 0 : parent._level + 1;

        forEach(data, (element) => {

            let node = {
                ...element,
                _hasChildren: element.children.length > 0,
                _level: level,
                _parent: parent._key,
                _key: UUID.v4(),
                _visible: level <= depth,
                _showChildren: level < depth,
                _checked: false,
                _indeterminate: false,
            };

            returnArray.push(node);

            if (node._hasChildren) {
                this.generateDataArray(element.children, depth, node, returnArray);
            }
        });

        return returnArray;
    }

    handleRowCheck(key, index) {
        const {onRowCheck} = this.props.options;

        let tempState = cloneDeep(this.state.dataToDisplay);
        const row = tempState[index];

        this.recursiveCheck(tempState, row, !row._checked);

        this.evaluateParent(row, tempState);

        const checkedRows = map(filter(tempState, (row) => row._checked), (row) => {
           return pickBy(row, (value, key) => !key.startsWith('_'))
        });

        this.setState({dataToDisplay: tempState},
            (onRowCheck) ? onRowCheck(checkedRows) : null);
    }


    evaluateParent(row, data, indeterminate) {
        indeterminate = indeterminate || false;

        if (row._parent !== undefined && row._parent !== null && row._parent !== "") {

            const parent = head(filter(data, (r) => r._key === row._parent));

            if (!indeterminate) {

                const children = filter(data, (r) => r._parent === row._parent);
                const allTrue = every(children, ['_checked', true]);
                const allFalse = every(children, ['_checked', false]);

                if (allFalse) {
                    parent._checked = false;
                    indeterminate = false;
                }
                else if (allTrue) {
                    parent._checked = true;
                    indeterminate = false;
                }
                else {
                    indeterminate = true;
                }
             }

            parent._indeterminate = indeterminate;

            if (parent._parent !== undefined && parent._parent !== null && parent._parent !== "") {
                this.evaluateParent(parent, data, indeterminate);
            }
        }

        return data;
    }



    handleRowExpand(key, index) {
        let tempState = cloneDeep(this.state.dataToDisplay);

        const row = tempState[index];
        row._showChildren = !row._showChildren;

        forEach(tempState, (row) => {
            if (row._parent === key) {
                if (!row._visible) {
                    row._visible = true;

                } else {
                    this.recursiveCollapse(tempState, row);
                }
            }

        });
        this.setState({dataToDisplay: tempState});
    }



    recursiveCollapse(data, row) {
        row._visible = false;
        row._showChildren = false;

        if (row._hasChildren) {
            forEach(data, (child) => {
                if (child._parent === row._key) {
                    this.recursiveCollapse(data, child);
                }
            })
        }

    }


    recursiveCheck(data, row, checked) {

        row._checked = checked;
        row._indeterminate = false;

        forEach(filter(data, (r) => r._parent === row._key), (r) => {
            this.recursiveCheck(data, r, checked);
        });
        return data;
    }

    render() {
        const {dataToDisplay} = this.state;

        return (
            <tbody  >
            {
                map(dataToDisplay, (row, i) => {
                        if (row._visible) {
                            return <Row
                                key={`row_${i}`}
                                options={this.props.options}
                                data={row}
                                level={row._level}
                                index={i}
                                onRowExpand={this.handleRowExpand}
                                onRowCheck={this.handleRowCheck}
                            />
                        }
                    }
                )
            }
            </tbody>
        )
    }
}

export default Body