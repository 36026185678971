import React, {useState} from 'react';
import {Button, withQuery} from "../../../../../../general";
import {map} from 'lodash';
import gql from "graphql-tag";

export const SALES_PUBLICATION_CHANNELS_QRY = gql` 
  query SalesPublicationChannels {
    salesPublicationChannels {
       id
       name
       description
       icon
       virtual
       priority
    }
  }
`;

const Channels = ({data: {salesPublicationChannels}, onSend}) => {
    return (
        <div className="pt-1">
            {map(salesPublicationChannels, (channel, i) => {
                return (
                    <div key={`channel_${i}`}  className="flex flex-row bg-blue-100 p-2 mb-2">

                        <div className="bg-white mr-2" style={{width: 100, height: 50}}>

                        </div>

                        <div className="flex flex-1 flex-row">
                            <div className="w-4/6 flex flex-col">
                                <span className="font-bold mb-2">{channel.name}</span>
                                <span className="text-blue-500">{channel.description}</span>
                            </div>

                            <div className="w-2/6 flex items-center justify-center">
                                <Button type="primary" onClick={() => onSend(channel.id)}>Send <i
                                    className="fal fa-location-circle ml-2 text-md"></i></Button>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
};

export default withQuery({query: SALES_PUBLICATION_CHANNELS_QRY, fetchPolicy: 'cache-first'})(Channels);
