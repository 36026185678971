import React from 'react';
import './TreeTable.css'
import UUID from 'uuid'
import {CheckBox} from '../../../general/base';

const LEVEL_OFFSET = 16

class Row extends React.Component {

    constructor(props) {
        super(props)

        this.getContent = this.getContent.bind(this);
        this.handleRowExpand = this.handleRowExpand.bind(this);
        this.handleRowCheck = this.handleRowCheck.bind(this);
    }


    handleRowCheck(e) {
        e.stopPropagation();

        const {index, data, onRowCheck} = this.props;

        if(onRowCheck){
            onRowCheck(data._key, index);
        }

    }


    handleRowExpand(e) {
        e.stopPropagation();

        const {index, data, onRowExpand} = this.props;

        if (data.children.length > 0) {
            onRowExpand(data._key, index)
        }
    }

    renderColumnCell(column) {
        const {data} = this.props;
        return (
            <td style={{width: column.width}} key={`${data._id}_${column.property}_${UUID.v4()}`}>
                {this.getContent(data, column.property, column.cell)}
            </td>
        )
    }

    getContent(data, property, cell) {
        if (cell && typeof cell === 'function') {
            return cell(data[property], data)
        }

        if (data[property] === null || data[property] === undefined) {
            return ''
        }

        return data[property];

    }

    renderExpander() {
        const {data} = this.props;

        let icon = '';
        if (data.children.length > 0) {
            if (data._showChildren)
                icon = 'fa fa-caret-down';
            else
                icon = 'fa fa-caret-right'
        }

        return (
            <span style={{fontSize: 20}}
                  className="treegrid-expander "
                  onClick={this.handleRowExpand}>
            <span className={`${icon}`}/>
        </span>
        )
    }

    renderIndent(level) {
        return <span className="treegrid-indent" style={{width: level * LEVEL_OFFSET}}></span>
    }

    renderTreeCell() {
        const {data, options, level} = this.props;

        let cursor = "cursor-default";
        if (data.children.length > 0) {
            cursor = "cursor-pointer"
        }

        return (
            <td style={{width: options.width}}>
                <div style={{display: 'flex', flex: 1}} className={`${cursor}`} onClick={this.handleRowExpand}>
                    {this.renderIndent(level)}
                    {this.renderExpander()}
                    {this.getContent(data, options.treeProperty, options.treeCell)}
                </div>
            </td>)
    }

    renderCheckCell() {
        const {data, options} = this.props;

        if (!options.checkboxes) return null;

        return (
            <td style={{width: 40, textAlign: 'center'}}>
                <CheckBox
                    className="cursor-pointer"
                    checked={data._checked}
                    onChange={this.handleRowCheck}
                    indeterminate={data._indeterminate}
                />
            </td>
        )
    }

    render() {
        const {options} = this.props;
        return (
            <tr className="table-row">

                {this.renderCheckCell()}

                {this.renderTreeCell()}

                {options.columns.map((column) => {
                    return this.renderColumnCell(column)
                })}
            </tr>
        )
    }
}

export default Row