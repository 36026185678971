import React from 'react';
import {Drawer, withMutation, MutationButton} from '../../../general';
import {Form, TextInput, Select, FormValidators, FormActionButton} from '../../../general/forms-final-form'
import UUID from 'uuid';
import gql from 'graphql-tag';
import ReactStreetview from 'react-streetview';
import {map} from 'lodash';
import {getText} from "../../../translations";

const formId = UUID.v4();

const UPDATE_ADDRESS = gql`
  mutation UpdateAddress($input: AddressInput!) {
    updateAddress(input: $input) {
        ok
        address {
            id
            addressFull
            address
            address1
            address2
            postCode
            city
            countryCode   
            latitude
            longitude
        }
    }
  }
`;

const TOGGLE_COMPANY_ADDRESS_COMPOUND = gql`
  mutation ToggleCompanyAddressCompound($input: ToggleCompanyAddressCompoundInput!) {
    toggleCompanyAddressCompound(input: $input) {
        ok        
        companyAddress {
            id                
            compound
        }
    }
  }
`;

const TOGGLE_COMPANY_ADDRESS_DEFAULT = gql`
  mutation ToggleCompanyAddressDefault($input: ToggleCompanyAddressDefaultInput!) {
    toggleCompanyAddressDefault(input: $input) {
        ok        
        companyAddress {
            id                
            default
        }
    }
  }
`;

const AddressDetail = ({companyAddress, mutation: {loading, error, success, mutate: saveAddressMutation}, onClose, countries}) => {

    const title = () => (
        <div className="flex flex-grow justify-between items-center">
            <div>
                <FormActionButton formId={formId}>{getText('Save')}</FormActionButton>
            </div>

        </div>
    );

    const handleSubmit = (data) => {
        saveAddressMutation({
            variables: {
                input: {
                    id: data.id,
                    address: data.address,
                    address1: data.address1 || "",
                    address2: data.address2 || "",
                    postCode: data.postCode,
                    city: data.city,
                    countryCode: data.countryCode,
                }
            }
        });
    };

    return (
        <Drawer size="sm"
                title={title}
                onClose={onClose}
                visible={true}>

            <Drawer.Header>

                <div className="flex justify-end">
                    <MutationButton type="toggle"
                                    className="mr-1"
                                    query={TOGGLE_COMPANY_ADDRESS_DEFAULT}
                                    variables={{id: companyAddress.id}}
                                    active={companyAddress.default}>
                        {getText("Default")}
                    </MutationButton>
                    <MutationButton
                        type="toggle"
                        query={TOGGLE_COMPANY_ADDRESS_COMPOUND}
                        variables={{id: companyAddress.id}}
                        active={companyAddress.compound}>
                        {getText("Compound")}
                    </MutationButton>
                </div>
            </Drawer.Header>

            <Form id={formId}
                  onSubmit={handleSubmit}
                  initialValues={companyAddress.address}
                  indicators={{
                      loading: loading,
                      error: (error && error.message) ? error.message : '',
                      success: success
                  }}
                  render={() => (
                      <div className="p-2">
                          <TextInput name="address"
                                     label={getText("Address")}
                                     placeholder={getText("Address")}
                                     disabled={loading}
                                     validators={[FormValidators.required]}
                          />

                          <TextInput name="address1"
                                     label={getText("Address 1")}
                                     placeholder={getText("Address 1")}
                                     disabled={loading}
                          />

                          <TextInput name="address2"
                                     label={getText("Address 2")}
                                     placeholder={getText("Address 2")}
                                     disabled={loading}
                          />

                          <Select name="countryCode"
                                  label={getText("Country")}
                                  placeholder={getText("Country")}
                                  disabled={loading}
                                  validators={[FormValidators.required]}
                                  options={map(countries, (country) => ({
                                      label: country.name,
                                      value: country.code
                                  }))}

                          />

                          <div className="flex flex-row justify-between">

                              <div className="w-full mr-4">
                                  <TextInput name="postCode"
                                             label={getText("Postal")}
                                             className="mr-4"
                                             disabled={loading}
                                             placeholder={getText("Postal")}
                                             validators={[FormValidators.required]}
                                  />
                              </div>

                              <div className="w-full">
                                  <TextInput name="city"
                                             label={getText("City")}
                                             disabled={loading}
                                             placeholder={getText("City")}
                                             validators={[FormValidators.required]}
                                  />
                              </div>

                          </div>

                      </div>
                  )}
            />

            <div className="flex justify-center items-center p-2">
                <div style={{width: 390, height: 390}}>
                    {companyAddress.address.latitude !== null && companyAddress.address.longitude !== null &&
                    <ReactStreetview
                        key={companyAddress.address.addressFull}
                        apiKey={'AIzaSyCuuvq9FOg5q4deZ_TmcGMhX1SadPmdkII'}
                        streetViewPanoramaOptions={{
                            position: {lat: companyAddress.address.latitude, lng: companyAddress.address.longitude},
                            pov: {heading: 100, pitch: 0},
                            zoom: 1
                        }}
                    />}
                </div>
            </div>
        </Drawer>
    )
};

export default withMutation({
    mutation: UPDATE_ADDRESS,
    successMessage: getText("Changes were made successfully")
})(AddressDetail);

