import React from 'react';
import {Drawer} from '../../../general';
import {Form, TextInput, Select, FormValidators, FormActionButton} from '../../../general/forms-final-form'
import UUID from 'uuid';
import {getText} from "../../../translations";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {mutations, queries} from "./graphql";
import {errorNotifier, successNotifier} from "../../../general/utils";
import LoadingIndicator from "../../../general/indicators/LoadingIndicator";

const formId = UUID.v4();

const PersonalInformationForm = ({onClose}) => {
    const {data, called, loading, error} = useQuery(queries.userProfile);
    const [personalInformationMutation, {called: personalInformationMutationCalled, loading: personalInformationMutationLoading}] = useMutation(mutations.personalInformationChange);

    const title = () => (
        <div className="flex flex-grow justify-between items-center">
            <FormActionButton formId={formId}
                              disabled={((called && loading) || (personalInformationMutationCalled && personalInformationMutationLoading))}>
                {getText('Save')}
            </FormActionButton>
        </div>
    );

    const handleSubmit = (data, form) => {

        personalInformationMutation({
            variables: {
                data: {
                    transactionId: UUID.v4(),
                    title: data.title,
                    firstName: data.user.firstName,
                    lastName: data.user.lastName,
                    email: data.user.email,
                    telephone: data.telephone,
                    mobile: data.mobile,
                }
            }
        }).then((result) => {
            const {userProfileUpdateHandler} = result.data;
            const {success} = userProfileUpdateHandler.result;

            if (success) {
                form.reset()
                successNotifier(getText("Personal information updated"))
            } else {
                errorNotifier(getText("Something went wrong"))
            }
        });
    };

    if (loading) {
        return <LoadingIndicator/>
    }

    const {userProfile} = data;
    return (
        <Drawer size="sm"
                title={title}
                onClose={onClose}
                visible={true}>

            <React.Fragment>
                {personalInformationMutationCalled && personalInformationMutationLoading &&
                <LoadingIndicator overlay={true}/>}

                <Form id={formId}
                      onSubmit={handleSubmit}
                      initialValues={userProfile}
                      indicators={{
                          loading: loading,
                          error: (error && error.graphQLErrors.length > 0) ? error.graphQLErrors[0].message : ''
                      }}
                      render={() => (
                          <div className="p-2">
                              <Select name="title"
                                      label={getText("Title")}
                                      placeholder={getText("Title")}
                                      disabled={loading}
                                      validators={[FormValidators.required]}
                                      options={[
                                          {
                                              label: getText("Mr"),
                                              value: "Dhr"
                                          },
                                          {
                                              label: getText('Mrs'),
                                              value: "Mrs"
                                          },
                                      ]}
                              />

                              <div className="flex flex-row flex-1 justify-between">
                                  <div className="w-full mr-4">
                                      <TextInput name="user.lastName"
                                                 label={getText("Last name")}
                                                 placeholder={getText("Last name")}
                                                 className="mr-4"
                                                 disabled={loading}
                                                 validators={[FormValidators.required]}
                                      />
                                  </div>

                                  <div className="w-full">
                                      <TextInput name="user.firstName"
                                                 label={getText("First name")}
                                                 placeholder={getText("First name")}
                                                 disabled={loading}
                                                 validators={[FormValidators.required]}
                                      />
                                  </div>
                              </div>

                              <TextInput name="user.email"
                                         label={getText("E-mail")}
                                         placeholder={getText("E-mail")}
                                         disabled={loading}
                                         validators={[FormValidators.email]}
                              />

                              <div className="flex flex-row justify-between">
                                  <div className="w-full mr-4">
                                      <TextInput name="telephone"
                                                 label={getText("Telephone")}
                                                 className="mr-4"
                                                 disabled={loading}
                                                 placeholder={getText("Telephone")}
                                      />
                                  </div>

                                  <div className="w-full">
                                      <TextInput name="mobile"
                                                 label={getText("Mobile")}
                                                 disabled={loading}
                                                 placeholder={getText("Mobile")}
                                      />
                                  </div>

                              </div>
                          </div>
                      )}
                />
            </React.Fragment>
        </Drawer>
    )
};

export default PersonalInformationForm;

