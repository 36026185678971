import React from 'react';
import {emptyModuleConfig} from "../common";
import {TakeOverView} from './lead';
import gql from "graphql-tag";

const MODULE_NAME = 'expert';


const DEFAULT_CACHE_QRY = gql`
query DefaultExpertCache{
    countries {
      id
      name
      code     
    }
                      
    legalForms {
      id
      description                      
    }
    
    salesPublicationChannels {
       id
       name
       description
       icon
       virtual
       priority
    }
}`;

export function gatherConfig() {
    const config = emptyModuleConfig(MODULE_NAME);
    config.setMainMenu("eXpert", '#e97a0e');
    config.setCacheQuery(DEFAULT_CACHE_QRY);

    config.addBlock("Dashboard", "fal fa-tachometer-alt", "manager", 0, null, null);
    config.addBlock("TakeoverWizard", "fal fa-car", "manager", 0, null, <TakeOverView/>);


    return config;
}


