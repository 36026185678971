import React from 'react';
import {GET_SESSION} from "./tags";

import {Query} from "react-apollo";

const withSession = (Component) => {
    class WithSession extends React.Component {

        render(){

            return (
                <Query query={GET_SESSION}>
                    {(data) => {
                        const {data: {session}} = data;
                        return <Component session={session} {...this.props} />
                    }}
                </Query>
            )
        }
    }

    return WithSession;
};

export default withSession;