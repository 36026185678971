import React, {useState} from 'react';
import {default as BaseTextArea} from "../../../../../../../general/base/TextArea";
import {default as BaseNumericInput} from "../../../../../../../general/base/NumericInput";
import {Button} from "../../../../../../../general";
import {useTranslate} from "../../../../../../../translations";
import {default as BaseTextInput} from "../../../../../../../general/base/TextInput";

const ManualOfferForm = ({dealer: dealerFromProps, price: priceFromProps, comment: commentFromProps, onCancel, onOffer}) => {
    const {getText} = useTranslate();
    const [dealer, setDealer] = useState({id: 1, name: "NV Van Lierde"});
    const [price, setPrice] = useState(priceFromProps);
    const [comment, setComment] = useState(commentFromProps);
    const [dealerHasFocus, setDealerHasFocus] = useState(true);

    const handleOffer = () => {
        const offer = {
            dealerId: dealer.id,
            price,
            comment
        };
        onOffer(offer);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 27 && onCancel) {
            onCancel()
        }
    };

    return (
        <div className="p-2 border border-gray-200 border-solid border-r-0 border-l-0 border-t-0 bg-white cursor-pointer" onKeyDown={handleKeyDown}>
            <div className="flex flex-row">
                <div className="flex justify-center mr-2 items-center" style={{width: 30}}>
                    <i className="fa fa-gavel text-xl text-gray-500"/>
                </div>
                <div className="w-2/3">
                    <div>
                        <BaseTextInput value={dealer.name}
                                       placeholder={getText("Dealer name")}
                                       onChange={(e) => setDealer({id: 1, name: "NV Van Lierde"})}
                                       showBorder={true}
                                       required={true}
                                       hasFocus={dealerHasFocus}
                                       onBlur={() => setDealerHasFocus(false)}
                                       onEscape={onCancel}
                        />
                    </div>
                    <div>
                        <BaseTextArea value={comment}
                                      placeholder={getText("Comment")}
                                      onChange={(e) => setComment(e.target.value)}
                                      showSelectionBorder={true}
                                      required={false}
                                      rows={1}
                        />
                    </div>
                </div>
                <div className="w-1/3 flex justify-end">
                    <div className="flex flex-row items-center">
                        <span className="flex items-center font-medium text-md justify-center mr-2 pt-2">€</span>
                        <BaseNumericInput
                            placeholder={getText("Price")}
                            value={price}
                            min={1}
                            max={999999}
                            onChange={(value) => setPrice(value)}
                            className="text-right font-medium"
                            required={true}
                            hasError={false}
                            onEnter={handleOffer}
                            onEscape={onCancel}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-row justify-end mt-2">
                <Button className="mr-2" onClick={onCancel}>Cancel</Button>
                <Button type="primary" onClick={handleOffer}>Add bid</Button>
            </div>
        </div>
    )
};

export default ManualOfferForm;
