import React from 'react';
import {wizardSummaryGroups, wizardSteps} from '../inspectionData';
import {map, intersection, filter, find, includes, sum, values, forEach} from 'lodash';
import {CircularProgressbarWithChildren} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {getText} from "../../../../translations";


const Summary = ({data: {flow, flowProgress, currentStepId, lastCompletedStepId}, collapsed, onCollapse, step, onStepClick}) => {

    const calcProgress = () => {
        const stepsInFlow = filter(wizardSteps, (step) => includes(flow, step.id));
        const totalWeight = sum(map(stepsInFlow, (step) => step.weight));
        const minStepWeight = 100 / totalWeight;
        const completedStepIds = map(filter(flowProgress, (fp) => fp.completed), (fp) => fp.stepId);

        let passedWeight = 0;
        forEach(completedStepIds, (id) => {
            const step = find(values(wizardSteps), (step) => step.id === id);
            passedWeight += step.weight;
        });
        return Math.ceil((passedWeight * minStepWeight));
    };

    const handleClick = (evt, group) => {
        evt.stopPropagation();
        if (onStepClick && group.steps[0] !== currentStepId) {
            onStepClick(group);
        } else {
            if (onCollapse) {
                onCollapse();
            }
        }
    };

    const hasValidationError = (group) => {
        const stepIdsWithValidationErrors = map(filter(flowProgress, (fp) => (!fp.completed && fp.errors.length !== 0)), (fp) => fp.stepId);

        let hasError = false;
        forEach(group.steps, (id) => {
            if (includes(stepIdsWithValidationErrors, id)) {
                hasError = true;
                return;
            }
        });
        return hasError;
    };

    const isCompleted = (group) => {
        const stepIdsCompleted = map(filter(flowProgress, (fp) => (fp.completed)), (fp) => fp.stepId);

        let completed = true;
        forEach(group.steps, (id) => {
            if (!includes(stepIdsCompleted, id)) {
                completed = false;
                return;
            }
        });
        return completed;
    };


    const groupsForFlow = filter(wizardSummaryGroups, (group) => intersection(flow, group.steps).length > 0);
    const currentGroup = find(wizardSummaryGroups, (group) => includes(group.steps, currentStepId));
    const progress = calcProgress();

    return (
        <React.Fragment>
            <div className={`flex flex-1 flex-col h-full ${(onCollapse !== undefined) ? "cursor-pointer" : ""}`}
                 style={{backgroundColor: '#f8f8f8'}}>
                <div className="w-full flex justify-center mt-5" style={{height: 150}}>
                    <div id="circular-wrapper" className="flex items-center" style={{width: collapsed ? 50 : 150}}>
                        <CircularProgressbarWithChildren text={`${progress}%`} value={progress}>
                            <div className="summary-completed-text"
                                 style={{fontSize: 14, marginTop: 50, opacity: collapsed ? 0 : 1}}>
                                <span className="text-gray-500 font-medium">{getText("completed")}</span>
                            </div>
                        </CircularProgressbarWithChildren>
                    </div>
                </div>

                <div className="mt-5">

                    {map(groupsForFlow, (group, i) => {
                            const isActive = group.name === currentGroup.name;
                            const completed = isCompleted(group);
                            const hasErrors = hasValidationError(group);

                            return (
                                <div key={i}
                                     className={`flex flex-row items-center p-3 sm:p-2 overflow-hidden cursor-pointer ${(isActive) && 'bg-blue-200'}`}
                                     style={{maxHeight: 54}}
                                     onClick={(evt) => handleClick(evt, group)}
                                >
                                    <div className="flex mr-4">
                                        <div
                                            className={`flex items-center justify-center border border-gray-300 relative rounded-full ${(completed) ? 'bg-blue-500' : 'bg-white'}`}
                                            style={{width: 30, height: 30}}>


                                            {hasErrors && <div className="flex items-center justify-center rounded-full bg-red-500 absolute "
                                                               style={{right: -2, top: -2, width: 10, height: 10}}>

                                            </div>}
                                            {!completed && <span
                                                className={`${(isActive) ? 'text-black' : 'text-gray-500'} `}>{i + 1}</span>}

                                            {completed && <span className={`text-white`}>{i + 1}</span>}


                                        </div>
                                    </div>
                                    <div className={`${collapsed ? "hidden" : ""}`}>
                                        <div className="flex flex-1 flex-col">
                                            <span className="text-gray-900 sm:text-sm text-md">{group.title}</span>
                                            <span className="text-gray-600 text-3xs mt-1">{group.description}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    )}

                </div>
            </div>
        </React.Fragment>
    )
};

Summary.defaultProps = {
    progress: 10,
    collapsed: false,
    step: ""
};

export default Summary;