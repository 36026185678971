import React from 'react';
import {default as BaseTextArea} from "../base/TextArea";
import * as PropTypes from "prop-types";
import LabelContainer from "./LabelContainer";

const TextArea = ({label, value, required, placeholder, disabled, onChange, className, inputClassName, showBorder, error, hasError}) => {

    return (
        <LabelContainer label={label}
                        disabled={disabled}
                        className={className}
                        error={error}
                        hasError={hasError}
                        hasValue={value !== ""}>
            <BaseTextArea
                value={value}
                required={required}
                placeholder={placeholder}
                disabled={disabled}
                onChange={onChange}
                className={inputClassName}
                showBorder={showBorder}
                hasError={hasError}
            />
        </LabelContainer>
    );
};

TextArea.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    value: PropTypes.string.isRequired,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    showBorder: PropTypes.bool,
    error: PropTypes.string,
    hasError: PropTypes.bool,
};

TextArea.defaultProps = {
    label: "Label",
    value: "",
    required: false,
    placeholder: "",
    disabled: false,
    onChange: null,
    className: "",
    inputClassName: "",
    showBorder: true,
    error: "",
    hasError: false,
};

export default TextArea;


