import {forEach, omit} from "lodash";
import UUID from "uuid";

export const INITIAL_DAMAGES = {
    damagesById: {},
    noDamages: false,
    detailDamageId: null,
};

const DAMAGE_TYPES = {
    DAMAGE_CREATE: 'DAMAGES_DAMAGE_CREATE',
    DAMAGE_SET_LOCATION: 'DAMAGES_DAMAGE_SET_LOCATION',
    DAMAGE_SET_TYPE: 'DAMAGES_DAMAGE_SET_TYPE',
    DAMAGE_SET_SEVERITY: 'DAMAGES_DAMAGE_SET_SEVERITY',
    DAMAGE_SET_COMMENT: 'DAMAGES_DAMAGE_SET_COMMENT',
    DAMAGE_DELETE: 'DAMAGES_DAMAGE_DELETE',
    NO_DAMAGES: 'DAMAGES_SET_NO_DAMAGES',
    SET_DETAIL_DAMAGE_ID: 'DAMAGES_SET_DETAIL_DAMAGE_ID',
};


export const damageReducer = (state, action) => {
    switch (action.type) {
        case DAMAGE_TYPES.DAMAGE_CREATE:
            return {
                ...state,
                damagesById: {
                    ...state.damagesById,
                    [action.payload.guid]: {
                        ...state.damagesById[action.payload.guid],
                        ...action.payload,
                    }
                },
                noDamages: false,
                detailDamageId: action.payload.guid,
            };
        case DAMAGE_TYPES.DAMAGE_SET_LOCATION:
        case DAMAGE_TYPES.DAMAGE_SET_TYPE:
        case DAMAGE_TYPES.DAMAGE_SET_SEVERITY:
        case DAMAGE_TYPES.DAMAGE_SET_COMMENT:
            return {
                ...state,
                damagesById: {
                    ...state.damagesById,
                    [action.payload.guid]: {
                        ...state.damagesById[action.payload.guid],
                        ...action.payload,
                    }
                },
                noDamages: false,
            };
        case DAMAGE_TYPES.DAMAGE_DELETE:
            return {
                ...state,
                damagesById: omit(state.damagesById, action.payload.guid),
                noDamages: false,
                detailDamageId: (action.payload.id === state.detailDamageId) ? null : state.detailDamageId,
            };
        case DAMAGE_TYPES.NO_DAMAGES:
            return {
                ...state,
                damagesById: {},
                noDamages: action.payload.noDamages,
                detailDamageId: null,
            };
        case DAMAGE_TYPES.SET_DETAIL_DAMAGE_ID:
            return {
                ...state,
                detailDamageId: action.payload.guid,
            };
        default:
            return state;
    }
};

export const EMPTY_DAMAGE = {
    guid: "",
    locationId: null,
    locationText: "",
    typeId: null,
    typeText: "",
    severityId: null,
    severityText: "",
    x: 0,
    y: 0,
    item: "",
    comment: ""
};


export const damageCreate = (dispatch, location, item, x, y) => {
    const damage = {
        ...EMPTY_DAMAGE,
        guid: UUID.v4(),
        locationId: location.id,
        locationText: location.description,
        x: x,
        y: y,
        item: item,
    };

    dispatch({
        type: DAMAGE_TYPES.DAMAGE_CREATE,
        payload: damage,
    });

    return damage;
};
export const damageDelete = (dispatch, guid) => {
    dispatch({
        type: DAMAGE_TYPES.DAMAGE_DELETE,
        payload: {
            guid
        }
    });
};
export const damageChangeLocation = (dispatch, guid, location, locationText, x, y) => {
    dispatch({
        type: DAMAGE_TYPES.DAMAGE_SET_LOCATION,
        payload: {
            guid,
            locationId: location.id,
            locationText: location.description,
            x,
            y,
        }
    });
};
export const damageSetDamageType = (dispatch, guid, type) => {
    dispatch({
        type: DAMAGE_TYPES.DAMAGE_SET_TYPE,
        payload: {
            guid,
            typeId:  type.id,
            typeText: type.description,
        },
    });
};
export const damageSetSeverity = (dispatch, guid, severity) => {
    dispatch({
        type: DAMAGE_TYPES.DAMAGE_SET_SEVERITY,
        payload: {
            guid,
            severityId: severity.id,
            severityText: severity.description,
        },
    });
};
export const damageSetComment = (dispatch, guid, comment) => {
    dispatch({
        type: DAMAGE_TYPES.DAMAGE_SET_COMMENT,
        payload: {
            guid,
            comment,
        },
    });
};

export const setNoDamages = (dispatch, noDamages) => {
    dispatch({
        type: DAMAGE_TYPES.NO_DAMAGES,
        payload: {
            noDamages,
        },
    });
};

export const setDetailDamageId = (dispatch, guid) => {
    dispatch({
        type: DAMAGE_TYPES.SET_DETAIL_DAMAGE_ID,
        payload: {
            guid,
        },
    });
};









