import React, {useState} from 'react';
import {useTranslate} from "../../../../../../../translations";
import Heading from "../Heading";
import {filter, find, map} from "lodash";
import {formatAmount} from "../../../../../../../general/utils";
import SellerProposal from "./SellerProposal";
import SellerProposalHistory from "./SellerProposalHistory";

const proposals = [{
    id: 1,
    validationDate: '2020-01-15',
    amount: 25000,
    distributorPremium: 1500,
    marginTransfer: -2500,
    status: 'refused'
}];

const Proposal = ({onAccept, onRefuse, onWithDraw, grayed}) => {
    const {getText} = useTranslate();
    const [proposals, setProposals] = useState([]);
    const [historyVisible, setHistoryVisible] = useState(false);

    const currentProposal = find(proposals, (proposal) => (proposal.status === 'pending' || proposal.status === 'accepted'));
    const historyProposals = filter(proposals, (proposal) => (proposal.status === 'refused' || proposal.status === 'withdraw'));


    const handlePropose = (proposal) => {
        const temp_proposals = [...proposals];
        temp_proposals.push(proposal);
        setProposals(temp_proposals);
    };

    const handleAccept = (id) => {
        const temp_proposals = [...proposals];
        const proposal = find(temp_proposals, (prop) => prop.id === id);
        proposal.status = 'accepted';

        setProposals(temp_proposals);
        onAccept();
    };

    const handleRefuse = (id) => {
        const temp_proposals = [...proposals];
        const proposal = find(temp_proposals, (prop) => prop.id === id);
        proposal.status = 'refused';


        setProposals(temp_proposals);
        onRefuse();
    };

    const handleWithDraw = (id) => {
        const temp_proposals = [...proposals];
        const proposal = find(temp_proposals, (prop) => prop.id === id);
        proposal.status = 'withdraw';

        setProposals(temp_proposals);
        onWithDraw();
    };

    return (
        <div className="">
            <Heading title={getText('Proposal for customer')}/>

            <SellerProposal proposal={currentProposal}
                            onPropose={handlePropose}
                            onAccept={handleAccept}
                            onRefuse={handleRefuse}
                            onWithDraw={handleWithDraw}
                            grayed={grayed}
            />

            {historyVisible &&
            <div className="mt-2">
                <SellerProposalHistory proposals={historyProposals}/>
            </div>}
        </div>
    )
};

export default Proposal;
