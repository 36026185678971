import React from 'react';
import {formatAmount} from "../../../../../../../general/utils";
import {useTranslate} from "../../../../../../../translations";

const ManagerOffer = ({price, comment}) => {
    const {getText} = useTranslate();

    return (
        <div className="p-2 bg-orange-200">
            <div className="flex flex-row">
                <div className="flex justify-center mr-2 items-center" style={{width: 30}}>
                    <i className="fa fa-gavel text-xl text-gray-500"/>
                </div>
                <div className="w-2/3">
                    <div className="mb-1">
                        <span className="font-medium text-orange-700">{getText('From your manager')}</span>
                    </div>
                    <div>
                        <span className="text-orange-600">{comment}</span>
                    </div>
                </div>
                <div className="w-1/3 flex justify-end">
                    <div className="flex flex-row items-center">
                            <span className="text-md font-medium text-orange-700 ">
                                {formatAmount(price)}
                            </span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ManagerOffer;
