import React, {useState} from 'react';
import {formatAmount} from "../../../../../../../general/utils";
import ProposalForm from "./ProposalForm";
import Button from "../../../../../../../general/action/Button";
import {useTranslate} from "../../../../../../../translations";


const ManagerOfferWithForm = ({price, comment, canPropose, onPropose}) => {
    const {getText} = useTranslate();
    const [hovered, setHovered] = useState(false);
    const [proposalFormVisible, setProposalFormVisible] = useState(false);

    const handleManagerProposal = (proposal) => {
        setProposalFormVisible(false);
        onPropose(proposal);
    };

    const icon = (canPropose && hovered && price !== null) ? (price === 0) ? 'fa fa-plus' : 'fa fa-pencil' : 'fa fa-gavel';


    return (
        <React.Fragment>
            <div className={`p-2 bg-orange-200 ${(canPropose) ? 'cursor-pointer hover:bg-orange-300' : 'cursor-default'} `}
                 onClick={() => (canPropose) ? setProposalFormVisible(!proposalFormVisible) : null}
                 onMouseEnter={() => (canPropose) ? setHovered(true) : null}
                 onMouseLeave={() => (canPropose) ? setHovered(false) : null}>

                <div className="flex flex-row">
                    <div className="flex justify-center mr-2 items-center" style={{width: 30}}>
                        <i className={`${icon} text-xl text-gray-500`}/>
                    </div>
                    <div className="w-2/3">
                        <div className="mb-1">
                            <span className="font-medium text-orange-700">{getText('Manager')}</span>
                        </div>
                        <div>
                            <span className="text-orange-600">{`${(comment !== "") ? comment : getText("Your proposal to seller")}`}</span>
                        </div>
                    </div>
                    <div className="w-1/3 flex justify-end">
                        <div className="flex flex-row items-center">
                            {canPropose && price === 0 && !proposalFormVisible &&
                            <Button onClick={() => setProposalFormVisible(true)}>Add</Button>}

                            {price !== 0 && !proposalFormVisible &&
                            <div className="flex flex-row">
                                <span className="text-md font-medium text-orange-700">{formatAmount(price)}</span>
                            </div>}

                        </div>
                    </div>
                </div>
            </div>

            {proposalFormVisible &&
            <ProposalForm price={price}
                          comment={comment}
                          onCancel={() => setProposalFormVisible(false)}
                          onPropose={handleManagerProposal}
            />}
        </React.Fragment>
    )
};

ManagerOfferWithForm.defaultProps = {
    canPropose: true
};

export default ManagerOfferWithForm;
