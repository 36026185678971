import React from 'react';
import Switch from './Switch';
import {map, keys, orderBy} from 'lodash';
import * as PropTypes from "prop-types";

const SelectionList = ({values, onSelectionChanged}) => {

    const handleOnChange = (id, checked) => {
        const result = {
            ...values,
            [id]: {
                ...values[id], checked: checked
            }
        };

        if (onSelectionChanged)
            onSelectionChanged(result)
    };

    const sorted = orderBy(map(keys(values), (key) => values[key]), ['name'], ['asc']);
    return (
        <div className="flex flex-row flex-wrap pt-2">
            {map(sorted, (value) => {
                    return (
                        <div key={value.id} className="w-1/2">
                            <Switch label={value.name}
                                    checked={value.checked}
                                    onChange={(checked) => handleOnChange(value.id, checked)}/>
                        </div>
                    )
                }
            )}
        </div>
    )
};

SelectionList.propTypes = {
    values: PropTypes.object.isRequired,
    onSelectionChanged: PropTypes.func,
};

SelectionList.defaultProps = {
    values: [],
    onSelectionChanged: null
};

export default SelectionList;





