import gql from "graphql-tag";

export const GET_SESSION = gql`
  {  
    session @client {
       id
       token      
       modules
       roles
       companyId
       subsidiaryId
       brandId
       locale
    }
  }
`;

export const SESSION_SET_TOKEN = gql`
  mutation setToken($token: String!) {
    setToken(token: $token) @client
  }
`;

export const SESSION_SET_COMPANY = gql`
  mutation setCompany($company: Int!) {
    setCompany(company: $company) @client
  }
`;

export const SESSION_SET_SUBSIDIARY = gql`
  mutation setSubsidiary($subsidiary:  Int!) {
    setSubsidiary(subsidiary: $subsidiary) @client
  }
`;

export const SESSION_SET_BRAND = gql`
  mutation setBrand($brand:  Int!) {
    setBrand(brand: $brand) @client
  }
`;

export const SESSION_SET_LOCALE = gql`
  mutation setLocale($locale:  String!) {
    setLocale(locale: $locale) @client
  }
`;

export const SESSION_CLEAR = gql`
  mutation clearSession {
    clearSession @client
  }
`;


export const SESSION_SET_COMPANY_SESSION = gql`
  mutation setBrand($company: Int!, $subsidiary:  Int!, $brand:  Int!) {
    setCompany(company: $company) @client
    setSubsidiary(subsidiary: $subsidiary) @client
    setBrand(brand: $brand) @client
  }
`;