import React, {useRef, useEffect} from 'react';
import {getText} from "../../translations";
import * as PropTypes from "prop-types";

const SearchField = ({value, placeholder, onChange, showButton, buttonText, onButtonClick, onSearchClear, clearIcon, onKeyDown, className, inputClassName, buttonClassName, searchIconClassName, loading}) => {
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    return (
        <div className="flex flex-row items-center w-full">
            <div className={`flex items-center relative w-full ${className}`}>
                <div className="ml-2" style={{width: 15}}>

                    {!loading && <i className={`fal fa-search text-gray-900 ${searchIconClassName}`}/>}

                    {loading &&
                    <div className={`lds-ring mr-2 lds-ring-black`} style={{width: 15, borderColor: 'black'}}>
                        <div></div>
                    </div>}
                </div>

                <input
                    ref={inputRef}
                    type="text"
                    placeholder={placeholder}
                    className={`rounded-l-sm w-full p-2 pl-2 ${inputClassName}`}
                    onChange={onChange}
                    onKeyDown={e => onKeyDown ? onKeyDown(e) : null}
                    value={value}
                />

                {value !== null && value !== undefined && value.length > 0 &&
                <i className={`${clearIcon} absolute text-gray-900 right-0 mr-4 cursor-pointer hover:text-red-400`}
                   onClick={() => {
                       inputRef.current.focus();
                       onSearchClear();
                   }}/>}
            </div>

            {showButton &&
            <button
                className={`h-full text-gray-600 p-2 bg-gray-100 hover:bg-gray-200 outline-none focus:outline-none border-l-0 ${buttonClassName}`}
                onClick={onButtonClick}>
                {buttonText}
            </button>}

        </div>
    );
};

SearchField.propTypes = {
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    showButton: PropTypes.bool,
    buttonText: PropTypes.string,
    onButtonClick: PropTypes.func,
    onSearchClear: PropTypes.func,
    clearIcon: PropTypes.string,
    onKeyDown: PropTypes.func,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
    searchIconClassName: PropTypes.string,
    loading: PropTypes.bool,
};

SearchField.defaultProps = {
    value: "",
    placeholder: "Search anything",
    onChange: null,
    showButton: false,
    buttonText: getText("Search"),
    onButtonClick: null,
    onSearchClear: null,
    clearIcon: "fal fa-eraser",
    onKeyDown: null,
    className: "",
    inputClassName: "",
    buttonClassName: "",
    searchIconClassName: "",
    loading: false
};


export default SearchField;