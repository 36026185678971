import {emptyModuleConfig} from "../common";
import React from "react";
import Home from "./home";


const MODULE_NAME = 'remarketing';

export function gatherConfig() {
    const config = emptyModuleConfig(MODULE_NAME);
    config.setMainMenu("Remarketing", "#644597");

    config.addBlock("Home", "fad fa-home", "manager", 0, <Home />, <Home />);
    config.addBlock("Customers","fad fa-building", "manager", 0);
    config.addBlock("Vehicles","fal fa-cars", "manager", 0);
    config.addBlock("Publications","fad fa-calendar-alt", "manager", 0);
    config.addBlock("Auction","fad fa-gavel", "manager", 0);
    config.addBlock("Delivery","fad fa-car-building", "manager", 0);
    config.addBlock("Reports","fad fa-file-chart-line", "manager", 0);


    return config;
}

