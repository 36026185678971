import React, {Component} from 'react';
import {
    Drawer,
    withMutation
} from "../../../general";
import {
    Form,
    TextInput,
    Select,
    Switch,
    SelectionList,
    FormValidators,
    FormActionButton
} from '../../../general/forms-final-form'
import UUID from "uuid";
import gql from "graphql-tag";
import {keyBy, map} from "lodash";
import {compose} from "recompose";
import {withSession} from "../../../project/session";
import {getText} from "../../../translations";

const formId = UUID.v4();

const ADD_NEW_USER = gql`
mutation UpdateCompanyUserProfile($input: CompanyUserProfileInput!) {
    updateCompanyUserProfile(input: $input) {
        ok    
        companyUser {
            id
            state
            company {
                id
            }
            userProfile{ 
                id
                title
                telephone
                mobile
                smsActive
                cultureMajor
                offerTax
                  user {
                    id
                    username
                    lastName
                    firstName
                    email
                    lastLogin
                    groups {
                        id
                        name
                    }
                  }
            }
          }
         
        
    }
  }
`;

export const ALL_COMPANY_USERS = gql`
query CompanyUsers($applicationId: ID!, $companyId: ID!){
  companyUsers(applicationId: $applicationId, companyId: $companyId) {   
             id
             state  
             company {
                id
             }   
             userProfile {
              id,
              title,
              telephone,
              mobile,
              smsActive,
              cultureMajor
              offerTax,
              user {         
                id,
                username
                lastName,
                firstName,
                email,  
                lastLogin
                groups {
                  id,
                  name
                }
              }
            }        
  }  
}
`;


class AddUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultFormValues: {
                groups: keyBy(map(props.drawerParams.groups, (group) => ({
                    name: group.name,
                    id: group.id,
                    checked: false
                })), 'id')
            }
        };

        this.addNewCompanyUser = this.addNewCompanyUser.bind(this);
    }

    addNewCompanyUser(data) {
        const {mutation, session, onClose} = this.props;
        return mutation.mutate({
                variables: {
                    input: {
                        title: data.title,
                        telephone: data.telephone,
                        mobile: data.mobile,
                        smsActive: data.smsActive,
                        offerTax: data.offerTax,
                        cultureMajor: data.cultureMajor,
                        user: {
                            username: data.username,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            email: data.email,
                        },
                        company: {
                            id: session.companyId,
                        },
                        groups: Object.keys(data.groups).map(key => data.groups[key]).filter(group => group.checked)
                    }
                }
            }, (data => {
                if (data.success) {
                    // this.formReset();
                    onClose();
                }
            })
        )
    }

    render() {
        const {onClose, mutation: {loading, error, success}} = this.props;
        const {defaultFormValues} = this.state;

        const title = () => (
            <div className="flex flex-grow justify-between items-center">
                <div className="flex flex-row">
                    <FormActionButton formId={formId}>{getText('Save')}</FormActionButton>
                </div>
            </div>
        );


        return (
            <Drawer title={title}
                    onClose={onClose}
                    visible={true}
            >
                <Form id={formId}
                      onSubmit={this.addNewCompanyUser}
                      initialValues={defaultFormValues}
                      className="w-full"
                      resetAfterSubmit={true}
                      indicators={{
                          loading: loading,
                          error: (error && error.message) ? error.message : '',
                          success: success
                      }}
                      render={({values, form}) => {
                          if (!this.formReset) {
                              this.formReset = form.reset;
                          }

                          return (
                              <div className="p-2">
                                  <TextInput name="username"
                                             label={getText('Username')}
                                             placeholder={getText("Username")}
                                             disabled={loading}
                                             validators={[FormValidators.required]}
                                  />

                                  <div className="flex flex-row flex-1 justify-between">
                                      <div className="w-full mr-4">
                                          <Select name="cultureMajor"
                                                  label={getText("Language")}
                                                  placeholder={getText("Select language")}
                                                  disabled={loading}
                                                  validators={[FormValidators.required]}
                                                  options={[
                                                      {
                                                          label: getText('Dutch'),
                                                          value: "nl"
                                                      },
                                                      {
                                                          label: getText('English'),
                                                          value: "en"
                                                      },
                                                      {
                                                          label: getText('French'),
                                                          value: "fr"
                                                      },
                                                  ]}
                                          />
                                      </div>

                                      <div className="w-full">
                                          <Select name="title"
                                                  label={getText('Title')}
                                                  placeholder={getText('Select title')}
                                                  disabled={loading}
                                                  validators={[FormValidators.required]}
                                                  options={[

                                                      {
                                                          label: "Mr",
                                                          value: "Mr"
                                                      },
                                                      {
                                                          label: "Mrs",
                                                          value: "Mrs"
                                                      },
                                                  ]}
                                          />
                                      </div>
                                  </div>

                                  <div className="flex flex-row justify-between">

                                      <div className="w-full mr-4">
                                          <TextInput name="firstName"
                                                     label={getText('First name')}
                                                     placeholder={getText('First name')}
                                                     validators={[FormValidators.required]}
                                                     disabled={loading}
                                          />
                                      </div>


                                      <div className="w-full mr-4">
                                          <TextInput name="lastName"
                                                     label={getText('Last name')}
                                                     placeholder={getText('Last name')}
                                                     validators={[FormValidators.required]}
                                                     disabled={loading}
                                          />
                                      </div>


                                  </div>

                                  <TextInput name="email"
                                             label={getText('Email')}
                                             placeholder={getText('Email')}
                                             disabled={loading}
                                             validators={[FormValidators.required]}
                                  />


                                  <div className="flex flex-row justify-between">

                                      <div className="w-full mr-4">
                                          <TextInput name="mobile"
                                                     label={getText('Mobile')}
                                                     className="mr-1"
                                                     disabled={loading}
                                                     placeholder={getText('Mobile')}
                                                     validators={[FormValidators.required]}
                                          />
                                      </div>

                                      <div className="w-full">
                                          <TextInput name="telephone"
                                                     label={getText('Telephone')}
                                                     disabled={loading}
                                                     placeholder={getText('Telephone')}
                                                     validators={[FormValidators.required]}
                                          />
                                      </div>

                                  </div>


                                  <div className="flex flex-row justify-between">
                                      <div className="w-full mr-4">
                                          <Switch name="offerTax"
                                                  label={getText('Tax included')}
                                                  placeholder={getText('Tax included')}
                                                  disabled={loading}
                                          />
                                      </div>

                                      <div className="w-full">
                                          <Switch name="smsActive"
                                                  label={getText('SMS notifications')}
                                                  placeholder={getText('SMS notifications')}
                                                  disabled={loading}
                                          />
                                      </div>

                                  </div>

                                  <SelectionList
                                      name="groups"
                                      label={getText('Roles')}
                                      validators={[FormValidators.minOneSelected(values.groups)]}
                                  />
                              </div>

                          )
                      }}
            />


    </Drawer>
    )
        ;
    }
}


export default compose(
    withSession,
    withMutation({
        mutation: ADD_NEW_USER,
        update: (props, cache, {data: {updateCompanyUserProfile}}) => {
            const variables = {
                applicationId: 1,
                companyId: props.session.companyId
            };

            const {companyUsers} = cache.readQuery({
                query: ALL_COMPANY_USERS,
                variables: variables,
            });

            cache.writeQuery({
                query: ALL_COMPANY_USERS,
                variables: variables,
                data: {companyUsers: companyUsers.concat([updateCompanyUserProfile.companyUser])},
            });
        },
        successMessage: "Changes were made successfully"
    })
)(AddUser);