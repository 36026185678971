import UUID from "uuid";
import {map, values, keyBy, get, filter} from 'lodash';
import {EMPTY_PICTURE} from "../components/pictures/data";
import {EMPTY_DAMAGE} from "../components/damages/data";
import {customer_initialize} from "../components/customer/data";
import {EMPTY_INSPECTION} from "./inspectionData";

export const inspectionStateToContext = (inspection) => {
   return {
       manual: inspection.manual,
       currentStepId: inspection.currentStepId,
       detailDamageId: inspection.vehicle.damages.detailDamageId,
       skippedSteps: inspection.skippedSteps,
       vehicleVersions: inspection.vehicleVersions,
       customerLookupDone: inspection.customer.lookupDone,
       showAllErrors: inspection.showAllErrors,
   }
};

export const inspectionStateToInspectionData = (inspection) => {
    const {vehicle, customer} = inspection;

    const damages = map(values(vehicle.damages.damagesById), (damage) => {
        return {
            id: damage.guid,
            locationId: (damage.locationId !== null) ? damage.locationId : 0,
            typeId: (damage.typeId !== null) ? damage.typeId : 0,
            severityId: (damage.severityId !== null) ? damage.severityId : 0,
            x: damage.x,
            y: damage.y,
            item: damage.item,
            comment: damage.comment,
        }
    });

    const checks = map(values(vehicle.checks), (check) => {
        return {
            checkId: check.checkId,
            value: check.value.toString(),
        }
    });

    const pictures = map(values(vehicle.pictures), (picture) => {
        return {
            id: picture.guid,
            fileId: (picture.fileId) ? picture.fileId : 0,
            refId: (picture.refId !== null && picture.refId !== 0) ? picture.refId : "",
            refType: (picture.refType !== null) ? picture.refType : ""
        }
    });

    const vehicleData = {
        id: vehicle.guid,
        makeId: vehicle.makeId,
        modelGroupId: vehicle.modelGroupId,
        modelId: vehicle.modelId,
        firstRegistration: (vehicle.firstRegistration !== null) ? vehicle.firstRegistration : "",
        powerKw: vehicle.powerKw,
        powerHp: vehicle.powerHp,
        versionId: vehicle.versionId,
        vin: vehicle.vin,
        co2: vehicle.co2,
        registration: vehicle.registration,
        reference: vehicle.reference,
        kilometers: (vehicle.kilometers !== "") ? parseInt(vehicle.kilometers) : 0,
        seats: vehicle.seats,
        doors: vehicle.doors,
        colorInteriorId: vehicle.colorInteriorId,
        colorInteriorOrigin: vehicle.colorInteriorOrigin,
        colorExteriorId: vehicle.colorExteriorId,
        colorExteriorOrigin: vehicle.colorExteriorOrigin,
        commentPositive: vehicle.commentPositive,
        commentNegative: vehicle.commentNegative,
        commentInternal: vehicle.commentInternal,
        title: vehicle.title,
        fuelId: vehicle.fuelId,
        gearboxId: vehicle.gearboxId,
        emissionNormId: vehicle.emissionNormId,
        engineId: vehicle.engineId,
        bodyId: vehicle.bodyId,
        wheelFormulaId: vehicle.wheelFormulaId,
        catalogPrice: vehicle.catalog,
        catalogPriceWithOptions: vehicle.catalogOptions,
    };

    const individual = {
        title: customer.individual.title,
        culture: customer.individual.language,
        firstName: customer.individual.firstName,
        lastName: customer.individual.lastName,
        address: {
            street: customer.individual.street,
            number: customer.individual.number,
            box: customer.individual.box,
            postalCode: customer.individual.postalCode,
            city: customer.individual.city,
            countryCode: customer.individual.country,
        },
        telephone: customer.individual.telephone,
        mobile: customer.individual.mobile,
        email: customer.individual.email,
    };

    const company = {
        name: customer.company.name,
        vatNumber: customer.company.vat,
        legalForm: customer.company.legalForm,
        address: {
            street: customer.company.street,
            number: customer.company.number,
            box: customer.company.box,
            postalCode: customer.company.postalCode,
            city: customer.company.city,
            countryCode: customer.company.country,
        },
        telephone: customer.company.telephone,
        email: customer.company.email,
    };

    const taxation = {
        isTaxable: (customer.taxation.isTaxable !== null) ? customer.taxation.isTaxable : undefined,
        isCorporation: (customer.taxation.isCompany !== null) ? customer.taxation.isCompany : undefined,
        taxablePercent: (customer.taxation.taxablePercent !== null) ? customer.taxation.taxablePercent : undefined,
        isDisabledPerson: (customer.taxation.isHandicapped !== null) ? customer.taxation.isHandicapped : undefined,
        isDiplomatic: (customer.taxation.isDiplomat !== null) ? customer.taxation.isDiplomat : undefined,
        hasBelgianRegistration: (customer.taxation.hasBelgianLicensePlate !== null) ? customer.taxation.hasBelgianLicensePlate : undefined,
    };

    const customerData = {
        id: customer.guid,
        origin: {
            origin: customer.origin,
            key: customer.key,
        },
        individual: individual,
        company: company,
        taxation: taxation,
    };

    return {
        guid: inspection.guid,
        vehicle: vehicleData,
        noDamages: vehicle.damages.noDamages,
        damages: damages,
        checks: checks,
        pictures: pictures,
        features: vehicle.featureIds,
        customer: customerData,
    }
};

export const inspectionToTempSaveCommandData = (inspection, version) => {
    const {vehicle, customer} = inspection;

    const damages = map(values(vehicle.damages.damagesById), (damage) => {
        return {
            id: damage.guid,
            locationId: (damage.locationId !== null) ? damage.locationId : 0,
            typeId: (damage.typeId !== null) ? damage.typeId : 0,
            severityId: (damage.severityId !== null) ? damage.severityId : 0,
            x: damage.x,
            y: damage.y,
            item: damage.item,
            comment: damage.comment,
        }
    });

    const checks = map(values(vehicle.checks), (check) => {
        return {
            checkId: check.checkId,
            value: check.value.toString(),
        }
    });

    const pictures = map(values(vehicle.pictures), (picture) => {
        return {
            id: picture.guid,
            fileId: (picture.fileId) ? picture.fileId : 0,
            refId: (picture.refId !== null && picture.refId !== 0) ? picture.refId : "",
            refType: (picture.refType !== null) ? picture.refType : ""
        }
    });

    const vehicleData = {
        id: vehicle.guid,
        makeId: vehicle.makeId,
        modelGroupId: vehicle.modelGroupId,
        modelId: vehicle.modelId,
        firstRegistration: (vehicle.firstRegistration !== null) ? vehicle.firstRegistration : "",
        powerKw: vehicle.powerKw,
        powerHp: vehicle.powerHp,
        versionId: vehicle.versionId,
        vin: vehicle.vin,
        co2: vehicle.co2,
        registration: vehicle.registration,
        reference: vehicle.reference,
        kilometers: (vehicle.kilometers !== "") ? parseInt(vehicle.kilometers) : 0,
        seats: vehicle.seats,
        doors: vehicle.doors,
        colorInteriorId: vehicle.colorInteriorId,
        colorInteriorOrigin: vehicle.colorInteriorOrigin,
        colorExteriorId: vehicle.colorExteriorId,
        colorExteriorOrigin: vehicle.colorExteriorOrigin,
        commentPositive: vehicle.commentPositive,
        commentNegative: vehicle.commentNegative,
        commentInternal: vehicle.commentInternal,
        title: vehicle.title,
        fuelId: vehicle.fuelId,
        gearboxId: vehicle.gearboxId,
        emissionNormId: vehicle.emissionNormId,
        engineId: vehicle.engineId,
        bodyId: vehicle.bodyId,
        wheelFormulaId: vehicle.wheelFormulaId,
        catalogPrice: vehicle.catalog,
        catalogPriceWithOptions: vehicle.catalogOptions,
    };


    const individual = {
        title: customer.individual.title,
        culture: customer.individual.language,
        firstName: customer.individual.firstName,
        lastName: customer.individual.lastName,
        address: {
            street: customer.individual.street,
            number: customer.individual.number,
            box: customer.individual.box,
            postalCode: customer.individual.postalCode,
            city: customer.individual.city,
            countryCode: customer.individual.country,
        },
        telephone: customer.individual.telephone,
        mobile: customer.individual.mobile,
        email: customer.individual.email,
    };

    const company = {
        name: customer.company.name,
        vatNumber: customer.company.vat,
        legalForm: customer.company.legalForm,
        address: {
            street: customer.company.street,
            number: customer.company.number,
            box: customer.company.box,
            postalCode: customer.company.postalCode,
            city: customer.company.city,
            countryCode: customer.company.country,
        },
        telephone: customer.company.telephone,
        email: customer.company.email,
    };

    const taxation = {
        isTaxable: (customer.taxation.isTaxable !== null) ? customer.taxation.isTaxable : undefined,
        isCorporation: (customer.taxation.isCompany !== null) ? customer.taxation.isCompany : undefined,
        taxablePercent: (customer.taxation.taxablePercent !== null) ? customer.taxation.taxablePercent : undefined,
        isDisabledPerson: (customer.taxation.isHandicapped !== null) ? customer.taxation.isHandicapped : undefined,
        isDiplomatic: (customer.taxation.isDiplomat !== null) ? customer.taxation.isDiplomat : undefined,
        hasBelgianRegistration: (customer.taxation.hasBelgianLicensePlate !== null) ? customer.taxation.hasBelgianLicensePlate : undefined,
    };

    const customerData = {
        id: customer.guid,
        origin: {
            origin: customer.origin,
            key: customer.key,
        },
        individual: individual,
        company: company,
        taxation: taxation,
    };

    const contextData = {
        manual: inspection.manual,
        currentStepId: inspection.currentStepId,
        detailDamageId: inspection.vehicle.damages.detailDamageId,
        skippedSteps: inspection.skippedSteps,
        vehicleVersions: inspection.vehicleVersions,
        lookupDone: inspection.customer.lookupDone,
        showAllErrors: inspection.showAllErrors,
    };

    return {
        guid: inspection.guid,
        vehicle: vehicleData,
        noDamages: vehicle.damages.noDamages,
        damages: damages,
        checks: checks,
        pictures: pictures,
        features: vehicle.featureIds,
        customer: customerData,
        context: {
            applicationId: "WEB/INSPECTOR/3",
            data: JSON.stringify(contextData)
        },

    }
};

export const StateToContext = (inspection) => {
    return {
        manual: inspection.manual,
        currentStepId: inspection.currentStepId,
        detailDamageId: inspection.vehicle.damages.detailDamageId,
        skippedSteps: inspection.skippedSteps,
        vehicleVersions: inspection.vehicleVersions,
        customerLookupDone: inspection.customer.lookupDone,
        showAllErrors: inspection.showAllErrors
    }
}

export const ContextMapper = (context) => {
    const manual = (context && context.manual) ? context.manual : false;
    const currentStepId = (context && context.currentStepId) ? context.currentStepId : 99;
    const detailDamageId = (context && context.detailDamageId && context.detailDamageId !== "") ? context.detailDamageId : null;
    const skippedSteps = (context && context.skippedSteps) ? context.skippedSteps : [];
    const vehicleVersions = (context && context.vehicleVersions) ? context.vehicleVersions : [];
    const customerLookupDone = (context && context.customerLookupDone) ? context.customerLookupDone : false;
    const showAllErrors = (context && context.showAllErrors) ? context.showAllErrors : false;


    return {
        manual: manual,
        currentStepId: currentStepId,
        skippedSteps: skippedSteps,
        vehicleVersions: vehicleVersions,
        showAllErrors: showAllErrors,
        detailDamageId: detailDamageId,
        customerLookupDone: customerLookupDone,
    }
};


export const TempInspectionToState = (data, context = null) => {

    const damages = {
        damagesById: keyBy(map(data.damages, (damage) => {
            return {
                ...EMPTY_DAMAGE,
                guid: damage.id || "",
                item: damage.item || "",
                locationId: (damage.locationId && damage.locationId !== 0) ? damage.locationId : null || null,
                locationText: damage.location || "",
                typeId: (damage.typeId && damage.typeId !== 0) ? damage.typeId : null || null,
                typeText: damage.type || "",
                severityId: (damage.severityId && damage.severityId !== 0) ? damage.severityId : null || null,
                severityText: damage.severity || "",
                x: damage.x || 0,
                y: damage.y || 0,
                comment: damage.comment || "",
            }
        }), 'guid'),
        detailDamageId: null,
        noDamages: data.noDamages,
    };

    const checks = keyBy(map(data.checks, (check) => {
        return {
            checkId: check.checkId,
            value: check.value,
        }
    }), "checkId");

    const pictures = keyBy(map(data.pictures, (picture) => {
        let refId = null;
        if (picture.refType && picture.refType === "pictureType") {
            if (picture.refId && picture.refId !== "") {
                refId = parseInt(picture.refId)
            }
        } else if (picture.refType && picture.refType === "damage") {
            if (picture.refId && picture.refId !== "") {
                refId = picture.refId
            }
        }

        return {
            ...EMPTY_PICTURE,
            guid: picture.id || "",
            fileId: (picture.fileId) ? (picture.fileId !== 0) ? picture.fileId : null : null,
            refId: refId,
            refType: (picture.refType) ? picture.refType !== "" ? picture.refType : null : null,
            url: picture.url || null,
            thumbnailUrl: picture.thumbnailUrl || null,
        }
    }), "guid");

    const vehicle = {
        guid: data.vehicle.id,
        makeId: data.vehicle.makeId,
        makeText: data.vehicle.make,
        modelGroupId: data.vehicle.modelGroupId,
        modelGroupText: data.vehicle.modelGroup,
        modelId: data.vehicle.modelId,
        modelText: data.vehicle.model,
        firstRegistration: (data.vehicle.firstRegistration !== "") ? data.vehicle.firstRegistration : null,
        powerKw: data.vehicle.powerKw,
        powerHp: data.vehicle.powerHp,
        versionId: data.vehicle.versionId,
        versionText: data.vehicle.version,
        vin: data.vehicle.vin,
        co2: data.vehicle.co2,
        registration: data.vehicle.registration,
        reference: data.vehicle.reference,
        kilometers: (data.vehicle.kilometers === 0) ? "" : data.vehicle.kilometers.toString(),
        seats: data.vehicle.seats,
        doors: data.vehicle.doors,
        colorInteriorId: data.vehicle.colorInteriorId,
        colorInteriorText: data.vehicle.colorInterior,
        colorInteriorOrigin: data.vehicle.colorInteriorOrigin,
        colorExteriorId: data.vehicle.colorExteriorId,
        colorExteriorText: data.vehicle.colorExterior,
        colorExteriorOrigin: data.vehicle.colorInteriorOrigin,
        commentPositive: data.vehicle.commentPositive,
        commentNegative: data.vehicle.commentNegative,
        commentInternal: data.vehicle.commentInternal,
        title: data.vehicle.title,
        fuelId: data.vehicle.fuelId,
        fuelText: data.vehicle.fuel,
        gearboxId: data.vehicle.gearboxId,
        gearboxText: data.vehicle.gearbox,
        emissionNormId: data.vehicle.emissionNormId,
        emissionNormText: data.vehicle.emissionNorm,
        engineId: data.vehicle.engineId,
        engineText: data.vehicle.engine,
        bodyId: data.vehicle.bodyId,
        bodyText: data.vehicle.body,
        wheelFormulaId: data.vehicle.wheelFormulaId,
        wheelFormulaText: data.vehicle.wheelFormula,
        catalog: data.vehicle.catalogPrice,
        catalogOptions: data.vehicle.catalogPriceWithOptions,
        damages: damages,
        checks: checks,
        pictures: pictures,
        featureIds: map(data.features, (feature) => feature.featureId),
    };

    const customer = {
        ...customer_initialize(),
        guid: data.customer.id,
        key: data.customer.origin.key,
        origin: data.customer.origin.key,
        individual: {
            title: data.customer.individual.title,
            lastName: data.customer.individual.lastName,
            firstName: data.customer.individual.firstName,
            street: data.customer.individual.address.street,
            number: data.customer.individual.address.number,
            box: data.customer.individual.address.box,
            postalCode: data.customer.individual.address.postalCode,
            city: data.customer.individual.address.city,
            country: data.customer.individual.address.countryCode,
            telephone: data.customer.individual.telephone,
            mobile: data.customer.individual.mobile,
            email: data.customer.individual.email,
            language: data.customer.individual.culture
        },
        company: {
            name: data.customer.company.name,
            vat: data.customer.company.vatNumber,
            legalForm: data.customer.company.legalForm,
            street: data.customer.company.address.street,
            number: data.customer.company.address.number,
            box: data.customer.company.address.box,
            postalCode: data.customer.company.address.postalCode,
            city: data.customer.company.address.city,
            country: data.customer.company.address.countryCode,
            telephone: data.customer.company.telephone,
            email: data.customer.company.email
        },
        taxation: {
            isTaxable: data.customer.taxation.isTaxable,
            isCompany: data.customer.taxation.isCorporation,
            taxablePercent: data.customer.taxation.taxablePercent,
            isHandicapped: data.customer.taxation.isDisabledPerson,
            isDiplomat: data.customer.taxation.isDiplomatic,
            hasBelgianLicensePlate: data.customer.taxation.hasBelgianRegistration,
        },
        process: {
            key: "",
            origin: "",
            brand: "",
            state: "",
            date: "",
            leadSalesMan: ""
        },
        lookupDone: false,
    };

    const inspection = {
        ...EMPTY_INSPECTION,
        id: data.id,
        guid: data.guid,
        currentStepId: 99,
        vehicle: vehicle,
        customer: customer
    };

    if (context) {
        inspection.manual = context.manual;
        inspection.currentStepId = context.currentStepId;
        inspection.vehicle.damages.detailDamageId = context.detailDamageId;
        inspection.skippedSteps = context.skippedSteps;
        inspection.vehicleVersions = context.vehicleVersions;
        inspection.customer.lookupDone = context.customerLookupDone;
        inspection.showAllErrors = context.showAllErrors;
    }

    return inspection;
}
