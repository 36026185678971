import React, {useState} from 'react';
import Image from "../../inspection/components/common/Image";
import DragAndDrop from "../../inspection/components/common/DragAndDrop";
import {find, map} from 'lodash';
import IconButton from "../../../../general/action/components/IconButton";
import {range, head} from 'lodash';
import ReactSVG from "react-svg";
import ClipLoader from "react-spinners/ClipLoader";

const empty_pictures = 5;

const DamageRow = ({damage, pictures, onDragPicture, handlePictureClick, handleDeleteDamagePicture, className, handleDropMoreByDrag, handleDropMoreByClick, handleDeleteDamage, onEditDamage, locations, height, width,}) => {
    const [opacity, setOpacity] = useState(1);


    const renderEmptyPictures = (total_pictures) => {
        const counter = empty_pictures - total_pictures;
        return range(counter).map(item => {

            return (
                <div key={item} className="flex items-center justify-center border border-gray-200 ml-1"
                     style={{height: height, width: width - 8, maxWidth: 158, flex: "0 0 auto"}}>
                    <i className="fal fa-image text-5xl text-gray-200"/>
                </div>
            )
        });
    };


    return (

        <div className={`damage-list-row flex flex-col opacity-100 overflow-scroll ${className}`}
             style={{opacity: opacity}}>
            <div className="flex flex-row">
                <DragAndDrop highlightOnHover={true} handleDrop={(files) => handleDropMoreByDrag(damage, files)}
                             onClick={() => handleDropMoreByClick(damage)} className="mr-1 w-full" borderClasses="">

                    <div
                        className="flex w-1/7 flex-col items-center justify-center p-2 border-gray-100 border-l border-t border-b"
                        style={{width: 40, height: height}}>
                        <IconButton icon="fa fa-highlighter" color="gray" size="md" className="mb-4" onClick={() => {
                            onEditDamage(damage.guid);
                        }}/>
                        <IconButton icon="fa fa-times" color="red" size="md" onClick={() => {
                            setOpacity(0);
                            setTimeout(() => handleDeleteDamage(damage.guid), 400);
                        }}/>
                    </div>


                    <div className="flex w-2/7 flex-col p-2 bg-gray-100 relative" style={{width: 180, height: height}}>
                        <div
                            className="mb-1 text-gray-900 font-medium">{damage.locationText}</div>
                        <div className="mb-1 text-gray-900 ">
                            {damage.typeText}
                        </div>
                        <div className="text-gray-800">
                            {damage.severityText}
                        </div>
                    </div>
                    <div className={`flex flex-row flex-1 w-4/7`}>
                        <div className="border border-gray-200">
                            <ReactSVG
                                renumerateIRIElements={false}
                                src={require(`../../../../PUBLIC/damages/${damage.item}.svg`)}
                                className="px-2"
                                beforeInjection={svg => {
                                    Object.assign(svg.style, {width: width, height: height - 2});

                                    try {
                                        const path = svg.getElementById(`location-${damage.locationId}`);
                                        path.style.fill = 'red';
                                        path.style.fillOpacity = '0.6';
                                    } catch (ex) {

                                    }
                                }}
                                loading={() => <ClipLoader
                                    sizeUnit={"px"}
                                    size={25}
                                    color={'black'}
                                />}
                            />
                        </div>
                        <div className="flex flex-row w-full flex-row flex-no-wrap overflow-x-scroll">

                            {map([...pictures].reverse(), (pic, i) => {
                                return (
                                    <div key={i} className="ml-1" style={{flex: "0 0 auto"}}>

                                        <Image picture={pic}
                                               onDrag={(e) => onDragPicture(e, pic)}
                                               onClick={(e, id) => handlePictureClick(id)}
                                               onRemove={(id) => handleDeleteDamagePicture(damage.guid, id)}
                                               height={height}
                                               width={width}
                                               selectAllowed={false}
                                        />

                                    </div>
                                )
                            })}

                            {pictures.length < 5 && renderEmptyPictures(pictures.length)}

                        </div>
                    </div>

                </DragAndDrop>


            </div>
        </div>
    )
};

DamageRow.defaultProps = {
    height: 110,
    width: 147,
    className: "mb-4"
};

export default DamageRow;