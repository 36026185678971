import React from 'react';
import {TextLabel} from "../../../../general/forms";
import moment from "moment";

const Summary = ({vehicle}) => {
    return (
        <div className="flex flex-row w-full bg-white">
            <div style={{width: 232}}>
                <img src={vehicle.pictureThumbnailUrl} alt={vehicle.vin}/>
            </div>

            <div className="flex flex-col w-full pl-2">

                <div className="flex flex-row w-full mb-2">
                    <span className="bg-green-400 text-white rounded p-1">
                        {(vehicle.vatDeductible) ? 'VAT deductible' : 'VAT not deductible'}
                    </span>
                </div>

                <div className="flex flex-row w-full">

                    <div className="w-3/10 mr-4">
                        <TextLabel name="vin"
                                   label="Vin number"
                                   placeholder="Vin number"
                                   value={vehicle.vin}
                                   className="p-1"
                                   marginBottom={2}
                        />
                    </div>

                    <div className="w-2/10 mr-4">
                        <TextLabel name="mileage"
                                   label="Mileage"
                                   placeholder="Mileage"
                                   value={vehicle.kilometers.toString()}
                                   className="p-1"
                                   marginBottom={2}
                        />
                    </div>

                    <div className="w-1/10 mr-4 mb-2">

                    </div>

                    <div className="w-4/10 mb-0">
                        <TextLabel name="takeOverPrice"
                                   label="Take over"
                                   value="0 €"
                                   inputClassName="bg-blue-500 text-white"
                                   className="bg-blue-500 text-white text-right p-1"
                                   textClassName="text-white text-right"
                                   labelTextClassName="text-white"
                                   showBorder={false}
                                   labelRight={true}
                                   marginBottom={2}
                        />

                    </div>
                </div>

                <div className="flex flex-row w-full">
                    <div className="w-3/10 mr-4">
                        <TextLabel name="registration"
                                   label="1st registration"
                                   value={moment(vehicle.firstRegistration).format('DD/MM/YYYY')}
                                   className="p-1"
                                   marginBottom={0}
                        />
                    </div>

                    <div className="w-2/10 mr-4">
                        <TextLabel name="registration"
                                   label="Registration"
                                   value={vehicle.registration}
                                   className="p-1"
                                   marginBottom={0}
                        />
                    </div>

                    <div className="w-1/10 mr-4">
                        <TextLabel name="internal"
                                   label="Internal"
                                   value={(vehicle.internal) ? vehicle.internal : ""}
                                   className="p-1"
                                   marginBottom={0}
                        />
                    </div>

                    <div className="flex flex-row w-4/10">
                        <div className="w-1/2 mr-4">
                            <TextLabel name="marketPrice"
                                       label="Market price"
                                       value="0€"
                                       className="p-1 text-right"
                                       labelTextClassName="text-blue-300"
                                       textClassName="text-blue-500 text-right "
                                       showBorder={false}
                                       marginBottom={0}
                            />

                        </div>
                        <div className="w-1/2">
                            <TextLabel name="marketStockDays"
                                       label="Market stock days"
                                       value="0"
                                       className="p-1 text-right"
                                       labelTextClassName="text-blue-300"
                                       textClassName="text-blue-500 text-right"
                                       showBorder={false}
                                       marginBottom={0}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Summary;