import React from "react";
import {default as BaseSelectionList} from "../base/SelectionList";
import LabelContainer from "./LabelContainer";

const SelectionList = ({label, options, required, disabled, onChange, className, inputClassName, showBorder, error, hasError}) => {

    return (
        <LabelContainer label={label}
                        disabled={disabled}
                        className={className}
                        error={error}
                        hasError={hasError}>

            <BaseSelectionList
                values={options}
                onSelectionChanged={onChange}
                disabled={disabled}
                required={required}
                inputClassName={inputClassName}
                hasError={hasError}
                showBorder={showBorder}
            />

        </LabelContainer>
    );
};

export default SelectionList;


