import React from 'react';
import {Button} from '../../general';
import UUID from "uuid";
import {useLazyQuery, useMutation, useQuery} from "@apollo/react-hooks";
import cogoToast from "cogo-toast";


const MutationButton = ({mutationQuery, mutationData, mutationResultQuery, mutationResultQueryVariables, children, active}) => {
    const [mutation, {called: mutationCalled, loading: mutationLoading}] = useMutation(mutationQuery);
    const [resultQuery, {called: resultQueryCalled, loading: updateLoading}] = useLazyQuery(mutationResultQuery, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            showSuccess('Setting changed');
        }
    });

    const loading = mutationLoading;

    const handleClick = (e) => {
        e.stopPropagation();
        executeMutation();
    };

    const executeMutation = () => {
        mutation({
            variables: {
                data: {
                    transactionId: UUID.v4(),
                    ...mutationData
                },
            }
        }).then((result) => {
            if (result.data) {
                const data = result.data[Object.keys(result.data)[0]];
                const {transactionId, success, errors} = data.result;

                // if (success) {
                //     executeMutationResultQuery(data.result)
                // }
            }
        })
    };

    const executeMutationResultQuery = (data) => {
        const variables = mutationResultQueryVariables(data);
        resultQuery({...variables})
    };

    const showSuccess = (message) => {
        cogoToast.success(message, {hideAfter: 5});
    };

    return (
        <Button type='toggle'
                active={active}
                loading={loading}
                onClick={handleClick}>
            {children}
        </Button>
    )
};


MutationButton.defaultProps = {
    type: 'default',
    size: 'md',
    tooltip: null,
    icon: null,
};

export default MutationButton;



