import React from 'react';
import Button from "../../../general/action/Button";

const Home = () => {
    return <div className="p-2 text-gray-600">
        <div className="text-2xl">Inventory</div>
        <div className="pt-1 pb-2 border-b border-gray-300 text-gray-500">Manage your stock</div>
        <div className="text-center pt-2">
            <Button icon={"fal fa-plus"} type="primary" className="w-full">new Car</Button>
        </div>
    </div>
};

export default Home;
