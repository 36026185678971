import React from 'react';
import {Drawer, withMutation} from '../../../general';
import {Form, MaskedInput, FormValidators, FormActionButton} from '../../../general/forms-final-form'
import UUID from 'uuid';
import gql from 'graphql-tag';
import {getText} from "../../../translations";
import {useMutation} from "@apollo/react-hooks";
import {mutations} from "./graphql";
import {errorNotifier, successNotifier} from "../../../general/utils";

const formId = UUID.v4();


const ChangePassword = ({onClose}) => {
    const [passwordResetMutation, {called, loading, error}] = useMutation(mutations.passwordReset);

    let formReset = null;

    const title = () => (
        <div className="flex flex-grow justify-between items-center">
            <FormActionButton formId={formId}
                              disabled={called && loading}
            >
                {getText('Save')}
            </FormActionButton>
        </div>
    );

    const handleSubmit = (data, form) => {
        passwordResetMutation({
            variables: {
                data: {
                    transactionId: UUID.v4(),
                    currentPassword: data.current_pw,
                    newPassword: data.new_pw,
                }
            }
        }).then((result) => {
            const {userPasswordChangeHandler} = result.data;
            const {success} = userPasswordChangeHandler.result;

            if (success) {
                form.reset();
                successNotifier(getText('Password changed successfully'))
            } else {
                const {errors} =  userPasswordChangeHandler.result;
                errorNotifier(getText("Something went wrong"))
            }
        });
    };

    console.log(error);

    return (
        <Drawer size="sm"
                title={title}
                onClose={onClose}
                visible={true}>

            <div className="p-2">
                <p>
                    {getText('To make an update, enter your existing password followed by a new one. If you don\'t know your existing password, you can logout and use the Reset Password link on the Sign In page.')}
                </p>

                <br/>

                <Form id={formId}
                      onSubmit={handleSubmit}
                      indicators={{
                          loading: loading,
                          error: (error && error.graphQLErrors.length > 0) ? error.graphQLErrors[0].message : ''
                      }}
                      render={({values, form}) => {
                          if (formReset === null) {
                              formReset = form.reset;
                          }

                          return (
                              <React.Fragment>
                                  <MaskedInput name="current_pw"
                                               label={getText("Current password")}
                                               placeholder={getText("Current password")}
                                               validators={[FormValidators.required]}
                                  />

                                  <MaskedInput name="new_pw"
                                               label={getText('New password')}
                                               placeholder={getText('New password')}
                                               validators={[FormValidators.required]}
                                  />

                                  <MaskedInput name="confirm_pw"
                                               label={getText("Confirm password")}
                                               placeholder={getText("Confirm password")}
                                               validators={[FormValidators.required, FormValidators.passwordsEqual(values.new_pw)]}
                                  />
                              </React.Fragment>
                          )
                      }}
                />

                <div>
                    the password requirements are:
                    <ul>
                        <li>min x characters</li>
                        <li>min x characters</li>
                        <li>min x characters</li>
                        <li>min x characters</li>
                        <li>min x characters</li>

                    </ul>

                </div>
            </div>

        </Drawer>
    )
};

export default ChangePassword;
