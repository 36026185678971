import React from 'react';
import TextButton from './components/TextButton';
import IconButton from './components/IconButton';
import ToggleButton from './components/ToggleButton'
/**
 * The only true button.
 *
 * @visibleName Button
 */
export default class Button extends React.Component {
    render() {
        const {type} = this.props;

        if (type === 'icon-only') {
            return (<IconButton {...this.props }/>);
        } else if (type === "toggle") {
            return (<ToggleButton {...this.props }/>);
        } else {
            return (<TextButton {...this.props }/>);

        }
    }
}


Button.defaultProps = {
    type: 'default',
    size: 'md',
    tooltip: null,
    icon: null,
    active: false,
    color: 'base',
};
