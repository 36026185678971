import React, {useState} from 'react';
import Channels from "./Channels";
import {default as BaseNumericInput} from "../../../../../../general/base/NumericInput";
import {default as BaseDatePicker} from "../../../../../../general/base/DatePicker";
import moment from "moment";
import {useTranslate} from "../../../../../../translations";
import {DatePicker, TextInput} from "../../../../../../general/forms";
import NumericInput from "../../../../../../general/forms/NumericInput";

const Publish = ({lead, onSend}) => {
    const {getText} = useTranslate();

    const [expectedPrice, setExpectedPrice] = useState(lead.expectationPrice !== 0 ? lead.expectationPrice : undefined);
    const [expectedDateFrom, setExpectedDateFrom] = useState(lead.expectationDateFrom);
    const [expectedDateTo, setExpectedDateTo] = useState(lead.expectationDateTo);
    const [expectedKilometers, setExpectedKilometers] = useState(lead.expectationKm !== 0 ? lead.expectationKm : undefined);
    const [showValidationErrors, setShowValidationErrors] = useState(false);

    const readOnly = lead.state !== "CONFIRMED";


    const handleExpectationDate = (date) => {
        setExpectedDateFrom(date);

        if (expectedKilometers === undefined || expectedKilometers === "") {
            const {firstRegistration, kilometers} = lead.inspection.vehicle;
            const daysPassed = moment().diff(moment(firstRegistration), 'days');
            const kilometersPerDay = kilometers / daysPassed;
            const daysToGo = moment(date).diff(moment(), 'days');

            const expectedKmSuggestion = kilometers + Math.round(daysToGo * kilometersPerDay);

            setExpectedKilometers(expectedKmSuggestion)
        }

        handleExpectations();
    };


    const handleExpectations = () => {
        // TODO mutation to server
        if (lead.expectationPrice !== expectedPrice ||
            lead.expectationDateFrom !== expectedDateFrom ||
            lead.expectationDateTo !== expectedDateTo ||
            lead.expectationKm !== expectedKilometers) {

        }
    };

    const handleSendToNetwork = () => {
        if (expectedDateFrom === "" || expectedDateFrom === null || !expectedKilometers) {
            setShowValidationErrors(true);
        } else {
            onSend();
        }
    };


    return (
        <div>
            <div className="flex flex-row w-full">
                <NumericInput label={getText("Expected price incl. 21% VAT")}
                              value={expectedPrice}
                              min={1}
                              max={999999}
                              onChange={(value) => setExpectedPrice(value)}
                              className={`flex flex-row w-2/3 items-center ${(readOnly) ? 'bg-gray-100' : 'bg-white'}`}
                              disabled={readOnly}
                              showBorder={!readOnly}
                              valueContainerClassName="w-1/3"
                              textClassName="text-gray-900"
                              inputClassName='text-right pr-1'
                              error=""
                              suffix={(expectedPrice) ? '€' : ""}
                              marginBottom={2}
                              onBlur={() => handleExpectations()}
                />
            </div>

            <div className="flex flex-row w-full">
                <DatePicker required={true}
                            label={getText("Expected to arrive on")}
                            onChange={handleExpectationDate}
                            className={`flex flex-row w-2/3 items-center ${(readOnly) ? 'bg-gray-100' : 'bg-white'}`}
                            value={expectedDateFrom}
                            disabled={readOnly}
                            showBorder={!readOnly}
                            defaultView="month"
                            minDate={new Date()}
                            valueContainerClassName="w-1/3"
                            textClassName="text-gray-900"
                            inputClassName='text-right pr-1'
                            marginBottom={2}
                            error={(showValidationErrors && !expectedDateFrom) ? getText('Expected date is required') : ""}
                />
            </div>

            <div className="flex flex-row w-full">
                <NumericInput label={(expectedDateFrom !== null) ? `${getText("Expected km’s on")} ${moment(expectedDateFrom).format("DD/MM/YYYY")}` : getText("Expected km’s on arrival")}
                              value={expectedKilometers}
                              required={true}
                              min={1}
                              max={999999}
                              onChange={(value) => setExpectedKilometers(value)}
                              className={`flex flex-row w-2/3 items-center ${(readOnly) ? 'bg-gray-100' : 'bg-white'}`}
                              disabled={readOnly}
                              showBorder={!readOnly}
                              valueContainerClassName="w-1/3"
                              textClassName="text-gray-900"
                              inputClassName='text-right pr-1'
                              suffix={(expectedKilometers) ? 'km' : ''}
                              marginBottom={2}
                              error={(showValidationErrors && !expectedKilometers) ? getText('Expected kilometers is required') : ""}
                              onBlur={() => handleExpectations()}
                />
            </div>


            <div className="mt-2">
                <Channels onSend={() => handleSendToNetwork()}/>
            </div>
        </div>
    )
};

export default Publish;
