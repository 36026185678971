import React from 'react';
import Heading from "./home/components/Heading";
import {useTranslate} from "../../../../translations";
import EuroTaxValuation from "./valuation/EuroTaxValuation";
import RadarValuation from "./valuation/RadarValuation";
import {Similarity} from './valuation/RadarValuation'

const Valuation = ({}) => {
    const {getText} = useTranslate();

    const vehicle = {
        kilometers: 106500,
        catalogPrice: 29900,
        catalogPriceWithOptions: 33500,
        modelStartDate: "2016-01-01",
        modelStopDate: "2018-06-05",
    };

    const valuation = {
        averagePriceB2B: 15000,
        averagePriceB2C: 12000,
        averageKm: 85200,
        ageAmount: 500,
        kmAmount: -200,
    };

    const marketVehicles = [
        {
            similarity: Similarity.HIGH,
            stockDays: 15,
            distance: 15,
            dealerName: 'TrendiCars',
            version: '1.4 TFSI Sport',
            firstRegistration: '2016-05-02',
            kilometers: 75000,
            price: 16500,
        },
        {
            similarity: Similarity.MEDIUM,
            stockDays: 33,
            distance: 75,
            dealerName: 'AlphaCars',
            version: '1.4 TFSI Sport',
            firstRegistration: '2016-05-02',
            kilometers: 85000,
            price: 17650,
        },
        {
            similarity: Similarity.VERY_HIGH,
            stockDays: 45,
            distance: 10,
            dealerName: 'BetaCars',
            version: '1.4 TFSI Sport',
            firstRegistration: '2016-05-02',
            kilometers: 105000,
            price: 22950,
        },
        {
            similarity: Similarity.MEDIUM,
            stockDays: 33,
            distance: 75,
            dealerName: 'AlphaCars',
            version: '1.4 TFSI Sport',
            firstRegistration: '2016-05-02',
            kilometers: 85000,
            price: 17650,
        },

    ];



    return (
        <div className="flex flex-row h-full mr-2">
            <div className={`flex flex-1 flex-col mr-2 p-2 pt-0 bg-white`}>
                <Heading title={getText('Eurotax')}/>
                <EuroTaxValuation vehicle={vehicle} valuation={valuation}/>
            </div>

            <div className={`flex flex-1 flex-col ml-2 p-2 pt-0 bg-white`}>
                <Heading title={getText('Radar')}/>
                <RadarValuation marketVehicles={marketVehicles}/>
            </div>
        </div>
    )
};

export default Valuation

