import React from 'react';
import Buttons from "./Buttons";
import Summary from "./Summary";
import WizardAnimation from "./WizardAnimation";
import {Customer} from '../../components/customer';
import {find, keyBy} from "lodash";
import {wizardSteps} from "../inspectionData";

const getValidationErrors = (flowProgress) => {
    const progressStep = find(flowProgress, (fp) => fp.stepId === wizardSteps.customer.id);
    if (progressStep !== undefined) {
        return keyBy(progressStep.errors, 'field');
    }
    return {}
};

const CustomerStep = ({onNext, onPrev, onStepClick, wizard, data}) => {
    const validationErrors = getValidationErrors(wizard.flowProgress);

    return (
        <div className="flex h-full w-full">
            <div className="w-1/4">
                <Summary data={wizard} onStepClick={onStepClick}/>
            </div>

            <div className="w-3/4 flex flex-col flex-1 bg-white p-2">
                <div className="flex flex-1 overflow-scroll ">
                    <WizardAnimation className="flex flex-row">
                        <Customer customer={data.inspection.customer}
                                  dispatch={data.dispatch}
                                  validationErrors={validationErrors}
                                  showAllErrors={data.inspection.showAllErrors}
                        />
                    </WizardAnimation>
                </div>

                <Buttons onNext={() => onNext({data: 'data'})}
                         onPrev={() => onPrev({data: 'data'})}
                         data={wizard}
                />
            </div>
        </div>
    )
};


export default CustomerStep;