import React from 'react'
import {useTranslate} from "../../../../../translations";
import {map} from "lodash";
import IndividualItem from "./IndividualItem";
import CompanyItem from "./CompanyItem";
import {Button} from "../../../../../general";
import SearchBalk from "./Search";
import LoadingIndicator from "../../../../../general/indicators/LoadingIndicator";
import ProcessItem from "./ProcessItem";

const SalesSearch = ({individuals, companies, processes, onSalesSearch, onSelectSales, onSelectSalesProcess, selectedSales, onResetSelectedSales, onResetSelectedSalesProcess, loading, errors, onCreateManual}) => {
    const {getText} = useTranslate();

    return (
        <div className="w-full h-full pl-1 relative">
            <React.Fragment>

                {individuals === null && companies === null &&
                <SearchBalk onSearch={onSalesSearch}/>}

                {errors !== null &&
                    <div>
                        <div>
                            {map(errors, (err) => (<span>{err}</span>))}
                        </div>

                        <Button onClick={onCreateManual}>
                            {getText("Contact manueel aanmaken")}
                        </Button>

                    </div>
               }

                {loading &&
                <LoadingIndicator/>}


                {((individuals !== null && individuals.length === 0) && (companies !== null && companies.length === 0) && processes === null) &&
                <div>
                    {errors === null &&
                    <div>
                        {getText("Geen resultaten gevonden.")}
                    </div>}

                    <Button onClick={onResetSelectedSales}>
                        {getText("Opnieuw zoeken")}
                    </Button>

                    <Button onClick={onCreateManual}>
                        {getText("Contact manueel aanmaken")}
                    </Button>

                </div>}


                <div className="mt-2">

                    {individuals !== null && individuals.length > 0 && (selectedSales === null || (selectedSales !== null && processes !== null && processes.length === 0)) &&
                    <div>
                        <div
                            className="flex flex-row justify-between p-2 pl-0 border border-gray-300 border-t-0 border-l-0 border-r-0 font-medium mb-2">

                            <div className="cursor-pointer" onClick={onResetSelectedSales}>
                                <i className="fal fa-chevron-left mr-2 text-xs font-medium"/>
                                <span className="cursor-pointer">
                                    {getText('back')}
                                </span>
                            </div>

                            {getText("Select individual")}

                            <div onClick={onCreateManual}>
                                CREATE MANUAL
                            </div>

                        </div>

                        <div>
                            {map(individuals, (individual, i) => {
                                return <IndividualItem individual={individual}
                                                       key={i}
                                                       onClick={() => {
                                                           onSelectSales({
                                                               individual
                                                           });


                                                       }}/>
                            })}
                        </div>
                    </div>}

                    {companies !== null && companies.length > 0 && (selectedSales === null || (selectedSales !== null && processes !== null && processes.length === 0)) &&
                    <div>
                        <div
                            className="flex flex-row justify-between p-2 pl-0 border border-gray-300 border-t-0 border-l-0 border-r-0 font-medium mb-2">

                            <div className="cursor-pointer" onClick={onResetSelectedSales}>
                                <i className="fal fa-chevron-left mr-2 text-xs font-medium"/>
                                <span className="cursor-pointer">
                                    {getText('back')}
                                </span>
                            </div>

                            {getText("Select company")}

                            <div onClick={onCreateManual}>
                                CREATE MANUAL
                            </div>

                        </div>
                        <div>
                            {map(companies, (company, i) => {
                                return <CompanyItem company={company}
                                                    key={i}
                                                    onClick={() => {
                                                        onSelectSales({
                                                            company
                                                        });
                                                    }}/>
                            })}
                        </div>
                    </div>}

                    {processes !== null && processes.length > 0 && selectedSales !== null &&
                    <div>
                        <div
                            className="flex flex-row justify-between p-2 pl-0 border border-gray-300 border-t-0 border-l-0 border-r-0 font-medium mb-2">

                            <div className="cursor-pointer" onClick={onResetSelectedSalesProcess}>
                                <i className="fal fa-chevron-left mr-2 text-xs font-medium"/>
                                <span className="cursor-pointer">
                                    {getText('back')}
                                </span>
                            </div>

                            {getText("Select sales process")}

                            <div>
                                NOT LISTED
                            </div>

                        </div>
                        <div>
                            {map(processes, (process, i) => {
                                return <ProcessItem process={process}
                                                    key={i}
                                                    onClick={() => {
                                                        onSelectSalesProcess({
                                                            process
                                                        });
                                                    }}/>
                            })}
                        </div>
                    </div>}
                </div>

            </React.Fragment>

        </div>
    )
};

export default SalesSearch;