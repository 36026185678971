import {emptyModuleConfig} from "../common";
import React from "react";
import Inventory from "./inventory/Inventory";
import Leads from "./leads/Leads";
import LeadsPanel from "./leads/LeadsPanel";
import Procurement from "./procurement/Procurement";
import InventoryPanel from "./inventory/InventoryPanel";
import ProcurementPanel from "./procurement/ProcurementPanel";
import Analytics from "./analytics/Analytics";


const MODULE_NAME = 'sales';

export function gatherConfig() {
    const config = emptyModuleConfig(MODULE_NAME);
    config.setMainMenu("Sales", '#ff0000');

    config.addBlock("Home", "fal fa-home", "manager", 0, null, null);
    config.addBlock("Leads", "fal fa-map-marker-smile", "manager", 0, <LeadsPanel />, <Leads />);
    config.addBlock("Stock", "fal fa-garage-car", "manager", 0, <InventoryPanel />, <Inventory />);
    config.addBlock("Procurement", "fal fa-search-dollar", "manager", 0, <ProcurementPanel />, <Procurement />);
    config.addBlock("Analytics", "fal fa-analytics", "manager", 0, null, <Analytics />);

    return config;

}


