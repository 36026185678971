import React from 'react';
import Template from '../common/Template';
import {required} from '../../general/forms/validators';
import {Form} from 'react-final-form'
import InputField from '../common/InputField';
import {withNavigation} from "../navigation";
import {useApolloClient, useMutation} from "@apollo/react-hooks";
import {TOKEN_AUTH} from "./mutations";
import cogoToast from "cogo-toast";
import {login} from './auth'

const Authentication = ({navigate}) => {
    const client = useApolloClient();
    const [tokenAuthMutation, {loading: tokenAuthLoading}] = useMutation(TOKEN_AUTH);

    const handleSubmit = (input) => {
        // Request token
        tokenAuthMutation({
            variables: {
                username: input.email,
                password: input.password,
            }
        }).then((result) => {
            if (result && result.data && result.data.jwtTokenAuthHandler) {
                const {token, refreshToken, user} = result.data.jwtTokenAuthHandler;
                login(client, user, token, refreshToken);
            }
        }).catch((err) => {
            if (err.graphQLErrors) {
                err.graphQLErrors.map(({code, message}) => {
                    if (code === "401") {
                        cogoToast.error(message, {hideAfter: 5});
                    }
                })
            }
        });
    };

    return (
        <Template title="Sign in" subtitle="With your Autralis account">
            <Form
                onSubmit={handleSubmit}
                render={({handleSubmit, pristine, invalid}) => (
                    <form onSubmit={handleSubmit}>
                        <div className="mt-6">
                            <InputField name="email"
                                        component="input"
                                        placeholder="Your email"
                                        validators={[required]}
                            />
                        </div>
                        <div className="mt-6">
                            <InputField name="password"
                                        component="input"
                                        type="password"
                                        placeholder="Your password"
                                        validators={[required]}
                            />
                        </div>
                        <div className="mt-6 flex flex-row justify-between items-center">
                            <div className="cursor-pointer">
                                <span onClick={() => navigate.register()}>Create account</span>
                            </div>
                            <div className="cursor-pointer">
                                    <span
                                        onClick={() => navigate.forgotPassword()}>Forgot password?</span>
                            </div>
                        </div>

                        <div className="mt-6 flex flex-row justify-center items-center">

                            <button type="submit"
                                    className="w-full text-center p-4 rounded bg-blue-500 hover:bg-blue-600 text-white hover:text-white outline-none button">
                                {tokenAuthLoading &&
                                <div className={`lds-ring mr-2 lds-ring-white`}
                                     style={{width: 14, borderColor: 'white'}}>
                                    <div></div>
                                </div>
                                }
                                Sign in
                            </button>
                        </div>

                        {/*{error}*/}


                    </form>
                )}
            />
        </Template>
    )
};


export default withNavigation(Authentication);


