import React, {useReducer} from 'react';
import {
    Customer,
    customerReducer,
} from "../../../components/customer";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {CUSTOMER_FULL} from "./queries";
import LoadingIndicator from "../../../../../general/indicators/LoadingIndicator";
import {customer_initialize, setCustomer} from "../../../components/customer/data";
import {CustomerToState, customerStateToCustomerInputType} from "./utils";
import {CUSTOMER_CHANGE} from "../../../components/customer/mutations";
import UUID from "uuid";


const LeadCustomer = ({customerId}) => {
    const [state, dispatch] = useReducer(customerReducer, null);

    const {loading: customerFetchLoading, error: customerFetchError} = useQuery(CUSTOMER_FULL, {
        fetchPolicy: 'network-only',
        variables: {customerId: customerId},
        onCompleted: data => {
            const parsed_data = CustomerToState(data.customer);
            setCustomer(dispatch, parsed_data);
        }
    });

    const [customerSaveMutation, {loading: customerSaveLoading, error: customerSaveError}] = useMutation(CUSTOMER_CHANGE);


    const handleCustomerChanges = () => {

        const variables = {
            data: {
                transactionId: UUID.v4(),
                id: customerId,
                customer: customerStateToCustomerInputType(state),
            }
        };

        customerSaveMutation({variables})
            .then((result) => {
            })
            .catch((ex) => {
            });
    };


    if (customerFetchLoading || state === null) {
        return <LoadingIndicator/>
    }

    return (
        <div className="pl-2 pr-2 pb-2 overflow-scroll">
            <Customer customer={state}
                      dispatch={dispatch}/>
        </div>
    )
};

export default LeadCustomer
