import React from 'react';
import {logout} from "../authentication";
import {useApolloClient} from "@apollo/react-hooks";

const SignOutButton = () => {
    const client = useApolloClient();

    return (
        <div className="cursor-pointer flex flex-row items-center hover:text-gray-400"
             onClick={() => logout(client)}>
            Sign Out <i className="fal fa-sign-out ml-2"/>
        </div>
    )
};
export default SignOutButton;
