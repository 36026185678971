import React from 'react';
import UUID from "uuid";

import {
    generate_initial_vehicle,
    vehicleAdditionalValidator,
    vehicleChecksValidator,
    vehicleDamagesValidator,
    vehicleFeaturesValidator,
    vehicleMakeValidator,
    vehicleModelGroupValidator,
    vehicleModelValidator,
    vehiclePicturesValidator,
    vehiclePowerValidator,
    vehicleReducer,
    vehicleRegistrationValidator,
    vehicleVersionValidator
} from './vehicleData';
import Make from "./wizard/Make";
import VinSearch from "./wizard/VinSearch";
import Registration from "./wizard/Registration";
import ModelGroup from "./wizard/Modelgroup";
import Model from "./wizard/Model";
import Power from "./wizard/Power";
import Version from "./wizard/Version";
import Features from "./wizard/Features";
import General from "./wizard/General";
import Checks from "./wizard/Checks";
import Pictures from "./wizard/Pictures";
import Damages from "./wizard/Damages";
import Customer from "./wizard/Customer";
import Confirmation from "./wizard/Confirmation";
import {getText} from "../../../translations";
import {
    customerReducer,
    customerValidator,
    customer_initialize,

} from "../components/customer/data";

export const wizardSteps = {
    vinSearch: {
        id: 0,
        weight: 0,
        name: 'vinSearch',
        component: <VinSearch/>,
        validator: (value, props) => {
            if (value.manual || value.vehicle.vin !== "") {
                return []
            }
            return [getText("No valid VIN or manual build selected")]
        }
    },
    make: {
        id: 2,
        weight: 0.3,
        name: 'make',
        component: <Make/>,
        validator: (value, props) => {
            return vehicleMakeValidator(value.vehicle.makeId, null)
        }
    },
    registration: {
        id: 3,
        weight: 0.3,
        name: 'registration',
        component: <Registration/>,
        validator: (value, props) => {
            return vehicleRegistrationValidator(value.vehicle.firstRegistration, null);
        }
    },
    modelGroup: {
        id: 4,
        weight: 0.3,
        name: 'modelGroup',
        component: <ModelGroup/>,
        validator: (value, props) => {
            return vehicleModelGroupValidator(value.vehicle.modelGroupId, null);
        }
    },
    model: {
        id: 5,
        weight: 0.3,
        name: 'model',
        component: <Model/>,
        validator: (value, props) => {
            return vehicleModelValidator(value.vehicle.modelId, null);
        }
    },
    power: {
        id: 6,
        weight: 0.3,
        name: 'power',
        component: <Power/>,
        validator: (value, props) => {
            return vehiclePowerValidator(value.vehicle.powerKw, null)
        }
    },
    version: {
        id: 7,
        weight: 0.3,
        name: 'version',
        component: <Version/>,
        validator: (value, props) => {
            return vehicleVersionValidator(value.vehicle.versionId, null)
        }
    },
    features: {
        id: 8,
        weight: 1,
        name: 'features',
        component: <Features/>,
        validator: (value, props) => {
            return vehicleFeaturesValidator(value.vehicle.featureIds, null);
        }
    },
    generalInfo: {
        id: 9,
        weight: 1,
        name: 'generalInfo',
        component: <General/>,
        validator: (value, props) => {
            return vehicleAdditionalValidator(value.vehicle, props.settings.customerSettings);
        }
    },
    checks: {
        id: 10,
        weight: 1,
        name: 'checks',
        component: <Checks/>,
        validator: (value, props) => {
            return vehicleChecksValidator(value.vehicle.checks, props.checkGroups);
        }
    },
    pictures: {
        id: 11,
        weight: 1,
        name: 'pictures',
        component: <Pictures/>,
        validator: (value, props) => {
            return vehiclePicturesValidator(value.vehicle.pictures, props.pictureTypes);
        }
    },
    damages: {
        id: 12,
        weight: 1,
        name: 'damages',
        component: <Damages/>,
        validator: (value, props) => {
            return vehicleDamagesValidator(value.vehicle.damages.damagesById, value.vehicle.damages.noDamages, props.settings.customerSettings.INSPECTION_DAMAGE_DETAIL_REQUIRED);
        }
    },
    customer: {
        id: 13,
        weight: 1,
        name: 'customer',
        component: <Customer/>,
        validator: (value, props) => {
            return customerValidator(value.customer, null)
        }
    },

    confirmation: {
        id: 99,
        weight: 0.1,
        name: 'confirmation',
        component: <Confirmation/>,
        validator: (value, props) => {
            if (!value.confirmed) {
                return [{message: getText("Inspection is not confirmed"), field: 'confirmation'}]
            } else {
                return [];
            }
        }
    }
};
export const wizardSummaryGroups = {
    model: {
        name: "model",
        title: getText("Model information"),
        description: getText("Make, model etc."),
        weight: 2,
        steps: [wizardSteps.vinSearch.id, wizardSteps.make.id, wizardSteps.registration.id, wizardSteps.modelGroup.id, wizardSteps.model.id, wizardSteps.power.id, wizardSteps.version.id]
    },
    general: {
        name: 'general',
        title: getText("General information"),
        description: getText("License, km, etc."),
        weight: 1,
        steps: [wizardSteps.generalInfo.id],
    },
    features: {
        name: 'features',
        title: getText("Features"),
        description: getText("All the equipments"),
        weight: 1,
        steps: [wizardSteps.features.id],
    },
    checks: {
        name: 'checks',
        title: getText('Checks'),
        description: getText('Check inspection condition'),
        weight: 1,
        steps: [wizardSteps.checks.id],
    },
    pictures: {
        name: 'pictures',
        title: getText("Pictures"),
        description: getText('Add some pictures'),
        weight: 1,
        steps: [wizardSteps.pictures.id],
    },
    damages: {
        name: 'damages',
        title: getText("Damages"),
        description: getText('Define al the damages'),
        weight: 1,
        steps: [wizardSteps.damages.id],
    },
    customer: {
        name: 'customer',
        title: getText('Customer'),
        description: getText('Collect customer_initialize information'),
        weight: 1,
        steps: [wizardSteps.customer.id],
    },
    confirmation: {
        name: "confirmation",
        title: getText('Confirmation'),
        description: getText('Confirm your inspection'),
        weight: 0.1,
        steps: [wizardSteps.confirmation.id]
    }
};

export const EMPTY_INSPECTION = {
    id: undefined,
    guid: "",
    manual: null,
    vehicle: null,
    currentStepId: 0,
    detailDamageId: null,
    skippedSteps: [],
    vehicleVersions: [],
    customer:null,
    showAllErrors: false,
};


export const generate_initial_inspection = (tempInspectionId) => {
    return {
        ...EMPTY_INSPECTION,
        id: (tempInspectionId) !== 0 ? tempInspectionId : null,
        guid: UUID.v4(),
        vehicle: generate_initial_vehicle(),
        customer: customer_initialize(),
    }
};

const INSPECTION_TYPES = {
    INSPECTION_SET_ID: 'INSPECTION_SET_ID',
    INSPECTION_SET_GUID: 'INSPECTION_SET_GUID',
    INSPECTION_SET_CURRENT_STEP: 'INSPECTION_SET_CURRENT_STEP',
    INSPECTION_SET_SKIPPED_STEPS: 'INSPECTION_SET_SKIPPED_STEPS',
    INSPECTION_SET_INITIAL_STATE: 'INSPECTION_SET_INITIAL_STATE',
    INSPECTION_SET_MANUAL_INPUT: 'INSPECTION_SET_MANUAL_INPUT',
    INSPECTION_SET_VEHICLE_DATA: 'INSPECTION_SET_VEHICLE_DATA',
    INSPECTION_SHOW_ALL_ERRORS: 'INSPECTION_SHOW_ALL_ERRORS',
};

export const inspectionReducer = (state, action) => {
    switch (action.type) {
        case INSPECTION_TYPES.INSPECTION_SET_GUID:
            return {
                ...state,
                guid: action.payload.guid,
            };
        case INSPECTION_TYPES.INSPECTION_SET_ID:
            return {
                ...state,
                id: action.payload.id,
            };
        case INSPECTION_TYPES.INSPECTION_SET_VEHICLE_DATA:
            return {
                ...state,
                vehicle: {
                    ...state.vehicle,
                    ...action.payload.data,
                },
                currentStepId: action.payload.stepId,
                vehicleVersions: action.payload.versions,
            };

        case INSPECTION_TYPES.INSPECTION_SET_CURRENT_STEP:
            return {
                ...state,
                currentStepId: action.payload.stepId,
                showAllErrors: (!state.showAllErrors) ? action.payload.stepId === wizardSteps.confirmation.id : state.showAllErrors
            };
        case INSPECTION_TYPES.INSPECTION_SET_SKIPPED_STEPS:
            return {
                ...state,
                skippedSteps: action.payload.skippedSteps,
            };
        case INSPECTION_TYPES.INSPECTION_SET_MANUAL_INPUT:
            return {
                ...state,
                manual: (action.payload.value !== null) ? action.payload.value : true,
            };
        default:
            return {
                ...state,
                guid: (state.guid == null) ? UUID.v4() : state.guid,
                vehicle: vehicleReducer(state.vehicle, action),
                customer: customerReducer(state.customer, action),
            };
    }
};

export const inspectionSetId = (dispatch, id) => {
    dispatch({
        type: INSPECTION_TYPES.INSPECTION_SET_ID,
        payload: {
            id,
        }
    });
};

export const inspectionSetGuid = (dispatch, guid) => {
    dispatch({
        type: INSPECTION_TYPES.INSPECTION_SET_GUID,
        payload: {
            guid,
        }
    });
};

export const inspectionSetCurrentStepId = (dispatch, stepId) => {
    dispatch({
        type: INSPECTION_TYPES.INSPECTION_SET_CURRENT_STEP,
        payload: {
            stepId,
        }
    });
};
export const inspectionSetSkippedSteps = (dispatch, skippedSteps) => {
    dispatch({
        type: INSPECTION_TYPES.INSPECTION_SET_SKIPPED_STEPS,
        payload: {
            skippedSteps,
        }
    });
};
export const inspectionSetManualInput = (dispatch, value = null) => {
    dispatch({
        type: INSPECTION_TYPES.INSPECTION_SET_MANUAL_INPUT,
        payload: {
            value: value,
        }
    });
};
export const inspectionSetVehicleData = (dispatch, stepId, data, versions = []) => {
    dispatch({
        type: INSPECTION_TYPES.INSPECTION_SET_VEHICLE_DATA,
        payload: {
            stepId,
            data,
            versions,
        }
    });
};






