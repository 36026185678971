import React, {useState} from "react";
import {useTranslate} from "../../../../translations";


const FeatureItem = ({feature, checked, onClick, width, icon}) => {
    const [hovered, setHovered] = useState(false);

    return (
        <div key={feature.id} className={`flex flex-col items-center p-3 cursor-pointer ${width}`}
             onMouseEnter={() => !checked ? setHovered(true) : null}
             onMouseLeave={() => !checked ? setHovered(false) : null}
             onClick={() => {
                 setHovered(false);
                 onClick(feature);
             }}>

            <div className="flex  text-gray-500">
                <div
                    className={`flex relative justify-center items-center bg-white ${(hovered || checked) ? "border-blue-400" : "border-gray-300"} `}
                    style={{
                        width: 60, height: 60,
                        borderRadius: 30,
                        borderWidth: 1,
                        borderStyle: 'solid',
                    }}>

                    <span
                        className={`icon-feature-${feature.id} ${(hovered || checked) ? "text-blue-600" : "text-gray-900"}`}
                        style={{fontSize: 35}}/>

                    {(checked || hovered) &&
                    <div
                        className={`${(checked || hovered) ? 'bg-blue-500 border-blue-500' : 'bg-gray-500 border-gray-500'} flex absolute items-center justify-center`}
                        style={{
                            width: 18, height: 18,
                            borderRadius: 9,
                            top: -2,
                            left: 44
                        }}>
                        <span className={`text-white ${icon}`} style={{fontSize: 10}}/>
                    </div>}
                </div>
            </div>

            <div
                className="flex flex-col items-center text-gray-900  mt-1 justify-center"
                style={{fontSize: 10, height: 25, maxWidth: 98}}>
                <span className="truncate" style={{maxWidth: 98}}>
                    {feature.description}
                </span>
            </div>
        </div>
    )
};


FeatureItem.defaultProps = {
    width: "w-1/4",
    icon: "fa fa-check",
};

export default FeatureItem;