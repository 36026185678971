import React, {useState, useRef} from 'react';
import Button from "../../../../../../../general/action/Button";
import moment from "moment";
import {formatAmount} from "../../../../../../../general/utils";
import {useTranslate} from "../../../../../../../translations";
import {useInterval} from "../../../../../../../general/hooks";

const DealerOffer = ({dealerName, dealerMail, dealerBid, dealerBidDate, clickable, onClick, checked}) => {
    const {getText} = useTranslate();
    const [focused, setFocused] = useState(false);
    const [showConfirmationWarning, setShowConfirmationWarning] = useState(false);
    const timerRef = useRef(0);

    const renderIcon = () => {
        if ((clickable && focused) || checked) {
            return (
                <div className="bg-green-400 text-white flex items-center justify-center"
                     style={{width: 24, height: 24, borderRadius: 12}}>
                    <i className="fa fa-check text-md "/>
                </div>
            )
        } else {
            return <i className="fa fa-gavel text-xl text-gray-500"/>
        }
    };

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (clickable) {
            setFocused(true);
            setShowConfirmationWarning(true);
            timerRef.current = setTimeout(() => { setShowConfirmationWarning(false)}, 5000);
        }
    };

    const handleConfirmationNo = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowConfirmationWarning(false);
    };

    const handleConfirmationYes = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowConfirmationWarning(false);
        onClick();
    };

    const handleOnMouseEnter = () => {
        if (clickable) {
            setFocused(true);
        }
    };

    const handleOnMouseLeave = () => {
        if (clickable) {
            setFocused(false);
        }
    };




    let bgColor = 'bg-white';
    if (showConfirmationWarning) {
        if (checked) {
            bgColor = 'bg-red-100'
        } else {
            bgColor = 'bg-blue-100'
        }
    } else if (focused) {
        if (checked) {
            bgColor = 'bg-green-100'
        } else {
            bgColor = 'bg-blue-100'
        }
    } else if (checked)
        bgColor = 'bg-green-200';


    return (
        <div
            className={`p-2 border border-gray-200 border-solid border-r-0 border-l-0 border-t-0 ${bgColor} ${(clickable) ? 'cursor-pointer' : 'cursor-default'} `}
            style={{minHeight: 48}}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleClick}
        >
            {showConfirmationWarning ?
                <div className="flex flex-row">
                    <div className="flex justify-center mr-2 items-center" style={{width: 30}}>
                        <i className={`${(!checked) ? 'fal fa-handshake' : 'fal fa-undo'}  text-xl text-gray-500`}/>
                    </div>
                    <div className="w-2/3 flex flex-row">
                        {!checked &&
                        <React.Fragment>
                            <div className="w-2/3">
                                <div className="mb-1">
                                    <span className="text-gray-600">{getText("Assign vehicle to")}</span>
                                </div>
                                <div>
                                    <span className="font-medium">{dealerName}</span>
                                </div>
                            </div>

                            <div className="w-1/3 flex justify-center">
                                <div className="flex flex-row items-center">
                                    <span className="text-md font-medium">{formatAmount(dealerBid)}</span>
                                </div>
                            </div>
                        </React.Fragment>}

                        {checked &&
                        <div className="w-2/3 flex items-center">
                            <span className="font-medium">{`${getText("Undo vehicle assignment")}?`}</span>
                        </div>}

                    </div>
                    <div className="w-1/3 flex justify-end">
                        <Button className="mr-1" onClick={handleConfirmationNo}>{getText("No")}</Button>
                        <Button type="primary" onClick={handleConfirmationYes}>{getText("Yes")}</Button>
                    </div>
                </div>
                :
                <div className="flex flex-row">
                    <div className="flex justify-center mr-2 items-center" style={{width: 30}}>
                        {renderIcon()}
                    </div>
                    <div className="w-2/3">
                        <div className="mb-1">
                            <span className="font-medium">
                                {dealerName} </span> on <span>{moment(dealerBidDate).format('DD/MM/YYYYY')}
                            </span>
                        </div>
                        <div>
                            <span className="text-gray-600">{dealerMail}</span>
                        </div>
                    </div>
                    <div className="w-1/3 flex justify-end">
                        <div className="flex flex-row items-center">
                            <span className="text-md font-medium">{formatAmount(dealerBid)}</span>
                        </div>
                    </div>
                </div>}
        </div>
    )
};

export default DealerOffer;
