import React, {useState} from 'react';
import Buttons from "./Buttons";
import Summary from "./Summary";
import WizardAnimation from "./WizardAnimation";
import {
    map,
    find,
    filter,
    isEmpty,
    orderBy,
    uniqBy,
    range,
    slice,
    includes,
    keyBy,
    values,
    findIndex,
    flatten,
    keys
} from 'lodash';
import moment from "moment";
import {wizardSummaryGroups} from "../inspectionData";
import withSession from "../../../../project/session/withSession";
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'
import {useTranslate} from "../../../../translations";
import {TextArea} from "../../../../general/forms";
import {vehicleSetCommentNegative, vehicleSetCommentPositive} from "../vehicleData";

const ValidationErrorBanner = ({message, onClick}) => {
    const {getText} = useTranslate();
    return (
        <div className="bg-orange-100 text-orange-800 mt-1 p-2 cursor-pointer" onClick={onClick}>
            {/*<div className="bg-orange-200 border border-orange-400 text-orange-800 mt-1 p-2">*/}
            {getText(message)}
        </div>
    )
};

const Confirmation = ({onPrev, onComplete, onStepClick, wizard, data: {inspection, featureGroups, damageLocations, checkGroups, pictureTypes, dispatch}}) => {
    const stepsWithErrors = filter(wizard.flowProgress, (fp) => fp.errors.length > 0 && fp.stepId !== 99);
    const [picturesForViewer, setPicturesForViewer] = useState([]);
    const {getText} = useTranslate();
    const [pictureViewerStartIndex, setPictureViewerStartIndex] = useState(0);
    const vehicleErrors = filter(stepsWithErrors, (fp) => includes([2, 3, 5, 7, 9, 11], fp.stepId));
    const featureErrors = filter(stepsWithErrors, (fp) => fp.stepId === 8);
    const checksErrors = filter(stepsWithErrors, (fp) => fp.stepId === 10);
    const damageErrors = filter(stepsWithErrors, (fp) => fp.stepId === 12);

    const temp = [];
    map(vehicleErrors, (step_error) => {
        map(step_error.errors, (error) => {
            temp.push(error);
        });
    });
    const VehicleErrorsByField = keyBy(temp, 'field');


    // let containsError = false;
    // if (checksErrors.length > 0) {
    //     const checkIds = map(checksByGroup, 'id');
    //     containsError = filter(checksErrors[0].errors, (err) => includes(checkIds, err.field)).length > 0;
    // }


    const vehiclePictures = orderBy(map(filter(inspection.vehicle.pictures, ip => ip.refType === "pictureType"), (picture) => {
        const pictureType = find(pictureTypes, (pt) => pt.id === picture.refId);
        return ({...picture, priority: (pictureType && pictureType.priority) ? pictureType.priority : 0})
    }), 'priority');
    const emptyVehiclePictures = (vehiclePictures.length < 6) ? (6 - vehiclePictures.length) : 0;
    const vehiclePicturesErrors = filter(values(VehicleErrorsByField), (err) => err.field.startsWith('pictureType_'));
    const vehiclePicturesErrorMessage = (vehiclePicturesErrors.length > 0) ? vehiclePicturesErrors[0].message : "";


    const allFeatures = flatten(map(featureGroups, (group) => group.features));
    const vehicleFeatures = orderBy(map(inspection.vehicle.featureIds, (id) => {
        const feature = find(allFeatures, (f) => f.id === id);
        return {
            id: feature.id,
            description: feature.description,
        };
    }), 'description');
    const triplePoint = Math.ceil(vehicleFeatures.length / 3);
    const featuresColumnA = slice(vehicleFeatures, 0, triplePoint);
    const featuresColumnB = slice(vehicleFeatures, triplePoint, (triplePoint * 2));
    const featuresColumnC = slice(vehicleFeatures, (triplePoint * 2));


    const allCheckGroups = orderBy(filter(checkGroups, (group) => group.checks.length > 0), 'description');

    const errorClass = "bg-orange-100 cursor-pointer";
    const defaultClass = "bg-gray-100 cursor-default";

    const handleShowAllPictures = (startPictureId) => {
        let allPictures = map(filter(inspection.vehicle.pictures, (p) => p.refId !== 0), (pic, i) => {
            let description = "";

            if (pic.refType === 'pictureType') {
                const pictureType = find(pictureTypes, (pt) => pt.id === pic.refId);
                if (pictureType) {
                    description = pictureType.name;
                }
            } else if (pic.refType === 'damage') {
                if (inspection.vehicle.damages.damagesById[pic.refId]) {
                    const damage = inspection.vehicle.damages.damagesById[pic.refId];
                    description = `${damage.typeText} - ${damage.severityText}`;
                }
            }
            return {
                id: pic.guid,
                original: pic.url,
                thumbnail: pic.thumbnailUrl,
                description: description,
            }
        });

        const index = findIndex(allPictures, (p) => p.guid === startPictureId);
        setPictureViewerStartIndex(index);
        setPicturesForViewer(allPictures);
    };


    return (
        <div className="flex h-full w-full">
            <div className="w-1/4">
                <Summary data={wizard} onStepClick={onStepClick}/>
            </div>

            <div className="w-3/4 flex flex-col flex-1 bg-white pt-2 pl-2 pr-2 overflow-hidden">

                <div className="flex flex-1 overflow-y-scroll" style={{height: 500}}>
                    <WizardAnimation>
                        <div className="w-full">

                            <div className="flex flex-col overflow-y-scroll ">
                                <TextArea value={inspection.vehicle.commentPositive}
                                          onChange={e => vehicleSetCommentPositive(dispatch, e.target.value)}
                                          label={() => <div className="text-green-400">
                                              <i className="fas fa-plus-circle"/>
                                              <i className="fas fa-plus-circle"/>
                                              <i className="fas fa-plus-circle"/>
                                          </div>}
                                          className={`p-2 bg-gray-100 ${(VehicleErrorsByField["commentPositive"]) ? errorClass : defaultClass}`}
                                          inputClassName="bg-gray-100"
                                          showSelectionBorder={false}
                                />


                                <TextArea value={inspection.vehicle.commentNegative}
                                          onChange={e => vehicleSetCommentNegative(dispatch, e.target.value)}
                                          label={() => <div className="text-red-400">
                                              <i className="fas fa-minus-circle"/>
                                              <i className="fas fa-minus-circle"/>
                                              <i className="fas fa-minus-circle"/>
                                          </div>}
                                          className={`p-2 bg-gray-100 ${(VehicleErrorsByField["commentPositive"]) ? errorClass : defaultClass}`}
                                          inputClassName="bg-gray-100"
                                          showSelectionBorder={false}


                                />
                                <div className="flex flex-col w-full">
                                    <div
                                        className="flex items-center pl-2 text-white bg-blue-500 text-md h-8 py-2 mb-2">
                                        <span>{getText("Vehicle")} </span>
                                    </div>
                                    <div className="flex flex-1 flex-col mb-2">
                                        <div className="block clearfix mb-2">
                                            <div className="float-left w-1/2 pr-1">
                                                <div
                                                    className={` p-2 ${(VehicleErrorsByField["vin"]) ? errorClass : defaultClass}`}
                                                    onClick={() => (VehicleErrorsByField["vin"]) ? onStepClick(wizardSummaryGroups.general) : null}>
                                                    <span
                                                        className="text-gray-600 flex flex-col justify-center text-xs w-1/3">{getText("VIN")}:</span>
                                                    <span
                                                        className="text-gray-900 flex flex-col justify-center text-sm font-medium">{inspection.vehicle.vin}</span>
                                                </div>
                                            </div>
                                            <div className="float-left w-1/2 pl-1">
                                                <div
                                                    className={`  p-2  ${(VehicleErrorsByField["firstRegistration"]) ? errorClass : defaultClass}`}
                                                    onClick={() => (VehicleErrorsByField["firstRegistration"]) ? onStepClick(wizardSummaryGroups.model) : null}>
                                                <span
                                                    className="text-gray-600 flex flex-col justify-center text-xs w-1/3">{getText("1st registration")}:</span>
                                                    <span
                                                        className="text-gray-900 flex flex-col justify-center text-sm font-medium">{(inspection.vehicle.firstRegistration !== null) ? moment(inspection.vehicle.firstRegistration).format('DD/MM/YYYY') : ""}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="block clearfix mb-2">
                                            <div className="float-left w-1/2 pr-1">
                                                <div
                                                    className={`p-2 ${(VehicleErrorsByField["make"]) ? errorClass : defaultClass}`}
                                                    onClick={() => (VehicleErrorsByField["make"]) ? onStepClick(wizardSummaryGroups.model) : null}>
                                                    <span
                                                        className="text-gray-600 flex flex-col justify-center  text-xs w-1/3">{getText("Make")}:</span>
                                                    <span
                                                        className="text-gray-900 flex flex-col justify-center text-sm font-medium">{inspection.vehicle.makeText}</span>
                                                </div>
                                            </div>
                                            <div className="float-left w-1/2 pl-1">
                                                <div
                                                    className={`p-2 ${(VehicleErrorsByField["model"]) ? errorClass : defaultClass}`}
                                                    onClick={() => (VehicleErrorsByField["model"]) ? onStepClick(wizardSummaryGroups.model) : null}>
                                                    <span
                                                        className="text-gray-600 flex flex-col justify-center  text-xs w-1/3">{getText("Model")}:</span>
                                                    <span
                                                        className="text-gray-900 flex flex-col justify-center text-sm font-medium">{inspection.vehicle.modelText}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="block clearfix mb-2">
                                            <div className="float-left w-1/2 pr-1">
                                                <div
                                                    className={`p-2 ${(VehicleErrorsByField["registration"]) ? errorClass : defaultClass}`}
                                                    onClick={() => (VehicleErrorsByField["registration"]) ? onStepClick(wizardSummaryGroups.general) : null}>
                                                <span
                                                    className="text-gray-600 flex flex-col justify-center text-xs w-1/3">{getText("Registration")}:</span>
                                                    <span
                                                        className="text-gray-900 flex flex-col justify-center text-sm font-medium">{inspection.vehicle.registration}</span>
                                                </div>
                                            </div>
                                            <div className="float-left w-1/2 pl-1">
                                                <div
                                                    className={`p-2 ${(VehicleErrorsByField["kilometers"]) ? errorClass : defaultClass}`}
                                                    onClick={() => (VehicleErrorsByField["kilometers"]) ? onStepClick(wizardSummaryGroups.general) : null}>
                                                <span
                                                    className="text-gray-600 flex flex-col justify-center text-xs w-1/3">{getText("Kilometers")}:</span>
                                                    <span
                                                        className="text-gray-900 text-sm font-medium">{inspection.vehicle.kilometers}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-1 mb-2">
                                            <div
                                                className={`flex flex-1 p-2 mr-1 ${(VehicleErrorsByField["seats"]) ? errorClass : defaultClass}`}
                                                onClick={() => (VehicleErrorsByField["seats"]) ? onStepClick(wizardSummaryGroups.general) : null}>
                                            <span
                                                className="text-gray-600 flex flex-col justify-center text-xs w-1/3">{getText("Seats")}:</span>
                                                <span
                                                    className="text-gray-900 flex flex-col justify-center text-sm font-medium">{inspection.vehicle.seats}</span>
                                            </div>
                                            <div
                                                className={`flex flex-1 p-2 ml-1 ${(VehicleErrorsByField["color"]) ? errorClass : defaultClass}`}
                                                onClick={() => (VehicleErrorsByField["color"]) ? onStepClick(wizardSummaryGroups.general) : null}>
                                                <div
                                                    className="text-gray-600 flex flex-col justify-center text-xs w-1/3">{getText("Color")}:
                                                </div>
                                                <div className="flex flex-row text-gray-900 text-sm font-medium">
                                                    {inspection.vehicle.colorInterior &&
                                                    <div
                                                        className="flex flex-row items-center mr-2">{getText("In")}: <div
                                                        className="rounded-full ml-2" style={{
                                                        width: 13,
                                                        height: 13,
                                                        backgroundColor: inspection.vehicle.colorInterior
                                                    }}/></div>}
                                                    {inspection.vehicle.colorExterior &&
                                                    <div className="flex flex-row items-center">{getText("Out")}: <div
                                                        className="rounded-full ml-2" style={{
                                                        width: 13,
                                                        height: 13,
                                                        backgroundColor: inspection.vehicle.colorExterior
                                                    }}/></div>}
                                                </div>
                                            </div>
                                        </div>

                                        {/*<div*/}
                                        {/*    className={`flex flex-col w-full h-12 pr-1 p-1 mb-2 ${(VehicleErrorsByField["commentPositive"]) ? errorClass : defaultClass}`}*/}
                                        {/*    onClick={() => (VehicleErrorsByField["commentPositive"]) ? onStepClick(wizardSummaryGroups.general) : null}>*/}
                                        {/*    <div className="flex text-green-400 mb-2">*/}
                                        {/*        <i className="fas fa-plus-circle mr-1"/>*/}
                                        {/*        <i className="fas fa-plus-circle mr-1"/>*/}
                                        {/*        <i className="fas fa-plus-circle"/>*/}
                                        {/*    </div>*/}
                                        {/*    <div*/}
                                        {/*        className="text-sm font-medium text-gray-900">{inspection.vehicle.commentPositive}</div>*/}
                                        {/*</div>*/}

                                        {/*<div*/}
                                        {/*    className={`flex flex-col w-full h-12 pr-1 p-1 mb-2 ${(VehicleErrorsByField["commentNegative"]) ? errorClass : defaultClass}`}*/}
                                        {/*    onClick={() => (VehicleErrorsByField["commentNegative"]) ? onStepClick(wizardSummaryGroups.general) : null}>*/}
                                        {/*    <div className="text-red-400 mb-2">*/}
                                        {/*        <i className="fas fa-minus-circle mr-1"/>*/}
                                        {/*        <i className="fas fa-minus-circle mr-1"/>*/}
                                        {/*        <i className="fas fa-minus-circle"/>*/}
                                        {/*    </div>*/}
                                        {/*    <div*/}
                                        {/*        className="text-gray-900 text-sm font-medium">{inspection.vehicle.commentNegative}</div>*/}
                                        {/*</div>*/}

                                        <div
                                            className={`flex flex-col w-full h-12 pr-1 p-1 mb-2 ${(VehicleErrorsByField["commentInternal"]) ? errorClass : defaultClass}`}
                                            onClick={() => (VehicleErrorsByField["commentInternal"]) ? onStepClick(wizardSummaryGroups.general) : null}>
                                            <div className="text-gray-600 mb-2">
                                                <span>{getText("Comment (internal)")}</span>
                                            </div>
                                            <div
                                                className="text-gray-900 text-sm font-medium">{inspection.vehicle.commentInternal}</div>
                                        </div>

                                        {vehiclePicturesErrorMessage !== "" &&
                                        <ValidationErrorBanner message={vehiclePicturesErrorMessage}
                                                               onClick={() => onStepClick(wizardSummaryGroups.pictures)}/>}

                                        <div
                                            className={`flex flex-row w-full  ${(vehiclePicturesErrorMessage !== "") ? errorClass : defaultClass}`}
                                            onClick={() => (vehiclePicturesErrorMessage !== "") ? onStepClick(wizardSummaryGroups.pictures) : null}>
                                            {map(slice(vehiclePictures, 0, 6), (pic, index) => {
                                                const overlay = (vehiclePictures.length > 6 && index === 5);

                                                return (
                                                    <React.Fragment key={pic.guid}>
                                                        <div className={`${(overlay) ? 'relative' : ''}`}>
                                                            <img src={pic.url}
                                                                 style={{
                                                                     height: 85,
                                                                     width: 109,
                                                                     marginRight: 1,
                                                                     marginBottom: 1
                                                                 }} alt={pic.guid}
                                                                 className="cursor-pointer"
                                                                 onClick={(e) => {
                                                                     e.stopPropagation();
                                                                     handleShowAllPictures(pic.guid);
                                                                 }}>

                                                            </img>


                                                            {overlay &&
                                                            <div
                                                                className="absolute top-0 right-0 flex items-center justify-center"
                                                                style={{
                                                                    width: 109,
                                                                    height: 85,
                                                                    marginRight: 1,
                                                                    marginBottom: 1,
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)'
                                                                }}>
                                                            <span
                                                                className="text-white text-3xl">+ {vehiclePictures.length - 6}</span>
                                                            </div>}
                                                        </div>

                                                    </React.Fragment>
                                                )
                                            })}

                                            {map(range(emptyVehiclePictures), (index) => {
                                                return (
                                                    <React.Fragment key={`dummy_picture_${index}`}>
                                                        <div>
                                                            <div
                                                                className="flex items-center justify-center border border-gray-200"
                                                                style={{
                                                                    height: 85,
                                                                    width: 109,
                                                                    marginRight: 1,
                                                                    marginBottom: 1
                                                                }}>
                                                                <i className="fal fa-image text-5xl text-gray-200"/>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })}


                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="flex items-center text-white bg-blue-500 pl-2 text-md h-8 py-2">
                                        {getText("Features")}
                                    </div>

                                    {featureErrors.length > 0 &&
                                    <ValidationErrorBanner message={featureErrors[0].errors[0].message}
                                                           onClick={() => onStepClick(wizardSummaryGroups.features)}/>}

                                    <div className="flex flex-1 flex-row w-full pt-2">
                                        <div className="flex flex-1 flex-col">
                                            {vehicleFeatures.length > 0 &&
                                            map(featuresColumnA, (feature, i) => {
                                                return (
                                                    <div key={feature.id}
                                                         className={`flex flex-col justify-center p-1 ${i % 2 !== 0 ? "bg-white" : 'bg-gray-100'}`}
                                                         style={{width: 200, height: 30, maxWidth: 200}}>
                                                        <div className="truncate"
                                                             style={{width: 185, maxWidth: 185}}>
                                                            <span
                                                                className="text-gray-900 text-xs "> {feature.description}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="flex flex-1 flex-col ">
                                            {vehicleFeatures.length > 0 &&
                                            map(featuresColumnB, (feature, i) => {
                                                return (
                                                    <div key={feature.id}
                                                         className={`flex flex-col justify-center p-1 ${i % 2 !== 0 ? "bg-gray-100" : 'bg-white'}`}
                                                         style={{width: 200, height: 30, maxWidth: 200}}>
                                                        <div className="truncate"
                                                             style={{width: 185, maxWidth: 185}}>
                                                            <span
                                                                className="text-gray-900 text-xs "> {feature.description}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="flex flex-1 flex-col">
                                            {vehicleFeatures.length > 0 &&
                                            map(featuresColumnC, (feature, i) => {
                                                return (
                                                    <div key={feature.id}
                                                         className={`flex flex-col justify-center  p-1 ${i % 2 !== 0 ? "bg-white" : 'bg-gray-100'}`}
                                                         style={{width: 200, height: 30, maxWidth: 200}}>
                                                        <div className="truncate"
                                                             style={{width: 185, maxWidth: 185}}>
                                                            <span
                                                                className="text-gray-900 text-xs "> {feature.description}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                    </div>
                                </div>

                                <div className="flex flex-col">
                                    <div className="flex items-center pl-2 text-white bg-blue-500 text-md  h-8 py-2">
                                        {getText("Checks")}
                                    </div>


                                    <div className="flex flex-row flex-wrap w-full">

                                        <div className="w-full overflow-scroll mb-1">
                                            {map(allCheckGroups, (group) => {

                                                const inspectionCheckIds = map(inspection.vehicle.checks, (check) => check.checkId);
                                                const inspectionChecks = orderBy(filter(group.checks, (check) => includes(inspectionCheckIds, check.id)), 'description');

                                                if(inspectionChecks.length === 0){
                                                    return;
                                                }

                                                const halfwayPoint = Math.ceil(inspectionChecks.length / 2);
                                                const columnA = slice(inspectionChecks, 0, halfwayPoint);
                                                const columnB = slice(inspectionChecks, halfwayPoint);

                                                let containsError = false;
                                                if (checksErrors.length > 0) {
                                                    const checkIds = map(group.checks, (ch) => `checkType_${ch.id}`);
                                                    containsError = filter(checksErrors[0].errors, (err) => includes(checkIds, err.field)).length > 0;
                                                }

                                                return (
                                                    <div key={`checkgroup_${group.id}`}
                                                         onClick={() => {
                                                             if (containsError) {
                                                                 onStepClick(wizardSummaryGroups.checks);
                                                             }
                                                         }}
                                                         className={`flex flex-1 flex-col collapsible-panel ${(containsError) ? 'bg-orange-100 cursor-pointer' : 'bg-gray-100 cursor-default'} mt-2 mb-2 `}>

                                                        {containsError &&
                                                        <ValidationErrorBanner
                                                            message={getText("Not all the required fields are filled")}
                                                            onClick={() => onStepClick(wizardSummaryGroups.checks)}/>
                                                        }

                                                        <div
                                                            className="flex flex-row  p-2 font-medium text-xs ">
                                                            <span
                                                                className="text-xs">{group.description}</span>
                                                        </div>
                                                        <div className="flex flex-1 flex-row p-2 pt-0">
                                                            <div className="flex flex-1 flex-col mr-8 ">
                                                                {map(columnA, (check) => {
                                                                    const checkValue = find(inspection.vehicle.checks, (i_ch) => i_ch.checkId === check.id)
                                                                    const value = (checkValue) ? checkValue.value : null;
                                                                    return (
                                                                        <div key={check.id}
                                                                             className="flex w-full justify-between mb-2">
                                                                            <div
                                                                                className="text-gray-900 text-xs">{check.description}</div>

                                                                            {check.type === "Boolean" &&
                                                                            <i className={`${(value === null) ? "fa fa-minus" : value === 'true' ? "fa fa-check" : "fa fa-times"} ${(value === null) ? 'text-gray-300' : 'text-gray-900'} text-sm`}/>
                                                                            }
                                                                            {check.type === "TrafficLight" && value !== null &&
                                                                            <div>
                                                                                <i className={`${(value == 'red') ? 'fas fa-circle text-red-500' : 'far fa-circle text-gray-900'} text-sm mr-1`}></i>
                                                                                <i className={`${(value == 'orange') ? 'fas fa-circle text-orange-500' : 'far fa-circle text-gray-900'}  text-sm mr-1`}></i>
                                                                                <i className={`${(value == 'green') ? 'fas fa-circle text-green-500' : 'far fa-circle text-gray-900'} text-sm `}></i>
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                            <div className="flex flex-1 flex-col">
                                                                {map(columnB, (check, index) => {
                                                                    const checkValue = find(inspection.vehicle.checks, (i_ch) => i_ch.checkId === check.id)
                                                                    const value = (checkValue) ? checkValue.value : null;
                                                                    return (
                                                                        <div key={check.id}
                                                                             className="flex w-full justify-between mb-2">
                                                                            <div
                                                                                className="text-gray-900 text-xs">{check.description}</div>

                                                                            {check.type === "Boolean" &&
                                                                            <i className={`${(value === null) ? "fa fa-minus" : value === 'true' ? "fa fa-check" : "fa fa-times"} ${(value === null) ? 'text-gray-300' : 'text-gray-900'} text-sm`}/>
                                                                            }
                                                                            {check.type === "TrafficLight" && value !== null &&
                                                                            <div>
                                                                                <i className={`${(value == 'red') ? 'fas fa-circle text-red-500' : 'far fa-circle text-gray-900'} text-sm mr-1`}></i>
                                                                                <i className={`${(value == 'orange') ? 'fas fa-circle text-orange-500' : 'far fa-circle text-gray-900'}  text-sm mr-1`}></i>
                                                                                <i className={`${(value == 'green') ? 'fas fa-circle text-green-500' : 'far fa-circle text-gray-900'} text-sm `}></i>
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                    )

                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            })}
                                        </div>

                                    </div>
                                </div>

                                <div className="flex flex-col">
                                    <div className="flex items-center pl-2 text-white bg-blue-500 text-md  h-8 py-2">
                                        {getText("Damages")}
                                    </div>

                                    {damageErrors.length > 0 &&
                                    <ValidationErrorBanner message={damageErrors[0].errors[0].message}
                                                           onClick={() => onStepClick(wizardSummaryGroups.damages)}/>}

                                    <div className="pt-2">

                                        {!isEmpty(inspection.vehicle.damages.damagesById) &&
                                        map(inspection.vehicle.damages.damagesById, (damage) => {
                                            const pictures = filter(inspection.vehicle.pictures, (p) => p.refId === damage.guid);
                                            const emptyDamagePictures = (pictures.length < 4) ? (4 - pictures.length) : 0;

                                            return (
                                                <div key={damage.guid} className="flex flex-row mb-2">
                                                    <div className="flex flex-col p-2 bg-gray-100"
                                                         style={{width: 230, height: 80}}>
                                                        <div
                                                            className="mb-1 text-gray-900 font-medium text-xs">{damage.locationText}</div>
                                                        <div className="mb-1 text-gray-900 text-xs">
                                                            {damage.typeText}
                                                        </div>
                                                        <div className="text-gray-900 text-xs">
                                                            {damage.severityText}
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row flex-1">

                                                        {map(slice(pictures, 0, 4), (pic, index) => {
                                                            const overlay = (pictures.length > 4 && index === 3);

                                                            return (
                                                                <React.Fragment key={pic.guid}>
                                                                    <div className={`${(overlay) ? 'relative' : ''}`}>
                                                                        <img src={pic.url}
                                                                             style={{
                                                                                 height: 80,
                                                                                 width: 106,
                                                                                 marginRight: 1,
                                                                                 marginBottom: 1
                                                                             }} alt={pic.guid}
                                                                             className="cursor-pointer"
                                                                             onClick={(e) => {
                                                                                 e.stopPropagation();
                                                                                 handleShowAllPictures(pic.guid);
                                                                             }}>
                                                                        </img>

                                                                        {overlay &&
                                                                        <div
                                                                            className="absolute top-0 right-0 flex items-center justify-center"
                                                                            style={{
                                                                                width: 106,
                                                                                height: 80,
                                                                                marginRight: 1,
                                                                                marginBottom: 1,
                                                                                backgroundColor: 'rgba(0, 0, 0, 0.5)'
                                                                            }}>
                                                                            <span
                                                                                className="text-white text-3xl">+ {pictures.length - 4}</span>
                                                                        </div>}
                                                                    </div>

                                                                </React.Fragment>
                                                            )
                                                        })}

                                                        {map(range(emptyDamagePictures), (index) => {
                                                            return (
                                                                <React.Fragment key={`dummy_picture_${index}`}>
                                                                    <div>
                                                                        <div
                                                                            className="flex items-center justify-center border border-gray-200"
                                                                            style={{
                                                                                height: 80,
                                                                                width: 106,
                                                                                marginRight: 1,
                                                                                marginBottom: 1
                                                                            }}>
                                                                            <i className="fal fa-image text-5xl text-gray-200"/>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            )
                                                        })}


                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>


                            </div>
                        </div>


                    </WizardAnimation>
                </div>
                <div style={{height: 45}} className="bg-green-100">
                    <Buttons onPrev={onPrev}
                             onNext={onComplete}
                             nextText={getText('Confirm inspection')}
                             nextDisabled={stepsWithErrors.length !== 0}
                             data={wizard}
                    />
                </div>
            </div>

            {picturesForViewer.length > 0 &&
            <div className="fixed top-0 left-0 right-0 bottom-0 bg-black text-white" style={{zIndex: 999}}>

                <div className="flex flex-row justify-end mt-2 mr-2 ">
                    <i onClick={() => setPicturesForViewer([])}
                       className="fal fa-times cursor-pointer text-white"
                       style={{fontSize: 30}}/>
                </div>

                <div className='flex h-full items-center justify-center'>
                    <div style={{width: '60%'}}>
                        <ImageGallery
                            showPlayButton={false}
                            showBullets={true}
                            startIndex={pictureViewerStartIndex}
                            items={picturesForViewer}/>
                    </div>
                </div>

            </div>
            }

        </div>
    )
};


export default withSession(Confirmation);