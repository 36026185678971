import React from 'react';

const SelectionList = ({items, onSelect}) =>
    <div className="flex flex-col cursor-pointer">
        {items.map((item, i) =>
            <div key={i} onClick={() => onSelect(item)}
                 className="w-full align-middle mb-4 text-center p-4 rounded bg-blue-500 hover:bg-blue-600 text-white hover:text-white outline-none button">
                {item.name}
            </div>
        )}
    </div>;

export default SelectionList;


