import React, {Component} from 'react';
import './Template.css';
import logo from '../../asset/logo-black.svg';
import Background from '../../asset/b2.jpg';

class Template extends Component {

    render() {
        const {title, subtitle} = this.props;

        return (
            <div className="w-full h-screen bg-blue-400 flex flex-col items-start"
                 style={{backgroundImage: `url(${Background})`, backgroundSize: 'cover'}}>
                <div className="bg-white h-full w-128 flex flex-col justify-between">
                    <div className="w-1/2 ml-10 mt-12">
                        <img alt="logo" src={logo} className="w-full"/>
                    </div>
                    <div>
                        <div className="text-4xl ml-10 ">
                            {title}
                        </div>
                        <div className="text-xl ml-10">
                            {subtitle}
                        </div>

                        <div className="text-base ml-10 mt-6 mr-10" style={{minHeight: 400}}>
                            {this.props.children}
                        </div>
                    </div>
                    <div className="p-6 flex flex-row justify-center">
                        <a className="px-6 minorlink" href="./privacy">Privacy</a>
                        <a className="px-6 minorlink" href="./terms">Terms</a>
                        <a className="px-6 minorlink" href="https://www.autralis.com">© 2019 Autralis</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Template
