import React from 'react';
import {Button, HeaderToolbar, TableToolbar, withQuery, Table, DrawerManager} from "../../../general";
import {CheckBox} from '../../../general/base'
import gql from 'graphql-tag';
import {DRAWER_TYPES} from './constants'
import EditUser from './EditUser';
import {
    head,
    filter,
    countBy,
    flatMap,
    map,
    remove,
    forEach,
    some,
    sortBy,
    groupBy
} from 'lodash';
import Gravatar from "react-gravatar";
import moment from "moment";
import AddUser from "./AddUser";
import StatesButtons from "./StatesButtons";
import SendInvitation from "./SendInvitation";
import Filters from './Filters'
import HeaderFilters from "./HeaderFilters";
import ResetPassword from "./ResetPassword";
import {compose} from "recompose";
import {withSession} from "../../../project/session";
import {getText} from "../../../translations";


const nr_table_rows = 15;
const rowSelectors = document.getElementsByClassName('rt-tr-group');
const rowActiveClasses = ["bg-gray-100"];

const DrawerManagerConfig = {
    [DRAWER_TYPES.USER_ADD]: {component: AddUser},
    [DRAWER_TYPES.USER_EDIT]: {component: EditUser},
    [DRAWER_TYPES.USER_SEND_INVITATION]: {component: SendInvitation},
    [DRAWER_TYPES.USER_RESET_PW]: {component: ResetPassword},
};


export const ALL_COMPANY_USERS = gql`
query CompanyUsers($applicationId: ID!){
  companyUsers(applicationId: $applicationId) {   
             id
             state  
             company {
                id
             }   
             userProfile {
              id,
              title,
              telephone,
              mobile,
              smsActive,
              cultureMajor
              offerTax,
              user {         
                id,
                username
                lastName,
                firstName,
                email,  
                lastLogin
                groups {
                  id,
                  name
                }
              }
            }        
  }
  groups {
    id
    name
  }
}

`;

class Users extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            prevDrawer: "",
            currentDrawer: "",
            drawerParams: null,
            multipleUserSelection: [],
            filterData: {
                roles: [],
                states: [{id: 0, name: "APPROVED"}, {id: 1, name: "LOCKED_APPROVED"}, {id: 2, name: "INITIAL"}]
            },
            filters: [],
        };

    }

    initialFilters() {
        const {data: {companyUsers}} = this.props;

        const groupedByState = groupBy(companyUsers, user => user.state.includes("LOCKED") ? "LOCKED" : user.state);
        const state_filters = Object.keys(groupedByState).map((key) => {
            return {name: key, count: groupedByState[key].length}
        });

        const roles_grouped = countBy(flatMap(companyUsers, 'userProfile.user.groups'), 'name');

        const role_filters = Object.keys(roles_grouped).map(key => {
            return {name: key, count: roles_grouped[key]}
        });

        this.setState({
            filterData: {
                states: Object.keys(state_filters).map(key => {
                    return {
                        id: key,
                        name: state_filters[key].name,
                        count: state_filters[key].count
                    }
                }),
                roles: Object.keys(role_filters).map(key => {
                    return {
                        id: key,
                        name: role_filters[key].name,
                        count: role_filters[key].count,
                    }
                })
            }
        })
    }

    componentDidMount() {
        this.initialFilters();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.currentDrawer !== "" && this.state.currentDrawer === "") {
            this.setState({prevDrawer: prevState.currentDrawer})
        }

    }

    deactivateRows = () => {
        // Remove those classes for the other rows
        for (let i = 0; i < rowSelectors.length; i++) {
            rowSelectors[i].classList.remove(...rowActiveClasses);
        }
    };

    activateRow(row_index) {
        // Remove those classes for the other rows
        this.deactivateRows();

        // Add classes to specific row
        if (row_index !== undefined) {
            const index = row_index - (Math.floor(row_index / nr_table_rows) * nr_table_rows);
            let clicked_el = rowSelectors[index];
            clicked_el.classList.add(...rowActiveClasses);
        }
    }

    openEditDrawer(id, row_index){
        const {data: {companyUsers, groups}} = this.props;
        const user = head(filter(companyUsers, (ca) => ca.id === id));
        this.setState({
            currentDrawer: DRAWER_TYPES.USER_EDIT,
            drawerParams: {companyUser: user, groups: groups}
        });
        this.activateRow(row_index);
    }

    renderStateCell(data, row_index) {
        const {id, state, userProfile: {user: {lastLogin}}} = data;
        const statusClass = state.includes('LOCKED_') ? "bg-orange-100 text-orange-600" : state.includes('INITIAL') ? "bg-gray-100 text-gray-600" : " bg-green-100 text-green-600";

        return (
            <div className="flex flex-col items-center h-full w-full justify-center" onClick={() => this.openEditDrawer(id, row_index)}>
                <div className={`tracking-wide py-1 px-2 text-xs text-center rounded-full font-medium ${statusClass}`}
                     style={{fontSize: "0.65rem", minWidth: 135}}>
                    {state.includes('LOCKED') ? "LOCKED" : state}
                </div>
                <div className="text-xs mt-2 text-gray-800">
                    {this.renderLastLoginCell(lastLogin)}
                </div>
            </div>
        )
    }

    renderUserCell(user_data, row_index) {
        const {id, userProfile: {user: {firstName, lastName, email}}} = user_data;

        return (
            <div className="max-w-lg cursor-pointer" onClick={() => this.openEditDrawer(id, row_index)}>
                <div className="flex p-1">
                    <div className="flex items-center justify-center text-gray-800 max-w-sm " style={{minHeight: 50}}>
                        <Gravatar email={email} size={50} default="mp" style={{minHeight: 50}}/>
                    </div>
                    <div className="flex flex-col flex-1 text-gray-800 pl-2 justify-center">
                        <div className="text-base font-medium"> {lastName} {firstName}</div>
                        <div className="mt-2 text-xs">{email}</div>
                    </div>
                </div>
            </div>
        )
    }

    renderRolesCell(data, row_index) {
        const {id, userProfile: {user: {groups}}} = data;
        return (
            <div className="flex flex-wrap flex-row items-center h-full w-full" onClick={() => this.openEditDrawer(id, row_index)}>
                {map(groups, (group, i) => {
                    const lastRecord = i === groups.length - 1;
                    return (
                        <div key={i}
                             className="bg-gray-200 rounded-full p-1 text-xs text-black text-center mr-2 px-6 mb-2"
                             style={{
                                 fontSize: "0.65rem",
                                 minWidth: 152,
                                 maxHeight: 19,
                                 marginRight: lastRecord && 0
                             }}>{group.name}</div>
                    )
                })}
            </div>
        )
    }

    renderLastLoginCell(date) {
        if (date === null) {
            return
        }
        const date_str = new Date(date.toLocaleString());
        const lastTimeLogged = moment(date_str, "YYYYMMDD").fromNow();
        return <React.Fragment><i className="fal fa-user-clock mr-1"/> {lastTimeLogged}</React.Fragment>
    }

    handleRowCheck(e, row_data) {
        const {id, userProfile: {user}, state} = row_data;
        const {session: {token, companyId}} = this.props;
        const isChecked = e.target.checked;
        const data = {
            companyUserId: id,
            email: user.email,
            userId: user.id,
            state: state,
            token: token,
            companyId: companyId
        };

        const multipleUserSelection = isChecked ? [...this.state.multipleUserSelection, data] : remove(this.state.multipleUserSelection, el => el.companyUserId !== data.companyUserId)

        this.setState({multipleUserSelection})
    }

    renderCheckbox(data) {
        const {id} = data;
        return (
            <CheckBox className="cursor-pointer"
                      checked={this.state.multipleUserSelection.some(el => el.companyUserId === id)}
                      onChange={(e) => this.handleRowCheck(e, data)}/>
        )
    }

    resetMultipleUserSelection() {
        this.setState({multipleUserSelection: []}, () => this.initialFilters())
    }

    renderActivationButtons() {
        const {multipleUserSelection} = this.state;
        const multipleUserSelected = multipleUserSelection.length > 1;

        if (multipleUserSelected) {
            return <StatesButtons
                data={multipleUserSelection}
                // onComplete={(updated_data) => this.multipleStatesUpdated(updated_data)}
                onComplete={() => this.resetMultipleUserSelection()}
            />
        }
    }

    removeFilter(e, filter, id) {
        let n_filter = {};

        if (filter === "roles") {
            n_filter = {
                ...this.state.filter,
                roles: this.state.filter.roles.filter(role => role.id !== id)
            }
        }

        if (filter === "states") {
            n_filter = {
                ...this.state.filter,
                states: this.state.filter.states.filter(state => state.id !== id)
            }
        }

        this.setState({filter: n_filter});
    }

    filterData(data, filter) {
        if (filter === null || filter === undefined) return sortBy(data, "userProfile.user.lastName");
        let result = [];

        forEach(data, (ca) => {
            if (filter.states.length > 0) {
                if (!some(filter.states, (item) => ca.state.startsWith(item.name)))
                    return;
            }

            if (filter.roles.length > 0) {
                if (!some(filter.roles, item => some(ca.userProfile.user.groups, usgr => usgr.name === item.name)))
                    return;
            }

            if (filter.searchTerm !== null && filter.searchTerm !== undefined) {
                const searchTerm = filter.searchTerm;
                const {userProfile: {user}} = ca;
                const match = user.email.includes(searchTerm) || user.firstName.includes(searchTerm) || user.lastName.includes(searchTerm) || user.username.includes(searchTerm);
                if (!match) return;
            }

            result.push(ca);
        });


        return sortBy(result, "userProfile.user.lastName");
    }


    render() {
        const {data: {companyUsers, groups}} = this.props;
        const filteredData = this.filterData(companyUsers, this.state.filter);
        const totalUsers = filteredData.length;
        const columns = [
            {
                className: "cursor-pointer flex items-center justify-start",
                Cell: ({original}) => this.renderCheckbox(original),
                minWidth: 8,
                style: {borderRight: "none", display: "flex", justifyContent: "center", paddingRight: 0},
                headerStyle: {display: "none"},
            },
            {
                className: "cursor-pointer hover:bg-transparent flex items-center",
                Cell: ({original, index}) => this.renderUserCell(original, index),
                minWidth: 100,
                style: {borderRight: "none"},
                headerStyle: {display: "none"},
            },
            {
                // Header: 'Status',
                Cell: ({original, index}) => this.renderStateCell(original, index),
                className: "cursor-pointer flex items-center justify-start",
                minWidth: 50,
                style: {borderRight: "none"},
                headerStyle: {display: "none"},
            },
            {
                // Header: 'Roles',
                Cell: ({original, index}) => this.renderRolesCell(original, index),
                className: "cursor-pointer flex items-center justify-start",
                style: {borderRight: "none"},
                headerStyle: {display: "none"},
            }
        ];
        const sorted = totalUsers > 1 ? [{id: "userProfile.user.firstName", desc: false}] : [];

        return (
            <div className="flex flex-col flex-1">
                <div>

                    <HeaderToolbar title={getText('User management')} subtitle={getText("Manage your application users")}/>

                    <div className="flex flex-row bg-white">

                        <Filters filters={this.state.filter}
                                 filterData={this.state.filterData}
                                 onFilterChanged={(filter) => {
                                     this.setState({filter: filter})
                                 }}
                        />

                        <div className="flex-1 bg-white" style={{flex: 5, borderLeft: "0.5rem solid #f4f4f5"}}>
                            <React.Fragment>
                                <div className="sticky z-50" style={{top: "4rem"}}>
                                    <TableToolbar title="Users">
                                        <div className="flex flex-col">
                                            <div className="flex flex-row items-center ">
                                                <Button
                                                    active={this.state.currentDrawer === DRAWER_TYPES.USER_ADD}
                                                    onClick={() => this.setState({
                                                        currentDrawer: DRAWER_TYPES.USER_ADD,
                                                        drawerParams: {groups: groups}
                                                    })}>
                                                    {getText('Add new')}
                                                </Button>
                                                <Button
                                                    active={this.state.currentDrawer === DRAWER_TYPES.USER_SEND_INVITATION}
                                                    onClick={() => this.setState({
                                                        currentDrawer: DRAWER_TYPES.USER_SEND_INVITATION,
                                                        drawerParams: {}
                                                    })}>
                                                    {getText('Send invitation')}
                                                </Button>
                                                {this.renderActivationButtons()}
                                                {this.state.multipleUserSelection.length > 0 &&
                                                <Button
                                                    onClick={() => this.setState({
                                                        currentDrawer: DRAWER_TYPES.USER_RESET_PW,
                                                        drawerParams: {users: this.state.multipleUserSelection}
                                                    })}>
                                                    {getText('Reset password')}
                                                </Button>}
                                                {this.state.multipleUserSelection.length > 0 &&
                                                <div className="absolute right-0 text-black text-xs">Users
                                                    {getText('selected')}: <strong>{this.state.multipleUserSelection.length}</strong>
                                                </div>}
                                            </div>
                                        </div>
                                    </TableToolbar>
                                    <HeaderFilters filters={this.state.filter} removeFilter={(e, filter, id) => this.removeFilter(e, filter, id)}/>
                                </div>

                                <Table columns={columns}
                                       data={filteredData}
                                       showPagination={true}
                                       defaultSorted={sorted}
                                       sortable={totalUsers > 1}
                                       pageSize={nr_table_rows}
                                       onPageChange={() => {
                                           this.deactivateRows()
                                       }}
                                       onRowClick={() => {}}
                                />

                            </React.Fragment>
                        </div>
                    </div>


                    <DrawerManager config={DrawerManagerConfig}
                                   current={this.state.currentDrawer}
                                   params={this.state.drawerParams}
                                   onCurrentDrawerClose={() => {

                                       this.setState({currentDrawer: '', drawerParams: ''}, () => {
                                           this.initialFilters();
                                           this.state.prevDrawer !== "" && this.state.prevDrawer !== DRAWER_TYPES.USER_EDIT && this.resetMultipleUserSelection();
                                           this.deactivateRows();
                                       })
                                   }}
                    />

                </div>
            </div>

        )
            ;
    }
}

export default compose(
    withSession,
    withQuery({
        query: ALL_COMPANY_USERS,
        variables: (props) => ({
            applicationId: 1,
        }),
    })
)(Users)

