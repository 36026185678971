import React, {useState} from 'react';
import {map, orderBy, filter, includes, find, flatten} from 'lodash';
import GridItem from "./GridItem";

const FeaturesTabView = ({featuresByGroup, handleClick, checkedFeatures}) => {
    const [active, setActive] = useState(-1);
    const allFeatures = flatten(map(featuresByGroup, (group) => group.features));
    const popular_features = orderBy(filter(allFeatures, f => f.priority === 1), 'description');
    const active_group = find(featuresByGroup, (g) => g.id === active);
    const features = active !== -1 ? orderBy(active_group.features, 'description') : popular_features;
    const top_active_style = active === -1 ? "border-l border-t border-r rounded-t border-gray-300 text-gray-900" : "text-gray-900 hover:text-gray-700";
    const top_margin = active === -1 ? "-mb-px" : "";

    const onChangeGroup = (id) => {
        setActive(id);
    };

    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-row sticky top-0 self-start w-full z-50">
                <ul className="flex justify-center border-b border-gray-300 bg-white">
                    <li key="top" className={`${top_margin} cursor-pointer`} onClick={() => onChangeGroup(-1)}>
                        <span
                            className={`bg-white flex py-2 px-4 font-medium text-blue-400 ${top_active_style}`}>Popular</span>
                    </li>
                    {map(featuresByGroup, group => {
                            const active_style = group.id === active ? "border-l border-t border-r rounded-t border-gray-300 text-gray-900" : "text-gray-900 hover:text-gray-700";
                            const margin = group.id === active ? "-mb-px" : "";
                            return (
                                <li key={group.id} className={`${margin} cursor-pointer`}
                                    onClick={() => onChangeGroup(group.id)}>
                                    <span className={`bg-white flex py-2 px-4  ${active_style}`}>{group.description}</span>
                                </li>
                            )
                        }
                    )}
                </ul>
            </div>


            <div className="flex py-2 flex-row flex-wrap">
                {map(features, feature => {
                    return (
                        <GridItem feature={feature}
                                  checked={includes(checkedFeatures, feature.id)}
                                  onClick={handleClick}
                                  key={feature.id}
                        />
                    )
                })}
            </div>

        </div>
    )
};

export default FeaturesTabView;