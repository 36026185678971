import React, {Component} from 'react';
import Template from '../common/Template';
import {required, minLength6, hasUpperCase, hasLowerCase, hasNumber} from '../../general/forms/validators';
import {Form} from 'react-final-form'
import InputField from '../common/InputField';
import gql from "graphql-tag";
import {withMutation} from '../../general'
import {withNavigation} from "../navigation";
import {compose} from "recompose";

const RESET_PASSWORD = gql`
mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
        ok 
    }
  }
`;


class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleSubmit(input) {
        const {mutation, navigate} = this.props;
        const {requestId} = this.props.match.params;

        return mutation.mutate({
            variables: {
                input: {
                    requestId: requestId,
                    password: input.password,
                }
            }
        }, (data => {

            if (data.success) {
                navigate.login();
            }
        }))
    };

    render() {
        const {navigate} = this.props;
        return (
            <Template title="Reset password" subtitle="">
                <Form
                    onSubmit={this.handleSubmit}
                    validate={values => {
                        const errors = {};
                        if (values.password !== values.passwordConfirm) {
                            errors.password = "Passwords are not equal";
                            errors.passwordConfirm = "Passwords are not equal";
                        }
                        return errors;
                    }}
                    render={({handleSubmit, pristine, invalid}) => (
                        <form onSubmit={handleSubmit}>
                            <div className="mt-6">
                                <InputField name="password"
                                            component="input"
                                           type="password"
                                            placeholder="New password"
                                            validators={[required, minLength6, hasUpperCase, hasLowerCase, hasNumber]}
                                />
                            </div>
                            <div className="mt-6">
                                <InputField name="passwordConfirm"
                                            component="input"
                                            type="password"
                                            placeholder="Confirm new password"
                                            validators={[required, minLength6, hasUpperCase, hasLowerCase, hasNumber]}
                                />
                            </div>

                            <div className="text-base mt-6 flex flex-row justify-center items-center">
                                <button className="w-full p-4 rounded bg-blue-500 text-white outline-none shadow-oultine button">
                                    Confirm
                                </button>
                            </div>

                            <div className="text-base mt-6 flex flex-row justify-center items-center">
                                Already have an account? &nbsp; <span className="cursor-pointer"
                                                                   onClick={() => navigate.login()}>Sign in</span>
                            </div>


                        </form>
                    )}
                />
            </Template>
        )
    }
}


export default compose(
    withNavigation,
    withMutation({
        mutation: RESET_PASSWORD,
        noAuthentication: true,
        successMessage: "Password reset ok"
    })
)(ResetPassword);

