import React, {Component} from 'react';

class ErrorIndicator extends Component {
    render() {
        const title = this.props.title ? this.props.title : "Error!";
        const description = this.props.description ? this.props.description : "Something went wrong!";

        return (
            <div className="w-full bg-red-100 border-l-4 border-red-500 text-red-600 p-4">
                <p className="font-medium mb-2 text-base">{title}</p>
                <p className="text-xs">{description}</p>
            </div>
        )
    }
}

export default ErrorIndicator