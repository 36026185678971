import {useContext} from "react";
import {buildTextdomain, TextdomainContext} from "react-gettext";
import {omit} from 'lodash';
import {includes, keys} from 'lodash';


export const languages = [
    {
        name: "Dutch",
        code: "nl",
        default: true,
        flag: require('../asset/img/flags/nl.png')
    },
    {
        name: "English",
        code: "en",
        default: false,
        flag: require('../asset/img/flags/en.png')
    },
    {
        name: "French",
        code: "fr",
        default: false,
        flag: require('../asset/img/flags/fr.png')
    },
    {
        name: "German",
        code: "de",
        default: false,
        flag: require('../asset/img/flags/de.png')
    }
];

export function getBrowserLanguage() {
    return navigator.language.substring(0, 2).toLowerCase();
}

export function getLanguage() {
    const browserLanguage = getBrowserLanguage();
    return getLanguageByLocale(browserLanguage);
}

export const getLanguageByLocale = (locale) => {
    const browserLanguageInList = languages.find(language => language.code.toLowerCase() === locale);
    const browserLanguageFound = browserLanguageInList !== undefined;

    return browserLanguageFound ? browserLanguageInList : languages.find(language => language.default);
};


export const BuildContextValue = (locale) => {
    let catalog = {};
    let plural = 'n != 1';

    if (locale === 'en') return buildTextdomain(catalog, plural);  //default dev language

    try {
        const pofile = require(`./files/${locale}.json`);
        catalog = omit(pofile.locale_data.messages, "");
        const plural_forms_tag = pofile.locale_data.messages[""].plural_forms;
        plural = plural_forms_tag.substring(plural_forms_tag.indexOf('plural=(') + 8, plural_forms_tag.lastIndexOf(');'));
    } catch (e) {
        // do nothing, default pofile will be used
    }

    return {
        ...buildTextdomain(catalog, plural),
        locale: locale,
    }
};

export function useTranslate() {
    const {gettext, ngettext, locale} = useContext(TextdomainContext);


    const handleGetText = (text) => {
        const translation = gettext(text);
        if (typeof translation === "object") {
            if (translation[0] !== "") {
                return translation[0];
            }
        } else if (typeof translation === 'string') {
            return translation;
        }

        return text;

    };

    return {
        getText: (text) => handleGetText(text),
        ngetText: ngettext,
    };
}

export const getText = (text) => {
    return text;
};