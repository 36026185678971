/*global google*/
import React from 'react';
import {
    Drawer,
    withMutation,
} from '../../../general';
import {Form, TextInput, Select, Switch, FormValidators, FormActionButton} from '../../../general/forms-final-form'
import UUID from 'uuid';
import gql from 'graphql-tag';
import ReactStreetview from 'react-streetview';
import PlacesAutocomplete, {
    geocodeByAddress,
} from 'react-places-autocomplete';
import {head, filter, includes, map} from 'lodash';
import {compose} from "recompose";
import {withSession} from "../../../project/session";
import {getText} from "../../../translations";

const formId = UUID.v4();

const searchOptions = {
    location: new google.maps.LatLng(50.84315, 4.36505),
    radius: 500,
    types: ['address']
};

const ADD_ADDRESS = gql`
  mutation AddCompanyAddress($input: CompanyAddressInput!) {
    addCompanyAddress(input: $input) {
        ok
        companyAddress {
            id
            name
            default
            compound
            address {
                id
                addressFull
                address
                address1
                address2
                countryCode
                city
                postCode             
                latitude
                longitude
            }
        }
    }
  }
`;


const GET_COMPANY = gql`
query Company($companyId: ID!) {
  company(companyId: $companyId){
        id
        name
        vatNumber
        primaryEmail
        primaryMobile
        primaryTelephone
        companyaddressSet{
            id
            name
            default
            compound            
            address {
                id
                addressFull
                address
                address1
                address2
                countryCode
                city
                postCode                
                latitude
                longitude
            }
        }
    } 
}
`;


class AddAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {searchAddress: "", address: null};


        this.handleSelect = this.handleSelect.bind(this);
        this.submit = this.submit.bind(this);
    }

    submit(data) {
        const {mutation, session} = this.props;

        mutation.mutate({
                variables: {
                    input: {
                        company: {
                            id: session.companyId,
                        },
                        default: data.default || false,
                        compound: data.compound || false,
                        address: {
                            id: -1,
                            address: data.address,
                            address1: data.address1 || "",
                            address2: data.address2 || "",
                            postCode: data.postCode,
                            city: data.city,
                            countryCode: data.countryCode,
                        }
                    }
                }
            }, (data => {
                    if (data.success) {
                        this.formReset()
                    }
                }
            )
        );
    }


    handleChange = address => {
        this.setState({searchAddress: address});
    };

    getValue(values, value, short = false) {
        return head(map(filter(values, (ac) => includes(ac.types, value)), (v) => (short) ? v.short_name : v.long_name));
    }

    parseAddress(result) {
        const address = this.getValue(result.address_components, 'route');
        const number = this.getValue(result.address_components, 'street_number');
        const postCode = this.getValue(result.address_components, 'postal_code');
        const city = this.getValue(result.address_components, 'locality');
        const countryCode = this.getValue(result.address_components, 'country', 'short');


        return {
            address: `${address} ${(number !== undefined) ? number : ''}`,
            postCode: `${(postCode !== undefined) ? postCode : ''}`,
            city: `${(city !== undefined) ? city : ''}`,
            countryCode: `${(countryCode !== undefined) ? countryCode : ''}`,
            latitude: result.geometry.location.lat(),
            longitude: result.geometry.location.lng(),
        };
    }

    handleSelect = (address, mutators) => {

        geocodeByAddress(address)
            .then(results => {
                const parsedAddress = this.parseAddress(results[0]);
                this.setState({
                    address: parsedAddress,
                    searchAddress: ""
                }, () => {
                    mutators.setValue('address', parsedAddress.address);
                    mutators.setValue('postCode', parsedAddress.postCode);
                    mutators.setValue('city', parsedAddress.city);
                    mutators.setValue('countryCode', parsedAddress.countryCode);
                    mutators.setValue('latitude', parsedAddress.latitude);
                    mutators.setValue('longitude', parsedAddress.longitude);
                })
            })
            .catch(error => console.error('Error', error));
    };


    render() {
        const {mutation: {loading, error, success}, onClose, countries} = this.props;

        const title = () => (
            <div className="flex flex-grow justify-between items-center">
                <div>
                    <FormActionButton formId={formId}>{getText("Save")}</FormActionButton>
                </div>

            </div>
        );

        return (
            <Drawer size="sm"
                    title={title}
                    onClose={onClose}
                    visible={true}>


                <Form id={formId}
                      onSubmit={this.submit}
                      mutators={{
                          setValue: (args, state, utils) => {
                              utils.changeValue(state, args[0], () => args[1])
                          },
                      }}
                      indicators={{
                          loading: loading,
                          error: (error && error.message) ? error.message : '',
                          success: success
                      }}
                      render={({form}) => {

                          if (!this.formReset) {
                              this.formReset = form.reset;
                          }

                          return (
                              <div className="p-2">


                                  <PlacesAutocomplete
                                      searchOptions={searchOptions}
                                      value={this.state.searchAddress}
                                      onChange={this.handleChange}
                                      onSelect={(address) => this.handleSelect(address, form.mutators)}
                                      shouldFetchSuggestions={this.state.searchAddress.length > 6}
                                  >
                                      {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (

                                          <div
                                              className={`w-full p-2 mb-2 bg-white`}>


                                              <input
                                                  style={{width: '100%'}}
                                                  {...getInputProps({
                                                      placeholder: getText("Search Address ..."),
                                                      className: 'location-search-input pt-2 font-medium text-base w-full text-blue-800 bg-white',
                                                  })}
                                                  value={(this.state.searchAddress !== "") ? this.state.searchAddress : ''}
                                              />

                                              <div style={{
                                                  position: 'absolute',
                                                  left: '0.5rem',
                                                  right: '0.5rem',
                                                  padding: '0.5rem',
                                                  paddingTop: 0,
                                                  backgroundColor: 'white',
                                                  zIndex: 50
                                              }}>
                                                  {loading && <div>Loading...</div>}
                                                  {suggestions.map(suggestion => {
                                                      const className = suggestion.active
                                                          ? 'suggestion-item--active'
                                                          : 'suggestion-item';

                                                      const style = suggestion.active
                                                          ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                                                          : {backgroundColor: '#ffffff', cursor: 'pointer'};
                                                      return (
                                                          <div
                                                              {...getSuggestionItemProps(suggestion, {
                                                                  className,
                                                                  style,
                                                              })}
                                                          >
                                                              <span>{suggestion.description}</span>
                                                          </div>
                                                      );
                                                  })}
                                              </div>


                                          </div>


                                      )}
                                  </PlacesAutocomplete>

                                  <div className="flex flex-row justify-between">
                                      <Switch name="default"
                                              label={getText("Default")}
                                              className="mr-1"
                                              disabled={loading}
                                      />

                                      <Switch name="compound"
                                              label={getText("Compound")}
                                              disabled={loading}

                                      />
                                  </div>

                                  <TextInput name="address"
                                             label={getText("Address")}
                                             placeholder={getText("Address")}
                                             disabled={loading}
                                             validators={[FormValidators.required]}
                                  />

                                  <TextInput name="address1"
                                             label={getText("Address 1")}
                                             placeholder={getText("Address 1")}
                                             disabled={loading}
                                  />

                                  <TextInput name="address2"
                                             label={getText("Address 2")}
                                             placeholder={getText("Address 2")}
                                             disabled={loading}
                                  />

                                  <Select name="countryCode"
                                          label={getText("Country")}
                                          placeholder={getText("Country")}
                                          defaultValue="BE"
                                          disabled={loading}
                                          validators={[FormValidators.required]}
                                          options={map(countries, (country) => ({
                                              label: country.name,
                                              value: country.code
                                          }))}
                                  />

                                  <div className="flex flex-row w-full">

                                      <div className="w-full mr-4">
                                          <TextInput name="postCode"
                                                     label={getText("Postal")}
                                                     disabled={loading}
                                                     placeholder={getText("Postal")}
                                                     validators={[FormValidators.required]}
                                          />
                                      </div>

                                      <div className='w-full'>
                                          <TextInput name="city"
                                                     label={getText("City")}
                                                     disabled={loading}
                                                     placeholder={getText("City")}
                                                     validators={[FormValidators.required]}
                                          />
                                      </div>

                                  </div>


                              </div>
                          )
                      }}
                />


                <div className="justify-center" style={{width: 390, height: 390}}>

                    {this.state.address !== null &&
                    <ReactStreetview
                        key={this.state.address.address}
                        apiKey={'AIzaSyCuuvq9FOg5q4deZ_TmcGMhX1SadPmdkII'}
                        streetViewPanoramaOptions={{
                            position: {lat: this.state.address.latitude, lng: this.state.address.longitude},
                            pov: {heading: 100, pitch: 0},
                            zoom: 1
                        }}
                    />}
                </div>
            </Drawer>
        )
    }
}


export default compose(
    withSession,
    withMutation({
        mutation: ADD_ADDRESS,
        update: (props
            ,
                 cache
            , {
                     data: {addCompanyAddress}
                 }) => {
            const variables = {
                companyId: props.session.companyId
            };

            const {company} = cache.readQuery({
                query: GET_COMPANY,
                variables: variables,
            });

            cache.writeQuery({
                query: GET_COMPANY,
                variables: variables,
                data: {
                    company: {
                        ...company,
                        companyaddressSet: company.companyaddressSet.concat([addCompanyAddress.companyAddress])
                    },
                }
            });
        }
        ,
        successMessage: getText("Changes were made successfully")
    })
)(AddAddress);

