import React from 'react';
import {Field} from "react-final-form";
import {composeValidators} from '../forms/validators';
import {default as FormMaskedInput} from "../forms/MaskedInput";

const MaskedInput = ({name, label, placeholder, validators, disabled, className, dataTip, dataFor, inputClassName, showBorder}) => {
    return (
        <Field name={name}
               validate={(validators) ? composeValidators(...validators) : false}
               data-tip={dataTip}
               data-for={dataFor}>

            {({input, meta}) => (
                <FormMaskedInput name={name}
                               label={label}
                               {...input}
                               placeholder={placeholder}
                               disabled={disabled}
                               className={className}
                               inputClassName={inputClassName}
                               showBorder={showBorder}
                               error={meta.error}
                                 hasError={meta.touched && meta.error !== undefined}
                />
            )}
        </Field>
    );
};

export default MaskedInput;


