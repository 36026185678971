import gql from "graphql-tag";

export const MASTER_MAIN_CONFIG = gql`
query MasterMainConfig($companyId: Int) {
  config(companyId: $companyId) {
    customerCode,
    companyId,
  }
}
`;




