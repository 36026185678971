import React, {Component} from 'react';
import {Button} from "../../../general";
import {withMutation} from "../../../general";
import gql from "graphql-tag";
import {getText} from "../../../translations";

const UPDATE_MULTIPLE_STATES = gql`
    mutation UpdateMultipleStates($input: [UpdateMultipleStatesInput]!){
        updateMultipleStates(input: $input){
            ok
            companyUsers {
                id
             state  
             company {
                id
             }   
             userProfile {
              id,
              user {         
                id
                email
                
              }
            }       
            }
            
        }
    }
`;

class StatesButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDeactivating: false,
            isActivating: false
        }
    }

    doMutation(data, type) {
        const {mutation, onComplete} = this.props;
        this.setState({isActivating: type === "activation", isDeactivating: type === "deactivation"}, () => {
            mutation.mutate({
                variables: {
                    input: data
                }
            }, (result) => {
                if (result.success) {
                    this.setState({isActivation: false, isDeactivating: false}, () => {
                        onComplete()
                    });
                } else {
                    this.setState({isActivation: false, isDeactivating: false});
                }
            })
        })
    }

    handleDeactivation(e, state) {
        const {data} = this.props;
        let updated_data = [];
        data.forEach(el => updated_data.push({companyUserId: parseInt(el.companyUserId), email: el.email, newState: state + el.state.replace("LOCKED_", '')}));
        this.doMutation(updated_data, "deactivation");
    }

    handleActivation(e, state) {
        const {data} = this.props;
        let updated_data = [];
        data.forEach(el => updated_data.push({companyUserId: parseInt(el.companyUserId), email: el.email, newState: state}));
        this.doMutation(updated_data, "activation");
    }

    render() {
        const {data} = this.props;

        if (data.every(el => el.state.includes("LOCKED_"))) {
            return <Button loading={this.state.isActivating} onClick={e => this.handleActivation(e, 'APPROVED')}>{getText('Activate')}</Button>
        } else if (data.every(el => el.state === "APPROVED")) {
            return <Button loading={this.state.isDeactivating} onClick={e => this.handleDeactivation(e, 'LOCKED_')}>{getText('Deactivate')}</Button>
        } else {
            return (
                <React.Fragment>
                    {<Button loading={this.state.isActivating} onClick={e => this.handleActivation(e, 'APPROVED')}>{getText('Activate all')}</Button>}
                    {<Button loading={this.state.isDeactivating} onClick={e => this.handleDeactivation(e, 'LOCKED_')}>{getText('Deactivate all')}</Button>}
                </React.Fragment>
            )
        }
    }
}

export default withMutation({
    mutation: UPDATE_MULTIPLE_STATES,
    successMessage: getText('Changes were made successfully')
})(StatesButtons);