import React from 'react';
import {withMutation} from "../../../../general";
import {
    Form,
    TextInput,
    Select,
    CheckBox,
    FormValidators,
} from '../../../../general/forms-final-form'
import gql from "graphql-tag";
import {GET_COMPANY_ADDRESSES} from "./SlotsConfiguration"
import {compose} from "recompose";
import {withSession} from "../../../../project/session";
import {getText} from "../../../../translations";

const ADD_NEW_SLOT = gql`
  mutation createSlot($input: SlotInput!) {
    createSlot(input: $input) {
        ok
        slot {
            address {
                id
                addressFull
                countryCode
                city
                postCode
            }
            id
            area
            zone
            aisle
            side
            level
            position
            disabled
            col
            printEntry
        }
    }
  }
`;


const initialSlot = (defaultValues) => {
    const initial = {
        id: "",
        area: null,
        zone: null,
        aisle: null,
        side: null,
        col: null,
        level: null,
        position: null,
        printEntry: false,
        disabled: false,
        colTo: null,
        levelTo: null,
        positionTo: null
    };
    return {...initial, ...defaultValues};
};


export class AddSlot extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            slot: initialSlot(props.data),
            saved: false,
            hasError: false,
            errorMessage: ""
        }

        this.handleSaveSlot = this.handleSaveSlot.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data !== this.props.data) {
            this.setState({slot: nextProps.data})
        }
    }

    handleSaveSlot(data) {
        const {onComplete, mutation} = this.props;
        const {address: {id}} = this.props.data;
        const slot = {
            ...data,
            address: id
        };

        mutation.mutate({variables: {input: slot}})
            .then(res => {
                if (res.data.createSlot.ok) {
                    this.setState({
                        hasError: false,
                        errorMessage: "",
                        saved: true,
                        slot: initialSlot()
                    }, () => onComplete(getText('The changes are saved successfully!')));
                }
            })
            .catch(err => {
                let error_msg = err.message.replace("GraphQL error: ", '');
                this.setState({hasError: true, saved: false, errorMessage: error_msg});
                throw(error_msg);
            })
    }

    render() {
        const {loading, error, success} = this.props.mutation;
        const {address: {addressFull, postCode, city}} = this.props.data;

        return (
            <Form id='add_slot_form'
                  onSubmit={this.handleSaveSlot}
                  initialValues={this.state.slot}
                  indicators={{
                      loading: loading,
                      error: (error && error.message) ? error.message : '',
                      success: success
                  }}
                  render={({values}) =>
                      <div>
                          <div
                              className="font-medium bg-white text-lg mb-4 border-b border-t border-gray-200 pt-2 pb-2 pl-2 ">
                              <i className="fal fa-map-marker-check mr-2 text-orange-500"/>{addressFull}, {postCode} {city}
                          </div>

                          <div className="p-2">

                              <TextInput name="area"
                                         label="Area"
                                         placeholder="Area"
                                         disabled={loading}
                              />

                              <TextInput name="zone"
                                         label={`${getText("Zone")}*`}
                                         placeholder={getText("Zone")}
                                         disabled={loading}
                                         validators={[FormValidators.required]}
                              />

                              <TextInput name="aisle"
                                         label={getText("Aisle")}
                                         placeholder={getText("Aisle")}
                                         disabled={loading}
                              />

                              <Select name="side"
                                      label={getText("Side")}
                                      placeholder={getText("Side")}
                                      disabled={loading}
                                      options={[
                                          {
                                              value: "",
                                              label: getText('Not applicable')
                                          },
                                          {
                                              value: "N",
                                              label: getText('North')
                                          },
                                          {
                                              value: "E",
                                              label: getText('East')
                                          },
                                          {
                                              value: "W",
                                              label: getText('West')
                                          },
                                          {
                                              value: "S",
                                              label: getText('South')
                                          },
                                      ]}
                              />

                              <div className="flex flex-row justify-between">
                                  <div className="mr-4">
                                      <TextInput name="col"
                                                 label={getText("Column")}
                                                 placeholder={getText("Column")}
                                                 disabled={loading}
                                      />
                                  </div>

                                  <TextInput name="colTo"
                                             label={getText("To column")}
                                             placeholder={getText("To column")}
                                             disabled={loading}
                                             validators={[FormValidators.isEqualOrLower(values.col)]}
                                  />
                              </div>

                              <div className="flex flex-row justify-between">
                                  <div className="mr-4">
                                      <TextInput name="level"
                                                 label={getText("Level")}
                                                 placeholder={getText("Level")}
                                                 disabled={loading}
                                      />
                                  </div>
                                  <TextInput name="levelTo"
                                             label={getText("To level")}
                                             placeholder={getText("To level")}
                                             disabled={loading}
                                             validators={[FormValidators.isEqualOrLower(values.level)]}
                                  />
                              </div>

                              <div className="flex flex-row justify-between">
                                  <div className="mr-1">
                                      <TextInput name="position"
                                                 label={getText("Position")}
                                                 placeholder={getText("Position")}
                                                 disabled={loading}
                                      />
                                  </div>
                                  <TextInput name="positionTo"
                                             label={getText("To position")}
                                             placeholder={getText("To position")}
                                             disabled={loading}
                                             validators={[FormValidators.isEqualOrLower(values.position)]}
                                  />
                              </div>

                              <CheckBox name="disabled"
                                        label={getText("Disabled")}
                                        disabled={loading}
                              />

                              <CheckBox name="printEntry"
                                        label={getText("Print at entry")}
                                        disabled={loading}
                              />
                          </div>
                      </div>
                  }
            />
        )
    }
}

export default compose(
    withSession,
    withMutation({
        mutation: ADD_NEW_SLOT,
        awaitRefetchQueries: true,
        refetchQueries: (props) => [{
            query: GET_COMPANY_ADDRESSES,
            variables: {companyId: props.session.companyId}
        }]
    })
)(AddSlot);


// update={(cache, { data: { createSlot } }) => {
//
//     const { companyAddresses } = cache.readQuery({ query: GET_COMPANY_ADDRESSES, variables: { companyId: this.props.session.company.id } });
//
//     // let test = companyAddresses.filter(address => address.compound)
//     let n_addresses = [];
//     companyAddresses.map(address => {
//         if(address.address.id == createSlot.slot.address.id){
//             address.address.slotSet.push({
//                 id: createSlot.slot.id,
//                 area: createSlot.slot.area,
//                 zone: createSlot.slot.zone,
//                 aisle: createSlot.slot.aisle,
//                 side: createSlot.slot.side,
//                 level: createSlot.slot.level,
//                 position: createSlot.slot.position,
//                 disabled: createSlot.slot.disabled,
//                 col: createSlot.slot.col,
//                 printEntry: createSlot.slot.printEntry,
//
//             });
//             n_addresses.push(address)
//         }else{
//             n_addresses.push(address)
//         }
//     });
//
//     debugger;
//
//     cache.writeQuery({
//         query: GET_COMPANY_ADDRESSES,
//         variables: { companyId: this.props.session.company.id },
//         data: { companyAddresses: companyAddresses[1].address.slotSet.concat(createSlot.slot) },
//     });
//
//
// }}
