import React from 'react';
import './TreeTable.css';
import Header from './Header'
import Body from './Body'
// inspired by https://github.com/PhillBeck/react-treegrid

const TreeTable = ({options, data}) => (
    <table className="table table-auto tableBodyScroll ml-4 flex flex-col flex-1">
        <Header options={options}/>

        <Body data={data}
              options={options}
        />

    </table>
);

export default TreeTable;