import React from 'react';
import {useTranslate} from "../../../../../translations";
import {TextLabel} from "../../../../../general/forms";
import {formatAmount, formatDate, stringFormat} from "../../../../../general/utils";

export type VehicleType = {
    kilometers: number,
    catalogPrice: number,
    catalogPriceWithOptions: number,
    modelStartDate: string,
    modelStopDate: string,
};

type ValuationType = {
    averagePriceB2B: number,
    averagePriceB2C: number,
    averageKm: number,
    ageAmount: number,
    kmAmount: number,
}

const EuroTaxValuation = ({vehicle, valuation}: { vehicle: VehicleType, valuation: ValuationType }) => {
    const {getText} = useTranslate();
    const totalB2B = (valuation.averagePriceB2B + valuation.ageAmount + valuation.kmAmount);
    const totalB2C = (valuation.averagePriceB2C + valuation.ageAmount + valuation.kmAmount);

    return (
        <React.Fragment>
            <div className="">
                <div className="flex flex-row">
                    <div className="w-1/2 mr-4">
                        <TextLabel label={getText('Model start date')}
                                   value={formatDate(vehicle.modelStartDate)}
                                   className="p-1"
                                   marginBottom={2}
                                   showBorder={false}
                        />
                    </div>

                    <div className="w-1/2 mr-4">
                        <TextLabel label={getText("Catalog price")}
                                   value={formatAmount(vehicle.catalogPrice)}
                                   className="p-1 text-right"
                                   textClassName="text-right"
                                   marginBottom={2}
                                   showBorder={false}
                        />
                    </div>
                </div>

                <div className="flex flex-row">
                    <div className="w-1/2 mr-4">
                        <TextLabel label={getText('Model stop date')}
                                   value={formatDate(vehicle.modelStopDate)}
                                   className="p-1"
                                   marginBottom={2}
                                   showBorder={false}
                        />
                    </div>

                    <div className="w-1/2 mr-4">
                        <TextLabel label={getText("Catalog price with options")}
                                   value={formatAmount(vehicle.catalogPriceWithOptions)}
                                   className="p-1 text-right"
                                   textClassName="text-right"
                                   marginBottom={2}
                                   showBorder={false}
                        />
                    </div>
                </div>
            </div>

            <div className="bg-gray-100 pl-2">
                <div className="flex flex-row">
                    <div className="w-3/5 flex flex-col mb-2"></div>
                    <div className="w-1/5 flex items-center justify-center text-sm font-medium bg-blue-200 p-1 mr-2"> B2B</div>
                    <div className="w-1/5 flex items-center justify-center text-sm font-medium bg-orange-200 p-1"> B2C</div>
                </div>

                <div className="flex flex-row">
                    <div className="w-3/5 flex flex-col mb-2">
                        <span className='flex flex-row mb-1'>{getText("Average price")}</span>
                        <span className='flex flex-row text-3xs text-gray-600'>{stringFormat(getText("Based on {0} km (avg)"), formatAmount(valuation.averageKm))}</span>
                    </div>

                    <div className="w-1/5 flex items-center justify-end font-medium pr-2 mr-2 bg-blue-200">
                        {formatAmount(valuation.averagePriceB2B)}
                    </div>

                    <div className="w-1/5 flex items-center justify-end font-medium pr-2 bg-orange-200">
                        {formatAmount(valuation.averagePriceB2C)}
                    </div>
                </div>

                <div className="flex flex-row ">
                    <div className="w-3/5 flex flex-col mb-2">
                        <span className='flex flex-row mb-1'>{getText("Age valuation")}</span>
                        <span className='flex flex-row text-3xs text-gray-600'>{getText("Based on actual age")}</span>
                    </div>

                    <div className="w-1/5 flex items-center justify-end text-xs pr-2 mr-2 bg-blue-200">
                        {`${(valuation.ageAmount >= 0) ? '+' : '-'} ${formatAmount((valuation.ageAmount < 0) ? (valuation.ageAmount * -1) : valuation.ageAmount)}`}
                    </div>

                    <div className="w-1/5 flex items-center justify-end text-xs pr-2 bg-orange-200">
                        {`${(valuation.ageAmount >= 0) ? '+' : '-'} ${formatAmount((valuation.ageAmount < 0) ? (valuation.ageAmount * -1) : valuation.ageAmount)}`}
                    </div>
                </div>

                <div className="flex flex-row">
                    <div className="w-3/5 flex flex-col mb-2">
                        <span className='flex flex-row mb-1'>{getText('Km valuation')}</span>
                        <span className='flex flex-row text-3xs text-gray-600'>{stringFormat(getText("Based on {0} actual kilometers"), formatAmount(vehicle.kilometers))}</span>
                    </div>

                    <div className="w-1/5 flex items-center justify-end text-xs pr-2 mr-2 border border-l-0 border-t-0 border-r-0 border-blue-800 bg-blue-200">
                        {`${(valuation.kmAmount >= 0) ? '+' : '-'}  ${formatAmount((valuation.kmAmount < 0) ? (valuation.kmAmount * -1) : valuation.kmAmount)}`}
                    </div>

                    <div className="w-1/5 flex items-center justify-end text-xs pr-2 border border-l-0 border-t-0 border-r-0 border-orange-800 bg-orange-200">
                        {`${(valuation.kmAmount >= 0) ? '+' : '-'} ${formatAmount((valuation.kmAmount < 0) ? (valuation.kmAmount * -1) : valuation.kmAmount)}`}
                    </div>
                </div>

                <div className="flex flex-row">
                    <div className="w-3/5 flex text-right"></div>

                    <div className="w-1/5 flex items-center justify-end font-medium bg-blue-200 text-blue-800 p-2 mr-2 text-sm">
                        {formatAmount(totalB2B)}
                    </div>

                    <div className="w-1/5 flex items-center justify-end font-medium bg-orange-200 text-orange-800 p-2 text-sm">
                        {formatAmount(totalB2C)}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default EuroTaxValuation
