import React from "react";
import Project from './Project';
import gql from "graphql-tag";
import {ErrorBoundary, withQuery} from "../general";
import {find} from "lodash";
import DefaultContent from "./common/DefaultContent";
import {checkApplicationBlockExists, checkApplicationExists} from "./applications/common";
import withNavigation from "./navigation/withNavigation";
import {compose} from "recompose";
import {client} from "../apollo";
import {withAuthorization} from "./authentication";
import {navigate} from "./navigation/utils";

const GET_APPLICATIONS = gql`
  {    
    applications @client {
      id
      name
      title     
      blocks {
        id
        name
        title
        icon
        security
        badgeCount
      } 
    }  
    
    currentApp @client {
      id
      name
      title
      color
      blocks {
        id
        name
        title
        icon
        security
        badgeCount
      }
    }
    
    currentBlock @client {
        id
        name
        title
        icon
        security
        badgeCount
    }
    
  }
`;

class ProjectContainer extends React.Component {
    constructor(props) {
        super(props);
        this.setApplicationBlock = this.setApplicationBlock.bind(this);
        this.fillDefaultCacheForApplication = this.fillDefaultCacheForApplication.bind(this);

    }

    fillDefaultCacheForApplication(application) {
        const {client, config} = this.props;

        const applicationConfig = find(config.modules, function (module) {
            return module.moduleName === application;
        });

        if (application && applicationConfig && applicationConfig.cacheQuery && applicationConfig.cacheQuery !== "") {
            client.query({
                query: applicationConfig.cacheQuery,
                fetchPolicy: 'network-only',
            }).then(r => {
            });
        }
    }

    setApplicationBlock(application, block) {
        const {client, navigate, data: {applications}} = this.props;

        const checked_app = checkApplicationExists(applications, application);
        const checked_block = checkApplicationBlockExists(applications, application, block);

        client.writeData({
            data: {
                currentApp: checked_app,
                currentBlock: checked_block,
            }
        });

        this.fillDefaultCacheForApplication(application);

        if (application !== checked_app.name || block !== checked_block.name) {
            navigate.application(checked_app.name, checked_block.name);
        }
    }

    componentWillMount() {
        const {match: {params: {application, block}}} = this.props;
        this.setApplicationBlock(application, block);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {match: {params: {application, block}}} = this.props;

        if (nextProps.match.params.application !== application || nextProps.match.params.block !== block) {
            this.setApplicationBlock(nextProps.match.params.application, nextProps.match.params.block);
        }
    }

    getPanelComponentForBlock(config, application, block) {
        const applicationConfig = find(config.modules, function (module) {
            return module.moduleName === application;
        });

        return applicationConfig.blocks[block].panelComponent
    }

    getContentComponentBlock(config, application, block) {
        const applicationConfig = find(config.modules, function (module) {
            return module.moduleName === application;
        });

        if (applicationConfig.blocks[block].contentComponent === undefined || applicationConfig.blocks[block].contentComponent === null) {
            return <DefaultContent/>;
        }
        return applicationConfig.blocks[block].contentComponent
    }


    render() {
        const {data: {currentApp, currentBlock}, config} = this.props;

        return (
            <ErrorBoundary>
                <Project application={currentApp}
                         block={currentBlock}
                         panelComponent={this.getPanelComponentForBlock(config, currentApp.name, currentBlock.name)}
                         contentComponent={this.getContentComponentBlock(config, currentApp.name, currentBlock.name)}
                />
            </ErrorBoundary>
        )
    }
}

//const securityCondition = (modules, roles, currentModule) => modules.includes(currentModule);
const securityCondition = (session) => {
    if (session.brandId > 0 && session.subsidiaryId > 0 && session.brandId > 0) {
        return true
    }
    navigate.selectCompany();
};

export default compose(
    withNavigation,
    withAuthorization(securityCondition),
    withQuery({query: GET_APPLICATIONS})
)(ProjectContainer);

