import React from 'react';
import "./loadingIndicator.css";

const LoadingIndicator = ({color, overlay}) => {
    const style = {border: `4px solid ${color}`};


    if (!overlay) {
        return (
            <div className="lds-ripple" style={{position: "absolute", zIndex: 9999}}>
                <div style={style}/>
                <div style={style}/>
            </div>
        )
    } else {
        return (
            <div className="flex justify-center z-50 items-center w-full absolute left-0 right-0 top-0 bottom-0"
                 style={{backgroundColor: "rgba(0, 0, 0, 0.1)"}}>
                <div className="lds-ripple" style={{position: "absolute", zIndex: 9999}}>
                    <div style={style}/>
                    <div style={style}/>
                </div>
            </div>
        )
    }

};


LoadingIndicator.defaultProps = {
    overlay: false,
    color: "#000",
};

export default LoadingIndicator;


