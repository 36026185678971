import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

export default class Pagination extends React.Component {
    constructor(props) {
        super(props);

        this.changePage = this.changePage.bind(this);

        this.state = {
            visiblePages: this.getVisiblePages(null, props.pages)
        };
    }

    static propTypes = {
        pages: PropTypes.number,
        page: PropTypes.number,
        PageButtonComponent: PropTypes.any,
        onPageChange: PropTypes.func,
        previousText: PropTypes.string,
        nextText: PropTypes.string
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.pages !== nextProps.pages) {
            this.setState({
                visiblePages: this.getVisiblePages(null, nextProps.pages)
            }, () => this.changePage(nextProps.page + 1));
        }


    }

    filterPages = (visiblePages, totalPages) => {
        return visiblePages.filter(page => page <= totalPages);
    };

    getVisiblePages = (page, total) => {
        if (total < 7) {
            return this.filterPages([1, 2, 3, 4, 5, 6], total);
        } else {
            if (page % 5 >= 0 && page > 4 && page + 2 < total) {
                return [1, page - 1, page, page + 1, total];
            } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
                return [1, total - 3, total - 2, total - 1, total];
            } else {
                return [1, 2, 3, 4, 5, total];
            }
        }
    };

    changePage(page) {
        const activePage = this.props.page + 1;
        const {pages} = this.props;

        if (page === activePage) {
            return;
        }

        const visiblePages = this.getVisiblePages(page, pages);

        this.setState({
            visiblePages: this.filterPages(visiblePages, pages)
        });

        this.props.onPageChange(page - 1);
    }

    render() {
        let {visiblePages} = this.state;
        const activePage = this.props.page + 1;
        const total_users = this.props.data.length;
        let current = parseInt(this.props.pageSize) * parseInt(activePage);
        current = current > total_users ? total_users : current;

        return (
            <div className="table-pagination pt-0">
                <section>
                    <nav role="navigation" className="flex justify-center relative py-1" style={{minHeight: 40}}>
                        <div className="absolute left-0" style={{top: "50%", transform: 'translateY(-50%)'}}>Results: <span className="font-medium">{current} / {total_users}</span></div>
                        <ul className={`${visiblePages.length === 1 ? "hidden" : "cd-pagination pl-0"}`}>
                            <li className="button">
                                <div
                                    className={`${activePage === 1 && 'disabled'} cursor-pointer hover:bg-gray-100 text-gray-800`}
                                    onClick={() => {
                                        if (activePage === 1) return;
                                        this.changePage(activePage - 1);
                                    }}>
                                    {this.props.previousText}
                                </div>
                            </li>

                            {visiblePages.map((page, index, array) => {
                                return (
                                    <React.Fragment key={page}>
                                        {array[index - 1] + 2 < page && <li><span>...</span></li>}
                                        <li key={page} className={`${activePage !== page ? 'cursor-pointer hover:bg-gray-100' : ''}`} onClick={this.changePage.bind(null, page)}>
                                            <div className={`${activePage === page ? 'bg-green-100 font-medium' : ''} text-gray-800`}>
                                                {page}
                                            </div>
                                        </li>
                                    </React.Fragment>
                                );

                            })}

                            <li className="button">
                                <div
                                   className={`${activePage === this.props.pages && 'disabled'} cursor-pointer hover:bg-gray-100 text-gray-800`}
                                   onClick={() => {
                                       if (activePage === this.props.pages) return;
                                       this.changePage(activePage + 1);
                                   }}>
                                    {this.props.nextText}
                                </div>
                            </li>
                        </ul>
                    </nav>
                </section>
            </div>
        );
    }
}
