import React from 'react';

const B2B = () => {

    return (
        <div>

            <h1>B2B</h1>

            <p> For manager only </p>

        </div>
    )
};

export default B2B
