import React from 'react';
import * as PropTypes from "prop-types";

const Switch = ({label, checked, onChange, showBorder, hasError, required, hasFocus, className}) => {

    const borderClass = (showBorder) ? `border ${(hasError) ? "border-red-500" : ((hasFocus) ? "border-blue-500" : (required && !checked) ? "border-blue-200" : "border-gray-200")} border-t-0 border-l-0 border-r-0` : '';

    return (
        <div className={`pretty p-switch w-full pt-2 pb-2 text-gray-900 bg-white ${borderClass} ${className}`}>
            <input type="checkbox"
                   checked={checked}
                   onChange={(e) => {
                       onChange(e.target.checked)
                   }}

            />
            <div className="state p-custom">
                <label>{(label && label !== '') ? label : ''}</label>
            </div>
        </div>
    )
}

Switch.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

Switch.defaultProps = {
    label: "",
    checked: false,
    onChange: null,
};

export default Switch;


