import React from 'react';

class FilterItem extends React.Component {

    render() {
        const {text, value, count, selected, onClick} = this.props;
        return (
            <div key={value} className={`flex flex-row items-center justify-between cursor-pointer hover:bg-gray-100 px-2 py-1 ${selected && "bg-grey-lightest"}`} onClick={() => onClick(value)}>
                <div className="capitalize">{text.includes("LOCKED_") ? "Locked" : text.toLowerCase()}</div>
                <div className={`font-medium p-1 ${selected && "bg-gray-200 border-gray-200 text-black"}`}>{count}</div>
            </div>
        );
    }
}

export default FilterItem;
