import React, {useState} from 'react';
import Buttons from "./Buttons";
import {DamagesComponent} from '../../components';
import Summary from "./Summary";

const DamagesStep = ({onNext, onPrev, onUpdate, onStepClick, wizard, data}) => {
    const [collapsed, setCollapsed] = useState(true);

    return (
        <div className="flex h-full w-full">
            <div className="damages-summary-panel relative overflow-hidden" style={{width: collapsed ? "55px" : "25%"}}>
                <Summary data={wizard}
                         onStepClick={onStepClick}
                         collapsed={collapsed}
                         onCollapse={() => setCollapsed(!collapsed)}/>
            </div>


            <div className={`flex flex-col flex-1 bg-white p-1 relative p-2`}
                 style={{width: collapsed ? "calc(100% - 55px)" : "75%"}}>
                <div className="flex flex-1 overflow-scroll">

                        <DamagesComponent dispatch={data.dispatch}
                                 damages={data.inspection.vehicle.damages.damagesById}
                                 noDamages={data.inspection.vehicle.damages.noDamages}
                                 pictures={data.inspection.vehicle.pictures}
                                 damageDetailsRequired={data.settings.customerSettings.INSPECTION_DAMAGE_DETAIL_REQUIRED}
                                 locations={data.damageLocations}
                                 detailDamageId={data.inspection.vehicle.damages.detailDamageId}
                        />

                </div>

                <Buttons onNext={onNext}
                         onPrev={onPrev}
                         data={wizard}
                />
            </div>

        </div>
    )
};

export default DamagesStep;