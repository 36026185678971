import React, {useEffect, useRef} from 'react';
import './Project.css';
import {ApplicationsMenu, ApplicationsBlocksMenu} from './applications';
import SignOutButton from './common/SignOutButton';
import LanguagesComp from "../translations/LanguagePicker";
import useSession from "./session/useSession";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import AppUpdate from "./AppUpdate";
import {Drawer} from "../general";

const GET_PROJECT_DATA = gql`
  query getProjectData {
     build {
       version
     }      
  }
`;

const Project = ({application, block, panelComponent, contentComponent, match}) => {
    const session = useSession();
    const dropRef = useRef(null);
    const {data: projectData} = useQuery(GET_PROJECT_DATA);

    useEffect(() => {
        dropRef.current.addEventListener('dragenter', e => {
            e.preventDefault();
            e.stopPropagation();
        });
        dropRef.current.addEventListener('dragleave', e => {
            e.preventDefault();
            e.stopPropagation();
        });
        dropRef.current.addEventListener('dragover', e => {
            e.preventDefault();
            e.stopPropagation();
        });
        dropRef.current.addEventListener('drop', e => {
            e.preventDefault();
            e.stopPropagation();


            return () => {
                dropRef.current.addEventListener('dragenter', e => {
                    e.preventDefault();
                    e.stopPropagation();
                });
                dropRef.current.addEventListener('dragleave', e => {
                    e.preventDefault();
                    e.stopPropagation();
                });
                dropRef.current.addEventListener('dragover', e => {
                    e.preventDefault();
                    e.stopPropagation();
                });
                dropRef.current.addEventListener('drop', e => {
                    e.preventDefault();
                    e.stopPropagation();
                });
            }
        });
    });

    return (
        <div className="app-container" ref={dropRef}>
            <div style={{height: 5, backgroundColor: application.color}}/>

            <div className="app-header-menu">

                <ApplicationsMenu/>


                <div className="header-menu">

                    <div className="global-search-wrapper" style={{width: 300, marginRight: 20}}>
                        {/*here was autocomplete searchbar*/}
                    </div>

                    <AppUpdate/>

                    <div className="flex flex-row items-center">
                        {/*here was the language menu selector*/}
                        <div className="mr-10">
                            <LanguagesComp/>
                        </div>
                        <div className="text-white mr-10">
                            <SignOutButton/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="app-content-container">

                <ApplicationsBlocksMenu/>


                {panelComponent &&
                <div className="content-container-panel">
                    {
                        React.cloneElement(
                            panelComponent,
                            {
                                block: block,
                                session: session,
                                match: match,
                            })
                    }
                </div>}


                <div className="content-container-content">
                    {
                        React.cloneElement(
                            contentComponent,
                            {
                                block: block,
                                session: session,
                                match: match,
                            })
                    }

                    {/*<div className="collapsiblePanel absolute top-0 right-0 bottom-0 bg-white flex flex-col z-50"*/}
                    {/*     style={{*/}
                    {/*         width: 400,*/}
                    {/*         right: 0,*/}
                    {/*         height: "calc(100vh - 75px)",*/}
                    {/*         overflow: 'auto',*/}
                    {/*         boxShadow: "rgba(0, 0, 0, 0.15) -2px 0 8px",*/}
                    {/*         marginTop: 47*/}
                    {/*     }}>*/}

                    {/*    <span>sidebar</span>*/}
                    {/*</div>*/}


                </div>
            </div>


            <div id="footer" className="module footer">
                <div className="headersub">
                    <div className="float-left text-gray-500">

                        &copy; Copyright {(new Date()).getFullYear()}

                        <a href="http://www.autralis.net"
                           title=""
                           className="text-blue-500 ml-2">
                            Autralis
                        </a>

                        {projectData &&
                        <span className="text-3xs text-gray-500 ml-2">
                            {`(build ${projectData.build.version})`}
                        </span>}
                    </div>


                    <div className="float-right text-gray-500">
                        Helpdesk : <a href="mailto:support@usedcars.center"
                                      className="text-blue-500">
                        support@usedcars.center
                    </a> &nbsp; - &nbsp;
                        NL : <a href="callto:0032093200023" className="text-blue-500">+32 (0)9 320 00
                        23</a> &nbsp; - &nbsp;
                        FR : <a href="callto:0032093200033" className="text-blue-500">+32 (0)9 320 00
                        33</a> &nbsp; - &nbsp;
                        WhatsApp : <a
                        href="https://api.whatsapp.com/send?phone=32472060140"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500">32 (0)472 06 01 40</a>
                    </div>
                    <div style={{clear: 'both'}}></div>
                </div>
            </div>
        </div>
    );
};

export default Project;
